export const GoalStatus = Object.freeze({
  PENDING: "PENDING" as "PENDING",
  PASSED: "PASSED" as "PASSED",
  FAILED: "FAILED" as "FAILED"
});

export const GoalFilter = Object.freeze({
  ALL: "ALL" as "ALL",
  PENDING: "PENDING" as "PENDING",
  PAST: "PAST" as "PAST"
});

export const SkillCategory = Object.freeze({
  TECHNICAL: "TECHNICAL" as "TECHNICAL",
  PERSONAL: "PERSONAL" as "PERSONAL",
  OTHER: "OTHER" as "OTHER"
});
