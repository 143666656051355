import React from "react";
import Button from "ui/common/Button";
import styles from "./TopLevelSkills.module.scss";
import { Col } from "reactstrap";

type TopLevelSkillsProps = {
  technicalSkills: string[];
  personalSkills: string[];
  selectedTopSkill: number;
  onChangeTopSkill: (selected: number) => void;
};
export default function TopLevelSkills(props: TopLevelSkillsProps) {
  const {
    technicalSkills,
    personalSkills,
    onChangeTopSkill,
    selectedTopSkill
  } = props;
  return (
    <Col className={styles.topSkills}>
      <div className={styles.wrapper}>
        <div className={`${styles.rail} ${styles.technical}`}>
          {technicalSkills.map((item, index) => (
            <Button
              className={`${styles.box} ${
                selectedTopSkill === index ? styles.highlighted : ""
              }`}
              title={item}
              onClick={() => onChangeTopSkill(index)}
              key={index}
            >
              {item}
            </Button>
          ))}
        </div>
        <div className={`${styles.rail} ${styles.personal}`}>
          {personalSkills.map((item, index) => {
            const cumulativeIndex = technicalSkills.length + index;
            return (
              <Button
                className={`${styles.box} ${
                  selectedTopSkill === cumulativeIndex ? styles.highlighted : ""
                }`}
                title={item}
                onClick={() => onChangeTopSkill(cumulativeIndex)}
                key={index}
              >
                {item}
              </Button>
            );
          })}
        </div>
      </div>
    </Col>
  );
}

TopLevelSkills.defaultProps = {
  selectedTopSkill: 1,
  handleChangeTopSkill: () => {}
};
