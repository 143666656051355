import React from "react";
import { FormGroup, Input } from "reactstrap";
import styles from "./SearchBarSkills.module.scss";
import { Icon } from "ui/common";
import { getLocalized } from "util/localizationUtil";

type SearchBarSkillsProps = {
  onChange: (text: string) => void;
  value: string;
};

export default function SearchBarSkills(props: SearchBarSkillsProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.value);
  };

  return (
    <div className={styles.skillSearchBar}>
      <FormGroup className={styles.wrapper}>
        <Input
          className={styles.search}
          value={props.value}
          onChange={handleChange}
          placeholder={getLocalized("search.by_name")}
          type="text"
          name="searchSkills"
          id="searchSkills"
        />
        <Icon name="search" />
      </FormGroup>
    </div>
  );
}
