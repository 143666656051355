import React from "react";
import { toast } from "react-toastify";
import ToastBody from "./ToastBody";

enum ToastType {
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
  DEFAULT = "default"
}

type ToastProps = { closeToast: () => void };

const Toast = {
  success: (bodyText: string) =>
    toast.success(({ closeToast }: ToastProps) => (
      <ToastBody
        closeToast={closeToast}
        bodyText={bodyText}
        type={ToastType.SUCCESS}
      />
    )),
  error: (bodyText: string) =>
    toast.error(({ closeToast }: ToastProps) => (
      <ToastBody
        closeToast={closeToast}
        bodyText={bodyText}
        type={ToastType.ERROR}
      />
    )),
  info: (bodyText: string) =>
    toast.info(({ closeToast }: ToastProps) => (
      <ToastBody
        closeToast={closeToast}
        bodyText={bodyText}
        type={ToastType.INFO}
      />
    ))
};

export default Toast;
