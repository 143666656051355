import React, { useState, useEffect, useMemo, useContext } from "react";
import { Row, Col, Alert } from "reactstrap";
import { Table, SearchBar, ButtonActionModalHolder, Toast } from "ui/common";
import JobRolesModal from "./JobRolesModal";
import { getJobRolesList, deleteJobRole } from "services/jobRoles";
import { DesignationData } from "../types";
import { apiResponseStatuses, tableEntityTypes } from "../constants";
import UserContext from "util/context/UserContext";
import {
  DEFAULT_ORDER_BY,
  DEFAULT_ORDER_DIRECTION
} from "ui/common/Table/constants";
import { getLocalized } from "util/localizationUtil";

export default function JobRoles() {
  const { accountId } = useContext(UserContext);
  const [jobRolesList, setJobRolesList] = useState(Array<DesignationData>());
  const [error, setError] = useState<string>("");
  const [searchResults, setSearchResults] = useState(Array<DesignationData>());
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    // fetch job role list on component mount
    const queryParams = {
      sort: `${DEFAULT_ORDER_BY}:${DEFAULT_ORDER_DIRECTION}`
    };
    const fetchData = async () => {
      setIsFetching(true);
      const { status, data } = await getJobRolesList(accountId, queryParams);
      if (status === apiResponseStatuses.success) {
        setJobRolesList(data);
      } else {
        setError(data.message);
      }
      setIsFetching(false);
      setForceRefresh(false); // set to false after fetch
    };
    fetchData();
  }, [accountId, forceRefresh]);

  function handleReload(forceRefresh: boolean) {
    setForceRefresh(forceRefresh);
  }

  // Table headers
  const headers = useMemo(
    () => [
      {
        name: "Title",
        selector: "title",
        sortable: true
      },
      {
        name: "Description",
        selector: "description",
        sortable: false
      },
      {
        name: "Tier",
        selector: "tier",
        sortable: false,
        grow: 2,
        format: (row: DesignationData) => (row.tier ? row.tier.title : "N/A")
      }
    ],
    []
  );

  // Suggestion list of names for the autocomplete
  const suggestionList = jobRolesList.map(item => ({
    value: item.title,
    label: item.title
  }));

  const handleSearch = (searchTerm: string) => {
    const inputValue = searchTerm.trim().toLowerCase();
    const inputLength = inputValue.length;

    const searchResults =
      inputLength === 0
        ? []
        : jobRolesList.filter(
            item =>
              item.title.toLowerCase().includes(inputValue) ||
              item.description.toLowerCase().includes(inputValue)
          );
    setSearchResults(searchResults);
  };

  const handleDelete = (designationId: string) => {
    const fetchData = async () => {
      const { status, message } = await deleteJobRole(accountId, designationId);
      if (status === apiResponseStatuses.success) {
        Toast.success(getLocalized("job_role.delete_success"));
      } else {
        setError(message);
        Toast.error(getLocalized("job_role.delete_failed"));
      }
      setForceRefresh(true);
    };
    fetchData();
  };

  return (
    <Row>
      <Col sm={{ size: 6, order: 1 }} xs={{ size: 12, order: 2 }}>
        <SearchBar
          suggestionList={suggestionList}
          searchByLabel={getLocalized("common.designation")}
          getSearchTerm={handleSearch}
        />
      </Col>
      <Col
        sm={{ size: 6, order: 2 }}
        xs={{ size: 12, order: 1 }}
        className="text-right mb-3"
      >
        <ButtonActionModalHolder
          label={getLocalized("role.add_new")}
          actionModal={JobRolesModal}
          onReload={handleReload}
        />
      </Col>
      <div>
        <Alert color="danger" isOpen={!!error}>
          {error}
        </Alert>
      </div>
      <Col xs={{ size: 12, order: 3 }}>
        <Table
          headers={headers}
          tableData={searchResults.length !== 0 ? searchResults : jobRolesList}
          entityType={tableEntityTypes.JOB_ROLES}
          actionModal={JobRolesModal}
          onReload={handleReload}
          deleteAction={handleDelete}
          noDataText={getLocalized("job_role.no_result_now")}
          isFetching={isFetching}
        />
      </Col>
    </Row>
  );
}
