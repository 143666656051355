import { ProfileMemberType } from "ui/account/types";
// import { Subjects } from "ui/common/types";

const profilePathRegex = Object.values(ProfileMemberType).join("|");
const routes = [
  {
    path: "/dashboard",
    exact: true,
    name: "Dashboard"
  },
  {
    path: "/manage-users",
    exact: true,
    name: "Manage users"
  },
  {
    path: "/my-team",
    exact: true,
    name: "My Team"
  },
  {
    path: `/profile/:userType(${profilePathRegex})/:userId`,
    name: "User Profile"
  },
  {
    path: "/manage-tiers",
    name: "Manage tiers"
  },
  {
    path: "/review-self-ranking",
    name: "Review self ranking"
  },
  {
    path: "/review-skill-goals",
    name: "Review skill goals"
  },
  {
    path: "/manage-skills",
    name: "Manage skills"
  },
  {
    path: "/manage-job-roles",
    name: "Manage job roles"
  },
  {
    path: "/reports",
    name: "Reports"
  },
  {
    path: "/settings",
    name: "Settings"
  }
];

export default routes;
