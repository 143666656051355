import { apiResponseStatuses } from "api/core";
import { BASE_NOTES_ATTACHMENT_URL } from "config/endpoints";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import { deleteNote } from "services/notes";
import { AccessControl, Button, Icon, Toast } from "ui/common";
import { AccessControlActions, Subjects } from "ui/common/types";
import UserContext, { ProviderValues } from "util/context/UserContext";
import { capitalizeFirstLetter, getDateString } from "util/helpers";
import { getLocalized } from "util/localizationUtil";
import { NoteData } from "..";
import styles from "./NoteDetailsModal.module.scss";
import { URL_REGEX_REQUIRED_PREFIX } from "../constants";

type NoteDetailsModalProps = {
  isOpen: boolean;
  toggle: () => void;
  onSuccess: () => void;
  item: NoteData;
};

export default function NoteDetailsModal(props: NoteDetailsModalProps) {
  const { toggle, isOpen, item, onSuccess } = props;

  const { accountId }: ProviderValues = useContext(UserContext);
  const { userId: memberId } = useParams();

  const [isDeleteNoteLoading, setIsDeleteNoteLoading] = useState(false);
  const [deleteNoteError, setDeleteNoteError] = useState(null);

  const createdAt = getDateString(new Date(item.date));

  const handleDelete = async () => {
    setIsDeleteNoteLoading(true);
    const { status, data } = await deleteNote(accountId, item.id);
    if (status === apiResponseStatuses.success) {
      setIsDeleteNoteLoading(false);
      setDeleteNoteError(null);
      onSuccess();
      toggle();
      Toast.success(getLocalized("note.delete_success"));
    } else {
      setIsDeleteNoteLoading(false);
      setDeleteNoteError(data.message);
      Toast.error(getLocalized("note.delete_failed"));
    }
  };

  const getFormattedUrl = (url: string) => {
    let formattedUrl = url;
    let displayUrl = url;

    if (!URL_REGEX_REQUIRED_PREFIX.test(formattedUrl)) {
      formattedUrl = "//" + formattedUrl;
    } else {
      let protocol = `${displayUrl.split("//")[0]}//`;
      displayUrl = displayUrl.replace(protocol, "");
    }

    return (
      <a
        href={formattedUrl}
        className={styles.attachment}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon name="link" /> {displayUrl}
      </a>
    );
  };

  const getCssClass = (type: string) => {
    switch (type) {
      case "positive":
        return styles.positive;
      case "negative":
        return styles.negative;
      case "constructive":
        return styles.neutral;
      default:
        return "";
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{item.title}</ModalHeader>
      <ModalBody>
        <Alert color="danger" isOpen={!!deleteNoteError}>
          {deleteNoteError}
        </Alert>
        <Row className={styles.detailsSection}>
          <Col>
            <div>
              <strong>{getLocalized("common.created_on")}</strong>&nbsp;
              <p>{createdAt}</p>
            </div>
          </Col>
          <Col>
            <div>
              <strong>{getLocalized("common.created_by")}</strong>&nbsp;
              <p>{`${item.author.user.firstName} ${item.author.user.lastName}`}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className={styles.noteMeta}>
            <span
              className={`${styles.badge} ${getCssClass(
                item.type.toLowerCase()
              )}`}
            >
              {capitalizeFirstLetter(item.type)}
            </span>
          </Col>
          <Col xs={12} className={styles.noteTitle}>
            <p>{item.title}</p>
          </Col>
          <Col xs={12}>
            <p>{item.description}</p>
          </Col>
          <Col xs="12">
            {item.url ? <>{getFormattedUrl(item.url)}</> : null}
            {item.filePath ? (
              <a
                className={styles.attachment}
                href={BASE_NOTES_ATTACHMENT_URL + item.filePath}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="insert_drive_file" />
                {getLocalized("common.view_attachment")}
              </a>
            ) : null}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <AccessControl.IsMyProfile
          memberId={memberId}
          yes={children => (
            <AccessControl.Can
              resource={Subjects.ProfileNote}
              action={AccessControlActions.DeleteOwn}
            >
              {children}
            </AccessControl.Can>
          )}
          no={children => (
            <AccessControl.Can
              resource={Subjects.ProfileNote}
              action={AccessControlActions.DeleteAny}
            >
              <AccessControl.OnlyAuthor authorId={item.author.id}>
                {children}
              </AccessControl.OnlyAuthor>
            </AccessControl.Can>
          )}
        >
          <Button
            className="text-button secondary-text"
            onClick={handleDelete}
            loading={isDeleteNoteLoading}
          >
            {getLocalized("note.delete")}
          </Button>
        </AccessControl.IsMyProfile>
      </ModalFooter>
    </Modal>
  );
}
