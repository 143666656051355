import React, { useState, useEffect, useMemo } from "react";
import { Row, Col } from "reactstrap";

import { Table } from "ui/common";
import { ReportData } from "ui/account/types";
import { tableEntityTypes } from "ui/account/constants";
import { getLocalized } from "util/localizationUtil";

type CategoryListProps = {
  categoryList: Array<ReportData>;
};

export default function CategoryList(props: CategoryListProps) {
  const [categoryList, setCategoryList] = useState(props.categoryList);

  useEffect(() => {
    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCategoryList(props.categoryList);
  }, [props.categoryList]);

  // Table headers
  const headers = useMemo(
    () => [
      {
        name: "Employee Id",
        selector: "employeeId",
        sortable: true,
        format: (row: ReportData) =>
          row.member ? row.member.employeeId : "N/A"
      },
      {
        name: "Name",
        selector: "user",
        sortable: true,
        format: (row: ReportData) => {
          if (row.user) {
            return `${row.user.firstName} ${row.user.lastName}`;
          }
        }
      },
      {
        name: "Designation",
        selector: "designation",
        sortable: false,
        format: (row: ReportData) => {
          if (row.designation) {
            return row.designation.description;
          }
        }
      },
      {
        name: "Tier",
        selector: "designation",
        sortable: false,
        format: (row: ReportData) => {
          if (row.designation && row.designation.tier) {
            return row.designation.tier.title;
          }
        }
      },
      {
        name: "Overall Score",
        selector: "overallScore",
        sortable: false
      },
      {
        name: "People Skills Contribution",
        selector: "peopleSkillsContribution",
        sortable: false
      },
      {
        name: "Specialist Skills Contribution",
        selector: "specialistSkillsContribution",
        sortable: false
      }
    ],
    []
  );

  let divEnd: any = null;
  const scrollToBottom = () => {
    divEnd.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Row>
      <Col xs="12">
        <Table
          headers={headers}
          tableData={categoryList}
          entityType={tableEntityTypes.REPORTS}
          showActions={false}
          onReload={() => {}}
          noDataText={getLocalized("common.no_reports_now")}
        />
      </Col>
      <div ref={el => (divEnd = el)} style={{ float: "left", clear: "both" }} />
    </Row>
  );
}
