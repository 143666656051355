import React from "react";
import { FieldProps, ErrorMessage, getIn } from "formik";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
import { InputType } from "reactstrap/lib/Input";

type InputCustomProps = {
  label: string;
  maxLength: number;
  required: boolean;
  type: InputType;
} & FieldProps;
export default function InputCustom({
  field,
  form: { touched, errors },
  required,
  type,
  ...props
}: InputCustomProps) {
  const error = getIn(errors, field.name);
  const touch = getIn(touched, field.name);
  const additionalProps = type === "textarea" ? { rows: 3 } : {};
  return (
    <FormGroup>
      {props.label && (
        <Label for={field.name}>
          {props.label} {required && `*`}
        </Label>
      )}
      <Input
        invalid={error && touch}
        {...field}
        {...props}
        {...additionalProps}
        type={type}
      />
      <ErrorMessage name={field.name}>
        {error => <FormFeedback>{error}</FormFeedback>}
      </ErrorMessage>
    </FormGroup>
  );
}

InputCustom.defaultProps = {
  maxLength: 255
};
