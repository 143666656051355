import React from "react";
import { FieldProps, ErrorMessage } from "formik";
import Select from "react-select";
import { FormGroup, Label } from "reactstrap";

import { OptionType } from "./types";
import AsyncSelect from "react-select/async";
import { OptionsType, ValueType, ActionMeta } from "react-select/src/types";

type SelectCustomProps = {
  label: string;
  options: OptionType[];
  getOptionValue?(option: OptionType): string;
  getOptionLabel?(option: OptionType): string;
  async: boolean;
  loadOptions: (
    inputValue: string,
    callback: (options: OptionsType<any>) => void
  ) => Promise<any> | void;
  defaultOptions: OptionsType<OptionType> | boolean;
  handleSelectedValue?: (
    option: ValueType<OptionType>,
    actionMeta: ActionMeta,
    setFieldValue: (fieldName: string, value: any) => void
  ) => {};
  required: boolean;
};

const SelectCustom = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  async,
  loadOptions,
  defaultOptions,
  required,
  ...props
}: FieldProps & SelectCustomProps) => {
  const {
    options,
    getOptionLabel,
    getOptionValue,
    handleSelectedValue
  } = props;
  return (
    <FormGroup>
      {props.label && (
        <Label htmlFor={field.name}>
          {props.label} {required && `*`}
        </Label>
      )}
      {async ? (
        <AsyncSelect
          {...field}
          {...props}
          className={`custom-react-select ${
            touched[field.name] && errors[field.name] ? "has-error" : ""
          }`}
          defaultOptions={defaultOptions}
          value={field.value}
          loadOptions={loadOptions}
          onChange={option => setFieldValue(field.name, option as any)}
          onBlur={field.onBlur}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          classNamePrefix="sp-select"
        />
      ) : (
        <Select
          {...field}
          {...props}
          options={options}
          value={field.value}
          onChange={(option, action) => {
            setFieldValue(field.name, option as any);
            handleSelectedValue &&
              handleSelectedValue(option, action, setFieldValue);
          }}
          onBlur={field.onBlur}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          className={`custom-react-select ${
            touched[field.name] && errors[field.name] ? "has-error" : ""
          }`}
          classNamePrefix="sp-select"
        />
      )}
      <ErrorMessage name={field.name}>
        {error => <div className="feedback-text">{error}</div>}
      </ErrorMessage>
    </FormGroup>
  );
};

SelectCustom.defaultProps = {
  async: false,
  loadOptions: () => {},
  defaultOptions: [] /* https://react-select.com/async#defaultoptions */,
  handleSelectedValue: null
};

export default SelectCustom;
