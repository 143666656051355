import { apiResponseStatuses } from "api/core";
import { Field, Form, Formik, FormikActions } from "formik";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ActionMeta, ValueType } from "react-select/src/types";
import {
  Alert,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import { createGoal, updateGoal } from "services/goals";
import { getSkills } from "services/skills";
import {
  Account,
  EntityStatus,
  ModalActionTypes,
  RatingScaleItem,
  Skill,
  SubSkill
} from "ui/account/types";
import {
  Button,
  DatePickerCustom,
  InputCustom,
  SelectCustom,
  Toast
} from "ui/common";
import { OptionType } from "ui/common/SelectCustom/types";
import UserContext, { ProviderValues } from "util/context/UserContext";
import { getDateString } from "util/helpers";
import { getLocalized } from "util/localizationUtil";
import * as Yup from "yup";
import { modalActionTypes } from "../../constants";
import { GoalStatus, SkillCategory } from "../constants";
import { GoalData } from "../types";

type CategoryType = "TECHNICAL" | "PERSONAL" | "OTHER";

type GoalsFormValues = {
  skillCategory?: { value: CategoryType; label: string };
  skill?: Skill | string;
  subSkill?: SubSkill | string;
  targetLevel?: RatingScaleItem | string;
  deadline: string;
  description: string;
};

type GoalsModalProps = {
  onToggle: (open: boolean) => void;
  isOpen: boolean;
  goal?: GoalData;
  actionType: ModalActionTypes;
  onSuccess: () => void;
};

type TechnicalSkillsMap = { [id: string]: Skill };
type TechnicalSubSkillsMap = { [id: string]: Array<SubSkill> };

function getRatingScale(account: Account) {
  let ratingScale;
  if (account !== null) {
    ratingScale = Object.values(account.preferences.ratingScale)
      .slice(0)
      .reverse();
  }
  return ratingScale;
}

export default function GoalsModal(props: GoalsModalProps) {
  const skillCategoryOptions = [
    {
      value: SkillCategory.TECHNICAL,
      label: getLocalized("skill.technical")
    },
    {
      value: SkillCategory.PERSONAL,
      label: getLocalized("skill.personal")
    },
    {
      value: SkillCategory.OTHER,
      label: getLocalized("common.other")
    }
  ];

  const getModalTitle = (actionType: ModalActionTypes) => {
    return actionType === modalActionTypes.EDIT
      ? getLocalized("goal.edit")
      : getLocalized("goal.add");
  };

  const getModalActionTitle = (actionType: ModalActionTypes) => {
    return actionType === modalActionTypes.EDIT
      ? getLocalized("action.save")
      : getLocalized("action.add");
  };

  const { onToggle, isOpen, actionType, onSuccess } = props;

  const { accountId, account }: ProviderValues = useContext(UserContext);
  const { userId: memberId } = useParams();

  // response data
  const [technicalSkillsList, setTechnicalSkillsList] = useState<Array<Skill>>(
    []
  );
  const [personalSkillsList, setPersonalSkillsList] = useState<Array<Skill>>(
    []
  );

  // mapped data from response
  const [technicalSkillsById, setTechnicalSkillsById] = useState<
    TechnicalSkillsMap
  >({});
  const [technicalSubSkillsById, setTechnicalSubSkillsById] = useState<
    TechnicalSubSkillsMap
  >({});

  const [actionTypePersist, setActionTypePersist] = useState<ModalActionTypes>(
    props.actionType
  ); // CREATE OR EDIT

  const ratingScale = useMemo(() => (account ? getRatingScale(account) : []), [
    account
  ]);

  const mapTechnicalSkillsById = (skillsArr: Array<Skill>) => {
    const mappedSkills = skillsArr.reduce(
      (acc: TechnicalSkillsMap, skill: Skill) => ({
        ...acc,
        [skill.id]: skill
      }),
      {}
    );
    const mappedSubSkills = skillsArr.reduce(
      (acc: TechnicalSubSkillsMap, skill: Skill) => ({
        ...acc,
        [skill.id]: skill.subSkills
      }),
      {}
    );
    setTechnicalSkillsById(mappedSkills);
    setTechnicalSubSkillsById(mappedSubSkills);
  };

  useEffect(() => {
    const fetchTechnicalSkillsData = async () => {
      const { status, data } = await getSkills(accountId, {
        "filter[type]": SkillCategory.TECHNICAL
      });
      if (status === apiResponseStatuses.success) {
        const activeTechnicalSkills = data.results.filter(
          (skill: Skill) => skill.status === EntityStatus.Active
        );
        setTechnicalSkillsList(activeTechnicalSkills);
        data && mapTechnicalSkillsById(activeTechnicalSkills);
      }
    };

    const fetchPersonalSkillsData = async () => {
      const { status, data } = await getSkills(accountId, {
        "filter[type]": SkillCategory.PERSONAL
      });
      if (status === apiResponseStatuses.success) {
        const activePersonalSkills = data.results.filter(
          (skill: Skill) => skill.status === EntityStatus.Active
        );
        setPersonalSkillsList(activePersonalSkills);
      }
    };

    if (isOpen) {
      fetchTechnicalSkillsData();
      fetchPersonalSkillsData();
    }
  }, [accountId, isOpen]);

  useEffect(() => {
    isOpen && setActionTypePersist(props.actionType);
  }, [isOpen, props.actionType]);

  const getInitialValues = () => {
    const item = props.goal as GoalData;
    if (item) {
      let selectedSkill;
      let selectedSubSkill;
      if (item.skill) {
        if (item.skill.type === SkillCategory.TECHNICAL) {
          // find the selected skill among the technical skills list
          selectedSkill = technicalSkillsList.find(
            (option: Skill) => option.id === item.skillId
          );
          // find the selected sub skill among the sub skills in the selected technical skills list
          selectedSubSkill =
            selectedSkill &&
            selectedSkill.subSkills.find(
              (option: SubSkill) => option.id === item.subSkillId
            );
        } else {
          selectedSkill = personalSkillsList.find(
            (option: Skill) => option.id === item.skillId
          );
        }
      } else {
        selectedSkill = "";
        selectedSubSkill = "";
      }

      return {
        skillCategory: skillCategoryOptions.find(option =>
          item.skill
            ? option.value === item.skill.type
            : option.value === SkillCategory.OTHER
        ),
        skill: selectedSkill,
        subSkill:
          item.skill && item.skill.type === SkillCategory.TECHNICAL
            ? selectedSubSkill
            : "", // only technical skills have sub skills
        targetLevel:
          ratingScale &&
          ratingScale.find(
            (option: RatingScaleItem) =>
              option.ratingScaleIndex === item.targetSkillLevel
          ),
        deadline: new Date(item.deadline).toISOString().split("T")[0],
        description: item.description
      };
    }
    return {
      skillCategory: skillCategoryOptions[0], // first option by default
      skill: "",
      subSkill: "",
      targetLevel: "",
      deadline: new Date(new Date().setDate(new Date().getDate() + 1))
        .toISOString()
        .split("T")[0], // tomorrow date by default
      description: ""
    };
  };

  // Get the skill options based on skill category selection
  const getSkillOptions = (values: GoalsFormValues) =>
    values.skillCategory &&
    values.skillCategory.value === SkillCategory.TECHNICAL
      ? technicalSkillsList
      : personalSkillsList;

  // get the sub skill options based on the skill selection
  const getSubSkillOptions = (values: GoalsFormValues) => {
    if (
      values.skillCategory &&
      values.skill &&
      values.skillCategory.value === SkillCategory.TECHNICAL
    ) {
      const skill: Skill = values.skill as Skill;
      const skillId: string = skill.id;

      return technicalSkillsById &&
        technicalSubSkillsById[skillId] &&
        technicalSubSkillsById[skillId].length > 0
        ? technicalSkillsById[skillId].subSkills
        : [];
    }
  };

  const handleCategorySelectedValue = (
    value: ValueType<OptionType>,
    actionMeta: ActionMeta,
    setFieldValue: (fieldName: string, value: string) => void
  ) => {
    const { action } = actionMeta;
    // Reset skill and subSkill selection when category is changed
    if (action === "select-option") {
      setFieldValue("skill", "");
      setFieldValue("subSkill", "");
    }
  };

  const handleSkillSelectedValue = (
    value: ValueType<Skill>,
    actionMeta: ActionMeta,
    setFieldValue: (fieldName: string, value: string) => void
  ) => {
    const { action } = actionMeta;
    if (action === "select-option") {
      setFieldValue("subSkill", "");
    }
  };

  const handleToggle = (resetForm: () => void) => {
    resetForm();
    onToggle(!isOpen);
  };

  const handleSubmit = async (
    values: GoalsFormValues,
    { setSubmitting, resetForm, setStatus }: FormikActions<GoalsFormValues>
  ) => {
    const item = props.goal as GoalData;
    const editRequestBody = {
      description: values.description,
      deadline: values.deadline,
      status: GoalStatus.PENDING
    };
    const createGoalBody = {
      accountId,
      memberId,
      skillId:
        values.skill && values.skill !== ""
          ? (values.skill as Skill).id
          : undefined,
      subSkillId:
        values.subSkill && values.subSkill !== ""
          ? (values.subSkill as SubSkill).id
          : undefined,
      targetSkillLevel:
        values.targetLevel && values.targetLevel !== ""
          ? (values.targetLevel as RatingScaleItem).ratingScaleIndex
          : undefined,
      description: values.description,
      deadline: values.deadline
    };
    const { data, status } =
      actionType === modalActionTypes.EDIT && item
        ? await updateGoal(accountId, item.id, editRequestBody)
        : await createGoal(accountId, createGoalBody);
    if (status === apiResponseStatuses.success) {
      actionType === modalActionTypes.EDIT
        ? Toast.success(getLocalized("goal.update_success"))
        : Toast.success(getLocalized("goal.add_success"));
      handleToggle(resetForm);
      onSuccess();
      setStatus(null);
    } else {
      setStatus(data.message || getLocalized("common.something_went_wrong"));
    }
    setSubmitting(false);
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={getInitialValues()}
      enableReinitialize
      validationSchema={goalsValidations}
      render={({ resetForm, values, status }) => {
        const enhancedToggle = () => handleToggle(resetForm);
        return (
          <Modal size="lg" isOpen={isOpen} toggle={enhancedToggle}>
            <ModalHeader toggle={enhancedToggle}>
              {getModalTitle(actionTypePersist)}
            </ModalHeader>
            <ModalBody>
              <Form noValidate>
                <div>
                  <Alert color="danger" isOpen={!!status}>
                    {status}
                  </Alert>
                </div>
                <Row>
                  <Col md="6" xs="12">
                    <Field
                      required
                      id="skillCategory"
                      name="skillCategory"
                      label={getLocalized("skill.category")}
                      component={SelectCustom}
                      options={skillCategoryOptions}
                      value={values.skillCategory}
                      isDisabled={actionTypePersist === modalActionTypes.EDIT}
                      handleSelectedValue={handleCategorySelectedValue}
                    />
                  </Col>
                  <Col md="6" xs="12">
                    <Field
                      required={
                        (values.skillCategory &&
                          values.skillCategory.value ===
                            SkillCategory.TECHNICAL) ||
                        (values.skillCategory &&
                          values.skillCategory.value === SkillCategory.PERSONAL)
                      }
                      id="skill"
                      name="skill"
                      label={getLocalized("skill")}
                      component={SelectCustom}
                      options={getSkillOptions(values)}
                      getOptionValue={
                        (option: Skill) => option.id // Setting id as the value
                      }
                      getOptionLabel={
                        (option: Skill) => option.name // Setting title as the label
                      }
                      value={values.skill}
                      isDisabled={
                        (values.skillCategory && // disable when other goal type is selected
                          values.skillCategory.value === SkillCategory.OTHER) ||
                        actionTypePersist === modalActionTypes.EDIT
                      }
                      handleSelectedValue={handleSkillSelectedValue}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="4" xs="12">
                    <Field
                      required={
                        (values.skillCategory && values.skillCategory.value) ===
                        SkillCategory.TECHNICAL
                      }
                      id="subSkill"
                      name="subSkill"
                      label={getLocalized("sub_skill")}
                      component={SelectCustom}
                      options={getSubSkillOptions(values)}
                      getOptionValue={
                        (option: SubSkill) => option.id // Setting id as the value
                      }
                      getOptionLabel={
                        (option: SubSkill) => option.title // Setting title as the label
                      }
                      value={values.subSkill}
                      isDisabled={
                        !values.skill || // disable when skills are not selected
                        (values.skillCategory && // disable when a personal skill is selected
                          values.skillCategory.value ===
                            SkillCategory.PERSONAL) ||
                        actionTypePersist === modalActionTypes.EDIT // disable in edit mode
                      }
                    />
                  </Col>
                  <Col md="4" xs="12">
                    <Field
                      id="targetLevel"
                      name="targetLevel"
                      label={getLocalized("skill_level.target")}
                      component={SelectCustom}
                      options={ratingScale}
                      getOptionValue={
                        (option: RatingScaleItem) => option.ratingScaleIndex // Setting index as the value
                      }
                      getOptionLabel={
                        (option: RatingScaleItem) =>
                          `${option.ratingLabel} - ${option.ratingLetter}` // Setting rating letter as the label
                      }
                      value={values.targetLevel}
                      isDisabled={
                        (values.skillCategory && // disable when other goal type is selected
                          values.skillCategory.value === SkillCategory.OTHER) ||
                        actionTypePersist === modalActionTypes.EDIT
                      }
                    />
                  </Col>
                  <Col md="4" xs="12">
                    <Field
                      required
                      id="deadline"
                      name="deadline"
                      label={getLocalized("common.deadline")}
                      component={DatePickerCustom}
                      minDate={
                        actionTypePersist === modalActionTypes.CREATE
                          ? new Date().setDate(new Date().getDate() + 1) // allow only future dates when creating new goal
                          : null
                      }
                      value={getDateString(new Date(values.deadline))}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      required={
                        (values.skillCategory && values.skillCategory.value) ===
                        SkillCategory.OTHER
                      }
                      type="textarea"
                      id="description"
                      name="description"
                      label={getLocalized("common.description")}
                      component={InputCustom}
                      maxLength="1000"
                    />
                  </Col>
                </Row>
                <ModalFooter>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={enhancedToggle}
                  >
                    {getLocalized("action.discard")}
                  </Button>
                  <Button type="submit" className="primary">
                    {getModalActionTitle(actionTypePersist)}
                  </Button>
                </ModalFooter>
              </Form>
            </ModalBody>
          </Modal>
        );
      }}
    />
  );
}

const goalsValidations = Yup.object({
  skill: Yup.mixed().when(["skillCategory"], {
    is: skillCategory =>
      skillCategory.value === SkillCategory.TECHNICAL ||
      skillCategory.value === SkillCategory.PERSONAL,
    then: Yup.object().required(getLocalized("required.skill"))
  }),
  subSkill: Yup.mixed().when(["skillCategory"], {
    is: skillCategory => skillCategory.value === SkillCategory.TECHNICAL,
    then: Yup.object().required(getLocalized("required.sub_skill"))
  }),
  deadline: Yup.date()
    .test("isPast", getLocalized("required.future_date"), function test(
      deadline
    ) {
      // Only future dates are allowed
      return !(new Date(deadline) < new Date());
    })
    .required(getLocalized("required.goal_deadline")),
  description: Yup.mixed().when(["skillCategory"], {
    is: skillCategory => skillCategory.value === SkillCategory.OTHER,
    then: Yup.string().required(getLocalized("required.description"))
  })
});
