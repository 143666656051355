import { SkillScores } from "./types";
import { SkillType } from "../Skills/types";

/* replaced enum due to inability to extend them for undoReducer and skillScoresReducer */
export type ScoresUndoableActionTypes =
  | "INITIALIZE"
  | "UPDATE_SUB_SKILL_SCORE"
  | "UPDATE_MAIN_SKILL_SCORE"
  | "UNDO";

export type ScoresUndoableAction = {
  type?: ScoresUndoableActionTypes;
  payload?: SkillScoresActionPayload;
};

type SkillScoresActionPayload = any;

export type UndoableState = {
  past: null | SkillScoresState;
  present: SkillScoresState;
};
export type SkillScoresState = {
  skillScores: {
    technicalById: { [key: string]: Required<SkillScores> };
    personalById: { [key: string]: Required<SkillScores> };
  };
  mvp: boolean;
  id: string;
};

export const skillScoresInitialState: SkillScoresState = {
  skillScores: { technicalById: {}, personalById: {} },
  mvp: false,
  id: ""
};

export const undoInitialState: UndoableState = {
  past: null,
  present: skillScoresInitialState
};

export const undoReducer = (reducer: any) => (state: any, action: any) => {
  const { past, present } = state;
  switch (action.type) {
    case "UNDO":
      return {
        past: null,
        present: past
      };

    default:
      const newPresent = reducer(present, action);
      const newPast = action.type === "INITIALIZE" ? past : present;
      if (present === newPresent) {
        return state;
      }
      return {
        past: newPast,
        present: newPresent
      };
  }
};

export const skillScoresReducer = (
  state: SkillScoresState,
  action: ScoresUndoableAction
) => {
  switch (action.type) {
    case "INITIALIZE" /* action.payload : Skill[] */:
      return action.payload.skillScores.reduce(
        (accumulator: SkillScoresState, currentValue: SkillScores) => ({
          ...accumulator,
          skillScores: {
            ...accumulator.skillScores,
            ...(currentValue.skill.type === SkillType.Technical
              ? {
                  technicalById: {
                    ...accumulator.skillScores.technicalById,
                    [currentValue.skillId]: {
                      ...currentValue,
                      subSkills: currentValue.subSkills
                        ? currentValue.subSkills.map(subSkill => ({
                            ...subSkill,
                            scoreIndex: subSkill.scoreIndex
                          }))
                        : []
                    }
                  }
                }
              : {
                  personalById: {
                    ...accumulator.skillScores.personalById,
                    [currentValue.skillId]: {
                      ...currentValue,
                      scoreIndex: currentValue.scoreIndex
                    }
                  }
                })
          }
        }),
        {
          id: action.payload.id,
          skillScores: skillScoresInitialState.skillScores,
          mvp: action.payload.mvp
        }
      );
    case "UPDATE_MAIN_SKILL_SCORE":
      return {
        ...state,
        skillScores: {
          ...state.skillScores,
          personalById: {
            ...state.skillScores.personalById,
            [action.payload.id]: {
              ...state.skillScores.personalById[action.payload.id],
              scoreIndex: action.payload.scoreIndex[0]
            }
          }
        }
      };
    case "UPDATE_SUB_SKILL_SCORE":
      return {
        ...state,
        skillScores: {
          ...state.skillScores,
          technicalById: {
            ...state.skillScores.technicalById,
            [action.payload.skillId]: {
              ...state.skillScores.technicalById[action.payload.skillId],
              subSkills: state.skillScores.technicalById[
                action.payload.skillId
              ].subSkills.map((subSkill: any) => {
                const item = action.payload.scores.find(
                  (value: any) => value.id === subSkill.subSkillId
                );
                return { ...subSkill, scoreIndex: item.scoreIndex[0] };
              })
            }
          }
        }
      };
    default:
      return state;
  }
};

/* selectors */

export const getAllSkills = (state: UndoableState) => [
  ...Object.values(state.present.skillScores.technicalById),
  ...Object.values(state.present.skillScores.personalById)
];

export const getTechnicalSkills = (state: UndoableState) => [
  ...Object.values(state.present.skillScores.technicalById)
];
export const getPersonalSkills = (state: UndoableState) => [
  ...Object.values(state.present.skillScores.personalById)
];

export const isMvp = (state: UndoableState) => state.present.mvp;
export const getScoreId = (state: UndoableState) => state.present.id;
