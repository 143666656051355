import React, { ReactNode } from "react";
import { Spinner, ButtonProps as ButtonPropsRs } from "reactstrap";

type ButtonProps = {
  children?: ReactNode;
  loading?: boolean;
  hideSelf?: boolean;
} & ButtonPropsRs;
export default function Button({
  loading,
  disabled,
  hideSelf,
  ...props
}: ButtonProps) {
  const buttonDisabled = disabled || loading;
  return (
    <>
      {hideSelf ? null : (
        <button
          className={props.className}
          {...props}
          disabled={buttonDisabled}
        >
          {loading ? (
            <Spinner size={props.size} color="light" />
          ) : (
            props.children
          )}
        </button>
      )}
    </>
  );
}

Button.defaultProps = {
  loading: false,
  hideSelf: false,
  size: "sm",
  color: "primary"
};
