import React from "react";
import * as Yup from "yup";
import { Formik, Field, Form, FormikActions } from "formik";
import { InputCustom, Button, Toast } from "ui/common";
import { changePassword } from "services/auth";
import { Row, Col, Alert } from "reactstrap";
import styles from "./ChangePasswordForm.module.scss";
import { apiResponseStatuses } from "api/core";
import { useTranslation } from "react-i18next";

export type Props = {
  onSubmit?: () => void;
};

type ChangePasswordFormValues = {
  password: string;
  passwordConfirm: string;
  currentPassword: string;
};

const passwordRegEx = /^(?=.*[a-zA-Z])(?=.*[0-9])/; /* contains both number and letter (at least) */

const ChangePasswordForm = (props: Props) => {
  const { t, ready } = useTranslation();

  const setPasswordFormValidations = Yup.object({
    currentPassword: Yup.string()
      .min(8, t("password_vdn.invalid_length"))
      .matches(passwordRegEx, t("password_vdn.invalid"))
      .trim()
      .required(t("password_vdn.current_required")),
    password: Yup.string()
      .min(8, t("password_vdn.invalid_length"))
      .matches(passwordRegEx, t("password_vdn.invalid"))
      .trim()
      .required(t("password_vdn.required")),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref("password"), null], t("password_vdn.mismatch"))
      .required(t("required.confirm_password"))
  });

  const handleSubmit = async (
    { password, currentPassword }: ChangePasswordFormValues,
    actions: FormikActions<ChangePasswordFormValues>
  ) => {
    const { status, data } = await changePassword({
      newPassword: password,
      currentPassword
    });

    if (status === apiResponseStatuses.success) {
      actions.setStatus(null);
      Toast.success(t("common.password_reset_success"));
      props.onSubmit && props.onSubmit();
    } else {
      Toast.error(t("common.password_reset_failed"));
      actions.setStatus(data.message);
    }
    actions.setSubmitting(false);
  };

  return (
    <Row className={styles.wrapper}>
      {ready && (
        <Col>
          <Formik
            onSubmit={handleSubmit}
            initialValues={{
              password: "",
              passwordConfirm: "",
              currentPassword: ""
            }}
            validationSchema={setPasswordFormValidations}
            render={({ isSubmitting, status }) => (
              <>
                <Alert color="danger" isOpen={!!status}>
                  {status}
                </Alert>
                <Form noValidate>
                  <Field
                    required
                    type="password"
                    label={t("password_text.current")}
                    name="currentPassword"
                    component={InputCustom}
                  />
                  <Field
                    required
                    type="password"
                    label={t("password_text.new")}
                    name="password"
                    component={InputCustom}
                  />
                  <Field
                    required
                    type="password"
                    label={t("password_text.confirm_new")}
                    name="passwordConfirm"
                    component={InputCustom}
                  />
                  <Row>
                    <Col xs="12" className="text-center">
                      <Button
                        className={`${styles.submit} primary`}
                        loading={isSubmitting}
                      >
                        {t("password_text.change")}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </>
            )}
          />
        </Col>
      )}
    </Row>
  );
};

export default ChangePasswordForm;
