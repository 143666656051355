import React, { useState, useContext } from "react";
import { Row, Col } from "reactstrap";
import SubSkillsModal from "./SubSkillsModal/index";
import { Skill, SubSkill } from "ui/account/Skills/types";
import { ModalActionTypes } from "ui/common/types";
import { modalActionTypes } from "ui/account/constants";
import styles from "./SubSkills.module.scss";
import SkillsContext from "util/context/SkillsContext";
import {
  ConfirmationModal,
  Button,
  Icon,
  AccessControl,
  Toast
} from "ui/common";
import { SkillsActionType } from "ui/account/Skills/reducers";
import { deleteSubSkill } from "services/subSkills";
import UserContext from "util/context/UserContext";
import { apiResponseStatuses } from "api/core";
import { Subjects, AccessControlActions } from "ui/common/types";
import { getLocalized } from "util/localizationUtil";

type SubSkillsProps = {
  item: Skill;
};

export default function SubSkills(props: SubSkillsProps) {
  const dispatch = useContext(SkillsContext);
  const { accountId } = useContext(UserContext);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalActionType, setModalActionType] = useState<ModalActionTypes>(
    modalActionTypes.CREATE
  );
  const [modalData, setModalData] = React.useState();
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [deleteItem, setDeleteItem] = React.useState();
  const [deleteError, setDeleteError] = useState();

  const handleToggle = (
    actionType?: ModalActionTypes,
    currentItem?: SubSkill
  ) => {
    setModalActionType(actionType || modalActionTypes.CREATE);
    setModalData(currentItem);
    setIsOpen(prevModalOpen => !prevModalOpen);
  };

  const handleExit = () => setDeleteItem(undefined);

  const handleDeleteToggle = (open: boolean = false, item?: SubSkill) => {
    setDeleteItem(item);
    setDeleteModalOpen(open);
  };
  const handleCancelDelete = () => setDeleteModalOpen(false);

  const handleConfirm = async () => {
    if (deleteItem) {
      const { data, status } = await deleteSubSkill(
        accountId,
        deleteItem.accountSkillId,
        deleteItem.id
      );
      if (status === apiResponseStatuses.success) {
        setDeleteError(null);
        removeSubSkill(deleteItem);
        handleDeleteToggle(false);
        Toast.success(getLocalized("sub_skill.delete_success"));
      } else {
        setDeleteError(data.message);
        Toast.error(getLocalized("sub_skill.delete_failed"));
      }
    }
  };

  const addSubSkill = (subSkill: SubSkill) =>
    dispatch({
      type: SkillsActionType.AddSubSkill,
      payload: { skillId: props.item.id, data: subSkill }
    });

  const removeSubSkill = (subSkill: SubSkill) =>
    dispatch({
      type: SkillsActionType.DeleteSubSkill,
      payload: {
        skillId: props.item.id,
        subSkillId: subSkill.id
      }
    });

  const editSubSkill = (subSkill: SubSkill) =>
    dispatch({
      type: SkillsActionType.EditSubSkill,
      payload: {
        skillId: props.item.id,
        subSkillId: modalData.id,
        data: subSkill
      }
    });

  return (
    <>
      <Col xs="12" className={styles.subSkills}>
        <Row>
          <Col xs="auto" className={styles.title}>
            {getLocalized("sub_skill.plural")}
          </Col>
          <Col className="text-right mb-3">
            <AccessControl.Can
              resource={Subjects.ManageSkillsSubSkill}
              action={AccessControlActions.AddAny}
            >
              <Button
                className="text-button primary-text small-button"
                onClick={() => handleToggle()}
              >
                {getLocalized("action.add_new")}
              </Button>
            </AccessControl.Can>
          </Col>
        </Row>
        <Row>
          <Col xs="12" className={styles.tagCloud}>
            {props.item.subSkills.map((tag, index: number) => (
              <div className={styles.tag} key={index}>
                <div className={styles.wrap}>
                  <div className={styles.name}>{tag.title}</div>
                  <div className={styles.actions}>
                    <AccessControl.Can
                      resource={Subjects.ManageSkillsSubSkill}
                      action={AccessControlActions.EditAny}
                    >
                      <span
                        className={styles.tagIcon}
                        onClick={() => handleToggle(modalActionTypes.EDIT, tag)}
                      >
                        <Icon name="create" />
                      </span>
                    </AccessControl.Can>
                    <AccessControl.Can
                      resource={Subjects.ManageSkillsSubSkill}
                      action={AccessControlActions.DeleteAny}
                    >
                      <span
                        className={styles.tagIcon}
                        onClick={() => handleDeleteToggle(true, tag)}
                      >
                        <Icon name="clear" />
                      </span>
                    </AccessControl.Can>
                  </div>
                </div>
              </div>
            ))}
          </Col>
        </Row>
        <SubSkillsModal
          isOpen={isOpen}
          onToggle={handleToggle}
          actionType={modalActionType}
          item={modalData}
          createAction={addSubSkill}
          editAction={editSubSkill}
          skill={props.item}
        />
        <ConfirmationModal
          onExit={handleExit}
          confirmError={deleteError}
          item={deleteItem}
          renderTitle={() => `${getLocalized("warning.irreversible_action")}`}
          renderBody={item => (
            <span>
              {`${getLocalized("common.delete_prompt")} "${item &&
                item.name}"?`}
              <br />
              {getLocalized("warning.delete_sub_skill")}
            </span>
          )}
          onToggle={handleCancelDelete}
          isOpen={deleteModalOpen}
          onCancel={handleCancelDelete}
          onConfirm={handleConfirm}
          cancelTitle={getLocalized("common.decline_operation")}
          confirmTitle={getLocalized("common.confirm_delete")}
        />
      </Col>
    </>
  );
}
