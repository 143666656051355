import React from "react";
import { Route, useRouteMatch, Switch } from "react-router-dom";
import { PrivateRoute, NoDataLabel } from "ui/common";
import { Subjects } from "ui/common/types";
import { ProfileMemberType } from "ui/account/types";
import { getLocalized } from "util/localizationUtil";
import UsersList from "ui/account/Users/UsersList";
import UserProfile from "ui/account/Users/UserProfile";

type UserProfileParams = {
  userId: string;
};

export default function MyTeam() {
  const match = useRouteMatch<UserProfileParams>();
  const path = match ? match.path : "/my-team";

  const profilePathRegex = Object.values(ProfileMemberType).join("|");

  const myTeamUsersList = () => <UsersList hideFilters showOnlyMyTeam />;

  return (
    <Switch>
      <Route exact path={path} component={myTeamUsersList} />
      <PrivateRoute
        path={`${path}/profile/:userType(${profilePathRegex})/:userId`}
        page={Subjects.ProfilePage}
      >
        <UserProfile basePath={path.split("/")[1]} />
      </PrivateRoute>
      <Route path="*">
        <NoDataLabel noDataLabel={getLocalized("common.page_not_found")} />
      </Route>
    </Switch>
  );
}
