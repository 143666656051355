import React, { Fragment } from "react";
import Can from "./Can";
import OnlySubordinates from "./OnlySubordinates";
import IsMyProfile from "./IsMyProfile";
import OnlyAuthor from "./OnlyAuthor";
import CanVisitProfile from "./CanVisitProfile";
import OnlySubordinateHierarchy from "./OnlySubordinateHierarchy";

export default function AccessControl() {
  /* necessary common logic for access control */
  return <Fragment></Fragment>;
}

AccessControl.Can = Can;
AccessControl.OnlySubordinates = OnlySubordinates;
AccessControl.IsMyProfile = IsMyProfile;
AccessControl.OnlyAuthor = OnlyAuthor;
AccessControl.CanVisitProfile = CanVisitProfile;
AccessControl.OnlySubordinateHierarchy = OnlySubordinateHierarchy;
