import React, { useContext, ReactNode } from "react";
import UserContext, { getDefaultMember } from "util/context/UserContext";

type IsMyProfileProps = {
  yes: (children: ReactNode) => ReactNode;
  no: (children: ReactNode) => ReactNode;
  children?: ReactNode;
  memberId?: string;
  skipCheck?: boolean;
};

IsMyProfile.defaultProps = {
  yes: () => <></>,
  no: () => <></>,
  skipCheck: false
};
export default function IsMyProfile(props: IsMyProfileProps) {
  const { yes, no, memberId, children, skipCheck } = props;
  const { user } = useContext(UserContext);

  const currentUserDefaultMembership = getDefaultMember(user);

  const isMyProfile =
    currentUserDefaultMembership &&
    memberId &&
    memberId === currentUserDefaultMembership.id;

  return skipCheck ? (
    <>{children}</>
  ) : isMyProfile ? (
    <>{yes(children)}</>
  ) : (
    <>{no(children)}</>
  );
}
