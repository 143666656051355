import React, { useContext, useState } from "react";
import { Modal, ModalHeader, ModalBody, Alert, Row, Col } from "reactstrap";
import { Button, Icon, Toast, AccessControl } from "ui/common";
import { getDateString } from "util/helpers";
import { getLocalized } from "util/localizationUtil";
import { deleteGoal, updateGoal } from "services/goals";
import UserContext, { ProviderValues } from "util/context/UserContext";
import { apiResponseStatuses } from "api/core";
import { GoalData, GoalStatusType } from "../types";
import { GoalStatus } from "../constants";
import styles from "./GoalDetailsModal.module.scss";
import { Subjects, AccessControlActions } from "ui/common/types";
import { useParams } from "react-router-dom";

type GoalDetailsModalProps = {
  isOpen: boolean;
  toggle: () => void;
  toggleEdit: (isOpen: boolean) => void;
  onSuccess: () => void;
  goal: GoalData;
  onGoalAchieved: (isGoalAchieved: boolean) => void;
};

export default function GoalDetailsModal(props: GoalDetailsModalProps) {
  const { toggle, toggleEdit, isOpen, goal, onSuccess, onGoalAchieved } = props;
  const { accountId }: ProviderValues = useContext(UserContext);
  const { userId: memberId } = useParams();

  const [isDeleteGoalLoading, setIsDeleteGoalLoading] = useState(false);
  const [deleteGoalError, setDeleteGoalError] = useState(null);

  const [goalStatusError, setGoalStatusError] = useState(null);

  const goalTitle =
    goal.title !== null ? goal.title : getLocalized("common.other");
  const goalType =
    goal.status === GoalStatus.PASSED
      ? getLocalized("common.achieved")
      : goal.status === GoalStatus.PENDING
      ? getLocalized("common.in_progress")
      : getLocalized("common.failed");
  const goalTarget =
    goal.targetSkillLevelLabel &&
    goal.targetSkillLevelLetter &&
    `• ${goal.targetSkillLevelLabel} (
              ${goal.targetSkillLevelLetter})`;

  const toggleEditModal = () => {
    toggleEdit(true);
    toggle();
  };

  const handleChangeGoalStatus = async (goalStatus: GoalStatusType) => {
    const editRequestBody = {
      status: goalStatus
    };
    setGoalStatusError(null);
    const { status, data } = await updateGoal(
      accountId,
      goal.id,
      editRequestBody
    );
    if (status === apiResponseStatuses.success) {
      setGoalStatusError(null);
      onSuccess();
      goalStatus === GoalStatus.PASSED && onGoalAchieved(true); // Should check for status returned in response
      toggle();
      Toast.success(getLocalized("goal.add_success"));
    } else {
      setGoalStatusError(data.message);
      Toast.error(getLocalized("goal.add_failed"));
    }
  };

  const handleDelete = async () => {
    setDeleteGoalError(null);
    setIsDeleteGoalLoading(true);
    const { status, data } = await deleteGoal(accountId, goal.id);
    if (status === apiResponseStatuses.success) {
      setIsDeleteGoalLoading(false);
      setDeleteGoalError(null);
      onSuccess();
      toggle();
      Toast.success(getLocalized("goal.delete_success"));
    } else {
      setIsDeleteGoalLoading(false);
      setDeleteGoalError(data.message);
      Toast.success(getLocalized("goal.delete_failed"));
    }
  };

  const getCssClass = (type: string) => {
    switch (type) {
      case "achieved":
        return styles.achieved;
      case "inprogress":
        return styles.ongoing;
      case "failed":
        return styles.failed;
      default:
        return "";
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {goalTitle} {goalTarget}
      </ModalHeader>
      <ModalBody>
        <Alert color="danger" isOpen={!!deleteGoalError}>
          {deleteGoalError}
        </Alert>
        <Alert color="danger" isOpen={!!goalStatusError}>
          {goalStatusError}
        </Alert>

        {goal.status === GoalStatus.PENDING && ( // only pending goals can be edited
          <Row noGutters>
            <AccessControl.IsMyProfile
              memberId={memberId}
              yes={children => (
                <AccessControl.Can
                  resource={Subjects.ProfileGoal}
                  action={AccessControlActions.EditOwn}
                >
                  {children}
                </AccessControl.Can>
              )}
              no={children => (
                <AccessControl.Can
                  resource={Subjects.ProfileGoal}
                  action={AccessControlActions.EditAny}
                >
                  <AccessControl.OnlyAuthor authorId={goal.author.id}>
                    {children}
                  </AccessControl.OnlyAuthor>
                </AccessControl.Can>
              )}
            >
              <Col className={styles.mainButtonWrap}>
                <Button
                  className="secondary line small-button"
                  onClick={() => handleChangeGoalStatus(GoalStatus.FAILED)}
                >
                  {getLocalized("common.mark_as_failed")}
                </Button>
                <Button
                  className="secondary line small-button"
                  onClick={() => handleChangeGoalStatus(GoalStatus.PASSED)}
                >
                  {getLocalized("common.mark_as_passed")}
                </Button>
              </Col>
            </AccessControl.IsMyProfile>

            <Col xs="auto" className={styles.controls}>
              <AccessControl.IsMyProfile
                memberId={memberId}
                yes={children => (
                  <AccessControl.Can
                    resource={Subjects.ProfileGoal}
                    action={AccessControlActions.EditOwn}
                  >
                    {children}
                  </AccessControl.Can>
                )}
                no={children => (
                  <AccessControl.Can
                    resource={Subjects.ProfileGoal}
                    action={AccessControlActions.EditAny}
                  >
                    <AccessControl.OnlyAuthor authorId={goal.author.id}>
                      {children}
                    </AccessControl.OnlyAuthor>
                  </AccessControl.Can>
                )}
              >
                <Button
                  className="icon-button dark small-button"
                  onClick={toggleEditModal}
                >
                  <Icon name="edit" />
                </Button>
              </AccessControl.IsMyProfile>

              <AccessControl.IsMyProfile
                memberId={memberId}
                yes={children => (
                  <AccessControl.Can
                    resource={Subjects.ProfileGoal}
                    action={AccessControlActions.DeleteOwn}
                  >
                    {children}
                  </AccessControl.Can>
                )}
                no={children => (
                  <AccessControl.Can
                    resource={Subjects.ProfileGoal}
                    action={AccessControlActions.DeleteAny}
                  >
                    <AccessControl.OnlyAuthor authorId={goal.author.id}>
                      {children}
                    </AccessControl.OnlyAuthor>
                  </AccessControl.Can>
                )}
              >
                <Button
                  className="icon-button dark small-button"
                  onClick={handleDelete}
                  loading={isDeleteGoalLoading}
                >
                  <Icon name="delete_outline" />
                </Button>
              </AccessControl.IsMyProfile>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <div>
              <label>{getLocalized("common.deadline")}</label>
              <p>{getDateString(new Date(goal.deadline))}</p>
            </div>
          </Col>
          {goal.status === GoalStatus.PASSED && goal.completedAt && (
            <Col>
              <div>
                <label>{getLocalized("common.completed_on")}</label>
                <p>{getDateString(new Date(goal.completedAt))}</p>
              </div>
            </Col>
          )}
          <Col>
            <div>
              <label>{getLocalized("common.created_by")}</label>
              <p>{`${goal.author.user.firstName} ${goal.author.user.lastName}`}</p>
            </div>
          </Col>
          <Col xs="12" className={styles.noteMeta}>
            <span
              className={`${styles.badge} ${getCssClass(
                goalType.replace("-", "").toLowerCase()
              )}`}
            >
              {goalType}
            </span>
          </Col>
          <Col xs="12" className={styles.noteTitle}>
            <p>
              {goalTitle} {goalTarget}
            </p>
          </Col>
          <Col xs="12" className={styles.noteBody}>
            <p>{goal.description}</p>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}
