import React, { useState } from "react";
import {
  FlexibleXYPlot,
  Hint,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  LineMarkSeries,
  Crosshair
} from "react-vis";
import { addMonths, format } from "date-fns/esm";
import { differenceInMonths } from "date-fns";

const MIN_X = 6;
type Coordinate = {
  x: Date;
  y: number;
};

type TimeLineSeriesProps = {
  data: Coordinate[];
  yDomain: [number, number];
  xDomain: [Date, Date];
  markSize: number;
  showCrossHairs: boolean;
  xAxisTickDateFormat: string;
  xAxisLabel: string;
  yAxisLabel: string;
};

export default function TimeLineSeries(props: TimeLineSeriesProps) {
  const {
    data,
    xDomain,
    yDomain,
    markSize,
    xAxisTickDateFormat,
    xAxisLabel,
    yAxisLabel,
    showCrossHairs
  } = props;

  const [hoveredMark, setHoveredMark] = useState();
  const [crossHairValues, setCrossHairValues] = useState<Coordinate[]>([]);
  const numberOfMonthsInDomain = differenceInMonths(xDomain[1], xDomain[0]);
  const xDomainLessThanMinimum = numberOfMonthsInDomain < MIN_X;
  const numberOfXTicks = xDomainLessThanMinimum
    ? MIN_X
    : numberOfMonthsInDomain;
  const xDomainTransform = [
    xDomain[0],
    xDomainLessThanMinimum ? addMonths(xDomain[0], MIN_X) : xDomain[1]
  ];

  const onNearestX = (value: Coordinate) => {
    setCrossHairValues([value]);
  };

  return (
    <FlexibleXYPlot
      xType="time"
      margin={{ top: 0, right: 0, left: 4 }}
      yDomain={yDomain}
      xDomain={xDomainTransform}
    >
      <VerticalGridLines
        tickTotal={numberOfXTicks}
        tickValues={[...Array(numberOfXTicks)].map((_, index) => {
          const month = index + 1;
          return addMonths(xDomainTransform[0], month);
        })}
      />
      <HorizontalGridLines />
      <XAxis
        attr="x"
        attrAxis="y"
        tickLabelAngle={30}
        tickValues={data.map((val: any) => val.x)}
        tickFormat={function tickFormat(d: any) {
          return (
            <tspan>
              <tspan x="0" dx="4em">
                {format(d, xAxisTickDateFormat)}
              </tspan>
            </tspan>
          );
        }}
      />
      <YAxis attr="y" attrAxis="x" hideTicks />

      <Hint value={data[data.length - 1]} style={{ padding: "3px" }}>
        <div className="rv-hint__content">{data[data.length - 1].y}</div>
      </Hint>
      {hoveredMark && (
        <Hint value={hoveredMark} style={{ padding: "3px" }}>
          <div className="rv-hint__content">
            {hoveredMark && hoveredMark.y ? hoveredMark.y : ""}
          </div>
        </Hint>
      )}
      <LineMarkSeries
        className="linemark-series"
        style={{
          strokeWidth: "1px"
        }}
        onNearestX={onNearestX}
        size={markSize}
        color="black"
        lineStyle={{ stroke: "black" }}
        markStyle={{ stroke: "black" }}
        onValueMouseOver={(dataPoint: Coordinate) => {
          setHoveredMark(dataPoint);
        }}
        onValueMouseOut={() => {
          setHoveredMark(undefined);
        }}
        data={data}
      />
      {showCrossHairs && (
        <Crosshair values={crossHairValues} className={"test-class-name"}>
          {crossHairValues.length > 0 && (
            <div className="rv-hint__content">
              <div>
                <small>{`${xAxisLabel}: ${format(
                  crossHairValues[0].x,
                  xAxisTickDateFormat
                )}`}</small>
              </div>
              <div>
                <small>{`${yAxisLabel}: ${crossHairValues[0].y}`}</small>
              </div>
            </div>
          )}
        </Crosshair>
      )}
    </FlexibleXYPlot>
  );
}

TimeLineSeries.defaultProps = {
  data: [],
  showCrossHairs: true,
  yDomain: [0, 100],
  xDomain: [0, 100],
  markSize: 2,
  xAxisTickDateFormat: "dd/MM/yy",
  xAxisLabel: "",
  yAxisLabel: ""
};
