import React, { useState, useContext } from "react";
import { Route } from "react-router-dom";
// import { Link as RouterLink, useLocation, } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  UncontrolledDropdown,
  Row,
  Col
  // NavLink
} from "reactstrap";
import { logout } from "services/auth";
import defaultProfileImg from "ui/App/default_profile.png";
import {
  Avatar
  // AccessControl
} from "ui/common";
import UserContext, {
  ProviderValues,
  UserConsumer,
  LoginResponse,
  getDefaultMember
} from "util/context/UserContext";
import styles from "./Appbar.module.scss";
import routes from "./routes";
import logo from "ui/App/logo.svg";
import { getLocalized } from "util/localizationUtil";
import ChangePasswordModal from "ui/auth/ChangePasswordModal";
// import PermissionContext, {
//   checkPermission
// } from "util/context/PermissionContext";
// import { Subjects, AccessControlActions } from "ui/common/types";
// import { slugify } from "util/helpers";

export default function Appbar() {
  // const location = useLocation();
  const [showChangePasswordPrompt, setShowChangePasswordPrompt] = useState(
    false
  );

  const { user } = useContext(UserContext);
  // const uc = useContext(UserContext);
  // const { permissions } = useContext(PermissionContext);

  const defaultMember = getDefaultMember(user);
  const defaultMemberAccessRole = defaultMember ? defaultMember.accessRole : "";
  // const defaultMemberId = defaultMember ? defaultMember.id : "";

  const showLogo = defaultMemberAccessRole === "MEMBER" || false; //TODO: use permissions

  // const profileUrl = `/profile/${slugify(
  //   defaultMemberAccessRole
  // )}/${defaultMemberId}/me`;

  // const teamUrl = `/team/${slugify(
  //   defaultMemberAccessRole
  // )}/${defaultMemberId}`;

  // const departmentUrl = `/department/${slugify(
  //   defaultMemberAccessRole
  // )}/${defaultMemberId}`;

  async function handleLogout(onLogout: () => void) {
    await logout();
    onLogout();
  }

  return (
    <div className={styles.appBar}>
      <Row>
        <Col className={styles.mobileHeader}>
          <img src={logo} alt="Logo" />
        </Col>
      </Row>
      <Navbar light expand="xs">
        {showLogo ? (
          <NavbarBrand className={`${styles.title} d-block`}>
            <Row>
              <Col className={styles.logo}>
                <img className={styles.long} alt="avatar" src={logo} />
              </Col>
            </Row>
          </NavbarBrand>
        ) : (
          <NavbarBrand className={`${styles.title} d-block`}>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                render={() => route.name}
              />
            ))}
          </NavbarBrand>
        )}

        {/* TODO: re-enable the topBar and use permissions to handle which top bar option to be displayed */}
        {/* {defaultMemberAccessRole === "MEMBER" && ( //TODO: use permissions
          <Nav className={styles.navWrap} navbar>
            <>
              {topBarRoutesWithProfile.map((route: any, index: number) => (
                // TODO: Implement the top bar feature with permissions
                // <AccessControl.Can
                //   resource={route.page}
                //   action={AccessControlActions.VisitAny}
                //   key={index}
                // >
                <NavLink
                  className={`${styles.topBar} ${
                    activeRoute(route.url) ? styles.active : ""
                  }`}
                  key={route.url}
                  tag={RouterLink}
                  to={route.url}
                  active={activeRoute(route.url)}
                >
                  <span>{route.name}</span>
                </NavLink>
                // </AccessControl.Can>
              ))}
            </>
          </Nav>
        )} */}

        <Nav className={styles.userOptions} navbar>
          <UserConsumer>
            {({ onLogout, user: currentUser }: ProviderValues) => {
              const user = currentUser as LoginResponse;

              return (
                <>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle className={styles.dropDown} nav caret>
                      <span className={styles.picture}>
                        <Avatar
                          size={[100, 100]}
                          src={defaultProfileImg}
                          alt={`${user.firstName} ${user.lastName}`}
                        />
                      </span>
                      <span className={styles.name}>
                        {`${user.firstName} ${user.lastName}`}
                      </span>
                    </DropdownToggle>
                    <DropdownMenu className={styles.dropDownMenu} center="true">
                      <DropdownItem
                        onClick={() => setShowChangePasswordPrompt(true)}
                      >
                        Change Password
                        <br />
                        <ChangePasswordModal
                          isOpen={showChangePasswordPrompt}
                          closeToggle={() => setShowChangePasswordPrompt(false)}
                          onSubmit={() => setShowChangePasswordPrompt(false)}
                        />
                      </DropdownItem>
                      <DropdownItem onClick={() => handleLogout(onLogout)}>
                        {getLocalized("common.logout")}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </>
              );
            }}
          </UserConsumer>
        </Nav>
      </Navbar>
    </div>
  );
}
