import {
  getUrl,
  GET_GOALS_URL,
  CREATE_GOAL_URL,
  UPDATE_GOAL_URL,
  DELETE_GOAL_URL
} from "config/endpoints";
import { getRequest, postRequest, patchRequest, deleteRequest } from "api";

export type GoalsQueryParams = {
  "filter[status]"?: string;
  "filter[memberId]"?: string;
  page?: string;
};

export const getGoalsList = async (
  accountId: string,
  queryParams?: GoalsQueryParams | null
): Promise<any> => {
  const url = getUrl(GET_GOALS_URL, { accountId });
  return getRequest(url, queryParams);
};

export const createGoal = async (
  accountId: string,
  bodyParams: { [param: string]: any }
): Promise<any> => {
  const url = getUrl(CREATE_GOAL_URL, { accountId });
  return postRequest(url, null, bodyParams);
};

export const updateGoal = async (
  accountId: string,
  goalId: string,
  bodyParams: { [param: string]: any }
): Promise<any> => {
  const url = getUrl(UPDATE_GOAL_URL, { accountId, goalId });
  return patchRequest(url, null, bodyParams);
};

export const deleteGoal = async (
  accountId: string,
  goalId: string
): Promise<any> => {
  const url = getUrl(DELETE_GOAL_URL, { accountId, goalId });
  return deleteRequest(url);
};
