import React, { useState, useEffect } from "react";
import { RatingScale } from "ui/account/types";
import { Button } from "ui/common";
import styles from "./SkillScoreSlider.module.scss";

type SkillScoreSlider = {
  drag: boolean;
  grades: RatingScale;
  value: number[];
  onChange: (selected: number[]) => void;
  readOnly: boolean;
};

const NON_SELECTION_GRADE_INDEX = -1;
export default function SkillScoreSlider({
  drag,
  grades,
  onChange,
  readOnly,
  value
}: SkillScoreSlider) {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<number[]>([]);
  useEffect(() => {
    setSelectedCheckboxes(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(value)]); //deep compare string array

  const gradesReversed: RatingScale = Object.values(grades).reduce(
    (accumulator, currentValue, index, array) => {
      const ratingIndex = array.length - index;
      return { ...accumulator, [ratingIndex]: currentValue };
    },
    {}
  );

  const maximumGradeIndex = gradesReversed[1].ratingScaleIndex;
  const minimumGradeIndex = grades[1].ratingScaleIndex;

  const onCheckboxBtnClick = (selected: number) => {
    let newSelectedCheckboxes;
    if (drag) {
      const isAlreadySelected = selectedCheckboxes.includes(selected);
      if (isAlreadySelected) {
        const currentGradeIndex = selected + 1;
        newSelectedCheckboxes =
          currentGradeIndex && currentGradeIndex <= maximumGradeIndex // 4
            ? [currentGradeIndex]
            : [NON_SELECTION_GRADE_INDEX];
        /* -1 is the value for non-selection, so anything out of range of the grades is considered as non selection */
      } else {
        newSelectedCheckboxes = [selected];
      }
    } else {
      const index = selectedCheckboxes.indexOf(selected);
      if (index < 0) {
        selectedCheckboxes.push(selected);
      } else {
        selectedCheckboxes.splice(index, 1);
      }
      newSelectedCheckboxes = [...selectedCheckboxes];
    }
    setSelectedCheckboxes(newSelectedCheckboxes);
    onChange(newSelectedCheckboxes);
  };

  return (
    <div className={styles.rail}>
      {Object.keys(gradesReversed).map((grade, index) => {
        const numberGrade = gradesReversed[grade].ratingScaleIndex;
        const active = drag
          ? selectedCheckboxes[0] >= minimumGradeIndex &&
            selectedCheckboxes[0] <= numberGrade
          : selectedCheckboxes.includes(numberGrade);

        return (
          <div className={styles.box} key={index}>
            <Button
              className={`${active ? styles.active : ""} ${
                readOnly ? styles.readOnly : ""
              }`}
              type="button"
              onClick={() => onCheckboxBtnClick(numberGrade)}
              disabled={readOnly}
              title={gradesReversed[grade].ratingLetter}
            />
          </div>
        );
      })}
    </div>
  );
}

SkillScoreSlider.defaultProps = {
  drag: true,
  onChange: () => {},
  readOnly: false,
  value: []
};
