import React from "react";
import styles from "./NoDataLabel.module.scss";

type NoDataLabelProps = {
  noDataLabel: string;
};

export default function NoDataLabel(props: NoDataLabelProps) {
  return (
    <div className={styles.noData}>
      <p>{props.noDataLabel}</p>
    </div>
  );
}

NoDataLabel.defaultProps = {
  noDataLabel: "No data at the moment"
};
