import * as React from "react";
import Select from "react-select";
import { ActionMeta, ValueType } from "react-select/src/types";
import { OptionType } from "./types";
import { Label } from "reactstrap";

type SelectFilterProps = {
  options: OptionType[];
  handleMultiChange: (
    option: ValueType<OptionType>,
    actionMeta?: ActionMeta
  ) => void;
  getOptionValue?(option: OptionType): string;
  getOptionLabel?(option: OptionType): string;
  clearable: boolean;
  setRef: any;
  label: string;
  prefix?: string;
};

export default function SelectFilter(props: SelectFilterProps) {
  const {
    options,
    handleMultiChange,
    getOptionValue,
    getOptionLabel,
    clearable,
    setRef,
    label,
    prefix
  } = props;

  return (
    <div>
      <Label>{label}</Label>
      <Select
        defaultValue={[]}
        isMulti
        isClearable={clearable}
        name="tiers"
        options={options}
        className="custom-react-select"
        classNamePrefix={prefix || "sp-select"}
        onChange={handleMultiChange}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        ref={setRef}
      />
    </div>
  );
}
