import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { modalActionTypes } from "ui/account/constants";
import { Props } from "util/context/UserContext";
import { getDateString } from "util/helpers";
import { getLocalized } from "util/localizationUtil";
import { GoalStatus } from "../constants";
import GoalDetailsModal from "../GoalDetailsModal";
import GoalsModal from "../GoalsModal";
import { GoalData } from "../types";
import styles from "./GoalListItem.module.scss";

type GoalListItemProps = {
  goal: GoalData;
  onGoalDelete: () => void;
  onGoalEdit: () => void;
  onGoalAchieved: (isGoalAchieved: boolean) => void;
} & Props;

export default function GoalListItem(props: GoalListItemProps) {
  const { goal, onGoalDelete, onGoalEdit, onGoalAchieved } = props;
  let goalType = "";
  let goalDate = "";
  let goalTarget = "";
  let goalTitle = "";

  const [goalDetailsModalOpen, setGoalDetailsModalOpen] = useState(false);
  const [editGoalModalOpen, setEditGoalModalOpen] = useState(false);

  goalTitle = goal.title !== null ? goal.title : getLocalized("common.other");
  goalTarget =
    goal.targetSkillLevelLabel &&
    goal.targetSkillLevelLetter &&
    `• ${goal.targetSkillLevelLabel} (
              ${goal.targetSkillLevelLetter})`;
  goalDate = getDateString(new Date(goal.deadline));

  if (goal.status === GoalStatus.PASSED) {
    goalType = getLocalized("common.achieved");
  } else {
    const dueDate = new Date(goal.deadline).getTime();
    const currentDate = new Date().getTime();
    // check goal status to show status label
    goalType =
      goal.status === GoalStatus.PENDING
        ? getLocalized("common.in_progress")
        : getLocalized("common.failed");

    if (goalType === getLocalized("common.in_progress")) {
      const remainingDays = Math.ceil(
        (dueDate - currentDate) / (1000 * 3600 * 24)
      );
      goalDate =
        remainingDays < 0
          ? remainingDays < -1
            ? `${Math.abs(remainingDays)} ${getLocalized("day.past_plural")}`
            : `${Math.abs(remainingDays)} ${getLocalized("day.past")}`
          : remainingDays > 1
          ? `${remainingDays} ${getLocalized("day.remaining_plural")}`
          : `${remainingDays} ${getLocalized("day.remaining")}`;
    }
  }

  const getCssClass = (type: string) => {
    switch (type) {
      case "achieved":
        return styles.achieved;
      case "inprogress":
        return styles.ongoing;
      case "failed":
        return styles.failed;
      default:
        return "";
    }
  };

  const handleCardClick = () => setGoalDetailsModalOpen(true);

  const handleCloseModal = () => setGoalDetailsModalOpen(false);

  const toggleEditModal = (isOpen: boolean) => setEditGoalModalOpen(isOpen);

  return (
    <>
      <Col xs="12" onClick={handleCardClick} className={styles.goalStripe}>
        <Row form>
          <Col md="9" className={styles.text}>
            <label>
              {goalTitle} {goalTarget}
            </label>
            <p>
              <span
                className={`${styles.badge} ${getCssClass(
                  goalType.replace("-", "").toLowerCase()
                )}`}
              >
                {goalType}
              </span>
              {goal.description}
            </p>
          </Col>
          <Col lg="3" className={styles.date}>
            <div className="text-right">
              <small>{goalDate}</small>
            </div>
            <div className="text-right"></div>
          </Col>
        </Row>
      </Col>

      <GoalDetailsModal
        isOpen={goalDetailsModalOpen}
        toggle={handleCloseModal}
        toggleEdit={toggleEditModal}
        onGoalAchieved={onGoalAchieved}
        onSuccess={onGoalDelete}
        goal={goal}
      />

      <GoalsModal
        isOpen={editGoalModalOpen}
        onToggle={isOpen => toggleEditModal(isOpen)}
        onSuccess={onGoalEdit}
        actionType={modalActionTypes.EDIT}
        goal={goal}
      />
    </>
  );
}
