import { deleteRequest, getRequest, postMediaRequest, postRequest } from "api";
import {
  CREATE_NOTE_URL,
  DELETE_NOTE_URL,
  getUrl,
  GET_NOTES_URL,
  UPLOAD_NOTE_ATTACHMENT_URL
} from "config/endpoints";

export type NotesQueryParams = {
  "filter[type]"?: string;
  "filter[memberId]"?: string;
  page?: string;
};
export const getNotesList = async (
  accountId: string,
  queryParams?: NotesQueryParams | null
): Promise<any> => {
  const url = getUrl(GET_NOTES_URL, { accountId });
  return getRequest(url, queryParams);
};

export const createNote = async (
  accountId: string,
  bodyParams: { [param: string]: any }
): Promise<any> => {
  const url = getUrl(CREATE_NOTE_URL, { accountId });
  return postRequest(url, null, bodyParams);
};
export const deleteNote = async (
  accountId: string,
  noteId: string
): Promise<any> => {
  const url = getUrl(DELETE_NOTE_URL, { accountId, noteId });
  return deleteRequest(url, null);
};

export const uploadNoteAttachment = async (
  accountId: string,
  bodyParams: { [param: string]: any }
): Promise<any> => {
  const url = getUrl(UPLOAD_NOTE_ATTACHMENT_URL, {
    accountId
  });
  return postMediaRequest(url, null, bodyParams);
};
