import React, { ReactNode, useEffect, useState, useContext } from "react";
import PermissionContext, {
  checkPermission
} from "util/context/PermissionContext";
import UserContext, { getDefaultMember } from "util/context/UserContext";
import { Loader } from "ui/common";
import { AccessControlActions } from "../../types";

type CanProps = {
  role?: string;
  resource?: string;
  skipCheck?: boolean;
  action?: AccessControlActions;
  yes?: () => ReactNode;
  no: () => ReactNode;
  children: ReactNode;
};

Can.defaultProps = {
  no: () => null,
  skipCheck: false
};

export default function Can(props: CanProps) {
  const { permissions } = useContext(PermissionContext);
  const { user } = useContext(UserContext);
  const [grant, setGrant] = useState<any>();

  const defaultMembership = getDefaultMember(user);

  const renderContent = () => {
    const { yes, no, children, skipCheck } = props;
    if (skipCheck) {
      return <>{children}</>;
    } else {
      if (grant) {
        const hasPermission = grant.granted;
        return hasPermission ? (
          yes ? (
            <>{yes()}</>
          ) : (
            <>{children}</>
          )
        ) : (
          <>{no()}</>
        );
      } else {
        return <Loader />;
      }
    }
  };
  useEffect(() => {
    if (!props.skipCheck) {
      if (defaultMembership) {
        const grant = checkPermission(permissions, {
          role: props.role || defaultMembership.accessRole,
          resource: props.resource,
          action: props.action,
          skipConditions: true
        });
        setGrant(grant);
      }
    }
  }, [
    defaultMembership,
    permissions,
    props.action,
    props.resource,
    props.role,
    props.skipCheck
  ]);

  return renderContent();
}
