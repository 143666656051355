import React, { useState } from "react";
import { Accordion } from "ui/common";
import SkillsHeader, { SkillsHeaderProps } from "./SkillsHeader";
import SkillsContent, { SkillsContentProps } from "./SkillsContent";
import { Skill } from "ui/account/Skills/types";

type SkillsListProps = {
  allowMultipleOpen: boolean;
  data: Skill[];
  headerComponent?: React.ComponentType<SkillsHeaderProps>;
  bodyComponent?: React.ComponentType<SkillsContentProps>;
};

type OpenedSection = { [id: string]: boolean };

export default function SkillsList(props: SkillsListProps) {
  const { allowMultipleOpen, data } = props;

  const getInitialOpenedSections = () => {
    const openedSections: any = [];

    // eslint-disable-next-line array-callback-return
    data.map((item: Skill) => {
      openedSections[item.id] = false;
    });

    return openedSections;
  };

  const [openedSections, setOpenedSections] = useState<OpenedSection>(
    getInitialOpenedSections
  );

  const toggleCollapsible = (id: string) => {
    const isOpen = openedSections[id];

    // if multiple sections are allowed to be open, then we store the ids of all opened sections
    // otherwise we only store the id of the one
    if (allowMultipleOpen) {
      setOpenedSections({
        ...openedSections,
        [id]: !isOpen
      });
    } else {
      setOpenedSections({
        [id]: !isOpen
      });
    }
  };

  return (
    <div>
      {data.map((item: any, index: number) => (
        <Accordion
          index={index}
          item={item}
          isOpen={openedSections[item.id]}
          onClick={toggleCollapsible}
          key={index}
          renderHeader={() => (
            <SkillsHeader item={item} isOpened={openedSections[item.id]} />
          )}
          renderBody={() => <SkillsContent item={item} />}
        />
      ))}
    </div>
  );
}

SkillsList.defaultProps = {
  allowMultipleOpen: false
};
