import React from "react";
import { Icon } from "ui/common";

type RowActionButtonProps = {
  onClick: () => void;
  icon: string;
  toolTipText: string;
  color?: string;
};

export default function RowActionButton(props: RowActionButtonProps) {
  const { onClick, icon, toolTipText, color } = props;

  return (
    <button
      title={toolTipText}
      className={`${color || "primary"} table-action icon-button dark`}
      onClick={onClick}
    >
      <Icon name={icon} />
    </button>
  );
}
