import React, { ReactNode } from "react";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Field, Form, FormikActions } from "formik";
import InputCustom from "ui/common/InputCustom";
import Button from "ui/common/Button";
import { setPassword } from "services/auth";
import { Row, Col, Alert } from "reactstrap";
import styles from "./SetPasswordForm.module.scss";
import { apiResponseStatuses } from "api/core";
import { getLocalized } from "util/localizationUtil";

type PasswordFormType = "INVITE" | "RESET";
export type SetPasswordFormProps = {
  passwordFormType?: PasswordFormType;
  children?: ReactNode;
};
export type SetPasswordFormValues = {
  password: string;
  passwordConfirm: string;
};

const passwordRegEx = /^(?=.*[a-zA-Z])(?=.*[0-9])/; /* contains both number and letter (at least) */

const SetPasswordForm = ({
  passwordFormType = "INVITE"
}: SetPasswordFormProps) => {
  const setPasswordFormValidations = Yup.object({
    password: Yup.string()
      .min(8, getLocalized("password_vdn.invalid_length"))
      .matches(passwordRegEx, getLocalized("password_vdn.invalid"))
      .trim()
      .required(getLocalized("password_vdn.required")),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref("password"), null], getLocalized("password_vdn.mismatch"))
      .required(getLocalized("required.confirm_password"))
  });

  const renderTitle = (formType: PasswordFormType) => {
    return formType === "INVITE" ? (
      <Row>
        <Col className={styles.title}>
          <h2>{getLocalized("common.welcome_to_sp")}</h2>
          <h5>{getLocalized("password_text.add_to_login")}</h5>
        </Col>
      </Row>
    ) : (
      <Row>
        <Col className={styles.title}>
          <h2>{getLocalized("password_text.reset")}</h2>
          <h5>{getLocalized("password_text.enter_new")}</h5>
        </Col>
      </Row>
    );
  };

  const history = useHistory();
  const { id: token } = useParams();

  const handleSubmit = async (
    { password }: SetPasswordFormValues,
    actions: FormikActions<SetPasswordFormValues>
  ) => {
    const { status, data } = await setPassword(token!, passwordFormType, {
      password
    });
    if (status === apiResponseStatuses.success) {
      actions.setStatus(null);
      history.push("/login"); /* can remove auto redirect if needed */
    } else {
      actions.setStatus(data.message);
    }
    actions.setSubmitting(false);
  };

  return (
    <>
      {renderTitle(passwordFormType)}
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          password: "",
          passwordConfirm: ""
        }}
        validationSchema={setPasswordFormValidations}
        render={({ isSubmitting, status }) => (
          <>
            <Alert color="danger" isOpen={!!status}>
              {status}
            </Alert>
            <Form noValidate>
              <Field
                required
                type="password"
                label={getLocalized("password_text.enter")}
                name="password"
                component={InputCustom}
              />
              <Field
                required
                type="password"
                label={getLocalized("password_text.confirm")}
                name="passwordConfirm"
                component={InputCustom}
              />
              <Row>
                <Col xs="12" className="text-center">
                  <Button
                    className={`${styles.submit} primary`}
                    loading={isSubmitting}
                  >
                    {passwordFormType === "INVITE"
                      ? getLocalized("password_text.add")
                      : getLocalized("password_text.change")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        )}
      />
    </>
  );
};

export default SetPasswordForm;
