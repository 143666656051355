import { createContext, Dispatch } from "react";
import { ScoresUndoableAction } from "ui/account/Scores/reducers";

/* use to pass dispatch only; the dispatch context never changes, 
so components that read it don’t need to re-render unless they 
also need the application state */
const SkillScoresContext = createContext<Dispatch<ScoresUndoableAction>>(
  () => {}
);

export default SkillScoresContext;
