import React, { useContext } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  ModalFooter,
  FormGroup,
  Row,
  Col,
  CustomInput
} from "reactstrap";
import { Formik, Form, Field, FieldProps, FormikActions } from "formik";
import { Button, Toast } from "ui/common";
import { Account } from "ui/account/types";
import { updateAccount } from "services/account";
import UserContext from "util/context/UserContext";
import { apiResponseStatuses } from "api/core";
import styles from "./ScoreContributionModal.module.scss";
import { getLocalized } from "util/localizationUtil";

type ScoreContributionFormValues = {
  contribution: number;
};
type ScoreContributionModalProps = {
  isOpen: boolean;
  item: [number, number];
  onToggle: (open: boolean) => void;
  onSuccess: (account: Account) => void;
};
export default function ScoreContributionModal(
  props: ScoreContributionModalProps
) {
  const { item, isOpen, onToggle, onSuccess } = props;
  const { accountId } = useContext(UserContext);

  const handleSubmit = async (
    { contribution }: ScoreContributionFormValues,
    { setSubmitting }: FormikActions<ScoreContributionFormValues>
  ) => {
    const { data, status } = await updateAccount(accountId, {
      preferences: {
        skillTypeContribution: {
          technical: contribution,
          personal: 100 - contribution
        }
      }
    });
    if (status === apiResponseStatuses.success) {
      onSuccess(data);
      handleToggle();
      Toast.success(getLocalized("employee_score_contribution.update_success"));
    } else {
      Toast.error(getLocalized("employee_score_contribution.update_failed"));
    }
    setSubmitting(false);
  };
  const handleToggle = () => onToggle(false);
  return (
    <Modal size="lg" isOpen={isOpen} toggle={handleToggle}>
      <ModalHeader toggle={handleToggle}>
        {getLocalized("employee_score_contribution.edit")}
      </ModalHeader>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ contribution: item[0] }}
        enableReinitialize
        render={({ status, values, resetForm, isSubmitting }) => {
          return (
            <Form noValidate>
              <ModalBody>
                <div>
                  <Alert color="danger" isOpen={!!status}>
                    {status}
                  </Alert>
                </div>
                <Row className={styles.scoreContribution}>
                  <Col className={styles.content}>
                    <label>
                      {getLocalized("skill.technical_contribution")}
                    </label>
                    <p>{`${values.contribution}%`}</p>
                  </Col>
                  <Col className={styles.content}>
                    <label>{getLocalized("skill.personal_contribution")}</label>
                    <p>{`${100 - values.contribution}%`}</p>
                  </Col>
                </Row>
                <Field
                  required
                  id="contribution"
                  name="contribution"
                  min={0}
                  max={100}
                  render={({ field }: FieldProps) => {
                    return (
                      <FormGroup>
                        <CustomInput
                          type="range"
                          id="exampleCustomRange"
                          {...field}
                        />
                      </FormGroup>
                    );
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <Button type="button" onClick={() => resetForm()}>
                  {getLocalized("action.reset")}
                </Button>
                <Button
                  loading={isSubmitting}
                  type="submit"
                  className="primary"
                >
                  {getLocalized("action.save")}
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      />
    </Modal>
  );
}
