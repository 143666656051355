import React from "react";
import styles from "./Card.module.scss";

type CardProps = {
  title?: string;
  children: React.ReactNode;
};

export default function Card(props: CardProps) {
  const { title } = props;

  return (
    <>
      <div className={styles.title}>{title && title}</div>
      <div className={styles.skillCard}>{props.children}</div>
    </>
  );
}
