import React, { useContext, useEffect, useState, useReducer } from "react";
import { Row, Col } from "reactstrap";

import Card from "ui/common/Card";
import SkillsList from "./SkillsList";
import Preview from "../Preview";
import { getSkills } from "services/skills";
import { apiResponseStatuses } from "api/core";
import {
  SkillsActionType,
  skillsInitialState,
  skillsReducer
} from "../reducers";
import SkillsContext from "util/context/SkillsContext";
import UserContext from "util/context/UserContext";
import { Skill, SkillType } from "../types";
import styles from "./PersonalSkills.module.scss";
import { Loader } from "ui/common";
import { getLocalized } from "util/localizationUtil";

export default function PersonalSkills() {
  const { accountId } = useContext(UserContext);
  const [skills, dispatch] = useReducer(skillsReducer, skillsInitialState);
  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsFetched(false);
      const queryParams = {
        "filter[type]": SkillType.Personal
      };
      const { status, data } = await getSkills(accountId, queryParams);
      if (status === apiResponseStatuses.success) {
        const skillData = data.results as Skill[];
        dispatch({
          type: SkillsActionType.Initialize,
          payload: skillData
        });
      }
      setIsFetched(true);
    };
    fetchData();
  }, [accountId]);

  const skillsData: Skill[] = Object.values(skills.skills.byId);

  return (
    <SkillsContext.Provider value={dispatch}>
      <Row>
        <Col xs="12">
          <div className={styles.heading}>{getLocalized("common.preview")}</div>
          <Preview items={skillsData} type={SkillType.Personal} />
        </Col>
        <Col xs="12">
          {isFetched ? (
            <Card title={getLocalized("skill.pool")}>
              <SkillsList data={skillsData} />
            </Card>
          ) : (
            <Loader />
          )}
        </Col>
      </Row>
    </SkillsContext.Provider>
  );
}
