import { useEffect, useState, useContext } from "react";
import PermissionContext, {
  checkPermission
} from "util/context/PermissionContext";
import UserContext, { getDefaultMember } from "util/context/UserContext";
import { AccessControlActions } from "../../ui/common/types";

type UseCanArgs = {
  role?: string;
  resource?: string;
  skipCheck?: boolean;
  action?: AccessControlActions;
};

useCan.defaultProps = {
  skipCheck: false
};

export default function useCan(args: UseCanArgs) {
  const { permissions } = useContext(PermissionContext);
  const { user } = useContext(UserContext);
  const [granted, setGranted] = useState();

  const defaultMembership = getDefaultMember(user);

  useEffect(() => {
    if (args.skipCheck) {
      setGranted(true);
    } else if (defaultMembership) {
      const grant = checkPermission(permissions, {
        role: args.role || defaultMembership.accessRole,
        resource: args.resource,
        action: args.action,
        skipConditions: true
      });
      setGranted(grant ? grant.granted : false);
    }
  }, [
    args.role,
    args.resource,
    args.action,
    defaultMembership,
    permissions,
    args.skipCheck
  ]);

  return granted;
}
