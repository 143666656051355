import history from "util/history";

/**
 *  Core methods for data fetching such as setting base url, store access tokens,
 *  build endpoint urls, handle requests (WIP)
 */

export const apiResponseStatuses = Object.freeze({
  success: "success",
  error: "error"
});

// Default request headers
const defaultRequestHeaders: { [header: string]: string } = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Request-Headers": "content-type"
};

// Base URL of the API
let BASE_URL: string | null = null;

export const setBaseURL = (baseURL: string | null): void => {
  BASE_URL = baseURL;
};

// Build endpoint url
const buildEndpointURL = (
  endpoint: string,
  queryParams?: { [param: string]: any } | null
): string => {
  let url = "";

  if (BASE_URL) {
    // Check if there are any query params and add to url
    if (queryParams) {
      let formattedQueryParams = "";
      Object.keys(queryParams).forEach((key, index) => {
        formattedQueryParams += `${index === 0 ? "?" : "&"}${key}=${
          (queryParams || {})[key]
        }`;
      });

      url = `${BASE_URL}/${endpoint}${formattedQueryParams}`;
    } else {
      url = `${BASE_URL}/${endpoint}`;
    }
  }

  return url;
};

export const httpRequestMethod = async (
  requestType: string,
  endpoint: string,
  //headers?: { [header: string]: any } | null,
  queryParams?: { [param: string]: any } | null,
  bodyParams?: { [param: string]: any } | null
): Promise<any> => {
  // Create the request URL
  const url: string = buildEndpointURL(endpoint, queryParams);
  const request = new Request(url, {
    credentials: "include",
    method: requestType, // request type
    mode: "cors",
    headers: new Headers({
      ...defaultRequestHeaders // Spread the default request headers
    }),
    ...(bodyParams && { body: JSON.stringify(bodyParams || {}) }) // Add body params if available
  });
  const response = await fetch(request).then(res => res);
  return handleResponse(response);
};

export const formDataHttpRequestMethod = async (
  requestType: string,
  endpoint: string,
  queryParams?: { [param: string]: any } | null,
  bodyParams?: any
): Promise<any> => {
  // Create the request URL
  const url: string = buildEndpointURL(endpoint, queryParams);
  const request = new Request(url, {
    credentials: "include",
    method: requestType,
    mode: "cors",
    ...(bodyParams && { body: bodyParams || {} }) // Add body params if available
  });
  const response = await fetch(request);
  return handleResponse(response);
};

const handleResponse = async (response: any) => {
  let customResponse;
  if (response.status === 401) {
    /* this is when the cookie expires/non-existent and redirects to the login page. */
    history.push("/login");
  }
  if (response.status === 404) {
    customResponse = {
      status: apiResponseStatuses.error,
      data: response.statusText
    };
  } else {
    const responseBody = await response
      .text()
      .then((text: string) => (text ? JSON.parse(text) : {}));
    if (!response.ok) {
      customResponse = {
        status: apiResponseStatuses.error,
        data: responseBody
      };
    } else {
      customResponse = {
        status: apiResponseStatuses.success,
        data: responseBody
      };
    }
  }
  return customResponse;
};
