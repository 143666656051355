import {
  getUrl,
  GET_USERS_URL,
  GET_FILTERED_USERS_URL,
  GET_USER_URL,
  GET_MANAGERS_URL,
  UPLOAD_USER_IMAGE_URL,
  GET_USER_IMAGE_URL,
  GET_MY_TEAM_USERS_URL
} from "config/endpoints";
import {
  getRequest,
  postRequest,
  postMediaRequest,
  putRequest,
  deleteRequest
} from "api";

export const getUsersList = async (
  accountId: string,
  queryParams?: { [param: string]: any } | null
) => {
  const url = getUrl(GET_USERS_URL, { accountId });
  return getRequest(url, queryParams);
};

export const getMyTeamUsersList = async (
  accountId: string,
  queryParams?: { [param: string]: any } | null
) => {
  const url = getUrl(GET_MY_TEAM_USERS_URL, { accountId });
  return getRequest(url, queryParams);
};

export const getFilteredUsersList = async (
  accountId: string,
  bodyParams: { [param: string]: any }
) => {
  const url = getUrl(GET_FILTERED_USERS_URL, { accountId });
  return postRequest(url, null, bodyParams);
};

export const getManagersList = async (
  accountId: string,
  queryParams?: { [param: string]: any } | null
) => {
  const url = getUrl(GET_MANAGERS_URL, { accountId });
  return getRequest(url, queryParams);
};

export const getUserDetails = async (accountId: string, memberId: string) => {
  const url = getUrl(GET_USER_URL, { accountId, memberId });
  return getRequest(url);
};

export const createUser = async (
  accountId: string,
  bodyParams: { [param: string]: any }
): Promise<any> => {
  const url = getUrl(GET_USERS_URL, { accountId });
  return postRequest(url, null, bodyParams);
};
export const updateUser = async (
  accountId: string,
  memberId: string,
  bodyParams: { [param: string]: any }
): Promise<any> => {
  const url = getUrl(GET_USER_URL, { accountId, memberId });
  return putRequest(url, null, bodyParams);
};

export const deleteUser = async (
  accountId: string,
  memberId: string
): Promise<any> => {
  const url = getUrl(GET_USER_URL, { accountId, memberId });
  return deleteRequest(url, null);
};

export const uploadUserImage = async (
  accountId: string,
  memberId: string,
  bodyParams: { [param: string]: any }
): Promise<any> => {
  const url = getUrl(UPLOAD_USER_IMAGE_URL, {
    accountId,
    memberId
  });
  return postMediaRequest(url, null, bodyParams);
};

export const getUserImage = async (
  accountId: string,
  memberId: string
): Promise<any> => {
  const url = getUrl(GET_USER_IMAGE_URL, { accountId, memberId });
  return getRequest(url);
};
