import React, {
  useState,
  useReducer,
  useContext,
  useEffect,
  ReactNode
} from "react";
import { Row, Col, Alert } from "reactstrap";
import TopLevelSkills from "./TopLevelSkills";
import SubSkillScores from "./SubSkillScores";
import ScoreGuidePreview from "./ScoreGuidePreview";
import UserContext, { ProviderValues } from "util/context/UserContext";
import SubSkillRatingModal from "./SubSkillRatingModal";
import { ScoresResponse } from "./types";
import styles from "./Scores.module.scss";
import {
  skillScoresReducer,
  getAllSkills,
  getPersonalSkills,
  getTechnicalSkills,
  getScoreId,
  undoReducer,
  undoInitialState
} from "./reducers";
import SkillScoresContext from "util/context/SkillScoreContext";
import { AccessControl } from "ui/common";
import { getScores } from "services/scores";
import { useParams } from "react-router-dom";
import LoadingContext from "util/context/LoadingContext";
import { apiResponseStatuses } from "api/core";
import { SkillType } from "../Skills/types";
import { Subjects, AccessControlActions } from "ui/common/types";
import { getLocalized } from "util/localizationUtil";

type ScoresProps = {
  renderCardDeck: (args: {
    scoreChanged: boolean;
    onMvpChanged: (mvpChanged: boolean) => void;
  }) => ReactNode;
  renderGoalsAndNotesDeck: (args: {
    onGoalAchieved: (trigger: boolean) => void;
  }) => ReactNode;
};
export default function Scores(props: ScoresProps) {
  const { accountId }: ProviderValues = useContext(UserContext);
  const { onChangeLoading } = useContext(LoadingContext);
  const { userId: memberId, userType } = useParams();
  const [selectedTopSkill, setSelectedTopSkill] = useState<number>(0);
  const [isSkillsLoaded, setIsSkillsLoaded] = useState(false);
  const [skillsFetchError, setSkillsFetchError] = useState("");
  const [scoreChanged, setScoreChanged] = useState(false);
  const [goalAchieved, setGoalAchieved] = useState(false);
  const [mvpChanged, setMvpChanged] = useState(false);

  const [skillScores, dispatch] = useReducer(
    undoReducer(skillScoresReducer),
    undoInitialState
  );

  const [ratingsModalOpen, setRatingsModalOpen] = useState(false);

  useEffect(() => {
    if (scoreChanged) {
      setScoreChanged(false);
    }
  }, [scoreChanged]);

  useEffect(() => {
    if (goalAchieved) {
      setGoalAchieved(false);
    }
  }, [goalAchieved]);

  useEffect(() => {
    if (mvpChanged) {
      setMvpChanged(false);
    }
  }, [mvpChanged]);

  useEffect(() => {
    const fetchData = async () => {
      setIsSkillsLoaded(false);
      onChangeLoading(true, "score");
      if (memberId) {
        const { status, data } = await getScores(accountId, memberId);
        if (status === apiResponseStatuses.success) {
          const skillsScoresData = data as ScoresResponse;
          dispatch({
            type: "INITIALIZE",
            payload: skillsScoresData
          });
        } else {
          setSkillsFetchError(
            data.message || getLocalized("score.could_not_fetch")
          );
        }
      }
      setIsSkillsLoaded(true);
      onChangeLoading(false, "score");
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, memberId]);

  useEffect(() => {
    const fetchData = async () => {
      if (memberId) {
        const { status, data } = await getScores(accountId, memberId);
        if (status === apiResponseStatuses.success) {
          const skillsScoresData = data as ScoresResponse;
          setScoreChanged(true);
          dispatch({
            type: "INITIALIZE",
            payload: skillsScoresData
          });
        }
      }
    };

    if (goalAchieved || mvpChanged) {
      /** goal achieved or mvp change will refetch score data and set scoreChanged  to true for 
      following children can listen to and update themselves */
      fetchData();
    } // refetch on goal achieved
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalAchieved, mvpChanged]);

  const fetchAndRehydrateSkillsState = async () => {
    if (memberId) {
      const { status, data } = await getScores(accountId, memberId);
      if (status === apiResponseStatuses.success) {
        const skillsScoresData = data as ScoresResponse;
        setScoreChanged(true);
        dispatch({
          type: "INITIALIZE",
          payload: skillsScoresData
        });
      }
    }
  };

  const handleGoalAchieved = (achieved: boolean) => {
    setGoalAchieved(achieved);
  };
  const handleMvpChanged = (mvpChanged: boolean) => {
    setMvpChanged(mvpChanged);
  };

  const handleClickEdit = () => setRatingsModalOpen(true);

  const allSkills = getAllSkills(skillScores);

  const technicalSkillSet = getTechnicalSkills(skillScores).map(
    skillScore => skillScore.skill.name
  );
  const personalSkillSet = getPersonalSkills(skillScores).map(
    skillScore => skillScore.skill.name
  );

  const scoreId = getScoreId(skillScores);
  const selectedSkillType = allSkills[selectedTopSkill]
    ? allSkills[selectedTopSkill].skill.type
    : SkillType.Technical;

  return (
    <SkillScoresContext.Provider value={dispatch}>
      <Alert color="danger" isOpen={!!skillsFetchError}>
        {skillsFetchError}
      </Alert>
      {isSkillsLoaded && !skillsFetchError && (
        <>
          {props.renderCardDeck({
            scoreChanged,
            onMvpChanged: handleMvpChanged
          })}

          <AccessControl.IsMyProfile
            memberId={memberId}
            yes={children => (
              <AccessControl.Can
                resource={Subjects.ProfileSkill}
                action={AccessControlActions.ViewOwn}
              >
                {children}
              </AccessControl.Can>
            )}
            no={children => (
              <AccessControl.Can
                resource={Subjects.ProfileSkill}
                action={AccessControlActions.ViewAny}
              >
                <AccessControl.OnlySubordinateHierarchy
                  accessRole={userType}
                  memberId={memberId}
                >
                  {children}
                </AccessControl.OnlySubordinateHierarchy>
              </AccessControl.Can>
            )}
          >
            <Row>
              <Col>
                <Row>
                  <TopLevelSkills
                    technicalSkills={technicalSkillSet}
                    onChangeTopSkill={setSelectedTopSkill}
                    selectedTopSkill={selectedTopSkill}
                    personalSkills={personalSkillSet}
                  />
                </Row>
                <div
                  className={`${styles.skillCard} ${
                    selectedSkillType === SkillType.Technical
                      ? styles.technical
                      : styles.personal
                  }`}
                >
                  <Row form>
                    <Col md="7" xs="12">
                      <SubSkillScores
                        onClickEdit={handleClickEdit}
                        skillScores={allSkills[selectedTopSkill]}
                      />
                    </Col>
                    <Col md="5" xs="12">
                      <ScoreGuidePreview
                        skillScores={allSkills[selectedTopSkill]}
                      />
                    </Col>
                  </Row>
                </div>
                <SubSkillRatingModal
                  item={allSkills[selectedTopSkill]}
                  scoreId={scoreId}
                  allSkills={allSkills}
                  isOpen={ratingsModalOpen}
                  onToggle={setRatingsModalOpen}
                  syncSkills={fetchAndRehydrateSkillsState}
                />
              </Col>
            </Row>
          </AccessControl.IsMyProfile>
          {props.renderGoalsAndNotesDeck({
            onGoalAchieved: handleGoalAchieved
          })}
        </>
      )}
    </SkillScoresContext.Provider>
  );
}
