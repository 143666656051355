import React, { useContext, useState } from "react";
import { Col, Row } from "reactstrap";
import { Button, AccessControl } from "ui/common";
import { SkillsActionType } from "ui/account/Skills/reducers";
import { Skill } from "ui/account/Skills/types";
import SkillsContext from "util/context/SkillsContext";
import styles from "./TagLine.module.scss";
import TaglineModal from "./TaglineModal";
import { Subjects, AccessControlActions } from "ui/common/types";
import { getLocalized } from "util/localizationUtil";

type TaglineProps = {
  item: Skill;
};

export default function Tagline(props: TaglineProps) {
  const { item } = props;
  const { title, description } = item;

  const dispatch = useContext(SkillsContext);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const editTagline = (skill: Skill) => {
    dispatch({
      type: SkillsActionType.EditTagline,
      payload: {
        skillId: props.item.id,
        skillTitle: skill.title,
        skillDescription: skill.description
      }
    });
  };

  return (
    <>
      <Col xs="12" className={styles.tagline}>
        <Row>
          <Col>
            <p className={styles.taglineTitle}>
              {title !== "" ? title : getLocalized("tag_line.place_holder")}
            </p>
            <p className={styles.description}>
              {description !== ""
                ? description
                : getLocalized("skill.desc_specific_skill")}
            </p>
          </Col>
          <Col xs="auto">
            <AccessControl.Can
              resource={Subjects.ManageSkillsMainSkillDescription}
              action={AccessControlActions.EditAny}
            >
              <Button
                className="text-button primary-text small-button"
                onClick={handleModalToggle}
              >
                {getLocalized("action.edit")}
              </Button>
            </AccessControl.Can>
          </Col>
        </Row>
      </Col>
      <TaglineModal
        isOpen={modalOpen}
        onToggle={handleModalToggle}
        item={item}
        actionType={"EDIT"}
        editAction={editTagline}
      />
    </>
  );
}
