import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "reactstrap";
import { ValueType, ActionMeta } from "react-select/src/types";
import { Formik, FormikActions, Form, Field, FieldArray, getIn } from "formik";
import * as Yup from "yup";
import { SelectCustom, Button, Icon } from "ui/common";
import ScoreSliderCustom from "./ScoreSliderCustom";
import UserContext from "util/context/UserContext";
import { apiResponseStatuses } from "api/core";
import { getJobRolesList } from "services/jobRoles";
import { getSkills } from "services/skills";
import { getTiersList } from "services/tiers";
import {
  DesignationData,
  ScoringGuide,
  Skill,
  SubSkill,
  SubSkillScored,
  RatingScale,
  TierData,
  TierDesignationData,
  ManagerData
} from "ui/account/types";
import { SkillType } from "../constants";
import {
  DEFAULT_ORDER_BY,
  DEFAULT_ORDER_DIRECTION
} from "ui/common/Table/constants";
import styles from "./userFilters.module.scss";
import { getManagersList } from "services/users";
import { getLocalized } from "util/localizationUtil";

export type DesignationMap = { [id: string]: Array<TierDesignationData> };
export type SubSkillMap = { [id: string]: Array<SubSkill> };

type UserFilterFormValues = {
  tier: TierData | string;
  designation: TierDesignationData | string;
  lineManager: ManagerData | string;
  skill: Skill | string;
  subSkills: Array<SubSkillScored> | string;
};

type UserFilter = {
  tierId?: string | null;
  designationId?: string | null;
  skillId?: string | null;
  lineManagerId?: string | null;
  subSkills?: Array<{
    subSkillId: string;
    scoreIndex: Array<number>;
  }>;
};

type UserFiltersProps = {
  getFilters: (userFilter: UserFilter) => void;
  resetFilters: () => void;
};

export default function UserFilters(props: UserFiltersProps) {
  const { accountId, account } = useContext(UserContext);
  const { getFilters, resetFilters } = props;

  // Response data
  const [tiers, setTiers] = useState([]);
  const [jobRoles, setJobRoles] = useState([]);
  const [skills, setSkills] = useState([]);
  const [managers, setManagers] = useState([]);
  // Mapped data from response
  const [mappedJobRoles, setMappedJobRoles] = useState<DesignationMap>({});
  const [mappedSubSkills, setMappedSubSkills] = useState<SubSkillMap>({});
  const [subSkillFilters, setSubSkillFilters] = useState<Array<SubSkill>>([]);

  const ratingScale: RatingScale = getIn(account, `preferences.ratingScale`);

  // Map Designations to Tiers
  const mapDesignationsToTiers = (tiersArr: Array<TierData>) => {
    const mappedDesignations = tiersArr.reduce(
      (acc: DesignationMap, tier: TierData) => ({
        ...acc,
        [tier.id]: [...tier.designations]
      }),
      {}
    );
    setMappedJobRoles(mappedDesignations);
  };

  // Map SubSkills to Skills
  const mapSubSkillsToSkills = (skillsArr: Array<Skill>) => {
    const mappedSubSkills = skillsArr.reduce(
      (acc: SubSkillMap, skill: Skill) => ({
        ...acc,
        [skill.id]: [...skill.subSkills]
      }),
      {}
    );
    setMappedSubSkills(mappedSubSkills);
  };

  useEffect(() => {
    const queryParams = {
      sort: `${DEFAULT_ORDER_BY}:${DEFAULT_ORDER_DIRECTION}`
    };

    // Get all tiers (including designations for a given tier)
    const fetchTiersData = async () => {
      const { status, data } = await getTiersList(accountId, queryParams);
      if (status === apiResponseStatuses.success) {
        setTiers(data);
        data && mapDesignationsToTiers(data);
      }
    };

    // Get all job roles
    const fetchJobRolesData = async () => {
      const { status, data } = await getJobRolesList(accountId, queryParams);
      if (status === apiResponseStatuses.success) {
        setJobRoles(data);
      }
    };

    // Get all managers
    const fetchManagersData = async () => {
      const { status, data } = await getManagersList(accountId, queryParams);
      if (status === apiResponseStatuses.success) {
        setManagers(data);
      }
    };

    // Get technical skills (including subskills for a given skill)
    const fetchSkillsData = async () => {
      const { status, data } = await getSkills(accountId, {
        "filter[type]": SkillType.Technical
      });
      if (status === apiResponseStatuses.success) {
        setSkills(data.results);
        data.results && mapSubSkillsToSkills(data.results);
      }
    };

    fetchTiersData();
    fetchJobRolesData();
    fetchSkillsData();
    fetchManagersData();
  }, [accountId]);

  const getInitialValues = () => {
    return {
      tier: "",
      designation: "",
      skill: "",
      subSkills: "",
      lineManager: ""
    };
  };

  const handleSubmit = async (
    values: UserFilterFormValues,
    { setSubmitting, setStatus }: FormikActions<UserFilterFormValues>
  ) => {
    let filterBody: UserFilter = {
      tierId: null,
      designationId: null,
      lineManagerId: null,
      skillId: null,
      subSkills: []
    };
    if (values.lineManager !== "" && values.lineManager !== null) {
      filterBody["lineManagerId"] = (values.lineManager as ManagerData).id;
    }
    if (values.tier !== "" && values.tier !== null) {
      filterBody["tierId"] = (values.tier as TierData).id;
    }
    if (values.designation !== "" && values.designation !== null) {
      filterBody[
        "designationId"
      ] = (values.designation as TierDesignationData).id;
    }
    if (values.skill !== "" && values.skill !== null) {
      filterBody["skillId"] = (values.skill as Skill).id;
    }
    if (values.subSkills !== "" && values.subSkills !== null) {
      const subSkillsFilters = (values.subSkills as Array<SubSkillScored>).map(
        (item: SubSkillScored) => ({
          subSkillId: item.id,
          scoreIndex: item.scores
        })
      );
      filterBody["subSkills"] = subSkillsFilters;
    }
    getFilters(filterBody);
  };

  const handleSelectedTierValue = (
    value: ValueType<TierData>,
    actionMeta: ActionMeta,
    setFieldValue: (fieldName: string, value: string) => void
  ) => {
    const { action } = actionMeta;
    // Resetting designation field only as its linked to tier selection
    if (action === "select-option") {
      setFieldValue("designation", "");
    } else if (action === "clear") {
      setFieldValue("tier", ""); // clear tiers and designations
      setFieldValue("designation", "");
    }
  };

  const handleSelectedDesignationValue = (
    value: ValueType<DesignationData>,
    actionMeta: ActionMeta,
    setFieldValue: (fieldName: string, value: string) => void
  ) => {
    const { action } = actionMeta;
    if (action === "clear") {
      setFieldValue("designation", ""); // clear designations
    }
  };

  const handleSelectedLineManagerValue = (
    value: ValueType<ManagerData>,
    actionMeta: ActionMeta,
    setFieldValue: (fieldName: string, value: string) => void
  ) => {
    const { action } = actionMeta;
    if (action === "clear") {
      setFieldValue("lineManager", ""); // clear line managers
    }
  };

  const handleSelectedSkillValue = (
    value: ValueType<Skill>,
    actionMeta: ActionMeta,
    setFieldValue: (fieldName: string, value: any) => void
  ) => {
    const { action } = actionMeta;
    // Once a skill is selected, we display the subskill row pre-populated with the first subskill option
    const subSkillFilterArray: SubSkill[] = [];
    if (action === "select-option") {
      // Check if the selected skill has subskills in the map
      mappedSubSkills[(value as Skill).id].length > 0 &&
        subSkillFilterArray.push(mappedSubSkills[(value as Skill).id][0]); // First element of the subskill map
      setFieldValue("subSkills", subSkillFilterArray);
      setSubSkillFilters(mappedSubSkills[(value as Skill).id]);
    } else if (action === "clear") {
      setFieldValue("skill", ""); // clear skills
      setFieldValue("subSkills", "");
      setSubSkillFilters([]);
    }
  };

  // Get all designations or designations of a particular tier
  const getDesignationOptions = (values: UserFilterFormValues) =>
    values.tier && values.tier !== ""
      ? mappedJobRoles[(values.tier as TierData).id]
      : jobRoles;

  // Get sub skill options that are not already selected
  const getSubSkillOptions = (values: SubSkill[]) =>
    subSkillFilters.filter(
      (subSkill: SubSkill) =>
        !values.some((value: SubSkill) => subSkill.id === value.id)
    );

  const isFilterApplicable = (values: UserFilterFormValues, valid: boolean) => {
    if (values.designation || values.lineManager || values.skill || values.tier)
      return valid;

    return false;
  };

  const renderSubSkills = (values: UserFilterFormValues) => {
    return values.subSkills.length > 0 ? (
      <FieldArray
        name="subSkills"
        render={arrayHelpers => (
          <div>
            <div className="w-100">
              <label>{getLocalized("sub_skill.plural")}</label>
            </div>
            <Col xs="12" className={styles.subSkills}>
              <Row>
                <Col sm={{ size: 7, offset: 4 }}>
                  <div className={styles.rail}>
                    {(values.skill as Skill).scoringGuide.data
                      .slice(0)
                      .reverse()
                      .map((value: ScoringGuide, scoringGuideIndex: number) => (
                        <div className={styles.box} key={scoringGuideIndex}>
                          {value.ratingLetter}
                        </div>
                      ))}
                  </div>
                </Col>
              </Row>
              {(values.subSkills as SubSkillScored[]).map(
                (value: SubSkillScored, index: number) => (
                  <Row className="mb-3" key={index}>
                    <Col sm="4" className={styles.skillSelectRemoveMargin}>
                      <Field
                        required
                        value={(values.subSkills[index] as SubSkillScored).name}
                        options={getSubSkillOptions(
                          values.subSkills as SubSkill[]
                        )}
                        getOptionValue={(option: SubSkillScored) => option.id}
                        getOptionLabel={(option: SubSkillScored) => option.name}
                        label={""}
                        name={`subSkills[${index}]`}
                        id={`subSkills[${index}]`}
                        component={SelectCustom}
                      />
                    </Col>
                    <Col sm="7">
                      <Field
                        required
                        value={
                          (values.subSkills[index] as SubSkillScored).scores
                        }
                        name={`subSkills[${index}].scores`}
                        id={`subSkills[${index}].scores`}
                        drag={false}
                        grades={ratingScale}
                        component={ScoreSliderCustom}
                        readOnly={values.subSkills[index] === ""}
                      />
                    </Col>
                    <Col sm="1">
                      <Button
                        type="button"
                        disabled={values.subSkills.length === 1}
                        onClick={() => arrayHelpers.remove(index)}
                        className="icon-button dark mt-1"
                      >
                        <Icon name="clear" />
                      </Button>
                    </Col>
                  </Row>
                )
              )}
              <Row>
                <Col xs="12">
                  <Button
                    type="button"
                    disabled={
                      values.subSkills.length === subSkillFilters.length
                    }
                    onClick={() => arrayHelpers.push("")}
                    className="text-button primary-text small-button"
                  >
                    {getLocalized("sub_skill.add")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </div>
        )}
      />
    ) : (
      <div>{getLocalized("sub_skill.not_available_for_selected_skills")}</div>
    );
  };

  return (
    <Col xs="12" className={styles.userFilters}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={getInitialValues()}
        enableReinitialize
        validationSchema={userFilterValidations}
        render={({ values, resetForm, isValid }) => (
          <Form noValidate>
            <Row form>
              <Col xs="6" sm="4">
                <Field
                  value={values.tier}
                  options={tiers}
                  getOptionValue={(option: TierData) => option.id}
                  getOptionLabel={(option: TierData) => option.title}
                  label={getLocalized("tier")}
                  name="tier"
                  id="tier"
                  component={SelectCustom}
                  isClearable
                  handleSelectedValue={handleSelectedTierValue}
                />
              </Col>
              <Col xs="6" sm="4">
                <Field
                  value={values.designation}
                  options={getDesignationOptions(values)}
                  getOptionValue={(
                    option: TierDesignationData | DesignationData
                  ) => option.id}
                  getOptionLabel={(
                    option: TierDesignationData | DesignationData
                  ) => option.description}
                  label={getLocalized("common.designation")}
                  name="designation"
                  id="designation"
                  component={SelectCustom}
                  isClearable
                  handleSelectedValue={handleSelectedDesignationValue}
                />
              </Col>
              <Col sm="4">
                <Field
                  value={values.skill}
                  options={skills}
                  getOptionValue={(option: Skill) => option.id}
                  getOptionLabel={(option: Skill) => option.name}
                  label={getLocalized("skill")}
                  name="skill"
                  id="skill"
                  component={SelectCustom}
                  isClearable
                  handleSelectedValue={handleSelectedSkillValue}
                />
              </Col>
              <Col sm="4">
                <Field
                  value={values.lineManager}
                  options={managers}
                  getOptionValue={(option: Skill) => option.id}
                  getOptionLabel={(option: Skill) =>
                    option.user &&
                    `${option.user.firstName} ${option.user.lastName}`
                  }
                  label={getLocalized("common.line_manager")}
                  name="lineManager"
                  id="lineManager"
                  component={SelectCustom}
                  isClearable
                  handleSelectedValue={handleSelectedLineManagerValue}
                />
              </Col>
            </Row>
            {values.skill && values.subSkills ? ( // Display sub skill filters only if skill is selected
              <div>{renderSubSkills(values)}</div>
            ) : null}
            <Row>
              <Col className={styles.buttonWrap}>
                <Button
                  type="button"
                  className="primary line small-button"
                  onClick={() => {
                    resetForm(getInitialValues());
                    resetFilters(); // Clears results
                  }}
                >
                  {getLocalized("action.reset")}
                </Button>
                <Button
                  type="submit"
                  disabled={!isFilterApplicable(values, isValid)}
                  className="primary small-button"
                >
                  {getLocalized("filter.apply")}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      />
    </Col>
  );
}

const userFilterValidations = Yup.object().shape({
  // skill: Yup.object().when(["tier", "designation", "lineManager"], {
  //   is: (tier, designation, lineManager) =>
  //     !tier && !designation && !lineManager,
  //   then: Yup.object().required(getLocalized(skillRequired)
  // }),
  subSkills: Yup.mixed().when(["skill"], {
    is: skill => skill,
    then: Yup.array().of(
      Yup.object()
        .transform(function(value, originalvalue) {
          return value !== null ? value : {};
        })
        .test("isRequired", getLocalized("required.sub_skill"), subSkills => {
          let returnObj =
            Object.entries(subSkills).length !== 0 && // check if sub skill is empty obj and scores is available
            subSkills.constructor === Object &&
            subSkills.scores &&
            subSkills.scores.length > 0;

          return returnObj;
        })
    )
  })
});
