import React, { useContext, useState } from "react";
import { CustomInput, Row, Col, Spinner } from "reactstrap";
import { SkillsActionType } from "ui/account/Skills/reducers";
import { updateSkill } from "services/skills";
import SkillsContext from "util/context/SkillsContext";
import UserContext from "util/context/UserContext";
import { Skill, SkillGroup } from "ui/account/Skills/types";
import { apiResponseStatuses } from "api/core";
import styles from "./SkillsHeader.module.scss";
import { AccessControlActions, Subjects } from "ui/common/types";
import useCan from "util/hooks/useCan";
import { getLocalized } from "util/localizationUtil";

export type SkillsHeaderProps = {
  isOpened: boolean;
  item: Skill;
};

export default function SkillsHeader(props: SkillsHeaderProps) {
  const { item } = props;
  const dispatch = useContext(SkillsContext);
  const { accountId } = useContext(UserContext);

  const canActivateSkill = useCan({
    resource: Subjects.ManageSkillsMainSkill,
    action: AccessControlActions.Activate
  });

  const [isStatusChangeLoading, setIsStatusChangeLoading] = useState(false);

  const handleChange = async () => {
    setIsStatusChangeLoading(true);
    const requestBody = {
      // check if skill is fundamental or structural
      group:
        item.group === SkillGroup.Fundamental
          ? SkillGroup.Structural
          : SkillGroup.Fundamental
    };
    const { status } = await updateSkill(accountId, item.id, requestBody);
    if (status === apiResponseStatuses.success) {
      dispatch({
        type: SkillsActionType.ToggleSkillGroup,
        payload: { skillId: props.item.id }
      });
    }
    setIsStatusChangeLoading(false);
  };

  return (
    <Row className={`${styles.skillsHeader} align-items-center`}>
      <Col md="3" lg="3" xl="2">
        <p className={styles.name}>{item.name}</p>
      </Col>
      <Col className="text-md-right text-left">
        {isStatusChangeLoading ? (
          <Spinner size="sm" color="primary" />
        ) : (
          <CustomInput
            type="checkbox"
            id={item.name}
            checked={item.group === SkillGroup.Fundamental ? true : false}
            onChange={handleChange}
            label={getLocalized("common.fundamental")}
            disabled={!canActivateSkill}
          />
        )}
      </Col>
    </Row>
  );
}
