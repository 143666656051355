import { Subjects } from "ui/common/types";

const sidebarTopRoutes = [
  {
    url: "/dashboard",
    exact: true,
    name: "Dashboard",
    icon: "dashboard",
    page: Subjects.DashboardPage
  },
  {
    url: "/manage-users",
    name: "Manage users",
    icon: "supervisor_account",
    page: Subjects.ManageUsersPage
  },
  {
    url: "/manage-tiers",
    name: "Manage tiers",
    icon: "line_weight",
    page: Subjects.ManageTiersPage
  },
  {
    url: "/manage-skills",
    name: "Manage skills",
    icon: "local_activity",
    page: Subjects.ManageSkillsPage
  },
  {
    url: "/manage-job-roles",
    name: "Manage job roles",
    icon: "business_center",
    page: Subjects.ManageJobRolesPage
  },
  {
    url: "/reports",
    name: "Reports",
    icon: "bar_chart",
    page: Subjects.ReportsPage
  }
];

const sidebarBottomRoute = [
  {
    url: "/settings",
    name: "Settings",
    icon: "perm_data_setting",
    page: Subjects.SettingsPage
  }
];
export { sidebarTopRoutes, sidebarBottomRoute };
