import React, { useState, useEffect, useContext } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from "reactstrap";
import { Field, Formik, Form, FormikActions } from "formik";
import * as Yup from "yup";
import { InputCustom, SelectCustom, Button, Toast } from "ui/common";
import { createJobRole, updateJobRole } from "services/jobRoles";
import { getTiersList } from "services/tiers";
import { modalActionTypes } from "../constants";
import { apiResponseStatuses } from "../../constants";
import {
  ActionModalProps,
  ModalItem,
  ModalActionTypes,
  TierData,
  TierDesignationData
} from "../../types";
import UserContext from "util/context/UserContext";
import { entityStatus } from "ui/account/constants";
import {
  DEFAULT_ORDER_BY,
  DEFAULT_ORDER_DIRECTION
} from "ui/common/Table/constants";
import { getLocalized } from "util/localizationUtil";

type JobRolesFormValues = {
  tier: { id: string } | string;
  jobRole: string;
  jobDescription: string;
};

export default function JobRolesModal(props: ActionModalProps) {
  const jobRolesModalValidations = Yup.object({
    tier: Yup.mixed()
      .test(
        "hasTier",
        getLocalized("required.tier"),
        type => type && type.id !== undefined
      )
      .required(getLocalized("required.tier")),
    jobRole: Yup.string().required(getLocalized("required.job_role.name"))
  });

  const getModalTitle = (actionType: ModalActionTypes) => {
    return actionType === modalActionTypes.EDIT
      ? getLocalized("job_role.edit")
      : getLocalized("job_role.add");
  };

  const getModalActionTitle = (actionType: ModalActionTypes) => {
    return actionType === modalActionTypes.EDIT
      ? getLocalized("action.save")
      : getLocalized("action.add");
  };

  const { isOpen, onToggle } = props;
  const { accountId } = useContext(UserContext);
  const [tiersList, setTiersList] = useState<Array<TierData>>([]);
  const [actionTypePersist, setActionTypePersist] = useState<ModalActionTypes>(
    props.actionType
  );

  useEffect(() => {
    const fetchData = async () => {
      const queryParams = {
        sort: `${DEFAULT_ORDER_BY}:${DEFAULT_ORDER_DIRECTION}`
      };
      const { status, data } = await getTiersList(accountId, queryParams);
      if (status === apiResponseStatuses.success) {
        setTiersList(data);
      }
    };
    isOpen && fetchData(); // fetch tiers list when modal open
  }, [accountId, isOpen]);

  useEffect(() => {
    isOpen && setActionTypePersist(props.actionType);
  }, [isOpen, props.actionType]);

  /**
   * We get a DesignationData type response here, so the tier
   * data inside it is of the following format { tierId: string, tierTitle:string, ...}
   * We assign that to TierData type, of OptionType because
   * dropdown response for tiers dropdown gives out response of that type
   */
  const getInitialValues = () => {
    const item = props.item as ModalItem<TierDesignationData>;
    if (item) {
      return {
        tier: item.tier,
        jobRole: item.title,
        jobDescription: item.description
      };
    }
    return {
      tier: "",
      jobRole: "",
      jobDescription: ""
    };
  };

  const handleToggle = () => onToggle(actionTypePersist);

  const handleExit = () => {
    setActionTypePersist(props.actionType);
  };

  const handleSubmit = async (
    values: JobRolesFormValues,
    { setSubmitting, setStatus }: FormikActions<JobRolesFormValues>
  ) => {
    setStatus(null);
    const item = props.item as ModalItem<TierDesignationData>;
    const requestBody = {
      title: values.jobRole,
      description: values.jobDescription,
      tier_id: (values.tier as { id: string }).id,
      status: entityStatus.active
    };
    const { data, status } =
      props.actionType === modalActionTypes.EDIT && item
        ? await updateJobRole(accountId, item.id, requestBody)
        : await createJobRole(accountId, requestBody);
    if (status === apiResponseStatuses.success) {
      Toast.success(
        props.actionType === modalActionTypes.EDIT
          ? getLocalized("job_role.update_success")
          : getLocalized("job_role.add_success")
      );
      onToggle(actionTypePersist, null, true);
      setStatus(null);
    } else {
      setStatus(data.message || getLocalized("common.something_went_wrong"));
      Toast.error(
        props.actionType === modalActionTypes.EDIT
          ? getLocalized("job_role.update_failed")
          : getLocalized("job_role.add_failed")
      );
    }
    setSubmitting(false);
  };

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} onExit={handleExit}>
      <ModalHeader toggle={handleToggle}>
        {getModalTitle(actionTypePersist)}
      </ModalHeader>
      <Formik
        onSubmit={handleSubmit}
        initialValues={getInitialValues()}
        enableReinitialize
        validationSchema={jobRolesModalValidations}
        render={({ values, status }) => {
          return (
            <Form noValidate>
              <ModalBody>
                <div>
                  <Alert color="danger" isOpen={!!status}>
                    {status}
                  </Alert>
                </div>
                <Field
                  required
                  id="tier"
                  name="tier"
                  label={getLocalized("tier")}
                  component={SelectCustom}
                  options={tiersList && tiersList}
                  getOptionValue={
                    (option: TierData) => option.id // Setting id as the value
                  }
                  getOptionLabel={
                    (option: TierData) => option.title // Setting title as the label
                  }
                  values={values.tier}
                />
                <Field
                  required
                  id="jobRole"
                  label={getLocalized("job_role.name")}
                  name="jobRole"
                  autoComplete="jobRole"
                  component={InputCustom}
                />
                <Field
                  id="jobDescription"
                  multiline="true"
                  type="textarea"
                  label={getLocalized("common.job_description")}
                  name="jobDescription"
                  autoComplete="jobDescription"
                  component={InputCustom}
                />
              </ModalBody>
              <ModalFooter>
                <Button type="submit" className="primary">
                  {getModalActionTitle(actionTypePersist)}
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      />
    </Modal>
  );
}
