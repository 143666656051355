export const BASE_URL = process.env.REACT_APP_BASE_URL || "";
export const BASE_IMAGE_URL = process.env.REACT_APP_BASE_IMAGE_URL || "";
export const BASE_NOTES_ATTACHMENT_URL =
  process.env.REACT_APP_BASE_NOTES_ATTACHMENT_URL || "";

/* auth */
export const LOGIN_URL = "auth/login";
export const FORGOT_PASSWORD_URL = "auth/forgot-password";
export const CHANGE_PASSWORD_URL = "auth/change-password";
export const SET_PASSWORD_URL =
  "auth/set-password?token={token}&tokenType={tokenType}";
export const LOGOUT_URL = "auth/logout";
export const REFRESH_URL = "auth/refresh";

/* permissions */
export const GET_PERMISSIONS_URL = "accounts/{accountId}/permissions";

/* account */
export const UPDATE_ACCOUNT_URL = "accounts/{accountId}";

/* job roles (designations) */
export const GET_JOB_ROLES_URL = "accounts/{accountId}/designations";
export const CREATE_JOB_ROLE_URL = "accounts/{accountId}/designations";
export const UPDATE_JOB_ROLE_URL =
  "accounts/{accountId}/designations/{designationId}";
export const DELETE_JOB_ROLE_URL =
  "accounts/{accountId}/designations/{designationId}";

/* tiers */
export const GET_TIERS_URL = "accounts/{accountId}/tiers";
export const CREATE_TIER_URL = "accounts/{accountId}/tiers";
export const UPDATE_TIER_URL = "accounts/{accountId}/tiers/{tierId}";
export const DELETE_TIER_URL = "accounts/{accountId}/tiers/{tierId}";

/* users (members) */
export const GET_USERS_URL = "accounts/{accountId}/members";
export const GET_MY_TEAM_USERS_URL = "accounts/{accountId}/my-team";
export const GET_FILTERED_USERS_URL = "accounts/{accountId}/member/filter";
export const GET_MANAGERS_URL = "accounts/{accountId}/members/manager";
export const GET_USER_URL = "accounts/{accountId}/members/{memberId}";
export const UPLOAD_USER_IMAGE_URL =
  "accounts/{accountId}/members/image/{memberId}";
export const GET_USER_IMAGE_URL =
  "accounts/{accountId}/members/image/{memberId}";

/* notes */
export const CREATE_NOTE_URL = "accounts/{accountId}/notes";
export const GET_NOTES_URL = "accounts/{accountId}/notes";
export const DELETE_NOTE_URL = "accounts/{accountId}/notes/{noteId}";
export const UPLOAD_NOTE_ATTACHMENT_URL =
  "accounts/{accountId}/notes/attachment";

/* goals */
export const GET_GOALS_URL = "accounts/{accountId}/goals";
export const CREATE_GOAL_URL = "accounts/{accountId}/goals";
export const UPDATE_GOAL_URL = "accounts/{accountId}/goals/{goalId}";
export const DELETE_GOAL_URL = "accounts/{accountId}/goals/{goalId}";

/* skills */
export const GET_SKILLS_URL = "accounts/{accountId}/skills";
export const UPDATE_SKILL_URL = "accounts/{accountId}/skills/{skillId}";

/* scores */
export const GET_SCORES_URL = "accounts/{accountId}/members/{memberId}/scores";
export const CREATE_SCORES_URL =
  "accounts/{accountId}/members/{memberId}/scores";
export const UPDATE_SCORES_URL =
  "accounts/{accountId}/members/{memberId}/scores/{scoreId}";

/* sub skills */
export const CREATE_SUB_SKILL =
  "accounts/{accountId}/skills/{skillId}/subSkill";
export const UPDATE_SUB_SKILL =
  "accounts/{accountId}/skills/{skillId}/subSkill/{subSkillId}";
export const DELETE_SUB_SKILL =
  "accounts/{accountId}/skills/{skillId}/subSkill/{subSkillId}";

/* reports */
export const GET_REPORTS_URL = "accounts/{accountId}/members/report";

/* graphs */
export const GET_QUADRANT_DATA_URL =
  "accounts/{accountId}/members/graphs/quadrant";
export const GET_INDIVIDUAL_QUADRANT_DATA_URL =
  "accounts/{accountId}/members/{memberId}/graphs/quadrant";
export const GET_DISTRIBUTION_DATA_URL =
  "accounts/{accountId}/members/graphs/bell-curve";
export const GET_SCORE_PROGRESSION_DATA_URL =
  "accounts/{accountId}/members/{memberId}/progression";

// Method to replace url placeholders
export const getUrl = (url: string, placeholders: any) => {
  Object.keys(placeholders).forEach(key => {
    url = url.replace(`{${key}}`, placeholders[key]);
  });

  return url;
};
