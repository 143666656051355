import React, {
  ReactNode,
  Fragment,
  useEffect,
  useMemo,
  useState
} from "react";
import DataTable from "react-data-table-component";
import { ConfirmationModal, Loader, AccessControl } from "ui/common";
import {
  ActionModalProps,
  ModalActionTypes,
  Nullable,
  TableData,
  AccessControlActions,
  Subjects
} from "../types";
import {
  DEFAULT_RESULTS_LIMIT,
  DEFAULT_PAGE_NUMBER,
  modalActionTypes,
  tableEntityTypes
} from "./constants";
import RowActionButton from "./RowActionButton";
import NoDataLabel from "../NoDataLabel";
import { getLocalized } from "util/localizationUtil";

type DeleteItem = {
  id: string;
  name: string;
  hasDesignations?: boolean;
};

type TableProps = {
  headers: Array<any>; // change
  tableData: Array<TableData>;
  dataCount?: number;
  onReload: (forceRefresh: boolean) => void;
  sortInServer: boolean;
  getSortParam?: (sortParam: string) => void;
  paginationInServer: boolean;
  getPaginationParam?: (paginationParam: string) => void;
  actionModal?: React.ComponentType<ActionModalProps>;
  entityType: string;
  deleteAction?: (id: string) => void;
  showActions: boolean;
  deleteContent?: ReactNode | string;
  noDataText?: string;
  onRowClicked?: (data: TableData) => void;
  highlightOnHover: boolean;
  pointerOnHover: boolean;
  isFetching?: boolean;
  resource?: Subjects;
  checkPermissions: boolean;
};

export default function Table(props: TableProps) {
  const {
    headers,
    tableData,
    dataCount,
    onReload,
    sortInServer,
    getSortParam,
    paginationInServer,
    getPaginationParam,
    actionModal,
    entityType,
    deleteAction,
    showActions,
    deleteContent,
    noDataText,
    onRowClicked,
    highlightOnHover,
    pointerOnHover,
    isFetching,
    checkPermissions,
    resource
  } = props;

  const [data, setData] = useState<Array<TableData>>([]); // table data
  const [page, setPage] = useState(DEFAULT_PAGE_NUMBER);

  // Action modal (edit and delete)
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<Nullable<TableData>>(null);
  const [modalActionType, setModalActionType] = useState<ModalActionTypes>(
    "CREATE"
  );
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState<Nullable<DeleteItem>>({
    id: "",
    name: ""
  });
  const ActionModal = actionModal;

  const skipPermissionCheck = !checkPermissions;

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  // row action button columns (edit and delete)
  const rowActionColumns = [
    {
      name: "",
      button: true,
      center: true,
      width: "120px",
      cell: (row: TableData) => (
        <>
          <AccessControl.IsMyProfile
            skipCheck={skipPermissionCheck}
            memberId={row.id}
            yes={children => (
              <AccessControl.Can
                skipCheck={skipPermissionCheck}
                resource={resource}
                action={AccessControlActions.EditOwn}
              >
                {children}
              </AccessControl.Can>
            )}
            no={children => (
              <AccessControl.Can
                skipCheck={skipPermissionCheck}
                resource={resource}
                action={AccessControlActions.EditAny}
              >
                {children}
              </AccessControl.Can>
            )}
          >
            <RowActionButton
              onClick={() => onHandleEditClick(row)}
              icon="create"
              toolTipText={getLocalized("action.edit")}
              color="warning"
            />
          </AccessControl.IsMyProfile>

          <AccessControl.IsMyProfile
            skipCheck={skipPermissionCheck}
            memberId={row.id}
            yes={children => (
              <AccessControl.Can
                skipCheck={skipPermissionCheck}
                resource={resource}
                action={AccessControlActions.DeleteOwn}
              >
                {children}
              </AccessControl.Can>
            )}
            no={children => (
              <AccessControl.Can
                skipCheck={skipPermissionCheck}
                resource={resource}
                action={AccessControlActions.DeleteAny}
              >
                {children}
              </AccessControl.Can>
            )}
          >
            <RowActionButton
              onClick={() => onHandleDeleteClick(row)}
              icon="delete"
              toolTipText={getLocalized("action.delete")}
              color="danger"
            />
          </AccessControl.IsMyProfile>
        </>
      )
    }
  ];

  const columns = useMemo(() => {
    const rowColumn = showActions ? rowActionColumns : [];
    return [...headers, ...rowColumn];
  }, [headers, rowActionColumns, showActions]);

  const handleModalToggle = (
    actionType?: ModalActionTypes | null,
    currentItem?: TableData | null,
    forceRefresh?: boolean | null
  ) => {
    if (currentItem && actionType === modalActionTypes.EDIT) {
      setModalOpen(prevModalOpen => !prevModalOpen);
      setModalData(currentItem);
      setModalActionType(actionType);
    } else {
      setModalOpen(false);
      if (forceRefresh) {
        onReload(forceRefresh);
      }
    }
  };

  const handleExit = () => setDeleteItem(null);

  const handleConfirm = () => {
    deleteAction && deleteItem && deleteAction(deleteItem.id);
    setConfirmationModalOpen(false);
  };

  const handleCancel = () => setConfirmationModalOpen(false);

  const handleDeleteConfirmationToggle = (
    open: boolean = false,
    item?: any
  ) => {
    let itemDelete = null;
    if (item) {
      if (
        entityType === tableEntityTypes.USERS &&
        item.hasOwnProperty("user")
      ) {
        itemDelete = {
          id: item.id,
          name: `${item.user.firstName} ${item.user.lastName}`
        };
      } else if (
        entityType === tableEntityTypes.TIERS &&
        item.hasOwnProperty("designations")
      ) {
        itemDelete = {
          id: item.id,
          name: item.title,
          hasDesignations: item.designations.length > 0
        };
      } else if (item.hasOwnProperty("title")) {
        itemDelete = {
          id: item.id,
          name: item.title
        };
      }
    }
    setDeleteItem(itemDelete);
    setConfirmationModalOpen(open);
  };

  // On edit row item
  const onHandleEditClick = (row: TableData) => {
    if (actionModal) {
      handleModalToggle("EDIT", row);
    }
  };

  // On delete row item
  const onHandleDeleteClick = (row: TableData) => {
    handleDeleteConfirmationToggle(true, row);
  };

  const onHandleSort = (column: any, sortDirection: any, event: any) => {
    if (sortInServer) {
      const orderBy = column.selector;
      const orderDirection = sortDirection;
      getSortParam && getSortParam(`${orderBy}:${orderDirection}`);
    }
  };

  const onHandlePagination = (page: number) => {
    if (paginationInServer) {
      getPaginationParam &&
        getPaginationParam(`${page - 1}:${DEFAULT_RESULTS_LIMIT}`);
    }
    setPage(page);
  };

  const onHandleChangeRowsPerPage = (
    currentRowsPerPage: number,
    currentPage: number
  ) => {
    if (paginationInServer) {
      getPaginationParam &&
        getPaginationParam(`${currentPage - 1}:${currentRowsPerPage}`);
    }
    setPage(currentPage);
  };

  const getBodyContent = (item?: any) => {
    if (entityType === tableEntityTypes.TIERS) {
      return item && item.hasDesignations
        ? deleteContent
        : `${getLocalized("common.delete_prompt")} "${item && item.name}"?`;
    }
    return `${getLocalized("common.delete_prompt")} "${item && item.name}"?`;
  };

  const tierHasDesignations =
    entityType === tableEntityTypes.TIERS &&
    deleteItem &&
    deleteItem.hasDesignations;

  // using total count for server pagination and data array length for local pagination
  const doPaginate = Boolean(
    paginationInServer
      ? dataCount !== undefined && dataCount > DEFAULT_RESULTS_LIMIT
      : data !== undefined && data.length > DEFAULT_RESULTS_LIMIT
  );

  return (
    <Fragment>
      <div className="w-100 table-wrapper">
        <DataTable
          noHeader
          columns={columns}
          data={data}
          pagination={doPaginate}
          currentPage={page}
          onChangePage={onHandlePagination}
          onChangeRowsPerPage={onHandleChangeRowsPerPage}
          paginationTotalRows={
            // using total count for server pagination and data array length for local pagination
            paginationInServer ? dataCount : data && data.length
          }
          paginationPerPage={DEFAULT_RESULTS_LIMIT}
          paginationServer={paginationInServer}
          onSort={onHandleSort}
          sortServer={sortInServer}
          noDataComponent={<NoDataLabel noDataLabel={noDataText} />}
          progressPending={isFetching}
          progressComponent={<Loader />}
          onRowClicked={onRowClicked}
          highlightOnHover={highlightOnHover}
          pointerOnHover={pointerOnHover}
          responsive
        />
      </div>
      {ActionModal && (
        <ActionModal
          isOpen={modalOpen}
          onToggle={handleModalToggle}
          item={modalData}
          actionType={modalActionType}
        />
      )}
      <ConfirmationModal
        item={deleteItem}
        renderTitle={
          tierHasDesignations
            ? undefined
            : () => getLocalized("warning.irreversible_action")
        }
        renderBody={getBodyContent}
        onToggle={handleCancel}
        onExit={handleExit}
        isOpen={confirmationModalOpen}
        onCancel={tierHasDesignations ? undefined : handleCancel}
        onConfirm={tierHasDesignations ? handleCancel : handleConfirm}
        cancelTitle={
          tierHasDesignations
            ? undefined
            : getLocalized("common.decline_operation")
        }
        confirmTitle={
          tierHasDesignations
            ? getLocalized("common.okay")
            : getLocalized("common.confirm_delete")
        }
      />
    </Fragment>
  );
}

Table.defaultProps = {
  sortInServer: false,
  paginationInServer: false,
  showActions: true,
  highlightOnHover: false,
  pointerOnHover: false,
  noDataText: getLocalized("common.no_data_now"),
  checkPermissions: false
};
