import React, { useState, useCallback, ReactNode, useContext } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { Col, Row } from "reactstrap";
import { Button } from "ui/common";
import { debouncePromise } from "util/helpers";
import { getLocalized } from "util/localizationUtil";
import Icon from "../Icon";
import { Nullable } from "../types";
import UserContext from "util/context/UserContext";
import styles from "./SearchBar.module.scss";
import { Action, Suggestion } from "./types";

type SearchBarProps = {
  searchByLabel: string;
  suggestionList: Array<Suggestion>;
  getSearchTerm: (searchTerm: string) => void;
  searchOnServer: boolean;
  suggestionEndpoint?: Function;
  displaySearchFilters: boolean;
  children?: ReactNode;
};

export default function SearchBar(props: SearchBarProps) {
  const { accountId } = useContext(UserContext);
  const {
    searchByLabel,
    suggestionList,
    getSearchTerm,
    searchOnServer,
    suggestionEndpoint,
    displaySearchFilters,
    children
  } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [selection, setSelection] = useState<Nullable<Suggestion>>(null);
  const [displayFilters, toggleFilter] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  let selectRef: any = null;
  let asyncSelectRef: any = null;

  const toggleSearchFilters = () => {
    toggleFilter(!displayFilters);
  };

  // On search button click
  const handleSearch = () => {
    setMenuIsOpen(false);
    selection ? getSearchTerm(selection.label) : getSearchTerm(searchTerm);
  };

  const handleClear = () => {
    setMenuIsOpen(false);
    setSelection(null);
    setSearchTerm("");
    getSearchTerm("");
  };

  const suggestionsWithSearchBy = [
    {
      value: null,
      label: `${getLocalized("search.by")} "${searchTerm}"`,
      searchTerm
    },
    ...suggestionList
  ];

  const formatSuggestions = (result: any) =>
    result.map(
      (item: any) =>
        item.user && {
          value: `${item.user.firstName} ${item.user.lastName}`,
          label: `${item.user.firstName} ${item.user.lastName}`
        }
    );

  const fetchSuggestions = (inputValue: string) => {
    const queryParams = {
      q: encodeURIComponent(inputValue)
    };
    return suggestionEndpoint && suggestionEndpoint(accountId, queryParams);
  };

  const debounceGetSuggestions = useCallback(
    debouncePromise(fetchSuggestions, 500),
    []
  );

  const loadSuggestions = (inputValue: string, callback: any) => {
    if (!inputValue) {
      return callback([]);
    }
    return debounceGetSuggestions(inputValue).then((response: any) => {
      let result = response.data.results;
      result = result && formatSuggestions(result);
      if (searchTerm)
        result = [
          {
            value: null,
            label: `${getLocalized("search.by")} "${searchTerm}"`,
            searchTerm
          },
          ...result
        ];
      callback(result);
    });
  };

  // on select suggested option
  const onChangeSearchTerm = (selectedOption: Suggestion) => {
    if (selectedOption) {
      setMenuIsOpen(false);
      let selection = selectedOption;
      if (selectedOption.value === null) {
        selection = {
          value: selection.searchTerm || "",
          label: selection.searchTerm || ""
        };
      }
      setSearchTerm("");
      setSelection(selection);
    }
  };

  // on change input value
  const onInputChange = (newValue: string, action: Action) => {
    if (action.action === "input-change") {
      setMenuIsOpen(!!newValue);
      setSearchTerm(newValue);
      setSelection(null);
    }
  };

  return (
    <>
      <Row className={styles.searchBarWrap}>
        <Col>
          <div className={styles.prependButtonWrap}>
            <Button
              onClick={handleSearch}
              title={getLocalized("search")}
              className="icon-button dark"
            >
              <Icon name="search" />
            </Button>
          </div>
          {searchOnServer ? ( // whether to call api search endpoint or search locally
            <AsyncSelect
              ref={ref => (asyncSelectRef = ref)}
              onKeyDown={event => {
                // handle search on enter key press
                if (!asyncSelectRef.state.menuIsOpen && "Enter" === event.key) {
                  handleSearch();
                }
              }}
              placeholder={`${getLocalized("search.by")} ${getLocalized(
                "NAME"
              )}`}
              value={selection}
              inputValue={searchTerm}
              cacheOptions
              loadOptions={loadSuggestions}
              defaultOptions
              onChange={(option: any) => onChangeSearchTerm(option)}
              onInputChange={onInputChange}
              classNamePrefix="search-bar"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null
              }}
              menuIsOpen={menuIsOpen}
              isClearable
              onFocus={() => {
                setSearchTerm(selection ? selection.label : "");
              }}
              blurInputOnSelect // dismissing the keyboard on touch devices
            />
          ) : (
            <Select
              ref={ref => (selectRef = ref)}
              onKeyDown={event => {
                // handle search on enter key press
                if (selectRef.state.menuIsOpen && "Enter" === event.key) {
                  handleSearch();
                }
              }}
              classNamePrefix="search-bar"
              isSearchable
              placeholder={`${getLocalized("search.by")} ${searchByLabel}`}
              value={selection}
              inputValue={searchTerm}
              onChange={(option: any) => onChangeSearchTerm(option)}
              onInputChange={onInputChange}
              options={suggestionsWithSearchBy}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null
              }}
              menuIsOpen={menuIsOpen}
              blurInputOnSelect // dismissing the keyboard on touch devices
              onFocus={() => {
                setSearchTerm(selection ? selection.label : "");
              }}
            />
          )}
          <div className={styles.buttonWrap}>
            {(searchTerm.length > 0 || selection) && (
              <Button
                color="light"
                onClick={handleClear}
                title={getLocalized("action.clear")}
                className={`icon-button dark ${styles.close}`}
              >
                <Icon name="close" />
              </Button>
            )}

            {displaySearchFilters ? (
              <>
                <span className={styles.line} />
                <Button
                  className={`icon-button dark ${styles.filter}`}
                  onClick={toggleSearchFilters}
                  title={
                    displayFilters
                      ? getLocalized("filter.hide")
                      : getLocalized("filter.show")
                  }
                >
                  {displayFilters ? (
                    <Icon name="keyboard_arrow_up" />
                  ) : (
                    <Icon name="keyboard_arrow_down" />
                  )}
                </Button>
              </>
            ) : null}
          </div>
        </Col>
      </Row>
      {displayFilters ? children : null}
    </>
  );
}

SearchBar.defaultProps = {
  searchByLabel: getLocalized("common.name"),
  suggestionList: [],
  searchOnServer: false,
  displaySearchFilters: false
};
