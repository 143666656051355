import React from "react";

type IconProps = {
  name: string;
  cssClass?: string;
  title?: string;
  onClick?: () => void;
};

export default function Icon(props: IconProps) {
  const { name, cssClass } = props;

  return (
    <i
      title={props.title}
      className={`material-icons-round ${cssClass && cssClass}`}
      onClick={props.onClick}
    >
      {name}
    </i>
  );
}
