import React, { useContext } from "react";
import { Row, Col } from "reactstrap";
import {
  Button,
  SkillScoreSlider,
  NoDataLabel,
  AccessControl
} from "ui/common";
import { SkillType } from "ui/account/Skills/types";
import UserContext from "util/context/UserContext";
import { getIn } from "formik";
import { RatingScaleItem, RatingScale } from "ui/account/types";
import { SkillScores, SubSkill } from "../types";
import styles from "./SubSkillScores.module.scss";
import { Subjects, AccessControlActions } from "ui/common/types";
import { useParams } from "react-router-dom";
import { getLocalized } from "util/localizationUtil";

type SubSkillScoresProps = {
  skillScores: Required<SkillScores>;
  onClickEdit: () => void;
};

export default function SubSkillScores(props: SubSkillScoresProps) {
  const { account } = useContext(UserContext);
  const { userId: memberId } = useParams();

  const { skillScores, onClickEdit } = props;

  const ratingScale: RatingScale = getIn(account, `preferences.ratingScale`);
  const renderGradeLabels = (
    <div className={styles.letterRail}>
      {Object.values(ratingScale).map(
        (_, index: number, array: RatingScaleItem[]) => (
          <div className={styles.box} key={index}>
            {array[array.length - 1 - index].ratingLetter}
          </div>
        )
      )}
    </div>
  );

  const hasSubSkills =
    skillScores.skill.type ===
      SkillType.Personal /* personal skills don't have subskills to this is always true for personal skills */ ||
    (skillScores.skill.type === SkillType.Technical &&
      skillScores.subSkills &&
      skillScores.subSkills.length > 0);

  return (
    <Col xs="12" className={styles.subsWrapper}>
      <Row>
        <Col xs="auto" className={styles.title}>
          {skillScores.skill.name}
        </Col>
        <Col className="text-right mb-3">
          <AccessControl.IsMyProfile
            memberId={memberId}
            yes={children => (
              <AccessControl.Can
                resource={Subjects.ProfileSkill}
                action={AccessControlActions.EditOwn}
              >
                {children}
              </AccessControl.Can>
            )}
            no={children => (
              <AccessControl.Can
                resource={Subjects.ProfileSkill}
                action={AccessControlActions.EditAny}
              >
                <AccessControl.OnlySubordinates memberId={memberId}>
                  {children}
                </AccessControl.OnlySubordinates>
              </AccessControl.Can>
            )}
          >
            <Button
              disabled={!hasSubSkills}
              className="text-button primary-text small-button"
              onClick={onClickEdit}
            >
              {getLocalized("action.edit")}
            </Button>
          </AccessControl.IsMyProfile>
        </Col>
      </Row>
      <>
        {skillScores.skill.type === SkillType.Personal ? (
          <div className={`${styles.scores} ${styles.personal}`}>
            <Row>
              <Col xs="12" className="mb-2">
                {renderGradeLabels}
              </Col>
            </Row>
            <Row>
              <Col>
                <SkillScoreSlider
                  value={[skillScores.scoreIndex]}
                  readOnly
                  grades={ratingScale}
                />
              </Col>
            </Row>
          </div>
        ) : (
          <div className={`${styles.scores} ${styles.technical}`}>
            {hasSubSkills ? (
              <>
                <Row>
                  <Col xs={{ size: 9, offset: 3 }}>{renderGradeLabels}</Col>
                </Row>
                {skillScores.subSkills.map((subSkill: SubSkill) => (
                  <Row key={subSkill.subSkillId}>
                    <Col md="3" className={styles.skillName}>
                      {subSkill.subSkill.name}
                    </Col>
                    <Col md="9">
                      <SkillScoreSlider
                        value={[subSkill.scoreIndex]}
                        readOnly
                        grades={ratingScale}
                      />
                    </Col>
                  </Row>
                ))}
              </>
            ) : (
              <NoDataLabel
                noDataLabel={getLocalized(
                  "sub_skill.not_available_for_top_level_skill"
                )}
              />
            )}
          </div>
        )}
      </>
    </Col>
  );
}
