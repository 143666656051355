import Tiers from "./Tiers";
import JobRoles from "./JobRoles";
import Skills from "./Skills";
import Reports from "./Reports";
import Settings from "./Settings";
import { Subjects } from "ui/common/types";
import { ProfileMemberType } from "ui/account/types";
import UserProfile from "./Users/UserProfile";
import Users from "./Users";
// import Home from "./Home";
import Dashboard from "./Dashboard";
import MyTeam from "./MyTeam";

const profilePathRegex = Object.values(ProfileMemberType).join("|");
export const routes = [
  {
    path: "/dashboard",
    exact: true,
    name: "Dashboard",
    component: Dashboard,
    icon: "dashboard",
    page: Subjects.DashboardPage
  },
  {
    path: `/profile/:userType(${profilePathRegex})/:userId`,
    name: "Profile",
    component: UserProfile,
    icon: "local_activity",
    page: Subjects.ProfilePage
  },
  {
    path: `/my-team`,
    name: "My team",
    component: MyTeam,
    icon: "local_activity",
    page: Subjects.ManageUsersPage
  },
  {
    path: "/manage-users",
    name: "Manage users",
    component: Users,
    icon: "supervisor_account",
    page: Subjects.ManageUsersPage
  },
  {
    path: "/manage-tiers",
    name: "Manage tiers",
    component: Tiers,
    icon: "line_weight",
    page: Subjects.ManageTiersPage
  },
  {
    path: "/manage-skills",
    name: "Manage skills",
    component: Skills,
    icon: "local_activity",
    page: Subjects.ManageSkillsPage
  },

  {
    path: "/manage-job-roles",
    name: "Manage job roles",
    component: JobRoles,
    icon: "business_center",
    page: Subjects.ManageJobRolesPage
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
    icon: "bar_chart",
    page: Subjects.ReportsPage
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    icon: "perm_data_setting",
    page: Subjects.SettingsPage
  }
];
