import { getUrl, GET_REPORTS_URL } from "config/endpoints";
import { getRequest } from "api";

export const getReportsList = async (
  accountId: string,
  queryParams?: { [param: string]: any } | null
) => {
  const url = getUrl(GET_REPORTS_URL, { accountId });
  return getRequest(url, queryParams);
};
