import React, { useEffect, useState, useContext } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from "reactstrap";
import { Field, Formik, Form, FormikActions } from "formik";
import * as Yup from "yup";
import { InputCustom, Button, Toast } from "ui/common";
import { updateTier, createTier } from "services/tiers";
import { modalActionTypes } from "../constants";
import {
  ActionModalProps,
  ModalItem,
  TierData,
  ModalActionTypes
} from "../../types";
import UserContext from "util/context/UserContext";
import { apiResponseStatuses } from "api/core";
import { entityStatus } from "ui/account/constants";
import { getLocalized } from "util/localizationUtil";

type TiersFormValues = {
  tierName: string;
  tierDescription: string;
};

export default function TiersModal(props: ActionModalProps) {
  const tiersModalValidations = Yup.object({
    tierName: Yup.string().required(getLocalized("required.tier_name"))
  });

  const getModalTitle = (actionType: ModalActionTypes) => {
    return actionType === modalActionTypes.EDIT
      ? getLocalized("tier.edit")
      : getLocalized("tier.add_new");
  };

  const getModalActionTitle = (actionType: ModalActionTypes) => {
    return actionType === modalActionTypes.EDIT
      ? getLocalized("action.save")
      : getLocalized("action.add");
  };

  const { isOpen, onToggle, actionType } = props;
  const { accountId } = useContext(UserContext);
  const [actionTypePersist, setActionTypePersist] = useState<ModalActionTypes>(
    actionType
  );

  useEffect(() => {
    isOpen && setActionTypePersist(actionType);
  }, [isOpen, actionType]);

  const getInitialValues = () => {
    const item = props.item as ModalItem<TierData>;
    if (item) {
      return {
        tierName: item.title,
        tierDescription: item.description
      };
    }
    return {
      tierName: "",
      tierDescription: ""
    };
  };

  const handleToggle = () => onToggle(actionTypePersist);

  const handleExit = () => {
    setActionTypePersist(props.actionType);
  };

  const handleSubmit = async (
    values: TiersFormValues,
    { setSubmitting, setStatus }: FormikActions<TiersFormValues>
  ) => {
    setStatus(null);
    const item = props.item as ModalItem<TierData>;
    const requestBody = {
      title: values.tierName,
      description: values.tierDescription,
      status: entityStatus.active
    };
    const { status, data } =
      actionType === modalActionTypes.EDIT && item
        ? await updateTier(accountId, item.id, requestBody)
        : await createTier(accountId, requestBody);
    if (status === apiResponseStatuses.success) {
      onToggle(actionTypePersist, null, true);
      setStatus(null);
      Toast.success(
        actionType === modalActionTypes.EDIT
          ? getLocalized("tier.update_success")
          : getLocalized("tier.add_success")
      );
    } else {
      setStatus(data.message || getLocalized("common.something_went_wrong"));
      Toast.error(
        actionType === modalActionTypes.EDIT
          ? "Tier update failed"
          : "Tier creation failed"
      );
    }
    setSubmitting(false);
  };

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} onExit={handleExit}>
      <ModalHeader toggle={handleToggle}>
        {getModalTitle(actionTypePersist)}
      </ModalHeader>
      <Formik
        onSubmit={handleSubmit}
        initialValues={getInitialValues()}
        enableReinitialize
        validationSchema={tiersModalValidations}
        render={({ status }) => {
          return (
            <Form noValidate>
              <ModalBody>
                <div>
                  <Alert color="danger" isOpen={!!status}>
                    {status}
                  </Alert>
                </div>
                <Field
                  required
                  id="tierName"
                  label={getLocalized("tier.name")}
                  name="tierName"
                  component={InputCustom}
                />
                <Field
                  id="tierDescription"
                  type="textarea"
                  multiline="true"
                  label={getLocalized("tier.description")}
                  name="tierDescription"
                  component={InputCustom}
                />
              </ModalBody>
              <ModalFooter>
                <Button type="submit" className="primary">
                  {getModalActionTitle(actionTypePersist)}
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      />
    </Modal>
  );
}
