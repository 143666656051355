import React, { ReactNode, useContext } from "react";
import AccessControl from "..";
import { Subjects, AccessControlActions } from "ui/common/types";
import { Redirect, useParams, useLocation } from "react-router-dom";
import UserContext, { getDefaultMember } from "util/context/UserContext";
import { slugify } from "util/helpers";

type CanVisitProfileProps = {
  children: ReactNode;
  basePath?: string;
};

export default function CanVisitProfile({
  children,
  basePath = "manage-users"
}: CanVisitProfileProps) {
  const { userId: memberId, userType } = useParams();
  const { user } = useContext(UserContext);
  const location = useLocation();

  const defaultMember = getDefaultMember(user);
  const defaultMemberAccessRole = defaultMember ? defaultMember.accessRole : "";
  const defaultMemberId = defaultMember ? defaultMember.id : "";
  const currentUserProfileUrl = `/profile/${slugify(
    defaultMemberAccessRole
  )}/${defaultMemberId}/me`;

  const otherUserProfileUrl = `/${basePath}/profile/${userType}/${memberId}`;
  console.log(otherUserProfileUrl);

  return (
    <AccessControl.IsMyProfile
      memberId={memberId}
      yes={children => (
        <AccessControl.Can
          resource={Subjects.ProfilePage}
          action={AccessControlActions.VisitOwn}
          no={() => <Redirect to="/dashboard" />}
        >
          {location.pathname.indexOf(currentUserProfileUrl) > -1 ? (
            children
          ) : (
            <Redirect to={currentUserProfileUrl} />
          )}
        </AccessControl.Can>
      )}
      no={children => (
        <AccessControl.OnlySubordinateHierarchy
          memberId={memberId}
          accessRole={userType}
          no={() => <Redirect to="/dashboard" />}
        >
          {location.pathname.indexOf(`/${basePath}/profile`) < 0 ? (
            <Redirect to={otherUserProfileUrl} />
          ) : (
            children
          )}
        </AccessControl.OnlySubordinateHierarchy>
      )}
    >
      {children}
    </AccessControl.IsMyProfile>
  );
}
