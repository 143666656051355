import {
  getUrl,
  GET_JOB_ROLES_URL,
  CREATE_JOB_ROLE_URL,
  UPDATE_JOB_ROLE_URL,
  DELETE_JOB_ROLE_URL
} from "config/endpoints";
import { getRequest, postRequest, putRequest, deleteRequest } from "api";

export const getJobRolesList = async (
  accountId: string,
  queryParams?: { [param: string]: any } | null
): Promise<any> => {
  const url = getUrl(GET_JOB_ROLES_URL, { accountId });
  return getRequest(url, queryParams);
};

export const createJobRole = async (
  accountId: string,
  bodyParams: { [param: string]: any }
): Promise<any> => {
  const url = getUrl(CREATE_JOB_ROLE_URL, { accountId });
  return postRequest(url, null, bodyParams);
};

export const updateJobRole = async (
  accountId: string,
  designationId: string,
  bodyParams: { [param: string]: any }
): Promise<any> => {
  const url = getUrl(UPDATE_JOB_ROLE_URL, { accountId, designationId });
  return putRequest(url, null, bodyParams);
};

export const deleteJobRole = async (
  accountId: string,
  designationId: string
): Promise<any> => {
  const url = getUrl(DELETE_JOB_ROLE_URL, { accountId, designationId });
  return deleteRequest(url);
};
