import { modalActionTypes } from "./constants";

export enum EntityStatus {
  Pending = "PENDING",
  Active = "ACTIVE",
  Blocked = "BLOCKED",
  Archived = "ARCHIVED",
  Inactive = "INACTIVE"
}
//Report Data
export type ReportData = {
  id: string;
  member: MemberData;
  user: UserData;
  designation: {
    id: string;
    title: string;
    description: string;
    tier: {
      // [key: string]: any;
      id: string;
      title: string;
    };
    status: string;
    createdAt: Date;
    updatedAt: Date;
    archivedAt: Date;
  };
  overallScore: number;
  peopleSkillsContribution: number;
  specialistSkillsContribution: number;
};
// Skill Data
export type SubSkill = {
  id: string;
  skillId: string;
  name: string;
  title: string;
  description: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  archivedAt: string;
};

export type SubSkillScored = {
  id: string;
  skillId: string;
  name: string;
  title: string;
  description: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  archivedAt: string;
  scores: number[];
};

export type ScoringGuides = {
  data: ScoringGuide[];
};

export type ScoringGuide = {
  [key: string]: any;
  index: number;
  ratingScaleIndex: number;
  ratingLetter: string;
  ratingLabel: string;
  description: string;
  sections: ScoringGuideSection[];
};

export type ScoringGuideSection = {
  [key: string]: any;
  index: number;
  heading: string;
  content: string;
};

export type Skill = {
  [key: string]: any;
  id: string;
  name: string;
  title: string;
  description: string;
  type: string;
  group: string;
  subSkills: SubSkill[];
  scoringGuide: ScoringGuides;
  status: string;
  createdAt: string;
  updatedAt: string;
  archivedAt: string;
};

// Table types
export type UserData = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  group: string;
  memberships: [null];
  createdAt: Date;
  updatedAt: Date;
  archivedAt: Date;
};

export type ManagerData = {
  id: string;
  accessRole: string;
  accountId: string;
  userId: string;
  default: boolean;
  designationId: string;
  managerId: string;
  permissions: {};
  preferences: {};
  user: UserData;
  status: string;
  archivedAt: Date;
  createdAt: Date;
  updatedAt: Date;
};

export type MemberData = {
  id: string;
  accountId: string;
  employeeId: string;
  user: UserData;
  default: boolean;
  designation: DesignationData;
  manager: ManagerData;
  accessRole: string;
  permissions: {};
  preferences: {};
  skills: any[]; // change
  scores: {};
  status: string;
  createdAt: Date;
  updatedAt: Date;
  archivedAt: Date;
};

export type Account = {
  id: string;
  name: string;
  preferences: AccountPreferences;
  paymentMethod?: any;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  archivedAt?: Date;
};

export type AccountPreferences = {
  ratingScale: RatingScale;
  skillTypeContribution: any;
};
export type RatingScale = {
  [key: string]: RatingScaleItem;
};
export type RatingScaleItem = {
  ratingScaleIndex: number;
  ratingLetter: string;
  ratingLabel: string;
  description: string;
};

export type DesignationData = {
  [key: string]: any;
  id: string;
  title: string;
  description: string;
  tier: {
    [key: string]: any;
    id: string;
    title: string;
  };
  status: string;
  createdAt: Date;
  updatedAt: Date;
  archivedAt: Date;
};

export type TierDesignationData = {
  [key: string]: any;
  id: string;
  title: string;
  description: string;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  archivedAt: Date;
};

export type TierData = {
  [key: string]: any;
  id: string;
  title: string;
  description: string;
  designations: TierDesignationData[];
  status: string;
  createdAt: Date;
  updatedAt: Date;
  archivedAt: Date;
};

export type TableData = DesignationData | TierData | MemberData | ReportData; // add all types of table data

// Modal types
export type Nullable<T> = T | null;
export type ModalActionTypes = keyof typeof modalActionTypes;
export type ModalItem<T> = Nullable<T>;

export type ModalProps<T> = {
  item: ModalItem<T>;
  actionType: ModalActionTypes;
  isOpen: boolean;
  onToggle: (
    actionType?: ModalActionTypes | null,
    currentItem?: TableData | null,
    forceRefresh?: boolean | null
  ) => void;
};

export type ActionModalProps = ModalProps<TableData>;

export type Suggestion = {
  label: string;
  value: string;
};

// Graph Data
export type QuadrantData = {
  data: QuadrantValue[];
  meta: GraphMeta;
};

export type QuadrantValue = {
  x: number;
  y: number;
  user: {
    memberId: string;
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: string;
  };
  designation: {
    id: string;
    title: string;
    description: string;
  };
  manager: {
    id: string;
    accessRole: string;
    user: {
      id: string;
      firstName: string;
      lastName: string;
      email: string;
    };
  };
  member: {
    id: string;
  };
};

export type DistributionData = {
  coordinates: DistributionCoordinates;
  data: DistributionList;
  meta: GraphMeta;
};

export type DistributionCoordinates = {
  allEmployees: GraphBinValue[];
  bottomFive: GraphBinValue[];
  topFive: GraphBinValue[];
};

export type DistributionList = {
  bottomFive: ReportData[];
  topFive: ReportData[];
};

export type Coordinate = {
  x: number;
  y: number;
};

export type GraphBinValue = {
  binIndex: number;
  frequency: number;
  from: number;
  to: number;
  mid: number;
};

export type GraphMeta = {
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
  topLeft: number;
  topRight: number;
  bottomLeft: number;
  bottomRight: number;
};

export enum ProfileMemberType {
  Member = "MEMBER",
  Manager = "MANAGER"
}
