import { ErrorMessage, FieldProps, getIn } from "formik";
import React from "react";
import { SkillScoreSlider } from "ui/common";
import { RatingScale } from "ui/account/types";

type ScoreSliderCustomProps = FieldProps & { grades: RatingScale };

const ScoreSliderCustom = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  grades,
  ...props
}: ScoreSliderCustomProps) => {
  const error = getIn(errors, field.name);
  return (
    <>
      <SkillScoreSlider
        {...props}
        grades={grades}
        value={field.value || []}
        onChange={(selected: number[]) => {
          setFieldValue(field.name, selected);
        }}
      />
      <ErrorMessage name={field.name}>{() => <div>{error}</div>}</ErrorMessage>
    </>
  );
};

export default ScoreSliderCustom;
