import React, { useState, useContext, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Button, Avatar, Icon, AccessControl, Toast } from "ui/common";
import defaultProfileImg from "ui/App/default_profile.png";
import styles from "./UserDetails.module.scss";
import { ProfileImageConfig } from "ui/account/constants";
import UserContext from "util/context/UserContext";
import UsersModal from "../../UsersModal";
import { modalActionTypes } from "ui/account/constants";
import { getUserDetails } from "services/users";
import { apiResponseStatuses } from "api/core";
import { useParams } from "react-router-dom";
import LoadingContext from "util/context/LoadingContext";
import { ModalActionTypes, TableData } from "ui/account/types";
import { getScores } from "services/scores";
import { uploadUserImage, getUserImage } from "services/users";
import { BASE_IMAGE_URL } from "config/endpoints";
import { AccessControlActions, Subjects } from "ui/common/types";
import { getLocalized } from "util/localizationUtil";

type UserDetailsProps = {
  onMvpChanged: (trigger: boolean) => void;
};
export default function UserDetails(props: UserDetailsProps) {
  const { onMvpChanged } = props;
  const { accountId } = useContext(UserContext);
  const { onChangeLoading } = useContext(LoadingContext);
  const { userId: memberId } = useParams();
  const [imagePreview, setImagePreview] = useState(defaultProfileImg);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [member, setMember] = useState<any>();
  const [scores, setScores] = useState<any>();
  const [imageUploaded, setImageUploaded] = useState(false);

  //image change effect
  useEffect(() => {
    const getImage = async () => {
      if (memberId) {
        const { data, status } = await getUserImage(accountId, memberId);
        if (status === apiResponseStatuses.success) {
          setImagePreview(
            BASE_IMAGE_URL + data.filePath + "?" + new Date().getTime()
          );
        }
      }
    };
    getImage();
  }, [accountId, memberId, imageUploaded]);

  useEffect(() => {
    const fetchScores = async () => {
      if (memberId) {
        onChangeLoading(true, "profile:mvp");
        const { data, status } = await getScores(accountId, memberId);
        if (status === apiResponseStatuses.success) {
          setScores(data);
        }
        onChangeLoading(false, "profile:mvp");
      }
    };
    const fetchData = async () => {
      if (memberId) {
        onChangeLoading(true, "profile:membership");
        const { status, data } = await getUserDetails(accountId, memberId);
        if (status === apiResponseStatuses.success) {
          setMember(data);
        }
        onChangeLoading(false, "profile:membership");
      }
    };
    fetchData();
    fetchScores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, memberId]);

  const handleModalToggle = (
    _actionType?: ModalActionTypes | null,
    _currentItem?: TableData | null,
    forceRefresh?: boolean | null
  ) => {
    const fetchScores = async () => {
      if (forceRefresh && memberId) {
        const { data, status } = await getScores(accountId, memberId);
        if (status === apiResponseStatuses.success) {
          setScores(data);
        }
      }
    };
    const fetchMember = async () => {
      if (forceRefresh && memberId) {
        const { status, data } = await getUserDetails(accountId, memberId);
        if (status === apiResponseStatuses.success) {
          setMember(data);
        }
      }
    };
    fetchMember();
    fetchScores();
    if (forceRefresh) {
      /* trigger score event to reflect mvp change hence score change and progression graph change */
      onMvpChanged(true);
    }
    setIsUserModalOpen(isUserModalOpen => !isUserModalOpen);
  };

  //on profile picture click
  const handleChange = (event: any) => {
    let file = event.target.files[0];
    if (file && file.size > ProfileImageConfig.SIZE) {
      setImageUploaded(false);
      Toast.error(getLocalized("file.size_limit"));
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const uploadImage = async () => {
          if (memberId) {
            const formData = new FormData();
            formData.append("image", file);
            const { status, data } = await uploadUserImage(
              accountId,
              memberId,
              formData
            );
            if (status === apiResponseStatuses.success) {
              setImagePreview(
                BASE_IMAGE_URL + data.filePath + "?" + new Date().getTime()
              );
              setImageUploaded(true);
              Toast.success(getLocalized("common.image_upload_success"));
            } else {
              setImageUploaded(false);
              Toast.error(getLocalized("common.image_upload_failed"));
            }
          }
        };
        uploadImage();
      };
    }
  };

  return (
    <Col xs="12" className={styles.profileCard}>
      {member && member.user && (
        <>
          <Row>
            <Col xs="12">
              <Row>
                <Col xs="12" className={`text-right ${styles.buttonWrap}`}>
                  {/* PERMISSION */}
                  <AccessControl.IsMyProfile
                    memberId={memberId}
                    yes={children => (
                      <AccessControl.Can
                        resource={Subjects.ManageUsersMember}
                        action={AccessControlActions.EditOwn}
                      >
                        {children}
                      </AccessControl.Can>
                    )}
                    no={children => (
                      <AccessControl.Can
                        resource={Subjects.ManageUsersMember}
                        action={AccessControlActions.EditAny}
                      >
                        {children}
                      </AccessControl.Can>
                    )}
                  >
                    <Button
                      className="text-button primary-text small-button"
                      onClick={() => handleModalToggle()}
                    >
                      {getLocalized("action.edit")}
                    </Button>
                  </AccessControl.IsMyProfile>
                </Col>

                <Col xs="12">
                  <AccessControl.IsMyProfile
                    memberId={memberId}
                    yes={() => (
                      <AccessControl.Can
                        resource={Subjects.profilePersonalInfo}
                        action={AccessControlActions.ViewOwn}
                      >
                        <div className={styles.picture}>
                          <label>
                            <span className={styles.frame}>
                              <Avatar
                                src={imagePreview}
                                alt={`${member.user.firstName} ${member.user.lastName}`}
                              />
                            </span>
                            {/* PERMISSION */}

                            <AccessControl.Can
                              resource={Subjects.profilePersonalInfo}
                              action={AccessControlActions.EditOwn}
                            >
                              <input
                                type="file"
                                name="image"
                                accept="image/x-png,image/jpeg"
                                onChange={handleChange}
                                id="image"
                                style={{ display: "none" }}
                              />
                              <span className={styles.camera}>
                                <Icon name="photo_camera" />
                              </span>
                            </AccessControl.Can>
                          </label>
                        </div>
                      </AccessControl.Can>
                    )}
                    no={() => (
                      <AccessControl.Can
                        resource={Subjects.profilePersonalInfo}
                        action={AccessControlActions.ViewAny}
                      >
                        <div className={styles.picture}>
                          <label>
                            <span className={styles.frame}>
                              <Avatar
                                src={imagePreview}
                                alt={`${member.user.firstName} ${member.user.lastName}`}
                              />
                            </span>
                            {/* PERMISSION */}

                            <AccessControl.Can
                              resource={Subjects.profilePersonalInfo}
                              action={AccessControlActions.EditAny}
                            >
                              <input
                                type="file"
                                name="image"
                                accept="image/x-png,image/jpeg"
                                onChange={handleChange}
                                id="image"
                                style={{ display: "none" }}
                              />
                              <span className={styles.camera}>
                                <Icon name="photo_camera" />
                              </span>
                            </AccessControl.Can>
                          </label>
                        </div>
                      </AccessControl.Can>
                    )}
                  />
                </Col>
                <Col xs="12">
                  <div className={styles.general}>
                    <Row>
                      <Col xs="12">
                        <div className={styles.employeeNumber}>
                          {member.employeeId}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <h3
                          className={styles.name}
                        >{`${member.user.firstName} ${member.user.lastName}`}</h3>
                      </Col>
                    </Row>
                    <p className={styles.title}>
                      {scores && scores.mvp && (
                        <span className={styles.mvp}>
                          {getLocalized("common.mvp")}
                        </span>
                      )}
                      {member.designation.description}
                    </p>
                    <p className={styles.email}>{member.user.email}</p>
                    {member.manager && (
                      <p className={styles.manager}>
                        {getLocalized("common.line_manager")}{" "}
                        {`${member.manager.user.firstName} ${member.manager.user.lastName}`}
                      </p>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
      <UsersModal
        isOpen={isUserModalOpen}
        onToggle={handleModalToggle}
        item={member}
        actionType={modalActionTypes.EDIT}
      />
    </Col>
  );
}
