import React from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import Goals from "ui/account/Goals";
import Notes from "ui/account/Notes";
import Scores from "ui/account/Scores";
import { Loader, AccessControl } from "ui/common";
import { LoadingConsumer, LoadingProvider } from "util/context/LoadingContext";
import IndividualQuadrantGraph from "./IndividualQuadrantGraph";
import ScoreProgressionGraph from "./ScoreProgressionGraph";
import UserDetails from "./UserDetails";
import styles from "./UserProfile.module.scss";
import { Subjects, AccessControlActions } from "ui/common/types";

type Params = {
  userId?: string;
  userType: string;
};

interface Props {
  basePath?: string;
}

export default function UserProfile({ basePath }: Props) {
  const { userId, userType } = useParams<Params>();
  const memberId = userId ? userId : "";

  return (
    <AccessControl.CanVisitProfile basePath={basePath}>
      <LoadingProvider>
        <LoadingConsumer>
          {({ loading }) => (
            <>
              {loading && <Loader overlay />}
              {/* this has to be an overlay */}
              <Scores
                renderCardDeck={({ scoreChanged, onMvpChanged }) => {
                  return (
                    <Row>
                      <Col sm="4">
                        <UserDetails onMvpChanged={onMvpChanged} />
                      </Col>

                      <AccessControl.IsMyProfile
                        memberId={memberId}
                        yes={children => (
                          <AccessControl.Can
                            resource={Subjects.ProfileProgression}
                            action={AccessControlActions.ViewOwn}
                          >
                            {children}
                          </AccessControl.Can>
                        )}
                        no={children => (
                          <AccessControl.Can
                            resource={Subjects.ProfileProgression}
                            action={AccessControlActions.ViewAny}
                          >
                            <AccessControl.OnlySubordinateHierarchy
                              memberId={memberId}
                              accessRole={userType}
                            >
                              {children}
                            </AccessControl.OnlySubordinateHierarchy>
                          </AccessControl.Can>
                        )}
                      >
                        <Col sm="4">
                          <ScoreProgressionGraph refetchData={scoreChanged} />
                        </Col>
                      </AccessControl.IsMyProfile>

                      <AccessControl.IsMyProfile
                        memberId={memberId}
                        yes={children => (
                          <AccessControl.Can
                            resource={Subjects.ProfileQuadrant}
                            action={AccessControlActions.ViewOwn}
                          >
                            {children}
                          </AccessControl.Can>
                        )}
                        no={children => (
                          <AccessControl.Can
                            resource={Subjects.ProfileQuadrant}
                            action={AccessControlActions.ViewAny}
                          >
                            <AccessControl.OnlySubordinateHierarchy
                              memberId={memberId}
                              accessRole={userType}
                            >
                              {children}
                            </AccessControl.OnlySubordinateHierarchy>
                          </AccessControl.Can>
                        )}
                      >
                        <Col sm="4">
                          <IndividualQuadrantGraph refetchData={scoreChanged} />
                        </Col>
                      </AccessControl.IsMyProfile>
                    </Row>
                  );
                }}
                renderGoalsAndNotesDeck={({ onGoalAchieved }) => {
                  return (
                    <Row>
                      <AccessControl.IsMyProfile
                        memberId={memberId}
                        yes={children => (
                          <AccessControl.Can
                            resource={Subjects.ProfileGoal}
                            action={AccessControlActions.ViewOwn}
                          >
                            {children}
                          </AccessControl.Can>
                        )}
                        no={children => (
                          <AccessControl.Can
                            resource={Subjects.ProfileGoal}
                            action={AccessControlActions.ViewAny}
                          >
                            {children}
                          </AccessControl.Can>
                        )}
                      >
                        <Col
                          className={styles.adjustSpacingInDesktop}
                          md="5"
                          xs="12"
                        >
                          <Goals
                            userId={memberId!}
                            onGoalAchieved={onGoalAchieved}
                          />
                        </Col>
                      </AccessControl.IsMyProfile>

                      <AccessControl.IsMyProfile
                        memberId={memberId}
                        yes={children => (
                          <AccessControl.Can
                            resource={Subjects.ProfileNote}
                            action={AccessControlActions.ViewOwn}
                          >
                            {children}
                          </AccessControl.Can>
                        )}
                        no={children => (
                          <AccessControl.Can
                            resource={Subjects.ProfileNote}
                            action={AccessControlActions.ViewAny}
                          >
                            {children}
                          </AccessControl.Can>
                        )}
                      >
                        <Col md="7" xs="12">
                          <Notes userId={memberId!} />
                        </Col>
                      </AccessControl.IsMyProfile>
                    </Row>
                  );
                }}
              />
            </>
          )}
        </LoadingConsumer>
      </LoadingProvider>
    </AccessControl.CanVisitProfile>
  );
}
