import {
  getUrl,
  GET_SCORES_URL,
  CREATE_SCORES_URL,
  UPDATE_SCORES_URL
} from "config/endpoints";
import { getRequest, postRequest, putRequest } from "api";

export const getScores = async (
  accountId: string,
  memberId: string,
  queryParams?: any
): Promise<any> => {
  const url = getUrl(GET_SCORES_URL, { accountId, memberId });
  return getRequest(url, queryParams);
};
export const createScores = async (
  accountId: string,
  memberId: string,
  bodyParams: { [param: string]: any }
): Promise<any> => {
  const url = getUrl(CREATE_SCORES_URL, { accountId, memberId });
  return postRequest(url, null, bodyParams);
};
export const updateScores = async (
  accountId: string,
  memberId: string,
  scoreId: string,
  bodyParams: { [param: string]: any }
): Promise<any> => {
  const url = getUrl(UPDATE_SCORES_URL, { accountId, memberId, scoreId });
  return putRequest(url, null, bodyParams);
};
