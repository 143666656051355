import React from "react";
import { Spinner } from "reactstrap";
import styles from "./Loader.module.scss";

type LoaderProps = {
  overlay: boolean;
  showLoadingText: boolean;
  // Add props if needed
};

export default function Loader(props: LoaderProps) {
  return (
    <>
      {props.overlay ? (
        <div className={styles.overlay}>
          {/* overlay */}
          <Spinner color="light" />
        </div>
      ) : (
        <div className={styles.loader}>
          <Spinner />
          {props.showLoadingText && <p>Loading</p>}
        </div>
      )}
    </>
  );
}

Loader.defaultProps = {
  overlay: false,
  showLoadingText: true
};
