import { Field, FieldProps } from "formik";
import React, { useState } from "react";
import { FormGroup, Label, FormText } from "reactstrap";
import Input, { InputType } from "reactstrap/lib/Input";
import styles from "ui/common/FileUpload/FileUpload.module.scss";
import { getAcceptedNoteAttachmentFileMimeTypes } from "util/file/FileHelper";
import { getLocalized } from "util/localizationUtil";

type InputCustomProps = {
  label: string;
  required: boolean;
  type: InputType;
  valueFieldName: string;
  fileState?: any;
  updateFileState?: any;
  className?: string;
} & FieldProps;

export default function FileUploadFormik({
  field,
  form: { touched, errors, setFieldValue, setFieldError },
  required,
  type,
  ...props
}: InputCustomProps) {
  const [fileInputText, setFileInputText] = useState(
    getLocalized("action.upload")
  );

  const onChange = (event: any) => {
    var file = event.target.files[0];

    if (file) {
      let fileData;
      if (!getAcceptedNoteAttachmentFileMimeTypes.includes(file.type)) {
        fileData = {
          file: file,
          isValid: false,
          validationMessage: getLocalized("file.unsupported")
        };
      } else if (file.size > 5000000) {
        fileData = {
          file: file,
          isValid: false,
          validationMessage: getLocalized("file.large")
        };
      } else {
        fileData = { file: file, isValid: true, validationMessage: "" };
      }

      setFieldValue(props.valueFieldName, fileData);
      setFileInputText(file.name);
      props.updateFileState(fileData);
    }
  };

  const onDragCapture = (drag: boolean) => {
    if (drag) setFileInputText(getLocalized("file.drop_here"));
    else if (props.fileState) setFileInputText(props.fileState.name);
    else setFileInputText(getLocalized("action.upload"));
  };

  return (
    <FormGroup>
      {props.label && (
        <Label for={field.name}>
          {props.label} {required && `*`}
        </Label>
      )}
      <div className={styles.buttonWrap}>
        <Label
          className={`${styles.newButton} ${props.className}`}
          htmlFor="upload"
        >
          {fileInputText}
        </Label>
        <Input
          type="file"
          id="upload"
          {...field}
          {...props}
          onChange={onChange}
          onDrop={onChange}
          onDragOverCapture={() => onDragCapture(true)}
          onDragLeaveCapture={() => onDragCapture(false)}
        />
      </div>
      <Field name={props.valueFieldName} style={{ display: "none" }} />
      <div className={styles.detailView}>
        {props.fileState && !props.fileState.isValid && (
          <p className={styles.error}>{props.fileState.validationMessage}</p>
        )}
      </div>
      <FormText color="muted">
        {getLocalized("file.supported_formats")} {getLocalized("file.max_size")}
      </FormText>
    </FormGroup>
  );
}
