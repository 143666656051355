import React from "react";
import { Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AccessControl } from "role-acl";
import AccountLayout from "ui/account/AccountLayout";
import AuthLayout, { SighInLayoutProps } from "ui/auth/AuthLayout";
import ForgotPasswordForm from "ui/auth/ForgotPasswordForm";
import SetPasswordForm, { SetPasswordFormProps } from "ui/auth/SetPasswordForm";
import SignInForm from "ui/auth/SignInForm";
import {
  HydratePermissions,
  PrivateRoute,
  PublicRoute,
  RefreshTokenHandler
} from "ui/common"; /*  */
import { Subjects } from "ui/common/types";
import { PermissionProvider } from "util/context/PermissionContext";
import { Props, UserProvider } from "util/context/UserContext";
import history from "util/history";
import withLayout from "util/hoc/withLayout";
import { importLocale } from "util/localizationUtil";

type SetPasswordLayoutProps = SetPasswordFormProps & SighInLayoutProps;

export const ac = new AccessControl();

const SignInLayoutPage = withLayout<SighInLayoutProps>(AuthLayout);
const SetPasswordLayoutPage = withLayout<SetPasswordLayoutProps>(AuthLayout);
const DrawerPage = withLayout<Props>(AccountLayout);

interface AppProps {
  locale?: string;
}

interface AppState {
  initialized: boolean;
}

const browserLanguage = navigator.language.split("-")[0];

export default class App extends React.Component<AppProps, AppState> {
  public static defaultProps = {
    locale: "en"
  };

  constructor(props: AppProps) {
    super(props);

    this.state = {
      initialized: false
    };
  }

  public componentDidMount() {
    this.loadLocales(browserLanguage);
  }

  public componentWillReceiveProps(newProps: any) {
    this.loadLocales(newProps.locale || browserLanguage);
  }

  public loadLocales(locale?: string) {
    this.setState({ initialized: false }, () => {
      let currentLocale: string;

      if (localStorage.getItem("locale") === null) {
        currentLocale = localStorage.getItem("locale") || "";
      } else {
        if (locale) {
          currentLocale = locale;
        } else {
          currentLocale = this.props.locale || "";
        }
      }

      if (locale !== undefined) {
        currentLocale = locale;
      }

      if (currentLocale.includes("-")) {
        currentLocale = currentLocale.substring(0, 2);
      }

      importLocale(currentLocale, () => this.setState({ initialized: true }));
    });
  }

  render() {
    return (
      <UserProvider>
        <PermissionProvider>
          <Router history={history}>
            <HydratePermissions />
            <Switch>
              <PublicRoute path="/login">
                <SignInLayoutPage formComponent={SignInForm} />
              </PublicRoute>
              <PublicRoute path="/reset-password/:id">
                <SetPasswordLayoutPage
                  passwordFormType="RESET"
                  formComponent={SetPasswordForm}
                />
              </PublicRoute>
              <PublicRoute path="/set-password/:id">
                <SetPasswordLayoutPage formComponent={SetPasswordForm} />
              </PublicRoute>
              <PublicRoute path="/forgot-password">
                <SignInLayoutPage formComponent={ForgotPasswordForm} />
              </PublicRoute>
              <PrivateRoute page={Subjects.DashboardPage} path="/">
                <RefreshTokenHandler />
                <DrawerPage />
                {/* <HomePage /> */}
              </PrivateRoute>
            </Switch>
          </Router>
          <ToastContainer
            hideProgressBar={true}
            closeButton={false}
            position="bottom-left"
          />
        </PermissionProvider>
      </UserProvider>
    );
  }
}
