import i18next from "i18next";
import { initReactI18next } from "react-i18next";

export const getLocalized = (key: string, obj?: Object) => {
  // have to work on getting html from json files, right now its only takes strings
  return i18next.t(key, obj);
};

export const importLocale = (currentLocale: string, callback: () => void) => {
  import(`locales/${currentLocale}/${currentLocale}`)
    .then(({ locale }: any) =>
      i18next
        .use(initReactI18next)
        .init({
          lng: currentLocale,
          returnedObjectHandler: (key: string, val: any) => {
            return val["default_text"];
          }, // by default return the default value in the object
          resources: { [currentLocale]: { translation: locale } }
        })
        .then(() => {
          localStorage.setItem("locale", currentLocale);
          callback();
        })
    )
    .catch(() => {
      // default fallback to 'en'
      import(`locales/en/en`).then(({ locale }: any) => {
        i18next
          .init({ lng: "en", resources: { en: { translation: locale } } })
          .then(() => {
            localStorage.setItem("locale", "en");
            callback();
          });
      });
    });
};
