export const DEFAULT_RESULTS_LIMIT = 10;
export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_RESULTS_OFFSET = 0;
export const DEFAULT_REPORT_ORDER_BY = "overallscore";
export const DEFAULT_REPORT_ORDER_DIRECTION = "desc";

export enum SkillType {
  Technical = "TECHNICAL",
  Personal = "PERSONAL"
}
