import {
  getUrl,
  GET_TIERS_URL,
  CREATE_TIER_URL,
  UPDATE_TIER_URL,
  DELETE_TIER_URL
} from "config/endpoints";
import { getRequest, postRequest, putRequest, deleteRequest } from "api";

export const getTiersList = async (
  accountId: string,
  queryParams?: { [param: string]: any } | null
): Promise<any> => {
  const url = getUrl(GET_TIERS_URL, { accountId });
  return getRequest(url, queryParams);
};

export const createTier = async (
  accountId: string,
  bodyParams: { [param: string]: any }
): Promise<any> => {
  const url = getUrl(CREATE_TIER_URL, { accountId });
  return postRequest(url, null, bodyParams);
};

export const updateTier = async (
  accountId: string,
  tierId: string,
  bodyParams: { [param: string]: any }
): Promise<any> => {
  const url = getUrl(UPDATE_TIER_URL, { accountId, tierId });
  return putRequest(url, null, bodyParams);
};

export const deleteTier = async (
  accountId: string,
  tierId: string
): Promise<any> => {
  const url = getUrl(DELETE_TIER_URL, { accountId, tierId });
  return deleteRequest(url);
};
