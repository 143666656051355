import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { getLocalized } from "util/localizationUtil";
import ChangePasswordForm from "./ChangePasswordForm";

interface Props {
  isOpen: boolean;
  closeToggle: () => void;
  onSubmit?: () => void;
}

const ChangePasswordModal = ({ isOpen, closeToggle, onSubmit }: Props) => {
  return (
    <Modal isOpen={isOpen} toggle={closeToggle}>
      <ModalHeader toggle={closeToggle}>
        {getLocalized("password_text.change")}
      </ModalHeader>
      <ModalBody>
        <ChangePasswordForm onSubmit={onSubmit} />
      </ModalBody>
    </Modal>
  );
};
export default ChangePasswordModal;
