import { httpRequestMethod, formDataHttpRequestMethod } from "./core";

export const getRequest = (
  endpoint: string,
  queryParams?: { [param: string]: any } | null
): Promise<any> => {
  return httpRequestMethod("GET", endpoint, queryParams);
};

export const postRequest = (
  endpoint: string,
  queryParams?: { [param: string]: any } | null,
  bodyParams?: { [param: string]: any } | null
): Promise<any> => {
  return httpRequestMethod("POST", endpoint, queryParams, bodyParams);
};

export const postMediaRequest = (
  endpoint: string,
  queryParams?: { [param: string]: any } | null,
  bodyParams?: { [param: string]: any } | null
): Promise<any> => {
  return formDataHttpRequestMethod("POST", endpoint, queryParams, bodyParams);
};

export const putRequest = (
  endpoint: string,
  queryParams?: { [param: string]: any } | null,
  bodyParams?: { [param: string]: any } | null
): Promise<any> => {
  return httpRequestMethod("PUT", endpoint, queryParams, bodyParams);
};

export const patchRequest = (
  endpoint: string,
  queryParams?: { [param: string]: any } | null,
  bodyParams?: { [param: string]: any } | null
): Promise<any> => {
  return httpRequestMethod("PATCH", endpoint, queryParams, bodyParams);
};

export const deleteRequest = (
  endpoint: string,
  queryParams?: { [param: string]: any } | null
): Promise<any> => {
  return httpRequestMethod("DELETE", endpoint, queryParams);
};
