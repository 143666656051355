import { getUrl, UPDATE_ACCOUNT_URL } from "config/endpoints";
import { patchRequest } from "api";

export const updateAccount = async (
  accountId: string,
  bodyParams: { [param: string]: any }
): Promise<any> => {
  const url = getUrl(UPDATE_ACCOUNT_URL, { accountId });
  return patchRequest(url, null, bodyParams);
};
