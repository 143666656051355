import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import { Avatar } from "ui/common";
import { getUserImage } from "services/users";
import UserContext from "util/context/UserContext";
import { apiResponseStatuses } from "ui/account/constants";
import { getDateString, slugify } from "util/helpers";
import { getLocalized } from "util/localizationUtil";
import { BASE_IMAGE_URL } from "config/endpoints";
import styles from "../Quadrant.module.scss";
import defaultProfileImg from "ui/App/default_profile.png";

type UserDetailHintProps = {
  selectedUser: any;
};

export default function UserDetailHint(props: UserDetailHintProps) {
  const { selectedUser } = props;
  const { accountId } = useContext(UserContext);

  const [isImageFetched, setIsImageFetched] = useState(false);
  const [imagePreview, setImagePreview] = useState();

  useEffect(() => {
    const fetchImagePreview = async () => {
      setIsImageFetched(true);
      const { status, data } = await getUserImage(
        accountId,
        selectedUser.member.id
      );
      if (status === apiResponseStatuses.success) {
        setImagePreview(
          BASE_IMAGE_URL + data.filePath + "?" + new Date().getTime()
        );
      } else {
        setImagePreview(defaultProfileImg);
      }
      setIsImageFetched(false);
    };
    fetchImagePreview();
  }, [accountId, selectedUser]);

  return (
    <div className={styles.detailCard}>
      <Row>
        <Col xs="auto">
          <div className={styles.picture}>
            <Avatar
              size={[100, 100]}
              src={imagePreview}
              loading={isImageFetched}
            />
          </div>
        </Col>
        <Col>
          <div className={styles.general}>
            {selectedUser.user && (
              <h3 className={styles.name}>
                {selectedUser.user.firstName} {selectedUser.user.lastName}
              </h3>
            )}
            {selectedUser.designation && (
              <p className={styles.title}>
                {selectedUser.designation.description}
              </p>
            )}
          </div>
          <div className={styles.additional}>
            {selectedUser.member && (
              <p>
                {getLocalized("employee.id")} {selectedUser.member.employeeId}
              </p>
            )}
            {selectedUser.manager && (
              <p>
                {getLocalized("common.line_manager")}{" "}
                {selectedUser.manager.user.firstName}{" "}
                {selectedUser.manager.user.lastName}
              </p>
            )}
            {selectedUser.user && (
              <p>
                {getLocalized("common.joined_on")}{" "}
                {getDateString(new Date(selectedUser.user.createdAt))}
              </p>
            )}
          </div>
          <div className={styles.footer}>
            {selectedUser.member && (
              <Link
                to={`/profile/${slugify(selectedUser.member.accessRole)}/${
                  selectedUser.member.id
                }`}
              >
                <span>{getLocalized("common.view_profile")}</span>
              </Link>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
