import React, { useContext, useEffect, useReducer, useState } from "react";
import { Col, Row } from "reactstrap";
import { EntityStatus } from "ui/account/types";
import Card from "ui/common/Card";
import SkillsContext from "util/context/SkillsContext";
import UserContext from "util/context/UserContext";
import Preview from "../Preview";
import {
  SkillsActionType,
  skillsInitialState,
  skillsReducer
} from "../reducers";
import { Skill, SkillType } from "../types";
import SearchBarSkills from "./../TechnicalSkills/SearchBarSkills";
import SkillsList from "./SkillsList";
import styles from "./TechnicalSkills.module.scss";
import { getSkills as fetchSkills } from "services/skills";
import { apiResponseStatuses } from "api/core";
import { Loader, NoDataLabel } from "ui/common";
import { getLocalized } from "util/localizationUtil";

const getSkills = (skillsData: Skill[], inputValue: string) => {
  const searchText = inputValue.trim().toLowerCase();
  return skillsData.filter(skill => {
    if (searchText.length > 0) {
      return (
        skill.name.toLowerCase().includes(searchText) ||
        skill.subSkills.some(subSkill => {
          return subSkill.name.toLowerCase().includes(searchText);
        })
      );
    }
    return true;
  });
};
export default function TechnicalSkills() {
  const { accountId } = useContext(UserContext);
  const [skills, dispatch] = useReducer(skillsReducer, skillsInitialState);
  const [searchInputValue, setSearchInput] = useState("");
  const [isFetched, setIsFetched] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      setIsFetched(false);
      const { status, data } = await fetchSkills(accountId, {
        "filter[type]": SkillType.Technical
      });
      if (status === apiResponseStatuses.success) {
        const skillData = data.results as Skill[];
        dispatch({
          type: SkillsActionType.Initialize,
          payload: skillData
        });
      } else {
      }
      setIsFetched(true);
    };
    fetchData();
  }, [accountId]);

  const skillsData: Skill[] = Object.values(skills.skills.byId);
  const skillsList = getSkills(
    skillsData,
    searchInputValue
  ); /* Memoize if needed */
  const previewSkills: Skill[] = skillsData.filter(
    skill => skill.status === EntityStatus.Active
  );

  return (
    <SkillsContext.Provider value={dispatch}>
      <Row>
        <Col xs="12">
          <div className={styles.heading}>{getLocalized("common.preview")}</div>
          <Preview items={previewSkills} type={SkillType.Technical} />
        </Col>
        <Col xs="12">
          <Card title={getLocalized("skill.pool")}>
            <SearchBarSkills
              onChange={setSearchInput}
              value={searchInputValue}
            />
            {isFetched ? (
              skillsList.length > 0 ? (
                <SkillsList data={skillsList} />
              ) : (
                <div>
                  <NoDataLabel
                    noDataLabel={
                      searchInputValue !== ""
                        ? `${getLocalized(
                            "SEARCH_NO_RESULTS"
                          )} "${searchInputValue}"`
                        : getLocalized("common.no_skills_now")
                    }
                  />
                </div>
              )
            ) : (
              <Loader />
            )}
          </Card>
        </Col>
      </Row>
    </SkillsContext.Provider>
  );
}
