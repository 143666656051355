import React from "react";
import {
  Col,
  FormGroup,
  Input,
  Label,
  Pagination as PaginationRs,
  PaginationItem,
  Row
} from "reactstrap";
import { Button, Icon } from "ui/common";
import styles from "./SimplePagination.module.scss";
import { getLocalized } from "util/localizationUtil";

type PaginationProps = {
  totalResults: number;
  currentPage: number;
  pageSize: number;
  onSetCurrentPage: (page: number) => void;
  onChangePageSize: (size: number) => void;
};

export const defaultPage = 1;
export const defaultLimit = 3;
export const pageSizes = [3, 5, 10];

export default function({
  totalResults,
  currentPage,
  pageSize,
  onSetCurrentPage,
  onChangePageSize
}: PaginationProps) {
  let numOfPages = Math.ceil(totalResults / pageSize);
  numOfPages = numOfPages === 0 ? defaultPage : numOfPages;

  const handleFirstPageClick = () => onSetCurrentPage(defaultPage);
  const handleLastPageClick = () => onSetCurrentPage(numOfPages);
  const handlePreviousPageClick = () => onSetCurrentPage(currentPage - 1);
  const handleNextPageClick = () => onSetCurrentPage(currentPage + 1);

  return (
    <Col xs="12" className={styles.pagination}>
      <Row className={styles.wrapper}>
        <Col xs="auto" className={styles.pageSize}>
          <FormGroup row>
            <Label for="pageSizeSelect">
              {getLocalized("common.page_size")}
            </Label>
            <Input
              type="select"
              name="select"
              id="pageSizeSelect"
              onChange={event => onChangePageSize(parseInt(event.target.value))}
            >
              {pageSizes.map((item, index) => (
                <option key={index}>{item}</option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col className={styles.items}>
          <PaginationRs aria-label="Page navigation">
            <PaginationItem>
              <Button
                disabled={currentPage === defaultPage}
                first="true"
                onClick={handleFirstPageClick}
                title={getLocalized("common.first")}
              >
                <Icon name="fast_rewind" />
              </Button>
            </PaginationItem>
            <PaginationItem>
              <Button
                disabled={currentPage === defaultPage}
                onClick={handlePreviousPageClick}
                title={getLocalized("common.previous")}
              >
                <Icon name="keyboard_arrow_left" />
              </Button>
            </PaginationItem>
            <PaginationItem>
              <Label className={styles.currentPage}>
                {`${currentPage} / ${numOfPages}`}
              </Label>
            </PaginationItem>
            <PaginationItem>
              <Button
                disabled={currentPage === numOfPages}
                onClick={handleNextPageClick}
                title={getLocalized("common.next")}
              >
                <Icon name="keyboard_arrow_right" />
              </Button>
            </PaginationItem>
            <PaginationItem>
              <Button
                disabled={currentPage === numOfPages}
                onClick={handleLastPageClick}
                title={getLocalized("common.last")}
              >
                <Icon name="fast_forward" />
              </Button>
            </PaginationItem>
          </PaginationRs>
        </Col>
      </Row>
    </Col>
  );
}
