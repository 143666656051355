import {
  getUrl,
  LOGIN_URL,
  REFRESH_URL,
  LOGOUT_URL,
  FORGOT_PASSWORD_URL,
  SET_PASSWORD_URL,
  CHANGE_PASSWORD_URL
} from "config/endpoints";
import { postRequest } from "api";

import { SignInFormValues } from "ui/auth/SignInForm";
import { ForgetPasswordFormValues } from "ui/auth/ForgotPasswordForm";
import { SetPasswordFormValues } from "ui/auth/SetPasswordForm";

export const login = async (credentials: SignInFormValues): Promise<any> => {
  const url = getUrl(LOGIN_URL, {});
  return postRequest(url, null, credentials);
};

export const logout = async (): Promise<any> => {
  const url = getUrl(LOGOUT_URL, {});
  return postRequest(url, null, null);
};
export const refresh = async (): Promise<any> => {
  const url = getUrl(REFRESH_URL, {});
  return postRequest(url, null, null);
};

export const forgotPassword = async (
  bodyParams: ForgetPasswordFormValues
): Promise<any> => {
  const url = getUrl(FORGOT_PASSWORD_URL, {});
  return postRequest(url, null, bodyParams);
};

export const setPassword = async (
  token: string,
  tokenType: string,
  bodyParams: Omit<SetPasswordFormValues, "passwordConfirm">
): Promise<any> => {
  const url = getUrl(SET_PASSWORD_URL, { token, tokenType });
  return postRequest(url, null, bodyParams);
};

export const changePassword = async (bodyParams: any): Promise<any> => {
  const url = getUrl(CHANGE_PASSWORD_URL, {});
  return postRequest(url, null, bodyParams);
};
