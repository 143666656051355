import {
  default as React,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { ValueType } from "react-select/src/types";
import { Alert, Col, Row } from "reactstrap";
import { getJobRolesList } from "services/jobRoles";
import { getTiersList } from "services/tiers";
import { getUsersList } from "services/users";
import { getQuadrantGraphData } from "services/graphs";
import {
  DesignationData,
  Nullable,
  GraphMeta,
  QuadrantValue,
  TierData,
  TierDesignationData
} from "ui/account/types";
import {
  Loader,
  Quadrant,
  SearchBar,
  Button,
  Icon,
  AccessControl,
  NoDataLabel
} from "ui/common";
import { OptionType } from "ui/common/SelectFilter/types";
import UserContext from "util/context/UserContext";
import { apiResponseStatuses } from "../constants";
import {
  DEFAULT_ORDER_BY,
  DEFAULT_ORDER_DIRECTION
} from "ui/common/Table/constants";
import SelectFilter from "ui/common/SelectFilter";
import styles from "./Dashboard.module.scss";
import { Subjects, AccessControlActions } from "ui/common/types";
import useCan from "util/hooks/useCan";
import { getLocalized } from "util/localizationUtil";

type DesignationMap = { [id: string]: Array<TierDesignationData> };

export default function Dashboard() {
  const { accountId } = useContext(UserContext);
  const [filterMode, setFilterMode] = useState(false);
  const [quadrantData, setQuadrantData] = useState<Array<QuadrantValue>>([]);
  const [quadrantMeta, setQuadrantMeta] = useState<Nullable<GraphMeta>>(null);
  const [currentUser, setCurrentUser] = useState(); // current user's position if available

  const [isQuadrantDataFetched, setIsQuadrantDataFetched] = useState(false);
  const [error, setError] = useState<string>(""); // error messages
  const [searchTerm, setSearchTermParam] = useState<Nullable<string>>(null);

  const [tiers, setTiers] = useState<Array<TierData>>([]);
  const [tierFilters, setTierFilters] = useState<Array<TierData>>([]);
  const [jobRoles, setJobRoles] = useState<Array<DesignationData>>([]);
  const [jobRoleFilters, setJobRoleFilters] = useState<
    Array<TierDesignationData | DesignationData>
  >([]);
  const [mappedJobRoles, setMappedJobRoles] = useState<DesignationMap>({});

  const tierFilterRef = useRef();
  let jobFilterRef: any = null;

  const canUseSearch = useCan({
    resource: Subjects.DashboardQuadrant,
    action: AccessControlActions.Search
  });
  const canUseFilters = useCan({
    resource: Subjects.DashboardQuadrant,
    action: AccessControlActions.Filter
  });

  const mapDesignationsToTiers = (tiersArr: Array<TierData>) => {
    const mappedDesignations = tiersArr.reduce(
      (acc: DesignationMap, tier: TierData) => ({
        ...acc,
        [tier.id]: [...tier.designations]
      }),
      {}
    );
    setMappedJobRoles(mappedDesignations);
  };

  useEffect(() => {
    const queryParams = {
      sort: `${DEFAULT_ORDER_BY}:${DEFAULT_ORDER_DIRECTION}`
    };
    const fetchTiers = async () => {
      const { status, data } = await getTiersList(accountId, queryParams);
      if (status === apiResponseStatuses.success) {
        setTiers(data);
        mapDesignationsToTiers(data);
      }
    };

    const fetchJobRoles = async () => {
      const { status, data } = await getJobRolesList(accountId, queryParams);
      if (status === apiResponseStatuses.success) {
        setJobRoles(data);
      }
    };

    fetchTiers();
    fetchJobRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const queryParams = {
        ...(searchTerm &&
          searchTerm !== "" && { searchTerm: encodeURIComponent(searchTerm) }),
        ...(tierFilters.length > 0 && { tierId: tierFilters.map(e => e.id) }),
        ...(jobRoleFilters.length > 0 && {
          designationIds: jobRoleFilters.map(e => e.id)
        })
      };
      setError("");
      setIsQuadrantDataFetched(false);
      const { status, data, message } = await getQuadrantGraphData(
        accountId,
        queryParams
      );
      if (status === apiResponseStatuses.success) {
        if (data.tierData) {
          // for member type users
          setQuadrantData(data.tierData); // all users coordinates
          setCurrentUser(data.data); // current user coordinates
        } else {
          // for admin / owner type users
          setQuadrantData(data.data); // all users coordinates and details
        }
        setQuadrantMeta(data.meta);
      } else {
        setError(message);
      }
      setIsQuadrantDataFetched(true);
    };

    fetchData();
  }, [accountId, searchTerm, tierFilters, jobRoleFilters]);

  // Either return all job roles or job roles specific to a tier
  const getJobRoleFilterList = () => {
    let filteredJobRoles: Array<TierDesignationData> = [];
    if (tierFilters && tierFilters.length > 0) {
      for (let tier of tierFilters) {
        const filteredDesignations: Array<TierDesignationData> =
          tier && mappedJobRoles[tier.id];
        filteredJobRoles.push(...filteredDesignations);
      }
      return filteredJobRoles;
    } else {
      return jobRoles;
    }
  };

  const setJobRoleMap = (option: TierData[]) => {
    (jobFilterRef as any).state.value = [];
    setJobRoleFilters([]);
    setTierFilters(option || []);
  };

  const handleMultiJobChange = (option: any) => {
    setJobRoleFilters(option || []);
  };

  const setJobFilterRef = (ref: any) => {
    jobFilterRef = ref;
  };

  const toggleFilterMode = (filterMode: boolean) => setFilterMode(!filterMode);

  const getSearchTerm = (searchTerm: string) => {
    const inputValue = searchTerm.trim().toLowerCase();
    setSearchTermParam(inputValue);
  };

  return (
    <>
      <div>
        <Alert color="danger" isOpen={!!error}>
          {error}
        </Alert>
      </div>
      <Row>
        <Col xs="12" className={`text-right ${styles.filterToggle}`}>
          <Button
            className="icon-button dark"
            onClick={() => {
              toggleFilterMode(filterMode);
            }}
          >
            <Icon name={filterMode ? "close" : "filter_list"} />
          </Button>
        </Col>
      </Row>
      <Row
        className={`${styles.filterWrapper} ${filterMode ? styles.open : ""}`}
      >
        <AccessControl.Can
          resource={Subjects.DashboardQuadrant}
          action={AccessControlActions.Search}
        >
          <Col xs="12" md="4">
            <label>{getLocalized("filter.by_name")}</label>
            <SearchBar
              searchOnServer
              suggestionEndpoint={getUsersList}
              getSearchTerm={getSearchTerm}
            />
          </Col>
        </AccessControl.Can>
        <AccessControl.Can
          resource={Subjects.DashboardQuadrant}
          action={AccessControlActions.Filter}
        >
          <Col xs="12" md="4" className="mb-3 mb-sm-0">
            <SelectFilter
              options={tiers}
              handleMultiChange={(option: ValueType<OptionType>) =>
                setJobRoleMap(option as TierData[])
              }
              getOptionValue={(option: TierData) => option.title}
              getOptionLabel={(option: TierData) => option.title}
              clearable
              setRef={tierFilterRef}
              label={getLocalized("filter.by_tiers")}
              prefix="sp-select-filter"
            />
          </Col>
          <Col xs="12" md="4" className="mb-3 mb-sm-0">
            <SelectFilter
              options={getJobRoleFilterList()}
              handleMultiChange={handleMultiJobChange}
              getOptionValue={(option: TierData) => option.title}
              getOptionLabel={(option: TierData) => option.description}
              clearable
              setRef={setJobFilterRef}
              label={getLocalized("filter.by_job_roles")}
              prefix="sp-select-filter"
            />
          </Col>
        </AccessControl.Can>
      </Row>
      {isQuadrantDataFetched ? (
        ((quadrantData && quadrantData.length > 0) ||
          (currentUser && currentUser.length > 0)) &&
        quadrantMeta ? (
          <Quadrant
            axisTickTotal={15}
            quadrantData={quadrantData}
            quadrantMeta={quadrantMeta}
            xAxisLabel={getLocalized("skill.personal")}
            yAxisLabel={getLocalized("skill.technical")}
            individualData={currentUser}
            isSearchEnabled={canUseSearch}
            isFilterEnabled={canUseFilters}
          />
        ) : (
          <NoDataLabel />
        )
      ) : (
        <Loader />
      )}
    </>
  );
}
