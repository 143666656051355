import React, { useState, useContext, useEffect } from "react";
import * as Yup from "yup";
import { Formik, FormikActions, FieldProps } from "formik";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  ModalFooter,
  Col,
  Row,
  FormGroup,
  CustomInput
} from "reactstrap";
import { Form } from "formik";
import { Field } from "formik";
import Button from "ui/common/Button";
import InputCustom from "ui/common/InputCustom";
import UserContext from "util/context/UserContext";
import { apiResponseStatuses } from "api/core";
import { createUser, getManagersList, updateUser } from "services/users";
import { SelectCustom } from "ui/common";
import {
  ActionModalProps,
  ModalActionTypes,
  MemberData
} from "ui/common/types";
import {
  ModalItem,
  DesignationData,
  ManagerData as ManagerOptionType
} from "ui/account/types";
import { modalActionTypes } from "ui/common/constants";
import { getJobRolesList } from "services/jobRoles";
import { OptionsType } from "react-select/src/types";
import { OptionType } from "ui/common/SelectCustom/types";
import { entityStatus, UserGroups } from "ui/account/constants";
import {
  DEFAULT_ORDER_BY,
  DEFAULT_ORDER_DIRECTION
} from "ui/common/Table/constants";
import { getScores } from "services/scores";
import { ScoresResponse } from "ui/account/Scores/types";
import Toast from "ui/common/Toast";
import { getLocalized } from "util/localizationUtil";

type ManagerData = {
  id: string;
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  designationId: string;
  permissions?: any;
};
type UsersFormValues = {
  userType: { value: UserType } | string;
  employeeId: string;
  firstName: string;
  lastName: string;
  email: string;
  designation: { id: string } | string;
  lineManager: { id: string } | string;
  isMvp: boolean;
};
type UserType = "EMPLOYEE" | "MANAGER";

const userTypes = Object.freeze({
  employee: "MEMBER" as "EMPLOYEE",
  manager: "MANAGER" as "MANAGER"
});

export default function UsersModal(props: ActionModalProps) {
  const usersValidations = Yup.object({
    userType: Yup.mixed().test(
      "hasUserType",
      getLocalized("required.user_type"),
      userType =>
        !!userType
          ? [userTypes.employee, userTypes.manager].includes(userType.value)
          : false
    ),
    employeeId: Yup.string().required(getLocalized("required.employee_id")),
    firstName: Yup.string().required(getLocalized("required.first_name")),
    lastName: Yup.string().required(getLocalized("required.last_name")),
    email: Yup.string()
      .email(getLocalized("email.invalid"))
      .required(getLocalized("required.email")),
    designation: Yup.mixed().test(
      "hasDesignation",
      getLocalized("required.designation"),
      designation => !!designation && designation.id !== undefined
    ),
    lineManager: Yup.mixed().test(
      "hasLineManager",
      getLocalized("required.line_manager"),
      function test(manager) {
        /* This field is mandatory for employees, and optional for managers. */
        return this.parent.userType &&
          this.parent.userType.value === userTypes.employee
          ? !!manager && manager.id !== undefined
          : true;
      }
    )
  });

  const userTypeOptions = [
    {
      value: userTypes.employee,
      label: getLocalized("employee")
    },
    {
      value: userTypes.manager,
      label: getLocalized("common.manager")
    }
  ];

  const getModalTitle = (actionType: ModalActionTypes) => {
    return actionType === modalActionTypes.EDIT
      ? getLocalized("user.edit")
      : getLocalized("user.add");
  };

  const getModalActionTitle = (actionType: ModalActionTypes) => {
    return actionType === modalActionTypes.EDIT
      ? getLocalized("action.save")
      : getLocalized("action.add");
  };

  const { isOpen, onToggle, actionType, item } = props;
  const { accountId } = useContext(UserContext);
  const [jobRoles, setJobRoles] = useState([]);
  const [actionTypePersist, setActionTypePersist] = useState<ModalActionTypes>(
    actionType
  );
  const [scores, setScores] = useState();

  useEffect(() => {
    isOpen && setActionTypePersist(actionType);
  }, [isOpen, actionType]);

  useEffect(() => {
    const fetchData = async () => {
      if (item) {
        const { data, status } = await getScores(accountId, item.id);
        if (status === apiResponseStatuses.success) {
          const skillsScoresData = data as ScoresResponse;
          setScores(skillsScoresData);
        }
      }
    };
    isOpen && fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    const fetchData = async () => {
      const queryParams = {
        sort: `${DEFAULT_ORDER_BY}:${DEFAULT_ORDER_DIRECTION}`
      };
      const { status, data } = await getJobRolesList(accountId, queryParams);
      if (status === apiResponseStatuses.success) {
        setJobRoles(data);
      }
    };
    isOpen && fetchData();
  }, [accountId, isOpen]);

  const getInitialValues = () => {
    const item = props.item as ModalItem<MemberData>;
    if (item) {
      return {
        userType:
          userTypeOptions.find(option => option.value === item.accessRole) ||
          "",
        employeeId: item.employeeId,
        firstName: item.user.firstName,
        lastName: item.user.lastName,
        email: item.user.email,
        designation: item.designation,
        lineManager: item.manager,
        isMvp: scores ? scores.mvp : false
      };
    }
    return {
      userType: "",
      employeeId: "",
      firstName: "",
      lastName: "",
      email: "",
      designation: "",
      lineManager: "",
      isMvp: false
    };
  };

  const handleToggle = () => onToggle(actionTypePersist);

  const handleExit = () => {
    setActionTypePersist(props.actionType);
  };

  const handleSubmit = async (
    values: UsersFormValues,
    { setSubmitting, setStatus }: FormikActions<UsersFormValues>
  ) => {
    setStatus(null);
    const item = props.item as ModalItem<MemberData>;
    const updateRequestBody = {
      employeeId: values.employeeId,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      managerId:
        values.lineManager && (values.lineManager as { id: string }).id,
      accessRole:
        values.userType && (values.userType as { value: string }).value,
      status: entityStatus.active,
      group: UserGroups.DEFAULT,
      designationId:
        values.designation && (values.designation as { id: string }).id,
      mvp: values.isMvp /* MVP update */
    };
    const createUserBody = {
      employeeId: values.employeeId,
      user: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        status: entityStatus.pending,
        group: UserGroups.DEFAULT
      },
      default: true,
      designationId:
        values.designation && (values.designation as { id: string }).id,
      managerId:
        values.lineManager && (values.lineManager as { id: string }).id,
      accessRole:
        values.userType && (values.userType as { value: string }).value,
      permissions: null,
      preferences: null,
      status: "INVITED"
    };
    const { data, status } =
      actionType === modalActionTypes.EDIT && item
        ? await updateUser(accountId, item.id, updateRequestBody)
        : await createUser(accountId, createUserBody);
    if (status === apiResponseStatuses.success) {
      actionType === modalActionTypes.EDIT
        ? Toast.success(getLocalized("user.update_success"))
        : Toast.success(getLocalized("user.add_success"));
      onToggle(actionTypePersist, null, true);
      setStatus(null);
    } else {
      setStatus(data.message || getLocalized("common.something_went_wrong"));
    }
    setSubmitting(false);
  };

  /* asynchronously load options for select component */
  const loadOptions = async (
    inputValue: string,
    callback: (options: OptionsType<OptionType>) => void
  ) => {
    const queryParams = {
      ...(inputValue !== "" && { q: encodeURIComponent(inputValue) })
    };
    const { status, data } = await getManagersList(accountId, queryParams);
    status === apiResponseStatuses.success && callback(data);
  };

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} onExit={handleExit}>
      <ModalHeader toggle={handleToggle}>
        {getModalTitle(actionTypePersist)}
      </ModalHeader>
      <Formik
        onSubmit={handleSubmit}
        initialValues={getInitialValues()}
        enableReinitialize
        validationSchema={usersValidations}
        render={({ values, status, isSubmitting }) => {
          return (
            <Form noValidate>
              <ModalBody>
                <div>
                  <Alert color="danger" isOpen={!!status}>
                    {status}
                  </Alert>
                </div>
                <Row>
                  <Col>
                    <Field
                      required
                      label={getLocalized("user.type")}
                      id="userType"
                      name="userType"
                      component={SelectCustom}
                      options={userTypeOptions}
                      values={values.userType}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      required
                      type="text"
                      label={getLocalized("employee.id")}
                      name="employeeId"
                      id="employeeId"
                      component={InputCustom}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      required
                      type="text"
                      label={getLocalized("common.first_name")}
                      name="firstName"
                      id="firstName"
                      component={InputCustom}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      required
                      type="text"
                      label={getLocalized("common.last_name")}
                      name="lastName"
                      id="lastName"
                      component={InputCustom}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      required
                      type="email"
                      label={getLocalized("email")}
                      name="email"
                      id="email"
                      component={InputCustom}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      required
                      value={values.designation}
                      options={jobRoles}
                      getOptionValue={(option: DesignationData) => option.id}
                      getOptionLabel={(option: DesignationData) =>
                        option.description
                      }
                      label={getLocalized("common.designation")}
                      name="designation"
                      id="designation"
                      component={SelectCustom}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      required={
                        (values.userType as { value: UserType }).value ===
                        userTypes.employee
                      }
                      async
                      loadOptions={loadOptions}
                      value={
                        values.lineManager
                          ? (values.lineManager as ManagerOptionType).user
                            ? (values.lineManager as ManagerOptionType).user
                            : null
                          : null
                      }
                      label={getLocalized("common.line_manager")}
                      placeholder={getLocalized("common.select_place_holder")}
                      getOptionValue={(option: ManagerOptionType) =>
                        option.user ? option.user.id : option.id
                      }
                      getOptionLabel={(
                        option: ManagerData | ManagerOptionType
                      ) =>
                        (option as ManagerOptionType).user
                          ? `${(option as ManagerOptionType).user.firstName} ${
                              (option as ManagerOptionType).user.lastName
                            }`
                          : `${(option as ManagerData).firstName} ${
                              (option as ManagerData).lastName
                            }`
                      }
                      id="lineManager"
                      name="lineManager"
                      component={SelectCustom}
                      isClearable
                    />
                  </Col>
                </Row>
                {actionType === modalActionTypes.EDIT && (
                  <Row>
                    <Col>
                      <Field
                        value={values.isMvp}
                        name="isMvp"
                        id="isMvp"
                        render={({ field }: FieldProps) => {
                          return (
                            <FormGroup>
                              <CustomInput
                                inline
                                label={`Mark as MVP`}
                                type="switch"
                                id="isMvp"
                                name="isMvp"
                                checked={field.value}
                                {...field}
                              ></CustomInput>
                            </FormGroup>
                          );
                        }}
                      />
                    </Col>
                  </Row>
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  className="default line"
                  type="button"
                  onClick={handleToggle}
                >
                  {getLocalized("action.discard")}
                </Button>
                <Button
                  type="submit"
                  className="primary"
                  loading={isSubmitting}
                >
                  {getModalActionTitle(actionTypePersist)}
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      />
    </Modal>
  );
}
