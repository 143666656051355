import React from "react";
import {
  CustomInput,
  FormGroup,
  FormFeedback,
  CustomInputProps
} from "reactstrap";
import { FieldProps, ErrorMessage } from "formik";

type CheckboxCustomProps = CustomInputProps & FieldProps;

export default function CheckboxCustom({
  field,
  form: { touched, errors, setFieldValue },
  ...props
}: CheckboxCustomProps) {
  return (
    <FormGroup>
      <CustomInput
        {...field}
        {...props}
        type="checkbox"
        id={field.name}
        checked={field.value}
        invalid={!!(touched[field.name] && errors[field.name])}
      />
      <ErrorMessage name={field.name}>
        {error => <FormFeedback>{error}</FormFeedback>}
      </ErrorMessage>
    </FormGroup>
  );
}
