import React, { useState, useRef, useLayoutEffect, useMemo } from "react";
import { UncontrolledTooltip } from "reactstrap";
import {
  FlexibleXYPlot,
  VerticalGridLines,
  HorizontalGridLines,
  XAxis,
  YAxis,
  AreaSeries,
  Borders,
  Hint,
  LineMarkSeries,
  VerticalRectSeries
} from "react-vis";

import { Coordinate, GraphMeta } from "../types";
import styles from "./DistributionGraph.module.scss";
import "../../../../node_modules/react-vis/dist/style.css";

type DistributionGraphProps = {
  distributionData: Array<Coordinate>;
  bottomFiveData: Array<Coordinate>;
  topFiveData: Array<Coordinate>;
  distributionBarData: Array<any>;
  distributionMeta: GraphMeta;
  xAxisLabel: string;
  yAxisLabel: string;
  toggleCategoryList: (categorySelection: string) => void;
  hintLabel: string;
};

function useDimensions() {
  const ref: any = useRef();
  const [dimensions, setDimensions] = useState(null);
  useLayoutEffect(() => {
    const values = ref.current.getBoundingClientRect().toJSON();
    setDimensions(values);
  }, []);

  return [ref, dimensions];
}

const CategoryType = {
  BOTTOM: "BOTTOM",
  TOP: "TOP"
};

export default function DistributionGraph(props: DistributionGraphProps) {
  const {
    distributionData,
    bottomFiveData,
    topFiveData,
    distributionBarData,
    distributionMeta: { maxY, minY, maxX, minX },
    xAxisLabel,
    yAxisLabel,
    toggleCategoryList,
    hintLabel
  } = props;

  const distributionConfig = useMemo(() => {
    const borderStyle = {
      bottom: { fill: "#fff" },
      left: { fill: "#fff" },
      right: { fill: "#fff" },
      top: { fill: "#fff" }
    };
    const gridlineStyle = { stroke: "#bcbcbc" };

    const bottomFiveMin =
      bottomFiveData.length > 0
        ? bottomFiveData.reduce((prev, curr) => (prev.x < curr.x ? prev : curr))
        : { x: minX, y: minY };
    const bottomFiveMax =
      bottomFiveData.length > 0
        ? bottomFiveData.reduce((prev, curr) => (prev.x > curr.x ? prev : curr))
        : { x: minX, y: minY };

    const topFiveMin =
      topFiveData.length > 0
        ? topFiveData.reduce((prev, curr) => (prev.x < curr.x ? prev : curr))
        : { x: maxX, y: maxY };
    const topFiveMax =
      topFiveData.length > 0
        ? topFiveData.reduce((prev, curr) => (prev.x > curr.x ? prev : curr))
        : { x: maxX, y: maxY };

    return {
      borderStyle,
      gridlineStyle,
      bottomFiveMin,
      bottomFiveMax,
      topFiveMin,
      topFiveMax
    };
  }, [bottomFiveData, topFiveData, minX, maxX, minY, maxY]);

  const [bellCurveRef, bellCurveSize] = useDimensions();

  return (
    <div className={styles.graphContainer}>
      <div className={styles.yLabel}>{xAxisLabel}</div>
      <div className={styles.xLabel}>{yAxisLabel}</div>
      <div className={styles.distributionGraph} ref={bellCurveRef}>
        {bellCurveSize ? (
          <FlexibleXYPlot
            animation
            margin={{ top: 5, right: 5, left: 35, bottom: 25 }}
            xDomain={[minX, maxX]}
            yDomain={[minY, maxY]}
          >
            {/* Distribution graph grid lines */}
            <VerticalGridLines />
            <HorizontalGridLines />

            {/* Bar series chart - all */}
            <VerticalRectSeries
              data={distributionBarData}
              style={{ strokeWidth: "1" }}
              fill="#ffcc99"
              opacity={0.5}
              stroke="white"
            />

            {/* LineMark & Area series chart - all */}
            <AreaSeries
              curve="curveMonotoneX"
              stroke="black"
              style={{ strokeWidth: "1" }}
              fill="none"
              data={distributionData}
            />
            <LineMarkSeries
              curve="curveMonotoneX"
              stroke="black"
              style={{ strokeWidth: "1" }}
              fill="white"
              size={4}
              data={distributionData}
            />

            {/* Static Hints */}
            <Hint
              values={bottomFiveData}
              value={{
                x:
                  distributionConfig.bottomFiveMin.x +
                  (distributionConfig.bottomFiveMax.x -
                    distributionConfig.bottomFiveMin.x) /
                    2,
                y:
                  (distributionConfig.bottomFiveMax.y -
                    distributionConfig.bottomFiveMin.y) /
                  15
              }}
              style={{ pointerEvents: "auto", cursor: "pointer" }}
            >
              <div
                id="bottomFive"
                className="rv-hint__content"
                onClick={() => toggleCategoryList(CategoryType.BOTTOM)}
              >
                5%
                <UncontrolledTooltip placement="right" target="bottomFive">
                  {hintLabel}
                </UncontrolledTooltip>
              </div>
            </Hint>
            <Hint
              values={bottomFiveData}
              value={{
                x:
                  distributionConfig.bottomFiveMin.x +
                  (distributionConfig.topFiveMax.x -
                    distributionConfig.bottomFiveMin.x) /
                    2,
                y: distributionConfig.bottomFiveMax.y / 2
              }}
            >
              <div className="rv-hint__content">90%</div>
            </Hint>
            <Hint
              values={topFiveData}
              value={{
                x:
                  distributionConfig.topFiveMin.x +
                  (distributionConfig.topFiveMax.x -
                    distributionConfig.topFiveMin.x) /
                    2,
                y:
                  (distributionConfig.bottomFiveMax.y -
                    distributionConfig.bottomFiveMin.y) /
                  15
              }}
              style={{ pointerEvents: "auto", cursor: "pointer" }}
            >
              <div
                id="topFive"
                className="rv-hint__content"
                onClick={() => toggleCategoryList(CategoryType.TOP)}
              >
                5%
                <UncontrolledTooltip placement="left" target="topFive">
                  {hintLabel}
                </UncontrolledTooltip>
              </div>
            </Hint>

            {/* Distribution graph border */}
            <Borders style={distributionConfig.borderStyle} />

            {/* Distribution graph axes */}
            <XAxis />
            <YAxis />
          </FlexibleXYPlot>
        ) : null}
      </div>
    </div>
  );
}

DistributionGraph.defaultProps = {
  distributionMeta: {
    minX: 0,
    maxX: 10,
    minY: 0,
    maxY: 10
  }
};
