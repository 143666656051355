import { ScoringGuides } from "../types";

export enum SkillGroup {
  Fundamental = "FUNDAMENTAL",
  Structural = "STRUCTURAL"
}

export enum SkillType {
  Technical = "TECHNICAL",
  Personal = "PERSONAL"
}

export type SubSkill = {
  id: string;
  skillId: string;
  name: string;
  title: string;
  description: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  archivedAt: string;
};

export type Skill = {
  [key: string]: any;
  id: string;
  name: string;
  title: string;
  description: string;
  type: string;
  group: string;
  subSkills: SubSkill[];
  scoringGuide: ScoringGuides;
  status: string;
  createdAt: string;
  updatedAt: string;
  archivedAt: string;
};

export type SkillsResponse = {
  total: number;
  results: Skill[];
};
