export const noteTypes = Object.freeze({
  positive: "POSITIVE" as "POSITIVE",
  negative: "NEGATIVE" as "NEGATIVE",
  constructive: "CONSTRUCTIVE" as "CONSTRUCTIVE"
});

export enum AttachmentType {
  None = 0,
  Link = 1,
  File = 2
}

export interface File {
  file: any;
  isValid: boolean;
  validationMessage: string;
}

// eslint-disable-next-line no-useless-escape
export const URL_REGEX_OPTIONAL_PREFIX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|\/\/www\.|\/\/| https: \/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,7}(:[0-9]{1,7})?(\/.*)?$/;
// eslint-disable-next-line no-useless-escape
export const URL_REGEX_REQUIRED_PREFIX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|\/\/www\.|\/\/| https: \/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,7}(:[0-9]{1,7})?(\/.*)?$/;
