import React, { ReactNode } from "react";
import { useContext, useState, useEffect } from "react";
import UserContext, { getDefaultMember } from "util/context/UserContext";
import PermissionContext, {
  checkPermission
} from "util/context/PermissionContext";
import Loader from "../Loader";
import { Redirect } from "react-router-dom";
import { Subjects, AccessControlActions } from "../types";
import { Permission } from "role-acl";

type PrivateRouteProps = {
  children?: ReactNode;
  page: Subjects;
};

export default function ProtectedRoute({ children, page }: PrivateRouteProps) {
  const { permissions } = useContext(PermissionContext);
  const { user } = useContext(UserContext);
  const [grant, setGrant] = useState<Permission | undefined>();

  const defaultMembership = getDefaultMember(user);

  const renderRoute = () => {
    if (grant) {
      const routeGrant = grant.granted;
      return routeGrant ? <>{children}</> : <Redirect to="/dashboard" />;
    } else {
      return <Loader overlay />;
    }
  };

  useEffect(() => {
    if (defaultMembership) {
      const grant = checkPermission(permissions, {
        role: defaultMembership.accessRole,
        resource: page,
        action: AccessControlActions.VisitAny,
        skipConditions: true
      });
      setGrant(grant);
    }
  }, [defaultMembership, page, permissions]);

  return renderRoute();
}
