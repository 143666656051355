// Data Table related constants
export const DEFAULT_RESULTS_LIMIT = 10;
export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_RESULTS_OFFSET = 0;
export const DEFAULT_ORDER_BY = "title";
export const DEFAULT_ORDER_DIRECTION = "asc";

export const modalActionTypes = Object.freeze({
  CREATE: "CREATE",
  EDIT: "EDIT"
});

export const tableEntityTypes = {
  JOB_ROLES: "JOB_ROLES",
  TIERS: "TIERS",
  USERS: "USERS"
};
