import React, { useContext, useRef, useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  Col,
  Row
} from "reactstrap";
import { Field, Formik, Form, FormikActions, FieldArray, getIn } from "formik";
import UserContext from "util/context/UserContext";
import { SkillType } from "ui/account/Skills/types";
import SkillScoresContext from "util/context/SkillScoreContext";
import { ScoresUndoableAction } from "../reducers";
import ScoreSliderCustom from "ui/account/Users/UsersList/UserFilters/ScoreSliderCustom";
import { RatingScaleItem, RatingScale } from "ui/account/types";
import { updateScores } from "services/scores";
import { apiResponseStatuses } from "ui/account/constants";
import { useParams } from "react-router-dom";
import { SkillScores } from "../types";
import { Button, Toast } from "ui/common";
import styles from "./SubSkillRatingModal.module.scss";
import { getLocalized } from "util/localizationUtil";

type SubSkillRatingsFormValues = {
  scores: any;
};

type SubSkillRatingModalProps = {
  onToggle: (open: boolean) => void;
  syncSkills: () => void;
  scoreId: string;
  allSkills: Required<SkillScores>[];
  isOpen: boolean;
  item?: Required<SkillScores>;
};

export default function SubSkillRatingModal(props: SubSkillRatingModalProps) {
  const { isOpen, onToggle, item, allSkills, syncSkills, scoreId } = props;
  const { account, accountId } = useContext(UserContext);
  const dispatch = useContext(SkillScoresContext);

  const isSubmittingRef = useRef(false);
  const { userId: memberId = "" } = useParams();
  const [error, setError] = useState("");

  const ratingScale: RatingScale = getIn(account, `preferences.ratingScale`);

  const handleToggle = (resetForm: () => void) => {
    if (!isSubmittingRef.current) {
      /* not let the user close the modal while submitting */
      resetForm();
      onToggle(!isOpen);
    }
  };

  useEffect(() => {
    setError("");
  }, [isOpen]);

  useEffect(() => {
    const postScores = async (requestBody: Required<SkillScores>[]) => {
      const formattedRequestBody = requestBody.map(mainSkill => {
        if (mainSkill.skill.type === SkillType.Technical) {
          return {
            id: mainSkill.skillId,
            account_id: mainSkill.skill.accountId,
            ...mainSkill.skill,
            subSkills: mainSkill.subSkills.map(subSkill => {
              return {
                id: subSkill.subSkillId,
                ...subSkill.subSkill,
                score_index: subSkill.scoreIndex,
                account_id: subSkill.subSkill.accountId,
                scoreIndex: subSkill.scoreIndex
              };
            })
          };
        } else {
          return {
            id: mainSkill.skillId,
            ...mainSkill.skill,
            score_index: mainSkill.scoreIndex,
            scoreIndex: mainSkill.scoreIndex
          };
        }
      }, {});
      const scoresRequestBody = {
        scores: { data: formattedRequestBody }
      };

      const { status, data } = await updateScores(
        accountId,
        memberId,
        scoreId,
        scoresRequestBody
      );

      if (status === apiResponseStatuses.success) {
        syncSkills();
        isSubmittingRef.current = false;
        setError("");
        onToggle(false);
        Toast.success(getLocalized("score.update_success"));
      } else {
        syncSkills();
        isSubmittingRef.current = false;
        setError(data.message || getLocalized("common.something_went_wrong"));
        Toast.error(getLocalized("score.update_failed"));
      }
    };

    if (isSubmittingRef.current) {
      postScores(allSkills);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, allSkills, memberId, syncSkills]);

  const handleSubmit = async (
    values: SubSkillRatingsFormValues,
    { setSubmitting }: FormikActions<SubSkillRatingsFormValues>
  ) => {
    isSubmittingRef.current = true;

    const action: ScoresUndoableAction =
      item && item.skill.type === SkillType.Personal
        ? {
            type: "UPDATE_MAIN_SKILL_SCORE",
            payload: values.scores[0]
          }
        : {
            type: "UPDATE_SUB_SKILL_SCORE",
            payload: { skillId: item && item.skillId, scores: values.scores }
          };

    dispatch(action);
    setSubmitting(false);
  };

  const renderGradeLabels = (
    <div className={styles.letterRail}>
      {Object.values(ratingScale).map(
        (_, index: number, array: RatingScaleItem[]) => (
          <div className={styles.box} key={index}>
            {array[array.length - 1 - index].ratingLetter}
          </div>
        )
      )}
    </div>
  );

  const initialValues: SubSkillRatingsFormValues = {
    scores:
      item && item.skill.type === SkillType.Personal
        ? [{ id: item.skillId, scoreIndex: [item.scoreIndex] }]
        : item &&
          item.subSkills.map((subSkill: any) => {
            return {
              id: subSkill.subSkillId,
              scoreIndex: [subSkill.scoreIndex]
            };
          })
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      enableReinitialize
      render={({ resetForm }) => {
        const enhancedToggle = () => handleToggle(resetForm);
        return (
          <Modal isOpen={isOpen} size="lg" toggle={enhancedToggle}>
            <ModalHeader toggle={enhancedToggle}>
              {`${getLocalized("sub_skill.rating")} - ${item &&
                item.skill.name}`}
            </ModalHeader>
            <Form noValidate>
              <ModalBody>
                <>
                  <div>
                    <Alert color="danger" isOpen={!!error}>
                      {error}
                    </Alert>
                  </div>
                  <FieldArray
                    name="skills"
                    render={arrayHelpers =>
                      item && item.skill.type === SkillType.Personal ? (
                        <>
                          <Row>
                            <Col xs="12" className="mb-2">
                              {renderGradeLabels}
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <Col xs="12">
                              <Field
                                grades={ratingScale}
                                name={`scores[0].scoreIndex`}
                                id={`scores[0].scoreIndex`}
                                component={ScoreSliderCustom}
                              />
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <>
                          <Row>
                            <Col xs={{ size: 9, offset: 3 }}>
                              {renderGradeLabels}
                            </Col>
                          </Row>
                          {item &&
                            item.subSkills &&
                            item.subSkills.length > 0 &&
                            item.subSkills.map(
                              (subSkill: any, index: number) => {
                                return (
                                  <Row
                                    key={subSkill.subSkillId}
                                    className="mb-2"
                                  >
                                    <Col md="3">{subSkill.subSkill.name}</Col>
                                    <Col md="9">
                                      <Field
                                        grades={ratingScale}
                                        name={`scores[${index}].scoreIndex`}
                                        id={`scores[${index}].scoreIndex`}
                                        component={ScoreSliderCustom}
                                      />
                                    </Col>
                                  </Row>
                                );
                              }
                            )}
                        </>
                      )
                    }
                  />
                </>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  disabled={isSubmittingRef.current}
                  onClick={enhancedToggle}
                >
                  {getLocalized("action.discard")}
                </Button>
                <Button
                  type="submit"
                  className="primary"
                  loading={isSubmittingRef.current}
                >
                  {getLocalized("action.save")}
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
        );
      }}
    />
  );
}
