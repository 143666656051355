import React, { useState, useContext } from "react";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import { ScoringGuide, ScoringGuideSection } from "ui/account/types";
import UserContext, { ProviderValues } from "util/context/UserContext";
import { getIn } from "formik";
import { SkillScores } from "../types";
import styles from "./ScoreGuidePreview.module.scss";
import { getLocalized } from "util/localizationUtil";

type ScoreGuidePreviewProps = {
  skillScores: SkillScores;
};
export default function ScoreGuidePreview(props: ScoreGuidePreviewProps) {
  const { skillScores } = props;
  const { account }: ProviderValues = useContext(UserContext);
  const [activeTab, setActiveTab] = useState(0);

  const toggleTab = (tab: number) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Col xs="12" className={styles.scoreGuidePreview}>
      <div className={styles.wrapper}>
        <Row>
          <Col className={styles.text}>
            <h2>
              {skillScores.skill.title !== ""
                ? skillScores.skill.title
                : getLocalized("tag_line.place_holder")}
            </h2>
            <p>
              {skillScores.skill.description !== ""
                ? skillScores.skill.description
                : getLocalized("skill.desc_specific_skill")}
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs="auto" className="pr-0">
            <Nav tabs vertical className={styles.navTabs}>
              {skillScores.skill.scoringGuide.data.map(
                (scoreGuide: ScoringGuide, index: number) => {
                  const scoreGuideItem = getIn(
                    account,
                    `preferences.ratingScale[${scoreGuide.ratingScaleIndex}]`
                  );

                  return (
                    <NavItem
                      className={`${styles.navItem} ${
                        activeTab === index ? styles.active : ""
                      }`}
                      key={index}
                    >
                      <NavLink
                        className={styles.navLink}
                        onClick={() => toggleTab(index)}
                      >
                        {scoreGuideItem ? `${scoreGuideItem.ratingLetter}` : ""}
                      </NavLink>
                    </NavItem>
                  );
                }
              )}
            </Nav>
          </Col>
          <Col className="pl-0">
            <TabContent activeTab={activeTab} className={styles.tabContent}>
              {skillScores.skill.scoringGuide.data.map(
                (scoreGuide: ScoringGuide, index: number) => {
                  const scoreGuideItem = getIn(
                    account,
                    `preferences.ratingScale[${scoreGuide.ratingScaleIndex}]`
                  );
                  return (
                    <TabPane tabId={index} key={index}>
                      <Row>
                        <Col xs="12">
                          <h3 className={styles.scoreLabel}>
                            {scoreGuideItem.ratingLabel}
                          </h3>
                          <div className={styles.description}>
                            <label className={styles.subTitle}>
                              {getLocalized("common.description")}
                            </label>
                            <p>{scoreGuide.description}</p>
                          </div>
                          {scoreGuide.sections
                            ? scoreGuide.sections.map(
                                (
                                  section: ScoringGuideSection,
                                  index: number
                                ) => (
                                  <div className={styles.section} key={index}>
                                    <label className={styles.subTitle}>
                                      {section.heading}
                                    </label>
                                    <p>{section.content}</p>
                                  </div>
                                )
                              )
                            : null}
                        </Col>
                      </Row>
                    </TabPane>
                  );
                }
              )}
            </TabContent>
          </Col>
        </Row>
      </div>
    </Col>
  );
}
