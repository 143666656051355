import React, { ReactNode, useState } from "react";
import * as Yup from "yup";
import { Formik, Field, Form, FormikActions } from "formik";
import { Link as RouterLink } from "react-router-dom";
import InputCustom from "ui/common/InputCustom";
import Button from "ui/common/Button";
import { NavLink, Alert, Row, Col } from "reactstrap";
import { forgotPassword } from "services/auth";
import styles from "./ForgotPasswordForm.module.scss";
import { apiResponseStatuses } from "api/core";
import { getLocalized } from "util/localizationUtil";

export type ForgotPasswordForm = {
  children?: ReactNode;
};
export type ForgetPasswordFormValues = { email: string };

const ForgotPasswordForm = (props: ForgotPasswordForm) => {
  const setPasswordFormValidations = Yup.object({
    email: Yup.string()
      .email(getLocalized("email.invalid"))
      .trim()
      .required(getLocalized("required.email"))
  });

  const [showAlert, setShowAlert] = useState<boolean>(false);
  const handleShowAlertToggle = () => setShowAlert(showAlert => !showAlert);

  const handleSubmit = async (
    values: ForgetPasswordFormValues,
    actions: FormikActions<ForgetPasswordFormValues>
  ) => {
    const { status, data } = await forgotPassword(values);
    if (status === apiResponseStatuses.success) {
      actions.setStatus(null);
      setShowAlert(true);
    } else {
      if (data.status === 400) {
        actions.setStatus(data.message);
      }
    }
    actions.setSubmitting(false);
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        email: ""
      }}
      validationSchema={setPasswordFormValidations}
      render={({ isSubmitting, status }) => (
        <>
          <Row>
            <Col className={styles.title} xs="12">
              <h1>{getLocalized("password_text.forgot")}</h1>
              <h5>{getLocalized("common.we_got_you_covered")}</h5>
            </Col>
          </Row>
          <div>
            <Alert color="danger" isOpen={!!status}>
              {getLocalized("email.does_not_exist")}
            </Alert>
            <Alert isOpen={showAlert} toggle={handleShowAlertToggle}>
              {getLocalized("password_text.reset_email_sent")}
            </Alert>
          </div>
          <Form noValidate>
            <Field
              required
              type="email"
              label={getLocalized("email.request")}
              name="email"
              component={InputCustom}
            />
            <Row form>
              <Col xs="12" className="text-center">
                <Button
                  className={`${styles.submit} primary`}
                  loading={isSubmitting}
                >
                  {getLocalized("common.send_reset_link")}
                </Button>
              </Col>
              <Col className={`text-center ${styles.backToLogin}`}>
                <NavLink
                  className="p-0 text-muted"
                  tag={RouterLink}
                  to="/login"
                >
                  {getLocalized("common.go_back_to_login")}
                </NavLink>
              </Col>
            </Row>
            <div></div>
          </Form>
        </>
      )}
    />
  );
};

export default ForgotPasswordForm;
