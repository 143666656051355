import React, { createContext, useState } from "react";
import { Props } from "util/context/UserContext";

const initialContext = {
  loading: false,
  onChangeLoading: (loading: boolean, where: string) => {}
};
const LoadingContext = createContext(initialContext);

const LoadingConsumer = LoadingContext.Consumer;
function LoadingProvider(props: Props) {
  const [loading, setLoading] = useState({});

  const handleChangeLoading = (newLoading: boolean, where: string) => {
    /* use unique names for where withing context provider */
    setLoading(prevLoading => {
      return { ...prevLoading, [where]: newLoading };
    });
  };

  const loadingStatus = Object.values(loading).includes(true);

  return (
    <LoadingContext.Provider
      value={{ loading: loadingStatus, onChangeLoading: handleChangeLoading }}
    >
      {props.children}
    </LoadingContext.Provider>
  );
}

export default LoadingContext;
export { LoadingProvider, LoadingConsumer };
