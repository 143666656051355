import React from "react";
import { Col, Row, UncontrolledTooltip } from "reactstrap";
import { Skill, SkillGroup, SkillType } from "../types";
import styles from "./Preview.module.scss";
import { Icon, NoDataLabel } from "ui/common";
import { getLocalized } from "util/localizationUtil";
type PreviewProps = {
  type: SkillType;
  items: Skill[];
};

export default function Preview(props: PreviewProps) {
  const renderLegend = () => (
    <>
      <div className={styles.fundamental}>
        {getLocalized("common.fundamental")}
        <span id="fundamental">
          <Icon name="info" />
          <UncontrolledTooltip placement="right" target="fundamental">
            {getLocalized("common.fundamental_desc")}
          </UncontrolledTooltip>
        </span>
      </div>
      <div className={styles.structural}>
        {getLocalized("common.structural")}
        <span id="structural">
          <Icon name="info" />
          <UncontrolledTooltip placement="right" target="structural">
            {getLocalized("common.structural_desc")}
          </UncontrolledTooltip>
        </span>
      </div>
    </>
  );

  return (
    <Row className={styles.scrollGradient}>
      <Col xs="12" className={styles.rail}>
        {props.items.length ? (
          props.items.map((item, index: number) => {
            const isHighlighted = item.group === SkillGroup.Fundamental;
            return (
              <div
                className={`${styles.box} ${
                  isHighlighted ? styles.highlighted : ""
                } ${
                  props.type === SkillType.Personal
                    ? styles.personal
                    : styles.technical
                }`}
                key={index}
              >
                <span>{item.name}</span>
              </div>
            );
          })
        ) : (
          <NoDataLabel
            noDataLabel={getLocalized("common.no_skills_selected")}
          />
        )}
      </Col>
      <Col xs="12" className={styles.legend}>
        {props.type === SkillType.Personal ? renderLegend() : null}
      </Col>
    </Row>
  );
}
