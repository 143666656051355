import React, { useState, useContext } from "react";
import { Row, Col, Alert } from "reactstrap";
import { Button, ButtonActionModalHolder } from "ui/common";
import ScoreContributionModal from "./ScoreContributionModal/index";
import styles from "./Settings.module.scss";
import SkillLevelLabelModal from "./SkillLevelLabelModal";
import UserContext, { ProviderValues } from "util/context/UserContext";
import { Account } from "ui/account/types";
import AdminModal from "./AdminModal/index";
import { getLocalized } from "util/localizationUtil";

function getRatingScale(account: any) {
  let ratingScale = null;
  if (account != null) {
    ratingScale = (account as Account).preferences.ratingScale;
  }
  return ratingScale;
}

function getScoreContribution(account: any): [number, number] {
  let technicalContribution = 60;
  let personalContribution = 40;
  if (account != null) {
    technicalContribution = (account as Account).preferences
      .skillTypeContribution.technical;
    personalContribution = (account as Account).preferences
      .skillTypeContribution.personal;
  }
  return [technicalContribution, personalContribution];
}

export default function Settings() {
  const { account, onChangeAccount }: ProviderValues = useContext(UserContext);

  const [showNotification, setShowNotification] = useState(true);
  const [scoreContributionModalOpen, setScoreContributionModalOpen] = useState(
    false
  );
  const [skillLevelLabelModalOpen, setSkillLevelLabelModalOpen] = useState(
    false
  );

  const ratingScale = getRatingScale(account);
  const scoreContribution: [number, number] = getScoreContribution(account);

  return (
    <div className={styles.settings}>
      <Row>
        <Col xs="12">
          <Alert
            color="primary"
            toggle={() => setShowNotification(false)}
            isOpen={showNotification}
          >
            {getLocalized("score.matrix_guide_info")}
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="text-right">
          <ButtonActionModalHolder
            label={getLocalized("admin.add")}
            actionModal={AdminModal}
            onReload={() => {}}
          />
        </Col>
      </Row>
      <Col xs="12" className={styles.card}>
        <Row>
          <Col xs="auto" className={styles.title}>
            {getLocalized("employee_score_contribution.description")}
          </Col>
          <Col className="text-right mb-3">
            <Button
              className="text-button primary-text small-button small-button"
              onClick={() => setScoreContributionModalOpen(true)}
            >
              {getLocalized("action.edit")}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <div className={styles.progress}>
              <div
                className={`${styles.section} ${styles.technical}`}
                style={{ minWidth: `${scoreContribution[0]}%` }}
              >
                {scoreContribution[0] > 0 && (
                  <div>
                    <p>{getLocalized("skill.technical_contribution")}</p>
                    <div className={styles.bar}>{scoreContribution[0]}%</div>
                  </div>
                )}
              </div>
              <div
                className={`${styles.section} ${styles.personal}`}
                style={{ minWidth: `${scoreContribution[1]}%` }}
              >
                {scoreContribution[1] > 0 && (
                  <div>
                    <p>{getLocalized("skill.personal_contribution")}</p>
                    <div className={styles.bar}>{scoreContribution[1]}%</div>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs="12" className={styles.card}>
        <Row>
          <Col xs="auto" className={styles.title}>
            {getLocalized("skill_level")}
          </Col>
          <Col className="text-right mb-3">
            <Button
              className="text-button primary-text small-button"
              onClick={() => setSkillLevelLabelModalOpen(true)}
            >
              {getLocalized("action.edit")}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs="12" className={styles.tagCloud}>
            {ratingScale &&
              Object.values(ratingScale).map(item => (
                <div className={styles.tag} key={item.ratingLetter}>
                  <span>
                    <b className="mr-2">{item.ratingLetter}</b>{" "}
                    {item.ratingLabel}
                  </span>
                </div>
              ))}
          </Col>
        </Row>
      </Col>
      <ScoreContributionModal
        isOpen={scoreContributionModalOpen}
        onToggle={setScoreContributionModalOpen}
        item={scoreContribution}
        onSuccess={onChangeAccount}
      />
      <SkillLevelLabelModal
        isOpen={skillLevelLabelModalOpen}
        onToggle={setSkillLevelLabelModalOpen}
        item={ratingScale}
        onSuccess={onChangeAccount}
      />
    </div>
  );
}
