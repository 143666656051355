import React from "react";
import { FormGroup, Label } from "reactstrap";
import DatePicker from "react-datepicker";
import { FieldProps, ErrorMessage } from "formik";
import "react-datepicker/dist/react-datepicker.css";

type DatePickerCustomProps = {
  label: string;
  readOnly: boolean;
  isDisabled: boolean;
};

const DatePickerCustom = ({
  field, // { name, value, onChange, onBlur } // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  form: { touched, errors, setFieldValue },
  isDisabled,
  ...props
}: FieldProps & DatePickerCustomProps) => {
  return (
    <FormGroup>
      {props.label && <Label for={field.name}>{props.label}</Label>}
      <DatePicker
        {...field}
        {...props}
        className="form-control"
        selected={(field.value && new Date(field.value)) || null}
        popperPlacement="bottom-end"
        onChange={option => {
          const newDate = new Date(option as any).toISOString().split("T")[0];
          setFieldValue(field.name, newDate);
        }} //only when value has changed
        popperModifiers={{
          offset: {
            enabled: true,
            offset: "-16px, 1px"
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
            boundariesElement: "viewport"
          }
        }}
      />
      <ErrorMessage name={field.name}>
        {error => <div>{error}</div>}
      </ErrorMessage>
    </FormGroup>
  );
};

DatePickerCustom.defaultProps = {
  label: "",
  readOnly: false,
  isDisabled: false
};
export default DatePickerCustom;
