import React from "react";
import Loader from "../Loader";

type AvatarProps = {
  size: [number, number];
  alt: string;
  src: string;
  loading?: boolean;
};
export default function Avatar(props: AvatarProps) {
  return !props.loading ? (
    <img
      alt={props.alt}
      width={props.size[0]}
      height={props.size[1]}
      src={props.src}
    />
  ) : (
    <Loader />
  );
}

Avatar.defaultProps = {
  size: [64, 64], //width, height
  alt: "image",
  loading: false
};
