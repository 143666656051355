import React, { useContext } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  ModalFooter,
  Row,
  Col
} from "reactstrap";
import * as Yup from "yup";
import { Formik, Form, Field, FieldArray, FormikActions } from "formik";
import { Button, InputCustom, Toast } from "ui/common";
import { RatingScale, Account } from "ui/account/types";
import { updateAccount } from "services/account";
import UserContext from "util/context/UserContext";
import { apiResponseStatuses } from "api/core";
import { getLocalized } from "util/localizationUtil";

type SkillLevelLabelFormValues = {
  skillLevels: RatingScale | null;
};
type SkillLevelLabelModalProps = {
  isOpen: boolean;
  item: RatingScale | null;
  onToggle: (open: boolean) => void;
  onSuccess: (account: Account) => void;
};

export default function SkillLevelLabelModal(props: SkillLevelLabelModalProps) {
  const skillLevelLabelValidations = Yup.object().shape({
    skillLevels: Yup.object().shape({
      1: Yup.object().shape({
        ratingLetter: Yup.string().required(getLocalized("required")),
        ratingLabel: Yup.string().required(getLocalized("required"))
      }),
      2: Yup.object().shape({
        ratingLetter: Yup.string().required(getLocalized("required")),
        ratingLabel: Yup.string().required(getLocalized("required"))
      }),
      3: Yup.object().shape({
        ratingLetter: Yup.string().required(getLocalized("required")),
        ratingLabel: Yup.string().required(getLocalized("required"))
      }),
      4: Yup.object().shape({
        ratingLetter: Yup.string().required(getLocalized("required")),
        ratingLabel: Yup.string().required(getLocalized("required"))
      })
    })
  });

  const { accountId } = useContext(UserContext);
  const { item, isOpen, onToggle, onSuccess } = props;
  const handleSubmit = async (
    { skillLevels }: SkillLevelLabelFormValues,
    { setSubmitting }: FormikActions<SkillLevelLabelFormValues>
  ) => {
    if (skillLevels != null) {
      const skillLevelsWithDescription = Object.keys(skillLevels).reduce(
        (accumulator, currentValue) => {
          return {
            ...accumulator,
            [currentValue]: {
              ...skillLevels[currentValue],
              description: `${skillLevels[currentValue].ratingLetter} ${skillLevels[currentValue].ratingLabel}`
            }
          };
        },
        skillLevels
      );
      const { data, status } = await updateAccount(accountId, {
        preferences: {
          ratingScale: skillLevelsWithDescription
        }
      });
      if (status === apiResponseStatuses.success) {
        onSuccess(data);
        handleToggle();
        Toast.success(getLocalized("skill_level.update_success"));
      } else {
        Toast.error(getLocalized("skill_level.update_failed"));
      }
    }
    setSubmitting(false);
  };
  const handleToggle = () => onToggle(false);

  return (
    <Modal isOpen={isOpen} toggle={handleToggle}>
      <ModalHeader toggle={handleToggle}>
        {getLocalized("skill_level.edit")}
      </ModalHeader>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          skillLevels: item
        }}
        enableReinitialize
        validationSchema={skillLevelLabelValidations}
        render={({
          status,
          values,
          resetForm,
          setFieldValue,
          isSubmitting
        }) => {
          return (
            <Form noValidate>
              <ModalBody>
                <div>
                  <Alert color="danger" isOpen={!!status}>
                    {status}
                  </Alert>
                </div>
                <FieldArray
                  name="skillLevels"
                  render={() => {
                    return (
                      values.skillLevels &&
                      Object.keys(values.skillLevels).map((key: string) => {
                        return (
                          <Row key={key}>
                            <Col
                              style={{ paddingTop: "0.625rem" }}
                              md={{ size: 1 }}
                            >
                              {key}
                              {"."}
                            </Col>
                            <Col md="2">
                              <Field
                                required
                                id={`ratingLetter.${key}`}
                                name={`skillLevels.${key}.ratingLetter`}
                                component={InputCustom}
                              />
                            </Col>
                            <Col md={{ size: 9 }}>
                              <Field
                                required
                                id={`ratingLabel.${key}`}
                                name={`skillLevels.${key}.ratingLabel`}
                                component={InputCustom}
                              />
                            </Col>
                          </Row>
                        );
                      })
                    );
                  }}
                />
                <small>
                  {getLocalized(
                    "common.skill_levels_in_desc_order_by_score_weight"
                  )}
                </small>
              </ModalBody>
              <ModalFooter>
                <Button type="button" onClick={() => resetForm()}>
                  {getLocalized("action.reset")}
                </Button>
                <Button
                  type="submit"
                  loading={isSubmitting}
                  className="primary"
                >
                  {getLocalized("action.save")}
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      />
    </Modal>
  );
}
