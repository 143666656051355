import React, { ReactNode } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { UserConsumer } from "util/context/UserContext";

type PublicRouteProps = {
  path: string;
  exact?: boolean;
  children?: ReactNode;
  icon?: string;
} & RouteProps;

export default function PublicRoute({ children, ...rest }: PublicRouteProps) {
  return (
    <UserConsumer>
      {({ user }) => {
        return (
          <Route {...rest}>
            {user !== null ? (
              <Redirect
                to={{
                  pathname: "/"
                }}
              />
            ) : (
              children
            )}
          </Route>
        );
      }}
    </UserConsumer>
  );
}
