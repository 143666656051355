export const modalActionTypes = Object.freeze({
  CREATE: "CREATE" as "CREATE",
  EDIT: "EDIT" as "EDIT"
});

export const apiResponseStatuses = Object.freeze({
  success: "success",
  error: "error"
});

export const entityStatus = {
  pending: "PENDING",
  active: "ACTIVE",
  blocked: "BLOCKED",
  archived: "ARCHIVED"
};

export const tableEntityTypes = {
  JOB_ROLES: "JOB_ROLES",
  TIERS: "TIERS",
  USERS: "USERS",
  REPORTS: "REPORTS"
};

export const UserGroups = {
  DEFAULT: "DEFAULT",
  SYSTEM: "SYSTEM"
};

export const ProfileImageConfig = {
  SIZE: 5000000 /* 5mb */
};
