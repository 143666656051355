import { addMonths } from "date-fns/esm";
import { default as React, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { getScoreProgressionData } from "services/graphs";
import { apiResponseStatuses } from "ui/account/constants";
import TimeLineSeries from "ui/common/TimeLineSeries";
import UserContext from "util/context/UserContext";
import styles from "./ScoreProgressionGraph.module.scss";
import { NoDataLabel, Loader } from "ui/common";
import { getLocalized } from "util/localizationUtil";

type ScoreProgressionGraphProps = {
  refetchData: boolean;
};

export default function ScoreProgressionGraph(
  props: ScoreProgressionGraphProps
) {
  const { refetchData } = props;
  const { accountId } = useContext(UserContext);
  const { userId: memberId } = useParams();

  const [coordinates, setCoordinates] = useState();
  const [meta, setMeta] = useState();
  const [isGraphDataFetched, setIsGraphDataFetched] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (memberId && accountId) {
        setIsGraphDataFetched(false);
        const { status, data } = await getScoreProgressionData(
          accountId,
          memberId
        );
        if (status === apiResponseStatuses.success) {
          setCoordinates(
            data.scores.map((coordinate: any) => ({
              x: new Date(coordinate.date),
              y: coordinate.score
            }))
          );
          setMeta(data.meta);
        }
        setIsGraphDataFetched(true);
      }
    };
    fetchData();
  }, [accountId, memberId]);

  useEffect(() => {
    const fetchData = async () => {
      if (memberId && accountId) {
        setIsGraphDataFetched(false);
        const { status, data } = await getScoreProgressionData(
          accountId,
          memberId
        );
        if (status === apiResponseStatuses.success) {
          setCoordinates(
            data.scores.map((coordinate: any) => ({
              x: new Date(coordinate.date),
              y: coordinate.score
            }))
          );
          setMeta(data.meta);
        }
        setIsGraphDataFetched(true);
      }
    };

    if (refetchData) {
      fetchData();
    }
  }, [accountId, memberId, refetchData]);

  const hasYearOnYearGrowth =
    isGraphDataFetched &&
    coordinates.length > 0 &&
    coordinates[0].x.getFullYear() ===
      coordinates[coordinates.length - 1].x.getFullYear();

  return (
    <Row className={styles.skillProgressWrap}>
      <Col xs="12" className={styles.skillProgress}>
        {isGraphDataFetched ? (
          coordinates.length > 0 ? (
            <>
              <span className={styles.x}>{getLocalized("SCORE")}</span>
              <span className={styles.y}>{getLocalized("common.time")}</span>
              <div className={styles.timeLine}>
                <TimeLineSeries
                  showCrossHairs={false}
                  xAxisLabel={getLocalized("common.time")}
                  yAxisLabel={getLocalized("SCORE")}
                  data={coordinates}
                  xDomain={[
                    coordinates[0].x,
                    addMonths(coordinates[coordinates.length - 1].x, 1)
                  ]}
                  yDomain={[0, meta.maxAchievableScore]}
                />
              </div>
              <div className={styles.data}>
                <Row>
                  <Col xs="6" className="text-left">
                    <div className={styles.label}>
                      {hasYearOnYearGrowth
                        ? getLocalized("common.year_on_year_growth")
                        : getLocalized("score.growth_since_first_score")}{" "}
                    </div>
                    <div className={styles.value}>{meta.yearOnYearGrowth}%</div>
                  </Col>
                  <Col xs="6" className="text-right">
                    <div className={styles.label}>
                      {getLocalized("score.growth_since_last_score")}{" "}
                    </div>
                    <div className={styles.value}>
                      {meta.growthSinceLastScore}%
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <NoDataLabel noDataLabel={getLocalized("score.no_result_now")} />
          )
        ) : (
          <Loader />
        )}
      </Col>
    </Row>
  );
}
