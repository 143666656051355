import React from "react";
import { Route, useRouteMatch, Switch } from "react-router-dom";
import UsersList from "./UsersList";
import UserProfile from "./UserProfile";
import { PrivateRoute, NoDataLabel } from "ui/common";
import { Subjects } from "ui/common/types";
import { ProfileMemberType } from "ui/account/types";
import { getLocalized } from "util/localizationUtil";

type UserProfileParams = {
  userId: string;
};

export default function Users() {
  const match = useRouteMatch<UserProfileParams>();
  const path = match ? match.path : "/manage-users";

  const profilePathRegex = Object.values(ProfileMemberType).join("|");

  return (
    <Switch>
      <Route exact path={path} component={UsersList} />
      <PrivateRoute
        path={`${path}/profile/:userType(${profilePathRegex})/:userId`}
        page={Subjects.ProfilePage}
      >
        <UserProfile />
      </PrivateRoute>
      <Route path="*">
        <NoDataLabel noDataLabel={getLocalized("common.page_not_found")} />
      </Route>
    </Switch>
  );
}
