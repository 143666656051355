import React from "react";
import { Col, Collapse, Row } from "reactstrap";
import Icon from "../Icon";
import styles from "./Accordion.module.scss";

type AccordionProps = {
  index: number;
  isOpen: boolean;
  onClick: (id: string) => void;
  item: any;
  headerComponent?: React.ComponentType;
  renderHeader?: () => React.ReactNode;
  bodyComponent?: React.ComponentType;
  renderBody?: () => React.ReactNode;
};

export default function Accordion(props: AccordionProps) {
  const {
    index,
    isOpen,
    onClick,
    item,
    headerComponent: HeaderComponent,
    renderHeader,
    bodyComponent: BodyComponent,
    renderBody
  } = props;

  const handleRowClick = () => {
    onClick(item.id || index);
  };

  return (
    <Col xs="12" className={`${styles.accordion} ${isOpen ? styles.open : ""}`}>
      <Row className={styles.bar}>
        <Col
          sm="auto"
          xs="12"
          className={styles.toggle}
          onClick={handleRowClick}
        >
          {isOpen ? (
            <Icon name="keyboard_arrow_up" />
          ) : (
            <Icon name="keyboard_arrow_down" />
          )}
        </Col>
        <Col>
          {renderHeader && renderHeader()}
          {HeaderComponent && <HeaderComponent />}
        </Col>
      </Row>
      <Collapse className={styles.details} isOpen={isOpen}>
        {renderBody && renderBody()}
        {BodyComponent && <BodyComponent />}
      </Collapse>
    </Col>
  );
}
