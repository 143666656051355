import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { getGoalsList, GoalsQueryParams } from "services/goals";
import { Button, NoDataLabel, AccessControl } from "ui/common";
import UserContext, {
  ProviderValues as UserProviderValues
} from "util/context/UserContext";
import { apiResponseStatuses, modalActionTypes } from "../constants";
import { GoalFilter } from "./constants";
import GoalListItem from "./GoalListItem";
import styles from "./Goals.module.scss";
import GoalsModal from "./GoalsModal";
import Pagination, {
  defaultLimit,
  defaultPage
} from "ui/common/SimplePagination";
import { GoalData, GoalFilterType } from "./types";
import { Subjects, AccessControlActions } from "ui/common/types";
import { getLocalized } from "util/localizationUtil";

type GoalsProps = {
  userId: string;
  onGoalAchieved: (isGoalAchieved: boolean) => void;
};

export default function Goals(props: GoalsProps) {
  const { accountId }: UserProviderValues = useContext(UserContext);
  const { userId: memberId } = useParams();

  const [goals, setGoals] = useState<GoalData[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(defaultPage);
  const [pageSize, setPageSize] = useState<number>(defaultLimit);

  const [goalFilter, setGoalFilter] = useState<GoalFilterType>(null);
  const [totalGoals, setTotalGoals] = useState<number>(0);

  const [goalModalOpen, setGoalModalOpen] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);

  useEffect(() => {
    const query: GoalsQueryParams = {
      page: `${currentPage - 1}:${pageSize}`,
      "filter[memberId]": memberId
    };
    if (goalFilter) {
      query["filter[status]"] = goalFilter;
    }
    if (memberId) {
      query["filter[memberId]"] = memberId;
    }
    const fetchData = async () => {
      const { status, data } = await getGoalsList(accountId, query);
      if (status === apiResponseStatuses.success) {
        setGoals(data.results);
        setTotalGoals(data.total);
      }
      setForceRefresh(false);
    };
    fetchData();
  }, [accountId, memberId, goalFilter, currentPage, pageSize, forceRefresh]);

  // Change goal filters
  const handleSetGoalFilter = (filter: GoalFilterType) => {
    setGoalFilter(filter);
    setCurrentPage(defaultPage);
  };

  const handleGoalChange = () => setForceRefresh(true);

  return (
    <Col xs="12" className={styles.goalsWrapper}>
      <Row>
        <Col xs="auto" className={styles.title}>
          {getLocalized("goal.plural")}
        </Col>
        <Col className="text-right mb-3">
          <AccessControl.IsMyProfile
            memberId={memberId}
            yes={children => (
              <AccessControl.Can
                resource={Subjects.ProfileGoal}
                action={AccessControlActions.AddOwn}
              >
                {children}
              </AccessControl.Can>
            )}
            no={children => (
              <AccessControl.Can
                resource={Subjects.ProfileGoal}
                action={AccessControlActions.AddAny}
              >
                <AccessControl.OnlySubordinates memberId={memberId}>
                  {children}
                </AccessControl.OnlySubordinates>
              </AccessControl.Can>
            )}
          >
            <Button
              className="text-button primary-text small-button"
              onClick={() => setGoalModalOpen(true)}
            >
              {getLocalized("goal.add")}
            </Button>
          </AccessControl.IsMyProfile>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.tabButtons}>
            <Button
              onClick={() => handleSetGoalFilter(null)}
              className={!goalFilter ? styles.active : ""}
            >
              {getLocalized("common.all")}
            </Button>
            <Button
              onClick={() => handleSetGoalFilter(GoalFilter.PENDING)}
              className={goalFilter === GoalFilter.PENDING ? styles.active : ""}
            >
              {getLocalized("common.pending")}
            </Button>
            <Button
              onClick={() => handleSetGoalFilter(GoalFilter.PAST)}
              className={goalFilter === GoalFilter.PAST ? styles.active : ""}
            >
              {getLocalized("common.past")}
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {goals.length > 0 ? (
            <div className={styles.goalsMarginCatcher}>
              {goals.map((goal: GoalData) => (
                <GoalListItem
                  key={goal.id}
                  goal={goal}
                  onGoalAchieved={props.onGoalAchieved}
                  onGoalDelete={handleGoalChange}
                  onGoalEdit={handleGoalChange}
                />
              ))}
            </div>
          ) : (
            <NoDataLabel noDataLabel={getLocalized("goal.no_result_now")} />
          )}
        </Col>
      </Row>
      {totalGoals > 3 && (
        <Row>
          <Col>
            <Pagination
              totalResults={totalGoals}
              currentPage={currentPage}
              pageSize={pageSize}
              onChangePageSize={setPageSize}
              onSetCurrentPage={setCurrentPage}
            />
          </Col>
        </Row>
      )}
      <GoalsModal
        isOpen={goalModalOpen}
        onToggle={setGoalModalOpen}
        onSuccess={handleGoalChange}
        actionType={modalActionTypes.CREATE}
      />
    </Col>
  );
}
