import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./ui/App";
import * as serviceWorker from "./serviceWorker";
import { setBaseURL } from "api/core";
import { BASE_URL } from "config/endpoints";

setBaseURL(BASE_URL);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
