import React, { createContext, useState, useEffect } from "react";
import { Props } from "util/context/UserContext";
import { ac } from "ui/App";
import { IQueryInfo } from "role-acl";

const PERMISSIONS_KEY = "PERMISSIONS";
const initialContext = {
  permissions: {},
  onGetPermissions: (permissions: any) => {}
};

const checkPermission = (permissions: any, query: IQueryInfo) => {
  if (Object.keys(permissions).length > 0) {
    ac.setGrants(permissions);
    return ac.permissionSync(query);
  }
};

function PermissionProvider(props: Props) {
  const [permissions, setPermissions] = useState(() => {
    const localData = localStorage.getItem(PERMISSIONS_KEY);
    return localData ? JSON.parse(localData) : {};
  });

  useEffect(() => {
    localStorage.setItem(PERMISSIONS_KEY, JSON.stringify(permissions));
  }, [permissions]);

  const onGetPermissions = (permissions: any) => {
    /* use unique names for where withing context provider */
    setPermissions(permissions);
  };

  return (
    <PermissionContext.Provider value={{ permissions, onGetPermissions }}>
      {props.children}
    </PermissionContext.Provider>
  );
}
const PermissionContext = createContext(initialContext);

const PermissionConsumer = PermissionContext.Consumer;

export default PermissionContext;
export { PermissionProvider, PermissionConsumer, checkPermission };
