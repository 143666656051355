import React from "react";
import { ActionModalProps, ModalActionTypes, TableData } from "../types";
import { modalActionTypes } from "ui/account/constants";

type ButtonActionModalHolderProps = {
  label: string;
  color?: string;
  outline?: boolean;
  actionModal: React.ComponentType<ActionModalProps>;
  onReload: (forceRefresh: boolean) => void;
};

export default function ButtonActionModalHolder(
  props: ButtonActionModalHolderProps
) {
  const { label, actionModal, onReload } = props;

  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  const ActionModal = actionModal;

  const handleModalToggle = (
    actionType?: ModalActionTypes | null,
    currentItem?: TableData | null,
    forceRefresh?: boolean | null
  ) => {
    setModalOpen(prevModalOpen => !prevModalOpen);
    if (forceRefresh) {
      onReload(forceRefresh);
    }
  };

  function handleClick() {
    handleModalToggle("CREATE");
  }

  return (
    <>
      <button onClick={handleClick} className="primary">
        {label}
      </button>
      {ActionModal && (
        <ActionModal
          isOpen={modalOpen}
          onToggle={handleModalToggle}
          item={null}
          actionType={modalActionTypes.CREATE}
        />
      )}
    </>
  );
}
