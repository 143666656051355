import React, { createContext, useState, useEffect, ReactNode } from "react";
import { Account } from "ui/account/types";
import { Nullable } from "ui/common/types";

export type Props = {
  children?: ReactNode;
};
type Subordinate = {
  [key: string]: string;
  id: string;
};
export type Membership = {
  id: string;
  accountId: string;
  userId: string;
  default: boolean;
  designationId: string;
  managerId: string;
  accessRole: string;
  status: string;
  subordinates: string[];
  immediateSubordinates: Subordinate[];
  account: Account;
  createdAt: Date;
  updatedAt: Date;
  archivedAt: Date;
};
export type LoginResponse = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  group: string;
  memberships: Membership[];
  createdAt: Date;
  updatedAt: Date;
  archivedAt: Date;
};

export type OnLogin = (user?: LoginResponse) => void;
export type ProviderValues = {
  account: Account | null;
  accountId: string;
  user: LoginResponse | null;
  onChangeAccount: (account: Account) => void;
  onLogin: OnLogin;
  onLogout: () => void;
};
export type UserProviderValues = ProviderValues | null;

const initialContext = {
  account: null,
  accountId: "",
  user: null,
  onChangeAccount: (account: Account) => {},
  onLogin: () => {},
  onLogout: () => {}
};

function getUserInitial() {
  const authUser = localStorage.getItem("user");
  return authUser ? JSON.parse(authUser) : null;
}
function getAccountInitial() {
  const account = localStorage.getItem("account");
  return account ? JSON.parse(account) : null;
}
export function getDefaultMember(user: Nullable<LoginResponse>) {
  const defaultMembership = user
    ? user.memberships.find(membership => membership.default)
    : null;
  return defaultMembership;
}

const UserContext = createContext(initialContext);
const UserConsumer = UserContext.Consumer; /* to be used with useContext */

const localStorageUser = getUserInitial();
const localStorageAccountId = localStorage.getItem("accountId") || "";

function UserProvider(props: Props) {
  const [user, setUser] = useState(
    localStorageUser
  ); /* should we make local storage the only source of truth? */
  const [accountId, setAccountId] = useState<string>(localStorageAccountId);
  const [account, setAccount] = useState(getAccountInitial());

  useEffect(() => {
    const defaultMember = getDefaultMember(user);

    const accountId = defaultMember ? defaultMember.accountId : "";
    const localStorageUser = user ? JSON.stringify(user) : null;
    const localStorageAccount = defaultMember
      ? JSON.stringify(defaultMember.account)
      : null;

    localStorage.setItem("accountId", accountId);

    localStorageUser
      ? localStorage.setItem("user", localStorageUser)
      : localStorage.removeItem("user");

    localStorageAccount
      ? localStorage.setItem("account", localStorageAccount)
      : localStorage.removeItem("account");
  }, [user]);

  const handleChangeAccount = (account: Account) => {
    setAccount(account);
  };

  const handleLogin = (user?: LoginResponse) => {
    let accountId = "";
    let account = null;
    if (user) {
      const defaultMember = getDefaultMember(user);
      if (defaultMember) {
        accountId = defaultMember.accountId;
        account = defaultMember.account;
      }
      setUser(user);
    } else {
      setUser(null);
    }
    setAccountId(accountId);
    setAccount(account);
  };

  const handleLogout = () => setUser(null);

  return (
    <UserContext.Provider
      value={{
        account,
        accountId,
        user: user,
        onChangeAccount: handleChangeAccount,
        onLogin: handleLogin,
        onLogout: handleLogout
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}

export default UserContext;
export { UserProvider, UserConsumer };
