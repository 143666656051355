import React, { useState, useRef, useLayoutEffect, useMemo } from "react";
import {
  FlexibleXYPlot,
  VerticalGridLines,
  HorizontalGridLines,
  XAxis,
  YAxis,
  MarkSeries,
  DecorativeAxis,
  Borders,
  Hint,
  Highlight
} from "react-vis";

import UserDetailHint from "./UserDetailHint";
import { Button, Icon } from "ui/common";
import { QuadrantValue, GraphMeta } from "ui/common/types";
import styles from "./Quadrant.module.scss";

type QuadrantProps = {
  quadrantData: Array<QuadrantValue>;
  quadrantMeta: GraphMeta;
  individualData?: Array<QuadrantValue>;
  axisTickTotal: number;
  hideAxisTicks: boolean;
  markSize: number;
  selectedMarkSize: number;
  xAxisLabel: string;
  yAxisLabel: string;
  drag: boolean;
  isSearchEnabled: boolean;
  isFilterEnabled: boolean;
};

function useDimensions() {
  const ref: any = useRef();
  const [dimensions, setDimensions] = useState(null);
  useLayoutEffect(() => {
    const values = ref.current.getBoundingClientRect();
    setDimensions(values);
  }, []);

  return [ref, dimensions];
}

export default function Quadrant(props: QuadrantProps) {
  const {
    quadrantData,
    quadrantMeta: {
      maxY,
      minY,
      maxX,
      minX,
      topLeft,
      topRight,
      bottomLeft,
      bottomRight
    },
    axisTickTotal,
    hideAxisTicks,
    markSize,
    selectedMarkSize,
    xAxisLabel,
    yAxisLabel,
    individualData,
    isSearchEnabled,
    isFilterEnabled
  } = props;

  const [currentValue, setCurrentValue] = useState(null); // current selected value
  const [hoveredMark, setHoveredMark] = useState<QuadrantValue | undefined>();
  const [lastDrawLocation, setLastDrawLocation] = useState<any>(null);
  const [zoomInCount, setZoomInCount] = useState<number>(0);
  const [zoomOutCount, setZoomOutCount] = useState<number>(3);

  const [quadrantRef, quadrantSize] = useDimensions();
  const quadrantConfig = useMemo(() => {
    const middleX = (maxX - minX) / 2;
    const middleY = (maxY - minY) / 2;
    const gridlineStyle = { stroke: "#ffffff", fill: "#ffffff" };
    const borderStyle = { all: { fill: "#fff" } };
    const markStyle = { stroke: "none", fill: "#bab9c9" };
    const selectedMarkStyle = { stroke: "#6c5feb", fill: "#6c5feb" };
    const hiddenMarkStyle = { stroke: "none", fill: "transparent" };

    // individual mark styles
    const individualStyle = { stroke: "none", fill: "#6c5feb" };
    const selectedIndividualStyle = { stroke: "#6c5feb", fill: "none" };

    return {
      middleX,
      middleY,
      gridlineStyle,
      borderStyle,
      markStyle,
      selectedMarkStyle,
      individualStyle,
      selectedIndividualStyle,
      hiddenMarkStyle
    };
  }, [maxX, minX, maxY, minY]);

  const handleOnClickValue = (value: any) => {
    setCurrentValue(value);
    setHoveredMark(undefined);
  };

  const renderHint = () => {
    return !individualData && <UserDetailHint selectedUser={currentValue} />;
  };

  const zoomHandler = (zoomMode: string) => {
    if (zoomMode === "ZOOM_IN" && zoomInCount < 3) {
      setLastDrawLocation({
        left: lastDrawLocation
          ? lastDrawLocation.left + maxX * 0.125
          : minX + maxX * 0.125,
        right: lastDrawLocation
          ? lastDrawLocation.right - maxX * 0.125
          : maxX - maxX * 0.125,
        top: lastDrawLocation
          ? lastDrawLocation.top - maxY * 0.125
          : maxY - maxY * 0.125,
        bottom: lastDrawLocation
          ? lastDrawLocation.bottom + maxY * 0.125
          : minY + maxY * 0.125
      });
      setZoomInCount(zoomInCount + 1);
      setZoomOutCount(zoomOutCount - 1);
    } else if (zoomMode === "ZOOM_OUT" && zoomOutCount >= 0) {
      setLastDrawLocation({
        left: lastDrawLocation
          ? lastDrawLocation.left - maxX * 0.125
          : minX - maxX * 0.25,
        right: lastDrawLocation
          ? lastDrawLocation.right + maxX * 0.125
          : maxX + maxX * 0.25,
        top: lastDrawLocation
          ? lastDrawLocation.top + maxY * 0.125
          : maxY + maxY * 0.25,
        bottom: lastDrawLocation
          ? lastDrawLocation.bottom - maxY * 0.125
          : minY - maxY * 0.25
      });
      setZoomInCount(zoomInCount - 1);
      setZoomOutCount(zoomOutCount + 1);
    }
  };

  return (
    <div className={styles.wrapper}>
      {/* Quadrant axes labels */}
      <span className={styles.x}>{xAxisLabel}</span>
      <span className={styles.y}>{yAxisLabel}</span>

      <div
        className={` ${styles.quadrantContainer} ${
          isSearchEnabled && isFilterEnabled ? styles.reduceHeight : ""
        }`}
        ref={quadrantRef}
      >
        {/* Zoom container */}
        <div className={styles.zoomContainer}>
          <Button
            className="icon-button dark mt-1"
            onClick={() => zoomHandler("ZOOM_IN")}
            disabled={!(zoomInCount < 3)}
          >
            <Icon name="add_circle_outline" />
          </Button>
          <Button
            className="icon-button dark mt-1"
            onClick={() => zoomHandler("ZOOM_OUT")}
            disabled={!(lastDrawLocation && zoomInCount > 0)}
          >
            <Icon name="remove_circle_outline" />
          </Button>
        </div>

        {quadrantSize ? (
          <FlexibleXYPlot
            animation={false}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
            xDomain={
              lastDrawLocation
                ? [lastDrawLocation.left, lastDrawLocation.right]
                : [minX, maxX]
            }
            yDomain={
              lastDrawLocation
                ? [lastDrawLocation.bottom, lastDrawLocation.top]
                : [minY, maxY]
            }
          >
            {/* Quadrant grid lines */}
            <VerticalGridLines tickTotal={axisTickTotal * 10} />
            <HorizontalGridLines tickTotal={axisTickTotal * 5} />

            {/* Decorative axes for visualization of the quadrants (always at 0,0) */}
            <DecorativeAxis
              axisStart={{ x: 0, y: minY }}
              axisEnd={{ x: 0, y: maxY }}
              axisDomain={[minX, maxX]}
              tickSize={0}
              tickValue={(t: any) => ""}
              style={{
                line: { stroke: "#000" }
              }}
            />
            <DecorativeAxis
              axisStart={{ x: minX, y: 0 }}
              axisEnd={{ x: maxX, y: 0 }}
              axisDomain={[minY, maxY]}
              tickSize={0}
              tickValue={(t: any) => ""}
              style={{
                line: { stroke: "#000" }
              }}
            />

            {/* Highlight chart component */}
            <Highlight
              // drag={drag} // enable drag mode
              // enableX
              // enableY
              onBrushEnd={(area: any) => {
                setLastDrawLocation(area);
                setCurrentValue(null);
              }}
              onDrag={(area: any) => {
                setLastDrawLocation({
                  bottom: lastDrawLocation.bottom + (area.top - area.bottom),
                  left: lastDrawLocation.left - (area.right - area.left),
                  right: lastDrawLocation.right - (area.right - area.left),
                  top: lastDrawLocation.top + (area.top - area.bottom)
                });
                setCurrentValue(null);
              }}
              // onDragEnd={(area: any) => {
              //   setLastDrawLocation(area);
              //   setCurrentValue(null);
              // }}
            />

            {/* Hidden point to display the chart when there are no values */}
            {((individualData && individualData.length === 0) ||
              (quadrantData && quadrantData.length === 0)) && (
              <MarkSeries
                data={[{ x: 0, y: 0 }]}
                size={markSize}
                style={quadrantConfig.hiddenMarkStyle}
              />
            )}

            {/* Mark series chart */}
            {individualData && individualData.length > 0 ? ( // This displays all user points excluding the current user (for member and manager roles)
              <MarkSeries
                data={quadrantData}
                size={markSize}
                style={quadrantConfig.markStyle}
              />
            ) : (
              // This displays all user points with details (for admin and owner roles)
              <MarkSeries
                data={quadrantData}
                onValueClick={handleOnClickValue}
                size={markSize}
                style={quadrantConfig.markStyle}
                onValueMouseOver={(dataPoint: QuadrantValue) => {
                  setHoveredMark(dataPoint);
                }}
                onValueMouseOut={() => {
                  setHoveredMark(undefined);
                }}
              />
            )}
            {individualData &&
            individualData.length > 0 && ( // This displays the current user's position in the quadrant (for member and manager roles)
                <MarkSeries
                  data={individualData}
                  size={markSize}
                  style={quadrantConfig.individualStyle}
                />
              )}
            {individualData && individualData.length > 0 && (
              <MarkSeries
                data={individualData}
                size={selectedMarkSize}
                style={quadrantConfig.selectedIndividualStyle}
              />
            )}
            {currentValue ? ( // Displaying selected point in the quadrant (for admin and owner roles)
              <MarkSeries
                data={[currentValue]}
                size={selectedMarkSize}
                style={quadrantConfig.selectedMarkStyle}
              />
            ) : null}

            {/* Quadrant border */}
            <Borders style={quadrantConfig.borderStyle} />

            {/* Quadrant axes */}
            <XAxis
              tickTotal={axisTickTotal}
              hideTicks={hideAxisTicks}
              style={{ line: { stroke: "none" } }}
            />
            <YAxis
              tickTotal={axisTickTotal}
              hideTicks={hideAxisTicks}
              style={{ line: { stroke: "none" } }}
            />

            {/* Hovered mark hints */}
            {hoveredMark ? (
              <Hint value={hoveredMark} style={{ padding: "3px" }}>
                <div className="rv-hint__content">
                  {hoveredMark.user
                    ? `${hoveredMark.user.firstName} ${hoveredMark.user.lastName}`
                    : ""}
                </div>
              </Hint>
            ) : null}

            {/* Hints for chart values */}
            {currentValue ? (
              <Hint value={currentValue} style={{ pointerEvents: "auto" }}>
                {renderHint()}
              </Hint>
            ) : null}

            {/* Hint for individual value */}
            {individualData && individualData.length > 0 ? (
              <Hint
                value={individualData[0]}
                align={{ vertical: "top", horizontal: "left" }}
              >
                <div className="rv-hint__content">You are here</div>
              </Hint>
            ) : null}

            {/* Static hints for quadrant population */}
            <Hint
              value={{
                x: minX + quadrantConfig.middleX / (axisTickTotal * 5),
                y: minY + quadrantConfig.middleY / (axisTickTotal * 1.9)
              }}
              horizontalAlign={Hint.ALIGN.LEFT}
              verticalAlign={Hint.ALIGN.BOTTOM}
            >
              <div className={styles.valueLabel}>
                <span>{bottomLeft}%</span>
              </div>
            </Hint>
            <Hint
              value={{
                x: minX + quadrantConfig.middleX / (axisTickTotal * 5),
                y: maxY - quadrantConfig.middleY / (axisTickTotal * 2)
              }}
              horizontalAlign={Hint.ALIGN.LEFT}
              verticalAlign={Hint.ALIGN.TOP}
            >
              <div className={styles.valueLabel}>
                <span>{topLeft}%</span>
              </div>
            </Hint>
            <Hint
              value={{
                x: maxX - quadrantConfig.middleX / (axisTickTotal * 5),
                y: minY + quadrantConfig.middleY / (axisTickTotal * 1.9)
              }}
              horizontalAlign={Hint.ALIGN.RIGHT}
              verticalAlign={Hint.ALIGN.BOTTOM}
            >
              <div className={styles.valueLabel}>
                <span>{bottomRight}%</span>
              </div>
            </Hint>
            <Hint
              value={{
                x: maxX - quadrantConfig.middleX / (axisTickTotal * 5),
                y: maxY - quadrantConfig.middleY / (axisTickTotal * 2)
              }}
              horizontalAlign={Hint.ALIGN.RIGHT}
              verticalAlign={Hint.ALIGN.TOP}
            >
              <div className={styles.valueLabel}>
                <span>{topRight}%</span>
              </div>
            </Hint>
          </FlexibleXYPlot>
        ) : null}
      </div>
    </div>
  );
}

Quadrant.defaultProps = {
  quadrantData: [],
  quadrantMeta: {
    minX: 0,
    maxX: 10,
    minY: 0,
    maxY: 10
  },
  axisTickTotal: 10,
  hideAxisTicks: true,
  markSize: 6,
  selectedMarkSize: 8,
  drag: true
};
