import React, { useState, useEffect, useContext, useRef } from "react";
import { Alert, Col, Row } from "reactstrap";
import {
  DistributionGraph,
  Loader,
  SelectFilter,
  NoDataLabel
} from "ui/common";
import CategoryList from "./CategoryList";
import { getDistributionGraphData } from "services/graphs";
import { getTiersList } from "services/tiers";
import { getJobRolesList } from "services/jobRoles";
import {
  TierData,
  DesignationData,
  TierDesignationData,
  GraphMeta,
  Nullable,
  DistributionCoordinates,
  DistributionList,
  GraphBinValue
} from "ui/account/types";
import UserContext from "util/context/UserContext";
import {
  DEFAULT_ORDER_BY,
  DEFAULT_ORDER_DIRECTION
} from "ui/common/Table/constants";
import { apiResponseStatuses } from "../../constants";
import styles from "./DistributionCurve.module.scss";
import { getLocalized } from "util/localizationUtil";

const CategoryType = {
  BOTTOM: "BOTTOM",
  TOP: "TOP"
};

type DesignationMap = { [id: string]: Array<TierDesignationData> };

export default function DistributionCurve() {
  const { accountId } = useContext(UserContext);

  const [distributionData, setDistributionData] = useState<
    DistributionCoordinates
  >();
  const [distributionList, setDistributionList] = useState<DistributionList>();
  const [distributionMeta, setDistributionMeta] = useState<Nullable<GraphMeta>>(
    null
  );
  const [isDistributionDataFetched, setIsDistributionDataFetched] = useState(
    false
  );
  const [error, setError] = useState<string>(""); // error messages

  const [categorySelection, setCategorySelection] = useState<Nullable<string>>(
    null
  ); // either bottom or top 5%

  const [tiers, setTiers] = useState<Array<TierData>>([]);
  const [tierFilters, setTierFilters] = useState<Array<TierData>>([]);
  const [jobRoles, setJobRoles] = useState<Array<DesignationData>>([]);
  const [jobRoleFilters, setJobRoleFilters] = useState<
    Array<TierDesignationData | DesignationData>
  >([]);
  const [mappedJobRoles, setMappedJobRoles] = useState<DesignationMap>({});

  const tierFilterRef = useRef();
  let jobFilterRef: any = null;

  const mapDesignationsToTiers = (tiersArr: Array<TierData>) => {
    const mappedDesignations = tiersArr.reduce(
      (acc: DesignationMap, tier: TierData) => ({
        ...acc,
        [tier.id]: [...tier.designations]
      }),
      {}
    );
    setMappedJobRoles(mappedDesignations);
  };

  const mapGraphBinValuesToCoordinates = (
    graphBinArr: Array<GraphBinValue>
  ) => {
    const mappedGraphBinValues = graphBinArr.map((value: GraphBinValue) => ({
      // using the mid point of the "bar" and the frequency (height) as x and y
      x: value.mid,
      y: value.frequency
    }));
    return mappedGraphBinValues;
  };

  const mapGraphBinValuesToBars = (graphBinArr: Array<GraphBinValue>) => {
    const mappedGraphBinValues = graphBinArr.map((value: GraphBinValue) => ({
      // using the "from" and "to" points of the "bar" as x0 and x respectively and the frequency (height) as y
      x0: value.from,
      x: value.to,
      y: value.frequency
    }));
    return mappedGraphBinValues;
  };

  useEffect(() => {
    const queryParams = {
      sort: `${DEFAULT_ORDER_BY}:${DEFAULT_ORDER_DIRECTION}`
    };
    const fetchTiers = async () => {
      const { status, data } = await getTiersList(accountId, queryParams);
      if (status === apiResponseStatuses.success) {
        setTiers(data);
        mapDesignationsToTiers(data);
      }
    };

    const fetchJobRoles = async () => {
      const { status, data } = await getJobRolesList(accountId, queryParams);
      if (status === apiResponseStatuses.success) {
        setJobRoles(data);
      }
    };

    fetchTiers();
    fetchJobRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const queryParams = {
      ...(tierFilters.length > 0 && { tierId: tierFilters.map(e => e.id) }),
      ...(jobRoleFilters.length > 0 && {
        designationIds: jobRoleFilters.map(e => e.id)
      })
    };
    const fetchDistributionData = async () => {
      setError("");
      setIsDistributionDataFetched(false);
      const { status, data, message } = await getDistributionGraphData(
        accountId,
        queryParams
      );
      if (status === apiResponseStatuses.success) {
        setDistributionData(data.coordinates);
        setDistributionList(data.data);
        setDistributionMeta(data.meta);
      } else {
        setError(message);
      }
      setIsDistributionDataFetched(true);
    };

    fetchDistributionData();
  }, [accountId, tierFilters, jobRoleFilters]);

  // Either return all job roles or job roles specific to a tier
  const getJobRoleFilterList = () => {
    let filteredJobRoles: Array<TierDesignationData> = [];
    if (tierFilters && tierFilters.length > 0) {
      for (let tier of tierFilters) {
        const filteredDesignations: Array<TierDesignationData> =
          tier && mappedJobRoles[tier.id];
        filteredJobRoles.push(...filteredDesignations);
      }
      return filteredJobRoles;
    } else {
      return jobRoles;
    }
  };

  // Set tier filters
  const handleMultiTierChange = (option: any) => {
    (jobFilterRef as any).state.value = [];
    setJobRoleFilters([]);
    setTierFilters(option || []);
    setCategorySelection(null);
  };

  // Set job role filters
  const handleMultiJobChange = (option: any) => {
    setJobRoleFilters(option || []);
    setCategorySelection(null);
  };

  const setJobFilterRef = (ref: any) => {
    jobFilterRef = ref;
  };

  return (
    <>
      <Row>
        <Col sm="6" className="mb-3">
          <SelectFilter
            options={tiers}
            handleMultiChange={handleMultiTierChange}
            getOptionValue={(option: TierData) => option.title}
            getOptionLabel={(option: TierData) => option.title}
            clearable
            setRef={tierFilterRef}
            label={getLocalized("filter.by_tiers")}
            prefix="sp-select"
          />
        </Col>
        <Col sm="6" className="mb-3">
          <SelectFilter
            options={getJobRoleFilterList()}
            handleMultiChange={handleMultiJobChange}
            getOptionValue={(option: TierData) => option.title}
            getOptionLabel={(option: TierData) => option.description}
            clearable
            setRef={setJobFilterRef}
            label={getLocalized("filter.by_job_roles")}
            prefix="sp-select"
          />
        </Col>
      </Row>
      <div className={styles.contentWrapper}>
        <div>
          <Alert color="danger" isOpen={!!error}>
            {error}
          </Alert>
        </div>
        <div>
          {isDistributionDataFetched ? (
            distributionData && // Check if coordinates are available for all employees
            distributionData.allEmployees.length > 0 &&
            distributionMeta ? (
              <DistributionGraph
                distributionData={mapGraphBinValuesToCoordinates(
                  distributionData.allEmployees
                )}
                topFiveData={mapGraphBinValuesToCoordinates(
                  distributionData.topFive
                )}
                bottomFiveData={mapGraphBinValuesToCoordinates(
                  distributionData.bottomFive
                )}
                distributionBarData={mapGraphBinValuesToBars(
                  distributionData.allEmployees
                )}
                distributionMeta={distributionMeta}
                xAxisLabel={getLocalized("score.overall")}
                yAxisLabel={getLocalized("employee.plural")}
                toggleCategoryList={(categorySelection: string) =>
                  setCategorySelection(categorySelection)
                }
                hintLabel={getLocalized("common.hint_label")}
              />
            ) : (
              <NoDataLabel />
            )
          ) : (
            <Loader />
          )}
        </div>
        {/* display either bottom or top five list */}
        {distributionList && categorySelection ? (
          <div className={styles.tableBelow}>
            <CategoryList
              categoryList={
                categorySelection === CategoryType.BOTTOM
                  ? distributionList.bottomFive
                  : distributionList.topFive
              }
            />
          </div>
        ) : null}
      </div>
    </>
  );
}
