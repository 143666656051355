import React, { ReactNode, useContext, Fragment } from "react";
import UserContext, { getDefaultMember } from "util/context/UserContext";
import { AccessRole } from "../../types";

type OnlySubordinatesProps = {
  children?: ReactNode;
  memberId?: string;
  skipCheck?: boolean;
};

OnlySubordinates.defaultProps = {
  skipCheck: false
};
export default function OnlySubordinates(props: OnlySubordinatesProps) {
  const { memberId, children, skipCheck } = props;
  const { user } = useContext(UserContext);

  const currentUserDefaultMembership = getDefaultMember(user);
  const isManager = currentUserDefaultMembership
    ? currentUserDefaultMembership.accessRole === AccessRole.Manager
    : false;

  const isManagedByMe =
    isManager &&
    currentUserDefaultMembership &&
    memberId &&
    currentUserDefaultMembership.immediateSubordinates.findIndex(
      subordinate => subordinate.id === memberId
    ) >= 0;

  const renderContent = () => {
    if (skipCheck) {
      return children;
    } else if (isManager) {
      if (isManagedByMe) {
        return children;
      } else {
        return <Fragment></Fragment>;
      }
    } else {
      return children;
    }
  };

  return <Fragment>{renderContent()}</Fragment>;
}
