import React, { useState, useContext, useEffect } from "react";
import * as Yup from "yup";
import { Formik, FormikActions } from "formik";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  ModalFooter,
  Col,
  Row
} from "reactstrap";
import { Form } from "formik";
import { Field } from "formik";
import Button from "ui/common/Button";
import InputCustom from "ui/common/InputCustom";
import UserContext from "util/context/UserContext";
import { apiResponseStatuses } from "api/core";
import { createUser, updateUser } from "services/users";
import {
  ActionModalProps,
  ModalActionTypes,
  MemberData
} from "ui/common/types";
import { ModalItem } from "ui/account/types";
import { modalActionTypes } from "ui/common/constants";
import { entityStatus, UserGroups } from "ui/account/constants";
import Toast from "ui/common/Toast";
import { AccessRole } from "ui/common/types";
import { getLocalized } from "util/localizationUtil";

type AdminFormValues = {
  userType: string;
  employeeId: string;
  firstName: string;
  lastName: string;
  email: string;
};

export default function AdminModal(props: ActionModalProps) {
  const usersValidations = Yup.object({
    firstName: Yup.string().required(getLocalized("required.first_name")),
    lastName: Yup.string().required(getLocalized("required.last_name")),
    email: Yup.string()
      .email(getLocalized("email.invalid"))
      .required(getLocalized("required.email"))
  });

  const getModalTitle = (actionType: ModalActionTypes) => {
    return actionType === modalActionTypes.EDIT
      ? getLocalized("admin.edit")
      : getLocalized("admin.add");
  };

  const getModalActionTitle = (actionType: ModalActionTypes) => {
    return actionType === modalActionTypes.EDIT
      ? getLocalized("action.save")
      : getLocalized("action.add");
  };

  const { isOpen, onToggle, actionType } = props;
  const { accountId } = useContext(UserContext);
  const [actionTypePersist, setActionTypePersist] = useState<ModalActionTypes>(
    actionType
  );

  useEffect(() => {
    isOpen && setActionTypePersist(actionType);
  }, [isOpen, actionType]);

  const getInitialValues = () => {
    const item = props.item as ModalItem<MemberData>;
    if (item) {
      return {
        userType: AccessRole.Admin,
        employeeId: item.employeeId,
        firstName: item.user.firstName,
        lastName: item.user.lastName,
        email: item.user.email
      };
    }
    return {
      userType: AccessRole.Admin,
      employeeId: "",
      firstName: "",
      lastName: "",
      email: ""
    };
  };

  const handleToggle = () => onToggle(actionTypePersist);

  const handleExit = () => {
    setActionTypePersist(props.actionType);
  };

  const handleSubmit = async (
    values: AdminFormValues,
    { setSubmitting, setStatus }: FormikActions<AdminFormValues>
  ) => {
    setStatus(null);
    const item = props.item as ModalItem<MemberData>;
    const updateRequestBody = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      accessRole: values.userType,
      group: UserGroups.DEFAULT
    };
    const createUserBody = {
      employeeId: values.employeeId,
      user: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        status: entityStatus.pending,
        group: UserGroups.DEFAULT
      },
      default: true,
      accessRole: values.userType
    };
    const { data, status } =
      actionType === modalActionTypes.EDIT && item
        ? await updateUser(accountId, item.id, updateRequestBody)
        : await createUser(accountId, createUserBody);
    if (status === apiResponseStatuses.success) {
      actionType === modalActionTypes.EDIT
        ? Toast.success(getLocalized("ADMIN_UPDATE_SUCCESS"))
        : Toast.success(getLocalized("admin.add_success"));
      onToggle(actionTypePersist, null, true);
      setStatus(null);
    } else {
      setStatus(data.message || getLocalized("common.something_went_wrong"));
    }
    setSubmitting(false);
  };

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} onExit={handleExit}>
      <ModalHeader toggle={handleToggle}>
        {getModalTitle(actionTypePersist)}
      </ModalHeader>
      <Formik
        onSubmit={handleSubmit}
        initialValues={getInitialValues()}
        enableReinitialize
        validationSchema={usersValidations}
        render={({ status, isSubmitting }) => {
          return (
            <Form noValidate>
              <ModalBody>
                <div>
                  <Alert color="danger" isOpen={!!status}>
                    {status}
                  </Alert>
                </div>
                <Row>
                  <Col>
                    <Field
                      required
                      type="text"
                      label={getLocalized("employee.id")}
                      name="employeeId"
                      id="employeeId"
                      component={InputCustom}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      required
                      type="text"
                      label={getLocalized("common.first_name")}
                      name="firstName"
                      id="firstName"
                      component={InputCustom}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      required
                      type="text"
                      label={getLocalized("common.last_name")}
                      name="lastName"
                      id="lastName"
                      component={InputCustom}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      required
                      type="email"
                      label={getLocalized("email")}
                      name="email"
                      id="email"
                      component={InputCustom}
                    />
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  className="default line"
                  type="button"
                  onClick={handleToggle}
                >
                  {getLocalized("action.discard")}
                </Button>
                <Button
                  type="submit"
                  className="primary"
                  loading={isSubmitting}
                >
                  {getModalActionTitle(actionTypePersist)}
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      />
    </Modal>
  );
}
