import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import ReportsList from "./ReportsList";
import DistributionCurve from "./DistributionCurve";
import { Button } from "ui/common";
import styles from "./Reports.module.scss";
import { getLocalized } from "util/localizationUtil";

const reportViewMode = Object.freeze({
  listView: "LIST_VIEW",
  distributionView: "DISTRIBUTION_VIEW"
});

export default function Skills() {
  const [activeTab, setActiveTab] = useState<string>(reportViewMode.listView);

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Row>
      <Col xs="12">
        <ul className={styles.navTabs}>
          <li className={styles.navItem}>
            <Button
              className={`
                ${styles.navLink} 
                ${activeTab === reportViewMode.listView ? styles.active : ""}
              `}
              onClick={() => toggle(reportViewMode.listView)}
            >
              {getLocalized("common.list_view")}
            </Button>
          </li>
          <li className={styles.navItem}>
            <Button
              className={`
                ${styles.navLink} 
                ${
                  activeTab === reportViewMode.distributionView
                    ? styles.active
                    : ""
                }
              `}
              onClick={() => toggle(reportViewMode.distributionView)}
            >
              {getLocalized("common.distribution_view")}
            </Button>
          </li>
        </ul>
      </Col>
      <Col xs="12">
        <div className={styles.tabContent}>
          {activeTab === reportViewMode.listView ? (
            <ReportsList />
          ) : (
            <DistributionCurve />
          )}
        </div>
      </Col>
    </Row>
  );
}
