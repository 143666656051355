import React from "react";
import { Col, Row } from "reactstrap";
import { Skill } from "ui/account/Skills/types";
import ScoreGuide from "../ScoreGuide";
import Tagline from "../Tagline";
import { AccessControl } from "ui/common";
import { Subjects, AccessControlActions } from "ui/common/types";

export type SkillsContentProps = {
  item: Skill;
};

export default function SkillsContent(props: SkillsContentProps) {
  const { item } = props;

  // Tagline and Scoreline guide components here
  return (
    <Col xs="12">
      <Row>
        <Col xs="12" className="mb-3">
          <AccessControl.Can
            resource={Subjects.ManageSkillsMainSkillDescription}
            action={AccessControlActions.ViewAny}
          >
            <Tagline item={item} />
          </AccessControl.Can>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <AccessControl.Can
            resource={Subjects.ManageSkillsScoreGuide}
            action={AccessControlActions.ViewAny}
          >
            <ScoreGuide item={item} />
          </AccessControl.Can>
        </Col>
      </Row>
    </Col>
  );
}
