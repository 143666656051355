import React, { useState, useEffect, useMemo, useContext } from "react";
import { Row, Col, Alert } from "reactstrap";
import { getIn } from "formik";
import { useLocation } from "react-router-dom";
import {
  Table,
  SearchBar,
  ButtonActionModalHolder,
  AccessControl,
  Toast
} from "ui/common";
import {
  getUsersList,
  getFilteredUsersList,
  deleteUser,
  getMyTeamUsersList
} from "services/users";
import { MemberData, Nullable, RatingScale, TableData } from "ui/account/types";
import {
  DEFAULT_ORDER_BY,
  DEFAULT_ORDER_DIRECTION,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_RESULTS_LIMIT
} from "./constants";
import { apiResponseStatuses, tableEntityTypes } from "../../constants";
import UsersModal from "./../UsersModal/index";
import UserFilters from "./UserFilters";
import UserContext from "util/context/UserContext";
import history from "util/history";
import { AccessControlActions, Subjects } from "ui/common/types";
import useCan from "util/hooks/useCan";
import { slugify } from "util/helpers";
import { getLocalized } from "util/localizationUtil";
import styles from "./UserFilters.module.scss";
import Button from "../../../common/Button/index";

type UserFilter = {
  tierId?: string | null;
  designationId?: string | null;
  skillId?: string | null;
  subSkills?: Array<{
    subSkillId: string;
    scoreIndex: Array<number>;
  }>;
};

interface Props {
  hideFilters?: boolean;
  showOnlyMyTeam?: boolean;
}

export default function UsersList({ hideFilters, showOnlyMyTeam }: Props) {
  const { accountId, account } = useContext(UserContext);
  const location = useLocation();
  const defaultPage = `${DEFAULT_PAGE_NUMBER - 1}:${DEFAULT_RESULTS_LIMIT}`;
  const [isFetching, setIsFetching] = useState(false);
  const [usersList, setUsersList] = useState(Array<MemberData>());
  const [count, setCount] = useState();
  const [error, setError] = useState<string>(""); // error messages
  const [forceRefresh, setForceRefresh] = useState(false);

  const [searchTerm, setSearchTermParam] = useState<Nullable<string>>(null); // search term
  const [sort, setSortParam] = useState(``); // sort params
  const [page, setPageParam] = useState(defaultPage); // page params
  const [userFilter, setUserFilter] = useState<Nullable<UserFilter>>(null); // filters
  const [isFilterSuccess, setFilterSuccess] = useState(false);

  const canUseFilters = useCan({
    resource: Subjects.ManageUsersMember,
    action: AccessControlActions.Filter
  });

  const ratingScale: RatingScale = getIn(account, `preferences.ratingScale`);

  const getList = (accountId: any, queryParams: any) => {
    if (showOnlyMyTeam) return getMyTeamUsersList(accountId, queryParams);
    else return getUsersList(accountId, queryParams);
  };

  useEffect(() => {
    // fetch users list on component mount and during search
    const queryParams = {
      ...(searchTerm &&
        searchTerm !== "" && { q: encodeURIComponent(searchTerm) }),
      sort: encodeURIComponent(sort),
      page: encodeURIComponent(page)
    };
    const fetchData = async () => {
      setIsFetching(true);
      setError("");
      const { status, data } = await getList(accountId, queryParams);
      if (status === apiResponseStatuses.success) {
        setUsersList(data.results);
        setCount(data.total);
      } else {
        setError(data.message);
      }
      setIsFetching(false);
      setForceRefresh(false); // set to false after fetch
    };
    !userFilter && fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceRefresh, searchTerm, sort, page, accountId, userFilter]);

  useEffect(() => {
    const splitPageParam = page.split(":");
    // fetch users list on applying filters
    const requestBody = {
      ...userFilter,
      meta: {
        sort:
          sort !== "" ? sort : `${DEFAULT_ORDER_BY}:${DEFAULT_ORDER_DIRECTION}`,
        pageNumber: parseInt(splitPageParam[0]),
        size: parseInt(splitPageParam[1])
      }
    };
    const fetchData = async () => {
      setIsFetching(true);
      setFilterSuccess(false);
      setError("");
      const { status, data } = await getFilteredUsersList(
        accountId,
        requestBody
      );
      if (status === apiResponseStatuses.success) {
        setUsersList(data.results);
        setCount(data.total);
        setFilterSuccess(true);
      } else {
        setError(data.message);
      }
      setIsFetching(false);
      setForceRefresh(false); // set to false after fetch
    };
    userFilter && fetchData();
  }, [forceRefresh, userFilter, accountId, sort, page]);

  const handleReload = (forceRefresh: boolean) => {
    setForceRefresh(forceRefresh);
  };

  // Table headers
  const headers = useMemo(
    () => [
      {
        name: "Employee Id",
        selector: "employeeId",
        sortable: true,
        cell: (row: MemberData) => (row.employeeId ? row.employeeId : "N/A"),
        grow: 1
      },
      {
        name: "Name",
        selector: "user",
        sortable: true,
        cell: (row: MemberData) => formatUserObject(row),
        grow: 1
      },
      {
        name: "Designation",
        selector: "designation",
        sortable: false,
        cell: (row: MemberData) =>
          row.designation ? row.designation.description : "N/A",
        grow: 1
      },
      {
        name: "Tier",
        selector: "tier",
        sortable: false,
        cell: (row: MemberData) =>
          row.designation && row.designation.tier
            ? row.designation.tier.title
            : "N/A",
        grow: 1
      },
      {
        name: "Line Manager",
        selector: "lineManager",
        sortable: true,
        format: (row: MemberData) =>
          row.manager && row.manager.user
            ? `${row.manager.user.firstName} ${row.manager.user.lastName}`
            : "N/A",
        grow: 1
      }
    ],
    []
  );

  const filterHeaders = useMemo(
    () => [
      ...headers,
      ...[
        {
          name: "Skill",
          selector: "skills",
          sortable: false,
          format: (row: MemberData) =>
            row.skills ? formatSkillsObject(row) : null,
          grow: 2
        }
      ]
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [headers]
  );

  const formatUserObject = (row: MemberData) => {
    if (row.hasOwnProperty("user") && row.user) {
      return `${row.user.firstName} ${row.user.lastName}`;
    }
  };

  const formatSkillsObject = (row: MemberData) => {
    let subSkillScores: string[] = [];
    if (row.skills) {
      for (let skill of row.skills) {
        if (skill.subSkills && skill.subSkills.length > 0) {
          skill.subSkills.forEach((subSkill: any) => {
            const rating = ratingScale[parseInt(subSkill.scoreIndex)];
            if (rating) {
              subSkillScores.push(
                `${subSkill.subSkill.name} : ${rating.ratingLetter}`
              );
            }
          });
        } else {
          return "N/A";
        }
      }
      return subSkillScores.join(", ");
    } else {
      return "Skills";
    }
  };

  const getSearchTerm = (searchTerm: string) => {
    const inputValue = searchTerm.trim().toLowerCase();
    setSearchTermParam(inputValue);
    setPageParam(defaultPage);
    setUserFilter(null);
    isFilterSuccess && setFilterSuccess(false);
  };

  const getSortParam = (sortParam: string) => {
    setSortParam(sortParam);
  };

  const getPaginationParam = (pageParam: string) => {
    setPageParam(pageParam);
  };

  const getFilters = (userFilter: UserFilter) => {
    setPageParam(defaultPage);
    setUserFilter(userFilter);
  };

  const handleDelete = (memberId: string) => {
    const fetchData = async () => {
      const { status, message } = await deleteUser(accountId, memberId);
      if (status === apiResponseStatuses.success) {
        Toast.success(getLocalized("user.delete_success"));
      } else {
        setError(message);
        Toast.error(getLocalized("user.delete_failed"));
      }
      setForceRefresh(true);
    };
    fetchData();
  };

  const handleRowClicked = (data: TableData) => {
    const memberData = data as MemberData;
    history.push(
      `${location.pathname}/profile/${slugify(memberData.accessRole)}/${
        memberData.id
      }`
    );
  };

  return (
    <Row>
      <Col sm={{ size: 9, order: 1 }} xs={{ size: 12, order: 2 }}>
        <AccessControl.Can
          resource={Subjects.ManageUsersMember}
          action={AccessControlActions.Search}
        >
          <SearchBar
            searchOnServer
            displaySearchFilters={!hideFilters && canUseFilters}
            suggestionEndpoint={getList}
            getSearchTerm={getSearchTerm}
          >
            {!hideFilters && (
              <UserFilters
                getFilters={getFilters}
                resetFilters={() => {
                  setUserFilter(null);
                  isFilterSuccess && setFilterSuccess(false);
                }}
              />
            )}
          </SearchBar>
        </AccessControl.Can>
      </Col>

      <Col
        sm={{ size: 3, order: 2 }}
        xs={{ size: 12, order: 1 }}
        className="text-right mb-3"
      >
        <AccessControl.Can
          resource={Subjects.ManageUsersMember}
          action={AccessControlActions.AddAny}
        >
          <ButtonActionModalHolder
            label={getLocalized("user.add_new")}
            actionModal={UsersModal}
            onReload={handleReload}
          />
        </AccessControl.Can>
      </Col>
      <Col xs={{ size: 12, order: 4 }}>
        <Alert color="danger" isOpen={!!error}>
          {error}
        </Alert>
      </Col>
      <Col xs={{ size: 12, order: 5 }}>
        {!isFetching &&
          (searchTerm || userFilter ? (
            <div className={styles.resultText}>
              <>
                <p>
                  <span>{count}</span> {getLocalized("common.results")}
                  {userFilter && (
                    <Button
                      className="text-button secondary-text"
                      onClick={() => {
                        setUserFilter(null);
                        setSearchTermParam(null);
                        isFilterSuccess && setFilterSuccess(false);
                      }}
                    >
                      {getLocalized("filter.clear")}
                    </Button>
                  )}
                </p>
              </>
            </div>
          ) : (
            <div className={styles.resultText}>
              <p>
                <span>{count}</span> {getLocalized("user.users")}
              </p>
            </div>
          ))}
      </Col>
      <Col xs={{ size: 12, order: 6 }}>
        <Table
          headers={isFilterSuccess ? filterHeaders : headers}
          tableData={usersList}
          dataCount={count}
          entityType={tableEntityTypes.USERS}
          onReload={handleReload}
          sortInServer
          getSortParam={getSortParam}
          actionModal={UsersModal}
          paginationInServer
          getPaginationParam={getPaginationParam}
          deleteAction={handleDelete}
          onRowClicked={handleRowClicked}
          noDataText={getLocalized("user.no_result_now")}
          checkPermissions={true}
          resource={Subjects.ManageUsersMember}
          highlightOnHover
          pointerOnHover
          isFetching={isFetching}
        />
      </Col>
    </Row>
  );
}
