/* Common helper functions */

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}

export function debouncePromise(inner: Function, ms: number = 0) {
  let timer: NodeJS.Timeout | null = null;
  let resolves: Array<any> = [];

  return function(...args: any[]) {
    // Run the function after a certain amount of time
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      // Get the result of the inner function, then apply it to the resolve function of
      // each promise that has been created since the last time the inner function was run
      let result = inner(...args);
      resolves.forEach((r: any) => r(result));
      resolves = [];
    }, ms);

    return new Promise(result => resolves.push(result));
  };
}

export const getDateString = (date: Date) => {
  /* return dd Mon YYYY date from Date object */
  const stringDate = date.toDateString();
  const dateParts = stringDate.split(" ");
  return `${dateParts[2]} ${dateParts[1]} ${dateParts[3]}`;
};

export const slugify = (text: string | undefined) =>
  text
    ? text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(/[^\w-]+/g, "") // Remove all non-word chars
        .replace(/--+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, "")
    : ""; // Trim - from end of text
