import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import { Row, Col, Alert } from "reactstrap";
import { Table, SelectFilter } from "ui/common";
import {
  ReportData,
  TierData,
  DesignationData,
  TierDesignationData
} from "../../types";
import { tableEntityTypes, apiResponseStatuses } from "../../constants";
import { OptionType } from "ui/common/SelectFilter/types";
import { ValueType } from "react-select/src/types";
import UserContext from "util/context/UserContext";
import { getTiersList } from "services/tiers";
import { getReportsList } from "services/reports";
import { getJobRolesList } from "services/jobRoles";
import {
  DEFAULT_REPORT_ORDER_BY,
  DEFAULT_REPORT_ORDER_DIRECTION,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_RESULTS_LIMIT
} from "../ReportsList/constants";
import {
  DEFAULT_ORDER_BY,
  DEFAULT_ORDER_DIRECTION
} from "ui/common/Table/constants";
import { getLocalized } from "util/localizationUtil";

type DesignationMap = { [id: string]: Array<TierDesignationData> };

type TierFilter = TierDesignationData | DesignationData;

export default function Reports() {
  const { accountId } = useContext(UserContext);
  const defaultSort = `${DEFAULT_REPORT_ORDER_BY}:${DEFAULT_REPORT_ORDER_DIRECTION}`;
  const defaultPage = `${DEFAULT_PAGE_NUMBER - 1}:${DEFAULT_RESULTS_LIMIT}`;

  const [searchResults, setSearchResults] = useState<Array<ReportData>>([]);
  const [count, setCount] = useState();
  const [tiers, setTiers] = useState<Array<TierData>>([]);
  const [selectedTier, setSelectedTier] = useState<Array<TierData>>([]);
  const [jobRoles, setJobRoles] = useState<Array<TierFilter>>([]);
  const [allJobRoles, setAllJobRoles] = useState<Array<DesignationData>>([]);
  const [mappedJobRoles, setMappedJobRoles] = useState<DesignationMap>({});
  const [forceRefresh, setForceRefresh] = useState(false);
  const [error, setError] = useState<string>("");
  const [sort, setSortParam] = useState(defaultSort);
  const [page, setPageParam] = useState(defaultPage);
  const [isFetching, setIsFetching] = useState(false);
  let jobFilterRef: any = null;
  const tierFilterRef = useRef();

  useEffect(() => {
    const abortController = new AbortController();
    const queryParams = {
      sort: `${DEFAULT_ORDER_BY}:${DEFAULT_ORDER_DIRECTION}`
    };
    const fetchTiers = async () => {
      const { status, data } = await getTiersList(accountId, queryParams);
      if (status === apiResponseStatuses.success) {
        setTiers(data);
        mapDesignationsToTiers(data);
      }
    };

    const fetchJobRoles = async () => {
      const { status, data } = await getJobRolesList(accountId, queryParams);
      if (status === apiResponseStatuses.success) {
        setAllJobRoles(data);
      }
    };

    fetchTiers();
    fetchJobRoles();
    abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const queryParams = {
      page: encodeURIComponent(page),
      sort: encodeURIComponent(sort),
      ...(selectedTier.length > 0 && { tierId: selectedTier.map(e => e.id) }),
      ...(jobRoles.length > 0 && { designationIds: jobRoles.map(e => e.id) })
    };

    const fetchData = async () => {
      setIsFetching(true);
      const { status, data } = await getReportsList(accountId, queryParams);
      if (status === apiResponseStatuses.success) {
        setSearchResults(data.results);
        setCount(data.total);
      } else {
        setError(data.message);
      }
      setIsFetching(false);
      setForceRefresh(false);
    };

    fetchData();
  }, [selectedTier, jobRoles, sort, page, accountId, forceRefresh]);

  // Table headers
  const headers = useMemo(
    () => [
      {
        name: "Employee Id",
        selector: "employeeId",
        sortable: true,
        format: (row: ReportData) =>
          row.member ? row.member.employeeId : "N/A"
      },
      {
        name: "Name",
        selector: "name",
        sortable: true,
        format: (row: ReportData) => {
          if (row.user) {
            return `${row.user.firstName} ${row.user.lastName}`;
          }
        }
      },
      {
        name: "Designation",
        selector: "designation",
        sortable: false,
        format: (row: ReportData) => {
          if (row.designation) {
            return row.designation.description;
          }
        }
      },
      {
        name: "Tier",
        selector: "designation",
        sortable: false,
        center: true,
        format: (row: ReportData) => {
          if (row.designation && row.designation.tier) {
            return row.designation.tier.title;
          }
        }
      },
      {
        name: "Overall Score",
        selector: "overallScore",
        sortable: true,
        right: true,
        width: 200,
        format: (row: ReportData) => row.overallScore
      },
      {
        name: "People Skills Contribution",
        selector: "peopleSkillsContribution",
        sortable: true,
        right: true,
        format: (row: ReportData) => row.peopleSkillsContribution
      },
      {
        name: "Specialist Skills Contribution",
        selector: "specialistSkillsContribution",
        sortable: true,
        right: true,
        format: (row: ReportData) => row.specialistSkillsContribution
      }
    ],
    []
  );
  const handleReload = (forceRefresh: boolean) => {
    setForceRefresh(forceRefresh);
  };

  const setJobRoleMap = (option: TierData[]) => {
    jobFilterRef.state.value = [];
    setJobRoles([]);
    setSelectedTier(option || []);
    handleMultiChange(option);
  };

  const handleMultiChange = (option: ValueType<OptionType>) => {
    setForceRefresh(true);
  };
  const handleMultiJobChange = (option: any) => {
    setJobRoles(option || []);
  };
  const mapDesignationsToTiers = (tiersArr: Array<TierData>) => {
    const mappedDesignations = tiersArr.reduce(
      (acc: DesignationMap, tier: TierData) => ({
        ...acc,
        [tier.id]: [...tier.designations]
      }),
      {}
    );
    setMappedJobRoles(mappedDesignations);
  };

  const getJobRoles = () => {
    let localJobRoles: Array<TierFilter> = [];
    if (selectedTier && selectedTier.length > 0) {
      for (let tier of selectedTier) {
        const filteredDesignations: Array<TierDesignationData> =
          tier && mappedJobRoles[tier.id];
        localJobRoles.push(...filteredDesignations);
      }
      return localJobRoles;
    } else {
      return allJobRoles;
    }
  };

  const setJobFilterRef = (ref: any) => {
    jobFilterRef = ref;
  };

  const getSortParam = (sortParam: string) => {
    setSortParam(sortParam);
  };

  const getPaginationParam = (pageParam: string) => {
    setPageParam(pageParam);
  };

  return (
    <Row>
      <Col sm="6" className="mb-3">
        <SelectFilter
          options={tiers}
          handleMultiChange={(option: ValueType<OptionType>) =>
            setJobRoleMap(option as TierData[])
          }
          getOptionValue={(option: TierData) => option.title}
          getOptionLabel={(option: TierData) => option.title}
          clearable
          setRef={tierFilterRef}
          label={getLocalized("filter.by_tiers")}
          prefix="sp-select"
        />
      </Col>
      <Col sm="6" className="mb-3">
        <SelectFilter
          options={getJobRoles()}
          handleMultiChange={handleMultiJobChange}
          getOptionValue={(option: TierData) => option.title}
          getOptionLabel={(option: TierData) => option.description}
          clearable
          setRef={setJobFilterRef}
          label={getLocalized("filter.by_job_roles")}
          prefix="sp-select"
        />
      </Col>
      <div>
        <Alert color="danger" isOpen={!!error}>
          {error}
        </Alert>
      </div>
      <Col xs="12">
        <Table
          headers={headers}
          tableData={searchResults}
          dataCount={count}
          entityType={tableEntityTypes.REPORTS}
          showActions={false}
          sortInServer
          getSortParam={getSortParam}
          paginationInServer
          getPaginationParam={getPaginationParam}
          onReload={handleReload}
          noDataText={getLocalized("common.no_reports_now")}
          isFetching={isFetching}
        />
      </Col>
    </Row>
  );
}
