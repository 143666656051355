import {
  getUrl,
  GET_QUADRANT_DATA_URL,
  GET_INDIVIDUAL_QUADRANT_DATA_URL,
  GET_DISTRIBUTION_DATA_URL,
  GET_SCORE_PROGRESSION_DATA_URL
} from "config/endpoints";
import { getRequest } from "api";

export const getQuadrantGraphData = async (
  accountId: string,
  queryParams?: { [param: string]: any } | null
): Promise<any> => {
  const url = getUrl(GET_QUADRANT_DATA_URL, { accountId });
  return getRequest(url, queryParams);
};

export const getIndividualQuadrantGraphData = async (
  accountId: string,
  memberId: string,
  queryParams?: { [param: string]: any } | null
): Promise<any> => {
  const url = getUrl(GET_INDIVIDUAL_QUADRANT_DATA_URL, { accountId, memberId });
  return getRequest(url, queryParams);
};

export const getDistributionGraphData = async (
  accountId: string,
  queryParams?: { [param: string]: any } | null
): Promise<any> => {
  const url = getUrl(GET_DISTRIBUTION_DATA_URL, { accountId });
  return getRequest(url, queryParams);
};

export const getScoreProgressionData = async (
  accountId: string,
  memberId: string
): Promise<any> => {
  const url = getUrl(GET_SCORE_PROGRESSION_DATA_URL, { accountId, memberId });
  return getRequest(url);
};
