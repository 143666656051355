import React, { ReactNode } from "react";
import { Route, Redirect, useLocation, RouteProps } from "react-router-dom";
import { UserConsumer } from "util/context/UserContext";
import { ProtectedRoute } from "ui/common";
import { Subjects } from "ui/common/types";

type PrivateRouteProps = {
  path: string;
  exact?: boolean;
  children?: ReactNode;
  page: Subjects;
  icon?: string;
} & RouteProps;

export default function PrivateRoute({
  children,
  page,
  ...rest
}: PrivateRouteProps) {
  const location = useLocation();

  return (
    <UserConsumer>
      {({ user }) => {
        return (
          <Route {...rest}>
            {user === null ? (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location }
                }}
              />
            ) : (
              <ProtectedRoute page={page} children={children} />
            )}
          </Route>
        );
      }}
    </UserConsumer>
  );
}
