import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from "reactstrap";
import TechnicalSkills from "./TechnicalSkills";
import PersonalSkills from "./PersonalSkills/index";
import styles from "./Skills.module.scss";
import { getLocalized } from "util/localizationUtil";

const skillType = Object.freeze({
  technical: "TECHNICAL",
  personal: "PERSONAL"
});
export default function Skills() {
  const [activeTab, setActiveTab] = useState<string>(skillType.technical);

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <Row>
      <Col xs="12">
        <Nav tabs className={styles.navTabs}>
          <NavItem className={styles.navItem}>
            <NavLink
              className={`${styles.navLink} ${
                activeTab === skillType.technical ? styles.active : ""
              }`}
              active={activeTab === skillType.technical}
              onClick={() => {
                toggle(skillType.technical);
              }}
            >
              {getLocalized("skill.technical")}
            </NavLink>
          </NavItem>
          <NavItem className={styles.navItem}>
            <NavLink
              className={`${styles.navLink} ${
                activeTab === skillType.personal ? styles.active : ""
              }`}
              active={activeTab === skillType.personal}
              onClick={() => {
                toggle(skillType.personal);
              }}
            >
              {getLocalized("skill.personal")}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className={styles.tabContent}>
          <TabPane tabId={skillType.technical}>
            <TechnicalSkills />
          </TabPane>
          <TabPane tabId={skillType.personal}>
            <PersonalSkills />
          </TabPane>
        </TabContent>
      </Col>
    </Row>
  );
}
