import React, { useContext, useState } from "react";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import { getIn } from "formik";

import { Button, AccessControl } from "ui/common";
import { SkillsActionType } from "ui/account/Skills/reducers";
import { Skill } from "ui/account/Skills/types";
import {
  ScoringGuides,
  ScoringGuide,
  ScoringGuideSection
} from "ui/account/types";
import SkillsContext from "util/context/SkillsContext";
import UserContext, { ProviderValues } from "util/context/UserContext";
import styles from "./ScoreGuide.module.scss";
import ScoreGuideModal from "./ScoreGuideModal";
import { Subjects, AccessControlActions } from "ui/common/types";
import { getLocalized } from "util/localizationUtil";

type ScoreGuideProps = {
  item: Skill;
};

export default function ScoreGuide(props: ScoreGuideProps) {
  const { item } = props;

  const { account }: ProviderValues = useContext(UserContext);
  const dispatch = useContext(SkillsContext);
  const [activeTab, setActiveTab] = useState(0);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  const toggleTab = (tab: number) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const editScoreGuide = (scoringGuides: ScoringGuides) => {
    dispatch({
      type: SkillsActionType.EditScoreGuide,
      payload: {
        skillId: item.id,
        scoringGuides
      }
    });
  };

  return (
    <>
      <Col xs="12" className={styles.scoreGuide}>
        <Row>
          <Col xs="auto" className={styles.title}>
            Score guide
          </Col>
          <Col className="text-right mb-3">
            <AccessControl.Can
              resource={Subjects.ManageSkillsScoreGuide}
              action={AccessControlActions.EditAny}
            >
              <Button
                className="small-button primary-text text-button"
                onClick={handleModalToggle}
              >
                {getLocalized("action.edit")}
              </Button>
            </AccessControl.Can>
          </Col>
        </Row>
        <div className={styles.wrap}>
          <Row>
            <Col xs="auto" className={styles.scrollGradient}>
              <Nav tabs vertical className={styles.navTabs}>
                {item.scoringGuide &&
                  item.scoringGuide.data.map(
                    (scoreGuide: ScoringGuide, index: number) => {
                      const scoreGuideItem = getIn(
                        account,
                        `preferences.ratingScale[${scoreGuide.ratingScaleIndex}]`
                      );
                      return (
                        <NavItem className={styles.navItem} key={index}>
                          <NavLink
                            className={`${styles.navLink} ${
                              activeTab === index ? styles.active : ""
                            }`}
                            onClick={() => toggleTab(index)}
                          >
                            {scoreGuide
                              ? `${scoreGuideItem.ratingLabel} (${scoreGuideItem.ratingLetter})`
                              : ""}
                          </NavLink>
                        </NavItem>
                      );
                    }
                  )}
              </Nav>
            </Col>
            <Col className="pl-md-0 pl-3">
              <TabContent className={styles.tabContent} activeTab={activeTab}>
                {item.scoringGuide &&
                  item.scoringGuide.data.map(
                    (scoreGuide: ScoringGuide, index: number) => {
                      return (
                        <TabPane tabId={index} key={index}>
                          <Row>
                            <Col xs="12">
                              <div className={styles.description}>
                                <label className={styles.subTitle}>
                                  {getLocalized("common.description")}
                                </label>
                                <p>{scoreGuide.description}</p>
                              </div>
                              {scoreGuide.sections
                                ? scoreGuide.sections.map(
                                    (
                                      section: ScoringGuideSection,
                                      innerIndex: number
                                    ) => (
                                      <div
                                        className={styles.section}
                                        key={innerIndex}
                                      >
                                        <label className={styles.subTitle}>
                                          {section.heading}
                                        </label>
                                        <p>{section.content}</p>
                                      </div>
                                    )
                                  )
                                : null}
                            </Col>
                          </Row>
                        </TabPane>
                      );
                    }
                  )}
              </TabContent>
            </Col>
          </Row>
        </div>
      </Col>
      <ScoreGuideModal
        isOpen={modalOpen}
        onToggle={handleModalToggle}
        skill={item}
        item={item.scoringGuide.data}
        actionType={"EDIT"}
        editAction={editScoreGuide}
      />
    </>
  );
}
