import React, { useEffect, useState, useContext } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from "reactstrap";
import { Field, Formik, Form, FormikActions } from "formik";
import * as Yup from "yup";
import { InputCustom, Button, Toast } from "ui/common";
import { modalActionTypes } from "../../../../../constants";
import { ModalItem, ModalActionTypes } from "ui/account/types";
import { Skill, SubSkill } from "ui/account/Skills/types";
import UserContext from "util/context/UserContext";
import { apiResponseStatuses } from "api/core";
import { entityStatus } from "ui/account/constants";
import { updateSubSkill, createSubSkill } from "services/subSkills";
import { getLocalized } from "util/localizationUtil";

type SubSkillsModalProps = {
  skill: Skill;
  item?: SubSkill;
  isOpen: boolean;
  createAction: (subSkill: SubSkill) => void;
  editAction: (subSkill: SubSkill) => void;
  actionType: ModalActionTypes;
  onToggle: (actionType?: ModalActionTypes) => void;
};
type SubSkillsFormValues = {
  name: string;
};

export default function SubSkillsModal(props: SubSkillsModalProps) {
  const getModalTitle = (actionType: ModalActionTypes, skill: Skill) => {
    return `${
      actionType === modalActionTypes.EDIT
        ? getLocalized("sub_skill.edit")
        : getLocalized("sub_skill.add")
    } - ${skill.name}`;
  };

  const getModalActionTitle = (actionType: ModalActionTypes) => {
    return actionType === modalActionTypes.EDIT
      ? getLocalized("action.save")
      : getLocalized("action.add");
  };

  const { isOpen, onToggle, actionType, skill } = props;
  const { accountId } = useContext(UserContext);
  const [actionTypePersist, setActionTypePersist] = useState<ModalActionTypes>(
    actionType
  );

  useEffect(() => {
    isOpen && setActionTypePersist(actionType);
  }, [isOpen, actionType]);

  const getInitialValues = () => {
    const item = props.item as ModalItem<SubSkill>;
    if (item) {
      return {
        name: item.name
      };
    }
    return {
      name: ""
    };
  };

  const handleToggle = () => onToggle(actionTypePersist);

  const handleExit = () => {
    setActionTypePersist(props.actionType);
  };

  const handleSubmit = async (
    values: SubSkillsFormValues,
    { setSubmitting, setStatus }: FormikActions<SubSkillsFormValues>
  ) => {
    const item = props.item as ModalItem<SubSkill>;

    const requestBody = {
      skillId: skill.id,
      name: values.name,
      title: values.name,
      description: values.name,
      status: entityStatus.active
    };
    const { status, data } =
      actionType === modalActionTypes.EDIT && item
        ? await updateSubSkill(accountId, skill.id, item.id, requestBody)
        : await createSubSkill(accountId, skill.id, requestBody);
    if (status === apiResponseStatuses.success) {
      handleToggle();
      setStatus(null);
      if (actionType === modalActionTypes.EDIT) {
        props.editAction(data);
        Toast.success(getLocalized("sub_skill.update_success"));
      } else {
        props.createAction(data);
        Toast.success(getLocalized("sub_skill.add_success"));
      }
    } else {
      let failToast = modalActionTypes.EDIT
        ? getLocalized("sub_skill.update_failed")
        : getLocalized("sub_skill.add_failed");
      Toast.error(failToast);
      setStatus(data.message || getLocalized("common.something_went_wrong"));
    }
    setSubmitting(false);
  };

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} onExit={handleExit}>
      <ModalHeader toggle={handleToggle}>
        {getModalTitle(actionTypePersist, skill)}
      </ModalHeader>
      <Formik
        onSubmit={handleSubmit}
        initialValues={getInitialValues()}
        enableReinitialize
        validationSchema={subSkillModalValidations}
        render={({ status }) => {
          return (
            <Form noValidate>
              <ModalBody>
                <div>
                  <Alert color="danger" isOpen={!!status}>
                    {status}
                  </Alert>
                </div>
                <Field
                  required
                  id="name"
                  label={getLocalized("sub_skill.name")}
                  name="name"
                  component={InputCustom}
                />
              </ModalBody>
              <ModalFooter>
                <Button size="small" type="submit" className="primary">
                  {getModalActionTitle(actionTypePersist)}
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      />
    </Modal>
  );
}

const subSkillModalValidations = Yup.object({
  name: Yup.string().required(getLocalized("required.sub_skill_name"))
});
