import React, { ReactNode, useContext, Fragment } from "react";
import UserContext, { getDefaultMember } from "util/context/UserContext";
import { AccessRole } from "../../types";

type OnlySubordinateHierarchyProps = {
  children?: ReactNode;
  memberId?: string;
  accessRole?: string;
  skipCheck?: boolean;
  no?: () => ReactNode;
};

OnlySubordinateHierarchy.defaultProps = {
  skipCheck: false,
  no: () => <Fragment></Fragment>
};

export default function OnlySubordinateHierarchy(
  props: OnlySubordinateHierarchyProps
) {
  const { memberId, children, skipCheck, accessRole, no } = props;
  const { user } = useContext(UserContext);

  const currentUserDefaultMembership = getDefaultMember(user);
  const isManager = currentUserDefaultMembership
    ? currentUserDefaultMembership.accessRole === AccessRole.Manager
    : false;

  const isMember = currentUserDefaultMembership
    ? currentUserDefaultMembership.accessRole === AccessRole.Member
    : false;

  const isManagedByMe =
    isManager &&
    currentUserDefaultMembership &&
    memberId &&
    currentUserDefaultMembership.subordinates.includes(memberId);

  const isViewingAMember =
    accessRole === AccessRole.Member.toString().toLowerCase();

  const renderContent = () => {
    if (skipCheck) {
      return children;
    } else if (isManager) {
      if (isManagedByMe || isViewingAMember) {
        return children;
      } else {
        return no ? no() : <Fragment></Fragment>;
      }
    } else {
      if (isMember) {
        return no ? no() : <Fragment></Fragment>;
      }
      return children;
    }
  };

  return <Fragment>{renderContent()}</Fragment>;
}
