import {
  getUrl,
  CREATE_SUB_SKILL,
  UPDATE_SUB_SKILL,
  DELETE_SUB_SKILL
} from "config/endpoints";
import { postRequest, putRequest, deleteRequest } from "api";

export const createSubSkill = async (
  accountId: string,
  skillId: string,
  bodyParams: { [param: string]: any }
): Promise<any> => {
  const url = getUrl(CREATE_SUB_SKILL, { accountId, skillId });
  return postRequest(url, null, bodyParams);
};

export const updateSubSkill = async (
  accountId: string,
  skillId: string,
  subSkillId: string,
  bodyParams: { [param: string]: any }
): Promise<any> => {
  const url = getUrl(UPDATE_SUB_SKILL, { accountId, skillId, subSkillId });
  return putRequest(url, null, bodyParams);
};

export const deleteSubSkill = async (
  accountId: string,
  skillId: string,
  subSkillId: string
): Promise<any> => {
  const url = getUrl(DELETE_SUB_SKILL, { accountId, skillId, subSkillId });
  return deleteRequest(url);
};
