import React, { useContext, ReactNode } from "react";
import UserContext, { getDefaultMember } from "util/context/UserContext";
import { AccessRole } from "ui/common/types";

type OnlyAuthorProps = {
  children?: ReactNode;
  authorId?: string;
  skipCheck?: boolean;
};

OnlyAuthor.defaultProps = {
  skipCheck: false
};

export default function OnlyAuthor(props: OnlyAuthorProps) {
  const { authorId, children, skipCheck } = props;
  const { user } = useContext(UserContext);

  const currentUserDefaultMembership = getDefaultMember(user);

  const isEitherManagerOrMember = currentUserDefaultMembership
    ? currentUserDefaultMembership.accessRole === AccessRole.Member ||
      currentUserDefaultMembership.accessRole === AccessRole.Manager
    : false; /* does not apply for ADMINs or OWNERs */

  const isAuthor =
    currentUserDefaultMembership &&
    authorId &&
    authorId === currentUserDefaultMembership.id;

  return isAuthor || skipCheck || !isEitherManagerOrMember ? (
    <>{children}</>
  ) : (
    <></>
  );
}
