import { useContext, useEffect } from "react";
import PermissionContext from "util/context/PermissionContext";
import { getPermissions } from "services/permissions";
import history from "util/history";
import UserContext, { getDefaultMember } from "util/context/UserContext";
import { apiResponseStatuses } from "api/core";

export default function HydratePermissions() {
  const { onGetPermissions, permissions } = useContext(PermissionContext);
  const { user, onLogout } = useContext(UserContext);

  useEffect(() => {
    const handleInvalidateAndLogout = () => {
      onLogout();
      history.push("/login");
    };
    const fetchPermissions = async () => {
      const defaultMembership = getDefaultMember(user);
      if (defaultMembership) {
        if (!permissions || Object.keys(permissions).length <= 0) {
          const {
            status: getPermissionsStatus,
            data: permissionData
          } = await getPermissions(defaultMembership.accountId);

          if (getPermissionsStatus === apiResponseStatuses.success) {
            onGetPermissions(permissionData);
          } else {
            handleInvalidateAndLogout();
          }
        }
      }
    };
    fetchPermissions();
  }, [onGetPermissions, onLogout, permissions, user]);

  return null;
}
