import { Skill, SkillGroup } from "./types";
import { EntityStatus } from "ui/account/types";

export enum SkillsActionType {
  Initialize = "INITIALIZE",
  AddSubSkill = "sub_skill.add",
  EditSubSkill = "sub_skill.edit",
  DeleteSubSkill = "DELETE_SUB_SKILL",
  ToggleSkillStatus = "TOGGLE_SKILL_STATUS",
  ToggleSkillGroup = "TOGGLE_SKILL_GROUP",
  EditTagline = "EDIT_TAGLINE",
  EditScoreGuide = "EDIT_SCORE_GUIDE"
}

type SkillsActionPayload = any;

export type SkillsReducerAction = {
  type?: SkillsActionType;
  payload?: SkillsActionPayload;
};

export type SkillsState = {
  skills: {
    byId: { [key: string]: Skill };
    allIds: string[];
  };
};

export const skillsInitialState = {
  skills: { byId: {}, allIds: [] }
};

export const skillsReducer = (
  state: SkillsState,
  action: SkillsReducerAction
) => {
  switch (action.type) {
    case SkillsActionType.Initialize /* action.payload : Skill[] */:
      return action.payload.reduce(
        (accumulator: SkillsState, currentValue: Skill) => ({
          ...accumulator,
          skills: {
            ...accumulator.skills,
            byId: {
              ...accumulator.skills.byId,
              [currentValue.id]: currentValue
            },
            allIds: [...accumulator.skills.allIds, currentValue.id]
          }
        }),
        skillsInitialState
      );
    case SkillsActionType.AddSubSkill:
      return {
        ...state,
        skills: {
          ...state.skills,
          byId: {
            ...state.skills.byId,
            [action.payload.skillId]: {
              ...state.skills.byId[action.payload.skillId],
              subSkills: [
                ...state.skills.byId[action.payload.skillId].subSkills,
                action.payload.data
              ]
            }
          }
        }
      };
    case SkillsActionType.EditSubSkill:
      const editSubSkillIndex = state.skills.byId[
        action.payload.skillId
      ].subSkills.findIndex(
        subSkill => subSkill.id === action.payload.subSkillId
      );
      return {
        ...state,
        skills: {
          ...state.skills,
          byId: {
            ...state.skills.byId,
            [action.payload.skillId]: {
              ...state.skills.byId[action.payload.skillId],
              subSkills: Object.assign(
                [],
                state.skills.byId[action.payload.skillId].subSkills,
                { [editSubSkillIndex]: action.payload.data }
              )
            }
          }
        }
      };
    case SkillsActionType.DeleteSubSkill:
      const newSubSkillArray = state.skills.byId[
        action.payload.skillId
      ].subSkills.filter(subSkill => subSkill.id !== action.payload.subSkillId);
      return {
        ...state,
        skills: {
          ...state.skills,
          byId: {
            ...state.skills.byId,
            [action.payload.skillId]: {
              ...state.skills.byId[action.payload.skillId],
              subSkills: newSubSkillArray
            }
          }
        }
      };
    case SkillsActionType.ToggleSkillStatus:
      return {
        ...state,
        skills: {
          ...state.skills,
          byId: {
            ...state.skills.byId,
            [action.payload.skillId]: {
              ...state.skills.byId[action.payload.skillId],
              status:
                state.skills.byId[action.payload.skillId].status ===
                EntityStatus.Active
                  ? EntityStatus.Inactive
                  : EntityStatus.Active
            }
          }
        }
      };
    case SkillsActionType.ToggleSkillGroup:
      return {
        ...state,
        skills: {
          ...state.skills,
          byId: {
            ...state.skills.byId,
            [action.payload.skillId]: {
              ...state.skills.byId[action.payload.skillId],
              group:
                state.skills.byId[action.payload.skillId].group ===
                SkillGroup.Fundamental
                  ? SkillGroup.Structural
                  : SkillGroup.Fundamental
            }
          }
        }
      };
    case SkillsActionType.EditTagline:
      return {
        ...state,
        skills: {
          ...state.skills,
          byId: {
            ...state.skills.byId,
            [action.payload.skillId]: {
              ...state.skills.byId[action.payload.skillId],
              title: action.payload.skillTitle,
              description: action.payload.skillDescription
            }
          }
        }
      };
    case SkillsActionType.EditScoreGuide:
      return {
        ...state,
        skills: {
          ...state.skills,
          byId: {
            ...state.skills.byId,
            [action.payload.skillId]: {
              ...state.skills.byId[action.payload.skillId],
              scoringGuide: action.payload.scoringGuides
            }
          }
        }
      };
    default:
      return state;
  }
};
