import React from "react";
import { Toast, ToastHeader } from "reactstrap";
import { TypeOptions } from "react-toastify";
import styles from "./ToastBody.module.scss";

type ToastBodyProps = {
  closeToast: () => void;
  bodyText: string;
  type: TypeOptions;
};
export default function ToastBody({
  closeToast,
  bodyText,
  type /* change style based on type */ /* 'info' | 'success' | 'warning' | 'error' | 'default'; */
}: ToastBodyProps) {
  return (
    <Toast className={styles.reactToast} isOpen={true}>
      <ToastHeader toggle={closeToast}>{bodyText}</ToastHeader>
    </Toast>
  );
}
