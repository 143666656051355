import { apiResponseStatuses } from "api/core";
import { Field, FieldArray, Form, Formik, FormikActions, getIn } from "formik";
import { default as React, useContext, useEffect, useState } from "react";
import { Alert, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { updateSkill } from "services/skills";
import { Skill } from "ui/account/Skills/types";
import {
  ModalActionTypes,
  ModalItem,
  ScoringGuide,
  ScoringGuides,
  ScoringGuideSection
} from "ui/account/types";
import { Accordion, Button, InputCustom, Toast } from "ui/common";
import Card from "ui/common/Card/index";
import UserContext, { ProviderValues } from "util/context/UserContext";
import * as Yup from "yup";
import styles from "./ScoreGuideModal.module.scss";
import { getLocalized } from "util/localizationUtil";

type OpenedSection = { [id: string]: boolean };

type ScoreGuideModalProps = {
  skill: Skill;
  item?: ScoringGuide[];
  isOpen: boolean;
  actionType: ModalActionTypes;
  onToggle: (actionType?: ModalActionTypes) => void;
  editAction: (scoringGuides: ScoringGuides) => void;
};

export default function ScoreGuideModal(props: ScoreGuideModalProps) {
  const { isOpen, onToggle, actionType, item, skill, editAction } = props;
  const { account }: ProviderValues = useContext(UserContext);
  const scoreGuides = item as ModalItem<ScoringGuide[]>;

  const getInitialOpenedSections = () => {
    const openedSections: any = [];

    scoreGuides &&
      // eslint-disable-next-line array-callback-return
      scoreGuides.map(({ index }: { index: number }) => {
        openedSections[index] = false;
      });

    return openedSections;
  };
  const [openedSections, setOpenedSections] = useState<OpenedSection>(
    getInitialOpenedSections
  );

  const toggleCollapsible = (id: string) => {
    const index = parseInt(id);
    const isOpen = openedSections[index];
    setOpenedSections({
      [index]: !isOpen
    });
  };

  const { accountId } = useContext(UserContext);
  const [actionTypePersist, setActionTypePersist] = useState<ModalActionTypes>(
    actionType
  );

  useEffect(() => {
    isOpen && setActionTypePersist(actionType);
  }, [isOpen, actionType]);

  const handleSubmit = async (
    values: any, //ScoreGuideFormValues,
    { setSubmitting, setStatus }: FormikActions<any>
  ) => {
    const { skill } = props;
    if (skill) {
      const scoreGuide = {
        scoringGuide: { data: [...values.scoreGuides] }
      };

      const { status, data } = await updateSkill(
        accountId,
        skill.id,
        scoreGuide
      );

      if (status === apiResponseStatuses.success) {
        handleToggle();
        setStatus(null);
        item && editAction({ data: [...values.scoreGuides] });
        Toast.success(getLocalized("score_guide.update_success"));
      } else {
        setStatus(data.message || getLocalized("common.something_went_wrong"));
        Toast.error(getLocalized("score_guide.update_failed"));
      }
    }
    setSubmitting(false);
  };

  const handleToggle = () => {
    setOpenedSections(getInitialOpenedSections());
    onToggle(actionTypePersist);
  };

  const handleExit = () => {
    setActionTypePersist(props.actionType);
  };

  const getModalTitle = (skill: Skill) => {
    return `${getLocalized("score_guide.edit")} - ${skill.name}`;
  };

  return (
    <Modal size="lg" isOpen={isOpen} toggle={handleToggle} onExit={handleExit}>
      <ModalHeader toggle={handleToggle}>
        {skill && getModalTitle(skill)}
      </ModalHeader>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ scoreGuides }}
        enableReinitialize
        validationSchema={scoreGuideModalValidations}
        render={({ values, status, errors }) => {
          return (
            <Form noValidate>
              <ModalBody>
                <div>
                  <Alert color="danger" isOpen={!!status}>
                    {status}
                  </Alert>
                </div>
                <FieldArray
                  name="scoreGuides"
                  render={() =>
                    values.scoreGuides &&
                    values.scoreGuides.length > 0 &&
                    values.scoreGuides.map(
                      (value: ScoringGuide, index: number) => {
                        const scoreGuideItem = getIn(
                          account,
                          `preferences.ratingScale[${value.ratingScaleIndex}]`
                        );
                        return (
                          <Accordion
                            key={index}
                            index={index}
                            item={value}
                            isOpen={openedSections[index]}
                            onClick={toggleCollapsible}
                            renderHeader={() => (
                              <h4>{`${scoreGuideItem.ratingLabel} (${scoreGuideItem.ratingLetter})`}</h4>
                            )}
                            renderBody={() => (
                              <div key={index}>
                                <Field
                                  required
                                  id={`scoreGuides[${index}].description`}
                                  multiline="true"
                                  type="textarea"
                                  label={getLocalized("common.description")}
                                  name={`scoreGuides[${index}].description`}
                                  component={InputCustom}
                                />
                                {value.sections &&
                                  value.sections.map(
                                    (
                                      section: ScoringGuideSection,
                                      i: number
                                    ) => (
                                      <div key={i}>
                                        <p className={styles.title}>
                                          {getLocalized("common.behavior")}
                                        </p>
                                        <Card>
                                          <Field
                                            required
                                            id={`scoreGuides[${index}].sections[${i}].heading`}
                                            multiline="true"
                                            label={getLocalized(
                                              "common.heading"
                                            )}
                                            name={`scoreGuides[${index}].sections[${i}].heading`}
                                            component={InputCustom}
                                          />
                                          <Field
                                            required
                                            id={`scoreGuides[${index}].sections[${i}].content`}
                                            multiline="true"
                                            type="textarea"
                                            label={getLocalized(
                                              "common.content"
                                            )}
                                            name={`scoreGuides[${index}].sections[${i}].content`}
                                            component={InputCustom}
                                            maxLength="1000"
                                          />
                                        </Card>
                                      </div>
                                    )
                                  )}
                              </div>
                            )}
                          />
                        );
                      }
                    )
                  }
                />
              </ModalBody>
              <ModalFooter>
                <Button size="small" type="submit" className="primary">
                  {getLocalized("action.update")}
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      />
    </Modal>
  );
}

const scoreGuideModalValidations = Yup.object({
  scoreGuides: Yup.array().of(
    Yup.object().shape({
      description: Yup.string().required(getLocalized("required.description")),
      sections: Yup.array().of(
        Yup.object().shape({
          heading: Yup.string().required(
            getLocalized("required.behavior_heading")
          ),
          content: Yup.string().required(
            getLocalized("required.behavior_content")
          )
        })
      )
    })
  )
});
