import { Field } from "formik";
import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { Button, Icon } from "ui/common";
import FileUploadFormik from "ui/common/FileUploadFormik";
import InputCustom from "ui/common/InputCustom";
import { getNotesAttachmentFileMimeTypeString } from "util/file/FileHelper";
import { AttachmentType } from "../constants";
import styles from "./NotesAttachmentWidget.module.scss";
import { getLocalized } from "util/localizationUtil";

interface Props {
  setFieldError: any;
  setFieldValue: any;
}

interface File {
  file: any;
  isValid: boolean;
  validationMessage: string;
}

const AttachmentsWidget = (props: Props) => {
  const [attachmentType, setType] = useState<AttachmentType>(
    AttachmentType.None
  );
  const [linkValue, setLinkValue] = useState<string>();
  const [file, setFile] = useState<File>();

  const removeAttachment = () => {
    setFile(undefined);
    setLinkValue(undefined);
    setType(AttachmentType.None);
    props.setFieldValue("url", "");
    props.setFieldValue("attachment", "");
  };

  const generateContent = () => {
    if (attachmentType === AttachmentType.None)
      return (
        <Row form className={styles.widget}>
          <Col>
            <Button type="button" onClick={() => setType(AttachmentType.Link)}>
              Attach Link
            </Button>
          </Col>
          <Col>
            <Button type="button" onClick={() => setType(AttachmentType.File)}>
              Attach File
            </Button>
          </Col>
        </Row>
      );

    let removeContent = (icon: string, title: string) => (
      <>
        <Button
          type="button"
          className="icon-button dark small-button"
          title={title}
          onClick={removeAttachment}
        >
          <Icon name={icon} />
        </Button>
      </>
    );

    if (attachmentType === AttachmentType.Link)
      return (
        <Row form>
          <Col>
            <Field
              required
              type="url"
              name="url"
              component={InputCustom}
              placeholder={getLocalized("common.link_place_holder")}
            />
          </Col>
          <Col xs="auto" className={styles.close}>
            {!linkValue && removeContent("close", "Remove")}
          </Col>
        </Row>
      );

    if (attachmentType === AttachmentType.File)
      return (
        <Row form>
          <Col>
            <Field
              name="upload"
              valueFieldName="attachment"
              className={
                file ? (file.isValid ? "" : "validation-error-button") : ""
              }
              component={FileUploadFormik}
              fileState={file}
              updateFileState={setFile}
              accept={getNotesAttachmentFileMimeTypeString()}
            />
          </Col>
          <Col xs="auto" className={styles.close}>
            {file
              ? removeContent("close", "Remove file")
              : removeContent("close", "Back")}
          </Col>
        </Row>
      );
  };

  return <>{generateContent()}</>;
};

export default AttachmentsWidget;
