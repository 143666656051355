import React, { ReactNode } from "react";
import { Modal, ModalBody, ModalFooter, Alert } from "reactstrap";
import { Button, Icon } from "ui/common";
import { Action } from "./types";
import styles from "./ConfirmationModal.module.scss";

type ConfirmationModalProps<T> = {
  onToggle: (open: boolean) => void;
  isOpen: boolean;
  bodyContent: ReactNode | string;
  titleContent: ReactNode | string;
  cancelTitle?: string;
  confirmTitle?: string;
  onCancel?: () => void;
  onExit: () => void /* onExit modal to persist item to avoid ui issues on transition */;
  onConfirm?: () => void;
  confirmError?: string | null;
  className?: string;
  item?: any;
  renderBody: (item?: any) => ReactNode;
  renderTitle?: (item?: any) => ReactNode;
};

export default function ConfirmationModal<T extends Action>(
  props: ConfirmationModalProps<T>
) {
  const {
    onToggle,
    isOpen,
    renderBody,
    renderTitle,
    item,
    onCancel,
    onConfirm,
    cancelTitle,
    confirmTitle,
    confirmError,
    onExit
  } = props;

  const toggle = () => {
    onToggle(!isOpen);
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} onExit={onExit}>
        <ModalBody>
          <Alert color="warning" isOpen={!!confirmError}>
            {confirmError}
          </Alert>
          <div className={styles.icon}>
            <Icon name="warning" />
          </div>
          {renderTitle ? (
            <div className={styles.topAlert}>{renderTitle(item)}</div>
          ) : (
            ""
          )}
          <p className={styles.bodyText}>{renderBody(item)}</p>
        </ModalBody>
        <ModalFooter>
          {cancelTitle && (
            <Button className="secondary line" onClick={onCancel}>
              {cancelTitle}
            </Button>
          )}
          {confirmTitle && (
            <Button className="primary" onClick={onConfirm}>
              {confirmTitle}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
}

ConfirmationModal.defaultProps = {
  bodyContent: "",
  titleContent: "",
  confirmError: null
};
