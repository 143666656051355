import React from "react";
import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  MarkSeries,
  VerticalGridLines,
  HorizontalGridLines,
  DecorativeAxis
} from "react-vis";

type Coordinate = {
  x: number;
  y: number;
};

type SingleMarkQuadrantProps = {
  data: Coordinate;
  quadrantValues?: Coordinate[];
  yDomain: [number, number];
  xDomain: [number, number];
  markSize: number;
  selectedMarkSize: number;
  axisTickTotal: number;
  xLabel: string;
  yLabel: string;
};

export default function SingleMarkQuadrant(props: SingleMarkQuadrantProps) {
  const {
    yDomain,
    xDomain,
    axisTickTotal,
    data,
    markSize,
    selectedMarkSize,
    quadrantValues
  } = props;

  return (
    <FlexibleXYPlot
      yDomain={yDomain}
      xDomain={xDomain}
      margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
    >
      <VerticalGridLines tickTotal={axisTickTotal * 2} />
      <HorizontalGridLines tickTotal={axisTickTotal * 2} />

      <DecorativeAxis
        axisStart={{ x: 0, y: yDomain[0] }}
        axisEnd={{ x: 0, y: yDomain[1] }}
        axisDomain={xDomain}
        tickSize={0}
        tickValue={(t: any) => ""}
        style={{
          line: { stroke: "#000" }
        }}
      />

      <DecorativeAxis
        axisStart={{ x: xDomain[0], y: 0 }}
        axisEnd={{ x: xDomain[1], y: 0 }}
        axisDomain={yDomain}
        tickSize={0}
        tickValue={(t: any) => ""}
        style={{
          line: { stroke: "#000" }
        }}
      />

      <XAxis
        tickTotal={axisTickTotal}
        hideTicks={true}
        style={{ line: { stroke: "none" } }}
      />
      <YAxis
        tickTotal={axisTickTotal}
        hideTicks={true}
        style={{ line: { stroke: "none" } }}
      />

      <MarkSeries
        data={data}
        size={markSize}
        style={{ stroke: "none", fill: "#6c5feb" }}
      />
      <MarkSeries
        data={data}
        size={selectedMarkSize}
        style={{ stroke: "#6c5feb", fill: "none" }}
      />
      {quadrantValues && quadrantValues.length > 0 && (
        <MarkSeries
          data={quadrantValues}
          size={markSize}
          style={{ stroke: "none", fill: "#bab9c9" }}
        />
      )}
    </FlexibleXYPlot>
  );
}

SingleMarkQuadrant.defaultProps = {
  data: [{ x: 0, y: 0 }],
  yDomain: [0, 10],
  xDomain: [0, 10],
  markSize: 6,
  selectedMarkSize: 8,
  axisTickTotal: 10
};
