import React, { useEffect, useState, useContext } from "react";
import { Col, Alert } from "reactstrap";
import { useParams } from "react-router-dom";
import {
  getIndividualQuadrantGraphData,
  getQuadrantGraphData
} from "services/graphs";

import UserContext from "util/context/UserContext";
import { apiResponseStatuses } from "ui/account/constants";
import { NoDataLabel, Loader } from "ui/common";
import SingleMarkQuadrant from "ui/common/SingleMarkQuadrant";
import styles from "./IndividualQuadrantGraph.module.scss";
import { getLocalized } from "util/localizationUtil";
import { Coordinate } from "ui/account/types";

type IndividualQuadrantGraphProps = {
  refetchData: boolean;
};

export default function IndividualQuadrantGraph(
  props: IndividualQuadrantGraphProps
) {
  const { refetchData } = props;
  const { accountId } = useContext(UserContext);
  const { userId: memberId } = useParams();

  const [coordinates, setCoordinates] = useState();
  const [otherCoordinates, setOtherCoordinates] = useState<Coordinate[]>();
  const [meta, setMeta] = useState();
  const [error, setError] = useState<string>(""); // error messages
  const [isGraphDataFetched, setIsGraphDataFetched] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsGraphDataFetched(false);
      if (accountId && memberId) {
        setError("");
        const { status, data, message } = await getIndividualQuadrantGraphData(
          accountId,
          memberId
        );
        if (status === apiResponseStatuses.success) {
          setCoordinates(data.data);
          setMeta(data.meta);
        } else {
          setError(message);
        }
      }
      setIsGraphDataFetched(true);
    };

    const fetchQuadrantData = async () => {
      if (accountId) {
        setError("");
        const { status, data, message } = await getQuadrantGraphData(
          accountId,
          {}
        );
        if (status === apiResponseStatuses.success) {
          setOtherCoordinates(data.tierData);
        } else {
          setError(message);
        }
      }
    };
    fetchQuadrantData();
    fetchData();
  }, [accountId, memberId]);

  useEffect(() => {
    const fetchData = async () => {
      if (accountId && memberId) {
        setError("");
        setIsGraphDataFetched(false);
        const { status, data, message } = await getIndividualQuadrantGraphData(
          accountId,
          memberId
        );
        if (status === apiResponseStatuses.success) {
          setCoordinates(data.data);
          setMeta(data.meta);
        } else {
          setError(message);
        }
        setIsGraphDataFetched(true);
      }
    };
    refetchData && fetchData();
  }, [accountId, memberId, refetchData]);

  return (
    <Col xs="12" className={styles.individualQuadrantWrap}>
      <span className={styles.x}>{getLocalized("skill.personal")}</span>
      <span className={styles.y}>{getLocalized("skill.technical")}</span>
      {isGraphDataFetched ? (
        coordinates.length > 0 ? (
          <div className={styles.quadrant}>
            <Alert color="danger" isOpen={!!error}>
              {error}
            </Alert>
            <SingleMarkQuadrant
              data={coordinates}
              quadrantValues={otherCoordinates}
              yDomain={meta && [meta.minY, meta.maxY]}
              xDomain={meta && [meta.minX, meta.maxX]}
              xLabel={getLocalized("skill.personal")}
              yLabel={getLocalized("skill.technical")}
            />
          </div>
        ) : (
          <NoDataLabel
            noDataLabel={getLocalized("common.no_individual_data_now")}
          />
        )
      ) : (
        <Loader />
      )}
    </Col>
  );
}
