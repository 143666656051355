import React from "react";
import { Row, Col, Container } from "reactstrap";
import styles from "./AuthLayout.module.scss";
import logo from "ui/App/logo.svg";

export type SighInLayoutProps = {
  formComponent: React.ComponentType;
  children?: React.ReactNode;
};
export default function AuthLayout({
  formComponent,
  ...rest
}: SighInLayoutProps) {
  const FormComponent = formComponent;

  return (
    <div className={`w-100 ${styles.background}`}>
      <Container>
        <Row>
          <Col md="5" className={styles.logo}>
            <img src={logo} alt="Logo" />
          </Col>
        </Row>
        <Row>
          <Col md="5" className={styles.mobileWrap}>
            <div className={styles.authWrapper}>
              <FormComponent {...rest} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
