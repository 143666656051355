import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { Props } from "util/context/UserContext";
import { getDateString, capitalizeFirstLetter } from "util/helpers";
import { getLocalized } from "util/localizationUtil";
import { NoteData } from "..";
import NoteDetailsModal from "../NoteDetailsModal";
import styles from "./NoteListItem.module.scss";
import { Icon } from "ui/common";

export type NoteListItemProps = {
  note: NoteData;
  onNoteDelete: () => void;
} & Props;

export default function NoteListItem(props: NoteListItemProps) {
  const { note, onNoteDelete } = props;
  const [cardDetailsModalOpen, setCardDetailsModalOpen] = useState(false);
  const updatedAtDateString = getDateString(new Date(note.date));

  const getCssClass = (type: string) => {
    switch (type) {
      case "positive":
        return styles.positive;
      case "negative":
        return styles.negative;
      case "constructive":
        return styles.neutral;
      default:
        return "";
    }
  };

  const handleCardClick = () => setCardDetailsModalOpen(true);
  const handleCloseModal = () => setCardDetailsModalOpen(false);

  return (
    <>
      <Col xs="12" onClick={handleCardClick} className={styles.noteStripe}>
        <Row>
          <Col sm="10">
            <label>
              {note.title}
              {note.url ? (
                <Icon
                  title={getLocalized("common.link_attached")}
                  name="link"
                />
              ) : null}
              {note.filePath ? (
                <Icon title={getLocalized("file.attached")} name="attachment" />
              ) : null}
            </label>
            <p>
              <span
                className={`${styles.badge} ${getCssClass(
                  note.type.toLowerCase()
                )}`}
              >
                {capitalizeFirstLetter(note.type)}
              </span>
              {note.description}
            </p>
          </Col>
          <Col sm="2">
            <div className="text-right">
              <small>{updatedAtDateString}</small>
            </div>
            <div className="text-right"></div>
          </Col>
        </Row>
      </Col>
      <NoteDetailsModal
        isOpen={cardDetailsModalOpen}
        toggle={handleCloseModal}
        onSuccess={onNoteDelete}
        item={note}
      />
    </>
  );
}
