import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { getNotesList, NotesQueryParams } from "services/notes";
import { AccessControl, Button, NoDataLabel } from "ui/common";
import Pagination, {
  defaultLimit,
  defaultPage
} from "ui/common/SimplePagination";
import { AccessControlActions, Subjects } from "ui/common/types";
import UserContext, {
  ProviderValues as UserProviderValues
} from "util/context/UserContext";
import { noteTypes } from "./constants";
import NoteListItem from "./NoteListItem";
import styles from "./Notes.module.scss";
import NotesModal from "./NotesModal";
import { getLocalized } from "util/localizationUtil";

type NoteType = "POSITIVE" | "NEGATIVE" | "CONSTRUCTIVE" | null;

type Author = {
  id: string;
  user: { firstName: string; lastName: string; id: string };
};
export type NoteData = {
  id: string;
  memberId: string;
  author: Author;
  title: string;
  description: string;
  type: string;
  visibility: string;
  date: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  archivedAt: string;
  filePath: string;
  url: string;
};

type NotesProps = {
  userId: string;
};

const getNotesQuery = (
  currentPage: number,
  pageSize: number,
  memberId: string | undefined,
  noteFilter: string | null
) => {
  const query: NotesQueryParams = {
    page: `${currentPage - 1}:${pageSize}`,
    "filter[memberId]": memberId
  };
  if (noteFilter) {
    query["filter[type]"] = noteFilter;
  }
  if (memberId) {
    query["filter[memberId]"] = memberId;
  }
  return query;
};

export default function Notes(props: NotesProps) {
  const { accountId }: UserProviderValues = useContext(UserContext);

  const { userId: memberId } = useParams();

  const [notes, setNotes] = useState<NoteData[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(defaultPage);
  const [pageSize, setPageSize] = useState<number>(defaultLimit);
  const [noteFilter, setNoteFilter] = useState<NoteType>(null);
  const [totalNotes, setTotalNotes] = useState<number>(0);
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(
    false
  ); /* when user adds a new note and updated the list, re-fetch list */

  useEffect(() => {
    const query = getNotesQuery(currentPage, pageSize, memberId, noteFilter);
    const fetchData = async () => {
      const { data } = await getNotesList(accountId, query);
      setNotes(data.results);
      setTotalNotes(data.total);
    };
    fetchData();
    setForceRefresh(false);
  }, [accountId, currentPage, memberId, noteFilter, pageSize, forceRefresh]);

  useEffect(() => {
    let page = currentPage;
    if (page > defaultPage && notes.length === 0) {
      page--;
      // resets page count to default page if notes count become zero in the non default page.
      setCurrentPage(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes.length]);

  const handleSetNoteFilter = (filter: NoteType) => {
    setNoteFilter(filter);
    setCurrentPage(defaultPage);
  };

  const handleNoteChange = () => setForceRefresh(true);

  return (
    <Col xs="12" className={styles.notesWrapper}>
      <Row>
        <Col xs="auto" className={styles.title}>
          {getLocalized("note.plural")}
        </Col>
        <Col className="text-right mb-3">
          <AccessControl.IsMyProfile
            memberId={memberId}
            yes={children => (
              <AccessControl.Can
                resource={Subjects.ProfileNote}
                action={AccessControlActions.AddOwn}
              >
                {children}
              </AccessControl.Can>
            )}
            no={children => (
              <AccessControl.Can
                resource={Subjects.ProfileNote}
                action={AccessControlActions.AddAny}
              >
                {children}
              </AccessControl.Can>
            )}
          >
            <Button
              className="text-button primary-text small-button"
              onClick={() => setNoteModalOpen(true)}
            >
              {getLocalized("note.add")}
            </Button>
          </AccessControl.IsMyProfile>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <div className={styles.tabButtons}>
            <Button
              onClick={() => handleSetNoteFilter(null)}
              className={!noteFilter ? styles.active : ""}
            >
              {getLocalized("common.all")}
            </Button>
            <Button
              onClick={() => handleSetNoteFilter(noteTypes.positive)}
              className={noteFilter === noteTypes.positive ? styles.active : ""}
            >
              {getLocalized("note.type.positive")}
            </Button>
            <Button
              onClick={() => handleSetNoteFilter(noteTypes.negative)}
              className={noteFilter === noteTypes.negative ? styles.active : ""}
            >
              {getLocalized("note.type.negative")}
            </Button>
            <Button
              onClick={() => handleSetNoteFilter(noteTypes.constructive)}
              className={
                noteFilter === noteTypes.constructive ? styles.active : ""
              }
            >
              {getLocalized("note.type.constructive")}
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {notes && notes.length > 0 ? (
            <div className={styles.notesMarginCatcher}>
              {notes.map((note: NoteData) => {
                return (
                  <NoteListItem
                    key={note.id}
                    note={note}
                    onNoteDelete={handleNoteChange}
                  />
                );
              })}
            </div>
          ) : (
            <NoDataLabel noDataLabel={getLocalized("note.no_result_now")} />
          )}
        </Col>
      </Row>
      {totalNotes > 3 && (
        <Row>
          <Col>
            <Pagination
              totalResults={totalNotes}
              currentPage={currentPage}
              pageSize={pageSize}
              onChangePageSize={setPageSize}
              onSetCurrentPage={setCurrentPage}
            />
          </Col>
        </Row>
      )}
      <NotesModal
        isOpen={noteModalOpen}
        onToggle={setNoteModalOpen}
        onSuccess={handleNoteChange}
      />
    </Col>
  );
}
