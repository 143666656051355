import React, { useContext, useState } from "react";
import { Col, CustomInput, Spinner, Row } from "reactstrap";
import { SkillsActionType } from "ui/account/Skills/reducers";
import { Skill, SubSkill } from "ui/account/Skills/types";
import { EntityStatus } from "ui/account/types";
import SkillsContext from "util/context/SkillsContext";
import styles from "./SkillsHeader.module.scss";
import { updateSkill } from "services/skills";
import UserContext from "util/context/UserContext";
import { apiResponseStatuses } from "api/core";
import useCan from "util/hooks/useCan";
import { AccessControlActions, Subjects } from "ui/common/types";

export type SkillsHeaderProps = {
  isOpened: boolean;
  item: Skill;
};

export default function SkillsHeader(props: SkillsHeaderProps) {
  const { isOpened, item } = props;
  const { accountId } = useContext(UserContext);
  const dispatch = useContext(SkillsContext);

  const canActivateSkill = useCan({
    resource: Subjects.ManageSkillsMainSkill,
    action: AccessControlActions.Activate
  });

  const [isStatusChangeLoading, setIsStatusChangeLoading] = useState(false);

  const handleChange = async () => {
    setIsStatusChangeLoading(true);
    const requestBody = {
      status:
        item.status === EntityStatus.Active
          ? EntityStatus.Inactive
          : EntityStatus.Active
    };
    const { status } = await updateSkill(accountId, item.id, requestBody);
    if (status === apiResponseStatuses.success) {
      dispatch({
        type: SkillsActionType.ToggleSkillStatus,
        payload: { skillId: props.item.id }
      });
    } else {
    }
    setIsStatusChangeLoading(false);
  };

  return (
    <Row
      className={`${styles.skillsHeader} align-items-bottom align-items-md-center`}
    >
      <Col md="3" lg="3" xl="2">
        <p className={styles.name}>{item.name}</p>
      </Col>
      <Col>
        {!isOpened ? (
          <div className={styles.badgeContainer}>
            {item.subSkills.map((subSkill: SubSkill, index: number) => (
              <span className={styles.badge} key={index}>
                {subSkill.name}
              </span>
            ))}
          </div>
        ) : null}
      </Col>
      <Col xs="auto">
        {isStatusChangeLoading ? (
          <Spinner size="sm" color="primary" />
        ) : (
          <CustomInput
            type="checkbox"
            id={item.id}
            checked={item.status === EntityStatus.Active ? true : false}
            onChange={handleChange}
            disabled={!canActivateSkill}
          />
        )}
      </Col>
    </Row>
  );
}
