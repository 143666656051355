import React, { useState, useContext } from "react";
import {
  Link as RouterLink,
  Redirect,
  Route,
  Switch,
  useLocation
} from "react-router-dom";
import { Col, Container, Nav, NavLink, Row } from "reactstrap";
import shortLogo from "ui/App/logo-short.svg";
import logo from "ui/App/logo.svg";
import { Icon, PrivateRoute, NoDataLabel } from "ui/common";
import { routes } from "../routes";
import styles from "./AccountLayout.module.scss";
import Appbar from "./Appbar";
import { AccessControl } from "ui/common";
import { AccessControlActions, Subjects } from "ui/common/types";
import UserContext, { getDefaultMember } from "util/context/UserContext";
import { sidebarTopRoutes, sidebarBottomRoute } from "./sidebarRoutes";
import { slugify } from "util/helpers";
import { getLocalized } from "util/localizationUtil";

export default function Sidebar() {
  const location = useLocation();
  const { user } = useContext(UserContext);
  let topRoutes = sidebarTopRoutes;

  const [collapsed, setCollapsed] = useState(
    /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  );

  const activeRoute = (routeName: string) => {
    return location.pathname.indexOf(routeName) > -1;
  };

  const defaultMember = getDefaultMember(user);
  const defaultMemberAccessRole = defaultMember ? defaultMember.accessRole : "";
  const defaultMemberId = defaultMember ? defaultMember.id : "";

  const profileUrl = `/profile/${slugify(
    defaultMemberAccessRole
  )}/${defaultMemberId}/me`;

  const myTeamRoute = {
    url: `/my-team`,
    name: "My Team",
    action: AccessControlActions.VisitOwn,
    icon: "supervisor_account",
    page: Subjects.ProfilePage // TODO: to introduce new subjects to handle my team view
  };

  if (defaultMemberAccessRole === "MANAGER") {
    let [first, ...rest] = topRoutes;
    topRoutes = [first, myTeamRoute, ...rest];
  }

  const sideBarBottomRoutesWithProfile = [
    ...sidebarBottomRoute,
    {
      url: profileUrl,
      name: getLocalized("common.profile"),
      action: AccessControlActions.VisitOwn,
      icon: "supervisor_account",
      page: Subjects.ProfilePage
    }
  ];
  return (
    <Container fluid className={styles.layout}>
      <Row>
        {defaultMemberAccessRole !== "MEMBER" && (
          <Col
            md="2"
            className={`${styles.sideBar} ${collapsed ? styles.closed : ""}`}
            key="sideBar"
          >
            <Row>
              <Col xs="12" className={styles.logo}>
                {collapsed ? (
                  <img className={styles.short} alt="avatar" src={shortLogo} />
                ) : (
                  <img className={styles.long} alt="avatar" src={logo} />
                )}
              </Col>
            </Row>
            <Nav className={styles.navWrap} vertical pills>
              <>
                {topRoutes.map((route: any, index: number) => (
                  <AccessControl.Can
                    resource={route.page}
                    action={AccessControlActions.VisitAny}
                    key={index}
                  >
                    <NavLink
                      className={`${styles.pill} ${
                        activeRoute(route.url) ? styles.active : ""
                      }`}
                      key={route.url}
                      tag={RouterLink}
                      to={route.url}
                      active={activeRoute(route.url)}
                    >
                      <Icon name={route.icon} />
                      <span>{route.name}</span>
                    </NavLink>
                  </AccessControl.Can>
                ))}

                {sideBarBottomRoutesWithProfile.map(
                  (route: any, index: number) => (
                    <AccessControl.Can
                      resource={route.page}
                      action={route.action || AccessControlActions.VisitAny}
                      key={index}
                    >
                      <NavLink
                        className={`${styles.pill} ${
                          activeRoute(route.url) ? styles.active : ""
                        }`}
                        key={route.url}
                        tag={RouterLink}
                        to={route.url}
                        active={activeRoute(route.url)}
                      >
                        <Icon name={route.icon} />
                        <span>{route.name}</span>
                      </NavLink>
                    </AccessControl.Can>
                  )
                )}
              </>
            </Nav>
            <Row>
              <Col>
                <button
                  className={`${styles.toggle} ${
                    collapsed ? styles.closed : ""
                  }`}
                  onClick={() => {
                    setCollapsed(!collapsed);
                  }}
                >
                  <Icon
                    cssClass={styles.desktop}
                    name={collapsed ? "arrow_right" : "arrow_left"}
                  />
                </button>
              </Col>
            </Row>
            {/* <Row>
              <Col xs="12" className={styles.version}>
                <small>{`Version: ${process.env.REACT_APP_VERSION}`}</small>
              </Col>
            </Row> */}
          </Col>
        )}
        <Col>
          <Row>
            <Col
              className={`
                ${styles.appBarWrap} 
                ${collapsed ? styles.closed : ""}
                ${
                  defaultMemberAccessRole === "MEMBER" ? styles.fullWidth : "" //TODO: use permissions
                }`}
            >
              <Appbar />
            </Col>
          </Row>
          <main
            className={`
                ${collapsed ? styles.closed : ""}
                ${
                  defaultMemberAccessRole === "MEMBER" ? styles.fullWidth : "" //TODO: use permissions
                }`}
          >
            {" "}
            {/* styles.fullWidth is used to make the profile view to full width */}
            <div className={styles.background} />
            <Col xs="12">
              <Switch>
                <Route exact path="/">
                  {defaultMemberAccessRole === "MEMBER" ? ( //TODO: use permissions
                    <Redirect to={profileUrl} />
                  ) : (
                    <Redirect to="/dashboard" />
                  )}
                </Route>
                {defaultMemberAccessRole === "MEMBER" && ( //TODO: use permissions
                  <Route exact path="/dashboard">
                    <Redirect to={profileUrl} />
                  </Route>
                )}
                {routes.map(({ path, exact, component: Component, page }) => (
                  <PrivateRoute
                    key={path}
                    path={path}
                    exact={exact}
                    page={page}
                  >
                    <Component />
                  </PrivateRoute>
                ))}
                <Route path="*">
                  <NoDataLabel
                    noDataLabel={getLocalized("common.page_not_found")}
                  />
                </Route>
              </Switch>
            </Col>
          </main>
        </Col>
        <Col xs="12">
          {/* Footer consisting of the version number */}
          <Row>
            <Col xs="12" className={styles.version}>
              <small>{`Version: ${process.env.REACT_APP_VERSION}`}</small>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
