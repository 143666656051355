// Data table related types
import { modalActionTypes } from "./constants";
import { ReportData } from "ui/account/types";

export type UserData = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  group: string;
  memberships: [null];
  createdAt: Date;
  updatedAt: Date;
  archivedAt: Date;
};

export interface TabSetting {
  name: string;
  key: string;
}

export type ManagerData = {
  id: string;
  accessRole: string;
  accountId: string;
  userId: string;
  default: boolean;
  designationId: string;
  managerId: string;
  permissions: {};
  preferences: {};
  user: UserData;
  status: string;
  archivedAt: Date;
  createdAt: Date;
  updatedAt: Date;
};

export type MemberData = {
  id: string;
  accountId: string;
  employeeId: string;
  user: UserData;
  default: boolean;
  designation: DesignationData;
  manager: ManagerData;
  accessRole: string;
  permissions: {};
  preferences: {};
  skills: any[]; // change
  scores: {};
  status: string;
  createdAt: Date;
  updatedAt: Date;
  archivedAt: Date;
};

export type DesignationData = {
  [key: string]: any;
  id: string;
  title: string;
  description: string;
  tier: {
    [key: string]: any;
    id: string;
    title: string;
  };
  status: string;
  createdAt: Date;
  updatedAt: Date;
  archivedAt: Date;
};

export type TierDesignationData = {
  [key: string]: any;
  id: string;
  title: string;
  description: string;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  archivedAt: Date;
};

export type TierData = {
  [key: string]: any;
  id: string;
  title: string;
  description: string;
  designations: TierDesignationData[];
  status: string;
  createdAt: Date;
  updatedAt: Date;
  archivedAt: Date;
};

export type TableData = DesignationData | TierData | MemberData | ReportData; // add all types of table data

export type Nullable<T> = T | null;
export type ModalActionTypes = keyof typeof modalActionTypes;
export type ModalItem<T> = Nullable<T>;

export type ModalProps<T> = {
  item: ModalItem<T>;
  actionType: ModalActionTypes;
  isOpen: boolean;
  onToggle: (
    actionType?: ModalActionTypes | null,
    currentItem?: TableData | null,
    forceRefresh?: boolean | null
  ) => void;
};

export type ActionModalProps = ModalProps<TableData>;

// Graph Data
export type QuadrantData = {
  data: QuadrantValue[];
  meta: GraphMeta;
};

export type QuadrantValue = {
  x: number;
  y: number;
  user: {
    memberId: string;
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: string;
  };
  designation: {
    id: string;
    title: string;
    description: string;
  };
  manager: {
    id: string;
    accessRole: string;
    user: {
      id: string;
      firstName: string;
      lastName: string;
      email: string;
    };
  };
  member: {
    id: string;
  };
};

export type DistributionData = {
  coordinates: DistributionCoordinates;
  data: DistributionList;
  meta: GraphMeta;
};

export type DistributionCoordinates = {
  allEmployees: Coordinate[];
  bottomFive: Coordinate[];
  topFive: Coordinate[];
};

export type DistributionList = {
  bottomFive: ReportData[];
  topFive: ReportData[];
};

export type Coordinate = {
  x: number;
  y: number;
};

export type GraphMeta = {
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
  topLeft: number;
  topRight: number;
  bottomLeft: number;
  bottomRight: number;
};

export enum AccessRole {
  Owner = "OWNER",
  Admin = "ADMIN",
  Manager = "MANAGER",
  Member = "MEMBER"
}

export enum Subjects {
  ManageUsersPage = "ManageUsersPage",
  ManageUsersMember = "ManageUsers:Member",
  ProfilePage = "ProfileDetailsPage",
  profilePersonalInfo = "ProfileDetails:PersonalInfo",
  ProfileQuadrant = "ProfileDetails:Quadrant",
  ProfileProgression = "ProfileDetails:Progression",
  ProfileSkill = "ProfileDetails:Skill",
  ProfileGoal = "ProfileDetails:Goal",
  ProfileNote = "ProfileDetails:Note",
  DashboardPage = "DashboardPage",
  DashboardQuadrant = "Dashboard:Quadrant",
  ManageSkillsPage = "ManageSkillsPage",
  ManageSkillsMainSkill = "ManageSkills:MainSkill",
  ManageSkillsMainSkillDescription = "ManageSkills:MainSkillDescription",
  ManageSkillsScoreGuide = "ManageSkills:ScoreGuide",
  ManageSkillsSubSkill = "ManageSkills:SubSkill",
  ManageTiersPage = "ManageTiersPage",
  ManageJobRolesPage = "ManageJobRolesPage",
  SettingsPage = "SettingsPage",
  ReportsPage = "ReportsPage"
}

export enum AccessControlActions {
  AddAny = "AddAny",
  AddOwn = "AddOwn",
  ViewAny = "ViewAny",
  ViewOwn = "ViewOwn",
  EditAny = "EditAny",
  EditOwn = "EditOwn",
  DeleteAny = "DeleteAny",
  DeleteOwn = "DeleteOwn",
  VisitAny = "VisitAny",
  VisitOwn = "VisitOwn",
  Activate = "Activate",
  Filter = "Filter",
  Search = "Search"
}
