import React, { useState, useEffect, useMemo, useContext } from "react";
import { Row, Col, Alert } from "reactstrap";
import { Table, ButtonActionModalHolder, Toast } from "ui/common";
import TiersModal from "./TiersModal";
import { getTiersList, deleteTier } from "services/tiers";
import { TierData, TierDesignationData } from "../types";
import { apiResponseStatuses, tableEntityTypes } from "../constants";
import UserContext from "util/context/UserContext";
import {
  DEFAULT_ORDER_BY,
  DEFAULT_ORDER_DIRECTION
} from "ui/common/Table/constants";
import { getLocalized } from "util/localizationUtil";

export default function Tiers() {
  const { accountId } = useContext(UserContext);
  const [tiersList, setTiersList] = useState<Array<TierData>>([]);
  const [error, setError] = useState<string>("");
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    // fetch tiers list on component mount
    const queryParams = {
      sort: `${DEFAULT_ORDER_BY}:${DEFAULT_ORDER_DIRECTION}`
    };
    const fetchData = async () => {
      setIsFetching(true);
      const { status, data } = await getTiersList(accountId, queryParams);
      if (status === apiResponseStatuses.success) {
        setTiersList(data);
      } else {
        setError(data.message);
      }
      setIsFetching(false);
      setForceRefresh(false); // set to false after fetch
    };
    fetchData();
  }, [accountId, forceRefresh]);

  function handleReload(forceRefresh: boolean) {
    setForceRefresh(forceRefresh);
  }

  // Table headers
  const headers = useMemo(
    () => [
      {
        name: "Name",
        selector: "title",
        sortable: true,
        grow: 1
      },
      {
        name: "Description",
        selector: "description",
        sortable: false,
        grow: 2
      },
      {
        name: "Job Roles",
        selector: "designations",
        sortable: false,
        format: (row: TierData) => {
          if (row.designations) {
            return formatDesignationArray(row.designations);
          }
        },
        grow: 4
      }
    ],
    []
  );

  // format designation array to designation string
  const formatDesignationArray = (data: Array<TierDesignationData>): string => {
    let arrayString = "";
    let formattedArray: Array<string> = [];
    data.forEach(item => {
      if (item.hasOwnProperty("description")) {
        formattedArray.push(item["description"]);
      }
    });

    arrayString = formattedArray.join(", ");
    return arrayString;
  };

  const handleDelete = (tierId: string) => {
    const fetchData = async () => {
      const { status, message } = await deleteTier(accountId, tierId);
      if (status === apiResponseStatuses.success) {
        Toast.success("Tier deleted successfully");
      } else {
        setError(message);
        Toast.error("Tier deletion failed");
      }
      setForceRefresh(true);
    };
    fetchData();
  };

  return (
    <Row>
      <Col sm="8" />
      <Col sm="4" className="text-right mb-3">
        <ButtonActionModalHolder
          label={getLocalized("tier.add_new")}
          actionModal={TiersModal}
          onReload={handleReload}
        />
      </Col>
      <div>
        <Alert color="danger" isOpen={!!error}>
          {error}
        </Alert>
      </div>
      <Col xs="12">
        <Table
          headers={headers}
          tableData={tiersList}
          entityType={tableEntityTypes.TIERS}
          actionModal={TiersModal}
          onReload={handleReload}
          deleteAction={handleDelete}
          deleteContent={getLocalized("warning.delete_tier")}
          noDataText={getLocalized("tier.no_result_now")}
          isFetching={isFetching}
        />
      </Col>
    </Row>
  );
}
