import React, { useEffect, useState, useContext } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from "reactstrap";
import { Field, Formik, Form, FormikActions } from "formik";
import * as Yup from "yup";

import { InputCustom, Button, Toast } from "ui/common";
import { updateSkill } from "services/skills";
import { ModalItem, ModalActionTypes } from "ui/account/types";
import { Skill as SkillData } from "ui/account/Skills/types";
import UserContext from "util/context/UserContext";
import { apiResponseStatuses } from "api/core";
import { getLocalized } from "util/localizationUtil";

type TaglineFormValues = {
  skillTagline: string;
  skillDescription: string;
};

type TaglineModalProps = {
  item?: SkillData;
  isOpen: boolean;
  actionType: ModalActionTypes;
  onToggle: (actionType?: ModalActionTypes) => void;
  editAction: (skill: SkillData) => void;
};

export default function TaglineModal(props: TaglineModalProps) {
  const { isOpen, onToggle, actionType, editAction, item } = props;

  const { accountId } = useContext(UserContext);
  const [actionTypePersist, setActionTypePersist] = useState<ModalActionTypes>(
    actionType
  );

  useEffect(() => {
    isOpen && setActionTypePersist(actionType);
  }, [isOpen, actionType]);

  const getInitialValues = () => {
    const item = props.item as ModalItem<SkillData>;
    if (item) {
      return {
        skillTagline: item.title,
        skillDescription: item.description
      };
    }
    return {
      skillTagline: "",
      skillDescription: ""
    };
  };

  const handleToggle = () => onToggle(actionTypePersist);

  const handleExit = () => {
    setActionTypePersist(props.actionType);
  };

  const handleSubmit = async (
    values: TaglineFormValues,
    { setSubmitting, setStatus }: FormikActions<TaglineFormValues>
  ) => {
    const item = props.item as ModalItem<SkillData>;
    if (item) {
      const requestBody = {
        title: values.skillTagline,
        description: values.skillDescription
      };

      const { status, data } = await updateSkill(
        accountId,
        item.id,
        requestBody
      );

      if (status === apiResponseStatuses.success) {
        handleToggle();
        setStatus(null);
        item && // dispatch action to update values in reducer
          editAction({
            ...item,
            title: values.skillTagline,
            description: values.skillDescription
          });
        Toast.success(getLocalized("sub_skill.update_success"));
      } else {
        setStatus(data.message || getLocalized("common.something_went_wrong"));
        Toast.error(getLocalized("sub_skill.update_failed"));
      }
    }
    setSubmitting(false);
  };

  const getModalTitle = (skill: SkillData) => {
    return `${getLocalized("tag_line.edit")} - ${skill.name}`;
  };

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} onExit={handleExit}>
      <ModalHeader toggle={handleToggle}>
        {item && getModalTitle(item)}
      </ModalHeader>
      <Formik
        onSubmit={handleSubmit}
        initialValues={getInitialValues()}
        enableReinitialize
        validationSchema={taglineModalValidations}
        render={({ status }) => {
          return (
            <Form noValidate>
              <ModalBody>
                <div>
                  <Alert color="danger" isOpen={!!status}>
                    {status}
                  </Alert>
                </div>
                <Field
                  required
                  id="skillTagline"
                  label={getLocalized("tag_line")}
                  name="skillTagline"
                  component={InputCustom}
                />
                <Field
                  id="skillDescription"
                  multiline="true"
                  type="textarea"
                  label={getLocalized("common.description")}
                  name="skillDescription"
                  component={InputCustom}
                />
              </ModalBody>
              <ModalFooter>
                <Button size="small" type="submit" className="primary">
                  {getLocalized("action.save")}
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      />
    </Modal>
  );
}

const taglineModalValidations = Yup.object({
  skillTagline: Yup.string().required(getLocalized("required.tag_line"))
});
