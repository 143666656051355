import React, { ReactNode, useContext } from "react";
import * as Yup from "yup";
import { useHistory } from "react-router-dom"; // useLocation
import { Formik, Field, Form, FormikActions } from "formik";
import { Link as RouterLink } from "react-router-dom";
import InputCustom from "ui/common/InputCustom";
import Button from "ui/common/Button";
import { NavLink, Row, Col, Alert } from "reactstrap";
import UserContext, {
  ProviderValues,
  getDefaultMember
} from "util/context/UserContext";
import { login } from "services/auth";
import styles from "./SignInForm.module.scss";
import { apiResponseStatuses } from "api/core";
import PermissionContext from "util/context/PermissionContext";
import { getPermissions } from "services/permissions";
import { getLocalized } from "util/localizationUtil";

export type SignInFormProps = {
  children?: ReactNode;
};
export type SignInFormValues = {
  email: string;
  password: string;
};

const passwordRegEx = /^(?=.*[a-zA-Z])(?=.*[0-9])/; /* contains both number and letter (at least) */

const SignInForm = (props: SignInFormProps) => {
  const signInFormValidations = Yup.object({
    email: Yup.string()
      .email(getLocalized("email.invalid"))
      .trim()
      .required(getLocalized("required.email")),
    password: Yup.string()
      .min(8, getLocalized("password_vdn.invalid_length"))
      .matches(passwordRegEx, getLocalized("password_vdn.invalid"))
      .trim()
      .required(getLocalized("password_vdn.required"))
  });

  const history = useHistory();
  // const location = useLocation();
  const { onLogin }: ProviderValues = useContext(UserContext);
  const { onGetPermissions } = useContext(PermissionContext);

  const handleSubmit = async (
    values: SignInFormValues,
    actions: FormikActions<SignInFormValues>
  ) => {
    // const { from } = location.state || { from: { pathname: "/" } };
    const { status, data } = await login(values);
    if (status === apiResponseStatuses.success) {
      const defaultMembership = getDefaultMember(data);
      if (defaultMembership && defaultMembership.accountId) {
        const {
          status: getPermissionsStatus,
          data: permissionData
        } = await getPermissions(defaultMembership.accountId);

        if (getPermissionsStatus === apiResponseStatuses.success) {
          actions.setStatus(null);
          onLogin(data);
          onGetPermissions(permissionData);

          history.push({ pathname: "/" });
        } else {
          actions.setStatus(getLocalized("common.permissions_fetch_failed"));
        }
      } else {
        actions.setStatus(getLocalized("common.login_failed_prompt"));
      }
    } else {
      actions.setStatus(data.message);
    }
    actions.setSubmitting(false);
  };

  return (
    <>
      <Row>
        <Col className={styles.title} xs="12">
          <h1>{getLocalized("common.account_login")}</h1>
        </Col>
      </Row>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          email: "",
          password: ""
        }}
        validationSchema={signInFormValidations}
        render={({ isSubmitting, status }) => (
          <>
            <Alert color="danger" isOpen={!!status}>
              {status}
            </Alert>
            <Form noValidate>
              <Field
                required
                type="email"
                label={getLocalized("email.your")}
                name="email"
                component={InputCustom}
              />
              <Field
                required
                type="password"
                label={getLocalized("password_text.your")}
                name="password"
                component={InputCustom}
              />
              <Row form>
                <Col xs="12" className="text-center">
                  <Button
                    className={`${styles.submit} primary`}
                    loading={isSubmitting}
                  >
                    {getLocalized("common.login")}
                  </Button>
                </Col>
                <Col className={`text-center ${styles.forgot}`}>
                  <NavLink
                    className="p-0 text-muted"
                    tag={RouterLink}
                    to="/forgot-password"
                  >
                    {getLocalized("password_text.forgot_your")}
                  </NavLink>
                </Col>
              </Row>
            </Form>
          </>
        )}
      />
    </>
  );
};

export default SignInForm;
