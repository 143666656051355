import { useEffect, useContext } from "react";
import { refresh } from "services/auth";
import { apiResponseStatuses } from "api/core";
import history from "util/history";
import UserContext, {
  ProviderValues,
  getDefaultMember
} from "util/context/UserContext";
import { getPermissions } from "services/permissions";
import PermissionContext from "util/context/PermissionContext";

/* whenever user logs in the token will be refreshed and new user info will be retrieved */
export default function RefreshTokenHandler() {
  const { onLogin, onLogout }: ProviderValues = useContext(UserContext);
  const { onGetPermissions } = useContext(PermissionContext);

  const handleInvalidateAndLogout = () => {
    onLogout();
    history.push("/login");
  };

  useEffect(() => {
    const fetchData = async () => {
      const { status, data } = await refresh();
      if (status === apiResponseStatuses.success) {
        const defaultMembership = getDefaultMember(data);
        if (defaultMembership && defaultMembership.accountId) {
          const {
            status: getPermissionsStatus,
            data: permissionData
          } = await getPermissions(defaultMembership.accountId);

          if (getPermissionsStatus === apiResponseStatuses.success) {
            onLogin(data);
            onGetPermissions(permissionData);
          } else {
            handleInvalidateAndLogout();
          }
        } else {
          handleInvalidateAndLogout();
        }
      } else {
        if (data.status === 401) {
          handleInvalidateAndLogout();
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
