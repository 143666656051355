import { Field, Form, Formik, FormikActions } from "formik";
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import { createNote, uploadNoteAttachment } from "services/notes";
import { DatePickerCustom, SelectCustom, Toast } from "ui/common";
import Button from "ui/common/Button";
import CheckboxCustom from "ui/common/CheckboxCustom";
import InputCustom from "ui/common/InputCustom";
import UserContext from "util/context/UserContext";
import { getDateString } from "util/helpers";
import { getLocalized } from "util/localizationUtil";
import * as Yup from "yup";
import { NoteData } from "..";
import {
  AttachmentType,
  File,
  noteTypes,
  URL_REGEX_OPTIONAL_PREFIX
} from "../constants";
import { apiResponseStatuses } from "./../../../../api/core";
import { entityStatus } from "./../../constants";
import AttachmentsWidget from "./NotesAttachmentWidget";

type NoteType = "POSITIVE" | "NEGATIVE" | "CONSTRUCTIVE";

type NotesFormValues = {
  type?: { value: NoteType; label: string };
  title: string;
  date: string;
  description: string;
  showToEmployee: boolean;
  attachment?: File;
  url?: string;
  attachmentType?: AttachmentType;
};
type NotesModalProps = {
  onToggle: (open: boolean) => void;
  isOpen: boolean;
  item?: NoteData;
  onSuccess: () => void;
};

export const noteVisibility = Object.freeze({
  public: "PUBLIC",
  private: "PRIVATE"
});

export default function NotesModal(props: NotesModalProps) {
  const { onToggle, isOpen, onSuccess } = props;
  const { accountId } = useContext(UserContext);
  const { userId: memberId } = useParams();

  const noteTypeOptions = [
    {
      value: noteTypes.positive,
      label: getLocalized("note.type.positive")
    },
    {
      value: noteTypes.negative,
      label: getLocalized("note.type.negative")
    },
    {
      value: noteTypes.constructive,
      label: getLocalized("note.type.constructive")
    }
  ];

  const getInitialValues = () => {
    const item = props.item as NoteData;
    if (item) {
      return {
        type: noteTypeOptions.find(option => option.value === item.type),
        date: new Date(item.updatedAt).toISOString().split("T")[0],
        title: item.title,
        description: item.description,
        showToEmployee: item.visibility === noteVisibility.public
      };
    }
    return {
      type: noteTypeOptions[0],
      date: new Date().toISOString().split("T")[0],
      title: "",
      description: "",
      showToEmployee: true
    };
  };

  const handleToggle = (resetForm: () => void) => {
    resetForm();
    onToggle(!isOpen);
  };

  const saveNoteData = async (
    values: NotesFormValues,
    setSubmitting: any,
    resetForm: any,
    setStatus: any,
    filePath?: string
  ) => {
    const requestBody = {
      memberId,
      title: values.title,
      description: values.description,
      type: values.type!.value,
      visibility: values.showToEmployee
        ? noteVisibility.public
        : noteVisibility.private,
      date: new Date(values.date).toISOString(),
      status: entityStatus.active,
      url: values.url ? values.url : null,
      filePath: filePath
    };

    const { status, data } = await createNote(accountId, requestBody);
    if (status === apiResponseStatuses.success) {
      handleToggle(resetForm);
      onSuccess();
      setStatus(null);
      Toast.success(getLocalized("note.add_success"));
    } else {
      setStatus(data.message || getLocalized("common.something_went_wrong"));
      Toast.error(getLocalized("note.add_failed"));
    }
    setSubmitting(false);
  };

  const handleSubmit = async (
    values: NotesFormValues,
    { setSubmitting, resetForm, setStatus }: FormikActions<NotesFormValues>
  ) => {
    if (values.attachment && values.attachment.file) {
      const attachmentFormData = new FormData();
      attachmentFormData.append("file", values.attachment.file);
      const { status, data } = await uploadNoteAttachment(
        accountId,
        attachmentFormData
      );

      if (status === apiResponseStatuses.success) {
        saveNoteData(
          values,
          setSubmitting,
          resetForm,
          setStatus,
          data.filePath
        );
      } else {
        setStatus(data.message || getLocalized("common.something_went_wrong"));
        Toast.error(getLocalized("note.add_failed"));
        setSubmitting(false);
      }
    } else {
      saveNoteData(values, setSubmitting, resetForm, setStatus);
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={getInitialValues()}
      enableReinitialize
      validationSchema={notesValidations}
      render={({
        resetForm,
        values,
        status,
        errors,
        isSubmitting,
        setFieldValue,
        setFieldError
      }) => {
        const enhancedToggle = () => handleToggle(resetForm);
        return (
          <>
            <Modal isOpen={isOpen} toggle={enhancedToggle}>
              <ModalHeader toggle={enhancedToggle}>
                {getLocalized("note.add")}
              </ModalHeader>
              <ModalBody>
                <Form noValidate>
                  <div>
                    <Alert color="danger" isOpen={!!status}>
                      {status}
                    </Alert>
                  </div>
                  <Row>
                    <Col md="6" xs="12">
                      <Field
                        required
                        type="text"
                        label={getLocalized("note.type")}
                        name="type"
                        component={SelectCustom}
                        options={noteTypeOptions}
                        values={values.type}
                      />
                    </Col>
                    <Col md="6" xs="12">
                      <Field
                        required
                        id="date"
                        name="date"
                        label={getLocalized("common.date")}
                        value={getDateString(new Date(values.date))}
                        component={DatePickerCustom}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Field
                        required
                        type="text"
                        label={getLocalized("note.title")}
                        name="title"
                        component={InputCustom}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Field
                        required
                        type="textarea"
                        label={getLocalized("note")}
                        name="description"
                        component={InputCustom}
                        maxLength="1000"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <FormGroup>
                        <label>Attachments</label>
                        <AttachmentsWidget
                          setFieldError={setFieldError}
                          setFieldValue={setFieldValue}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="padding-top-lg">
                    {" "}
                    {/* Added 30px spacing, to be evaluated*/}
                    <Col>
                      <Field
                        required
                        label={getLocalized("common.show_to_employee")}
                        name="showToEmployee"
                        component={CheckboxCustom}
                      />
                    </Col>
                  </Row>
                  <ModalFooter>
                    <Button type="button" onClick={enhancedToggle}>
                      {getLocalized("action.discard")}
                    </Button>
                    <Button
                      type="submit"
                      className="primary"
                      loading={isSubmitting}
                    >
                      {getLocalized("action.add")}
                    </Button>
                  </ModalFooter>
                </Form>
              </ModalBody>
            </Modal>
          </>
        );
      }}
    />
  );
}

const notesValidations = Yup.object({
  type: Yup.mixed().test(
    "hasType",
    getLocalized("required.note_type"),
    type => type && type.value !== undefined
  ),
  title: Yup.string().required(getLocalized("required.note_title")),
  description: Yup.string().required(getLocalized("required.note_desc")),
  showToEmployee: Yup.boolean(),
  date: Yup.date().required(getLocalized("required.date")),
  url: Yup.string().matches(
    URL_REGEX_OPTIONAL_PREFIX,
    getLocalized("common.invalid_url")
  ),
  attachment: Yup.mixed().test(
    "isValidFileType",
    getLocalized("file.invalid"),
    attachment =>
      attachment === undefined || (attachment && attachment.isValid === true)
  )
});
