import { getUrl, GET_SKILLS_URL, UPDATE_SKILL_URL } from "config/endpoints";
import { getRequest, putRequest } from "api";

export const getSkills = async (
  accountId: string,
  queryParams?: any
): Promise<any> => {
  const url = getUrl(GET_SKILLS_URL, { accountId });
  return getRequest(url, queryParams);
};
export const updateSkill = async (
  accountId: string,
  skillId: string,
  bodyParams: { [param: string]: any }
): Promise<any> => {
  const url = getUrl(UPDATE_SKILL_URL, { accountId, skillId });
  return putRequest(url, null, bodyParams);
};
